import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Typography,
  Card,
  CardContent,
  Avatar,
  Grid,
  Chip,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Button,
  Rating,
  Stack,
  Link as MuiLink,
  Breadcrumbs,
  CircularProgress,
  styled,
  TextField, // Added
  Select, // Added
  MenuItem, // Added
  FormControl, // Added
  InputLabel, // Added
} from "@mui/material";
import {
  Edit as EditIcon,
  LocationOn,
  WorkOutline,
  Save as SaveIcon,
  School,
  Assessment,
  LinkedIn,
  Description,
  AccessTime,
  NavigateNext,
  ArrowBack,
} from "@mui/icons-material";
import { Link, useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import { useAuthToken } from "../TokenContext";
import { useIntl } from "react-intl";
import PortfolioList from "./PortfolioList";
import defaultimg from "./assets/defaultimge.png";
import DocumentViewer from "./DocumentViewer";
// Styled components
const StyledContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(12),
  marginBottom: theme.spacing(4),
}));

const ProfileCard = styled(Card)(({ theme }) => ({
  position: "relative",
  marginBottom: theme.spacing(3),
  padding: theme.spacing(3),
}));

const SectionCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  padding: theme.spacing(3),
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(2),
  fontWeight: 600,
}));

const ReviewCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  padding: theme.spacing(2),
}));

const Candidatedetailsbyid = () => {
  const token = useAuthToken();
  const location = useLocation();
  const history = useHistory();
  const intl = useIntl();
  const { c_id } = location.state;

  const [state, setState] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(true);
  const [arrexp, setArrexp] = useState({
    experience: [],
    education: [],
    certification: [],
  });
  const [pod, setPod] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [resume, setResume] = useState('');
  const [assessment, setAssesment] = useState('');
  const [error, setError] = useState('');
  const [authImage, setAuthImage] = useState('');




  const generatePreSignedPutUrl11 = async () => {
    try {
      if (!state?.profilePicture) return;

      const response = await fetch(process.env.REACT_APP_TALENT_CLOUDFRONTAPI, {
        method: "POST",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          url: process.env.REACT_APP_CLOUDFRONT + state.profilePicture,
        }),
      });

      const data = await response.json();
      const signedUrl = JSON.parse(data.body).signedUrl;
      setAuthImage(signedUrl);
    } catch (error) {
      console.error("Error generating signed URL:", error);
      setError("Failed to load profile picture");
    }
  };

  // Update the generateAndSetSignedUrl function
const generateAndSetSignedUrl = async () => {
  try {
    if (!state.resume) return;
    
    // Encode the resume filename to handle special characters like '+'
    const encodedResume = encodeURIComponent(state.resume);
    
    const response = await fetch(process.env.REACT_APP_TALENT_CLOUDFRONTAPI, {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        url: process.env.REACT_APP_CLOUDFRONT + encodedResume,
      }),
    });

    const data = await response.json();
    const signedUrl = JSON.parse(data.body).signedUrl;
    setResume(signedUrl);
  } catch (error) {
    console.error("Error generating signed URL:", error);
    setError("Failed to load resume URL");
  }
};

  const generateAndSetSignedUrlassessment = async () => {
    try {
      if (!state.coding_assessment_url) return;

      const response = await fetch(process.env.REACT_APP_TALENT_CLOUDFRONTAPI, {
        method: "POST",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          url: process.env.REACT_APP_CLOUDFRONT + state.coding_assessment_url,
        }),
      });

      const data = await response.json();
      const signedUrl = JSON.parse(data.body).signedUrl;
      setAssesment(signedUrl);
    } catch (error) {
      console.error("Error generating signed URL:", error);
      setError("Failed to load assessment URL");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const headers = {
          Authorization: token,
          "Content-Type": "application/json",
        };

        const [candidateData, additionalData] = await Promise.all([
          axios.post(
            process.env.REACT_APP_CLIENT_GETCANDIDATEBYID,
            { id: c_id, review: "yes" },
            { headers }
          ),
          axios.post(
            process.env.REACT_APP_CLIENT_GETCANDIDATEDATA,
            { id: c_id },
            { headers }
          ),
        ]);

        setState(candidateData.data.data[0]);
        setPod(additionalData.data.data.podExperience);
        setArrexp({
          experience: additionalData.data.data.exp,
          education: additionalData.data.data.education,
          certification: additionalData.data.data.certification,
        });
        setReviews(candidateData.data.data[0].reviews || []);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [token, c_id]);

  // Generate signed URLs when state is loaded
  useEffect(() => {
    if (state) {
      generatePreSignedPutUrl11();
      generateAndSetSignedUrl();
      generateAndSetSignedUrlassessment();
    }
  }, [state]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const headers = {
          Authorization: token,
          "Content-Type": "application/json",
        };

        const [candidateData, additionalData] = await Promise.all([
          axios.post(
            process.env.REACT_APP_CLIENT_GETCANDIDATEBYID,
            { id: c_id, review: "yes" },
            { headers }
          ),
          axios.post(
            process.env.REACT_APP_CLIENT_GETCANDIDATEDATA,
            { id: c_id },
            { headers }
          ),
        ]);

        setState(candidateData.data.data[0]);
        setPod(additionalData.data.data.podExperience);
        setArrexp({
          experience: additionalData.data.data.exp,
          education: additionalData.data.data.education,
          certification: additionalData.data.data.certification,
        });
        setReviews(candidateData.data.data[0].reviews || []);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [token, c_id]);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!state) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Typography>No data found</Typography>
      </Box>
    );
  }

  const handleInputChange = (field, value) => {
    setState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  // Add this save handler
  const handleSave = async () => {
    try {
      const headers = {
        Authorization: token,
        "Content-Type": "application/json",
      };

      const payload = {
        id: c_id,
        ...state,
      };

      await axios.put(process.env.REACT_APP_TALENT_EDITCANDIDATE, payload, {
        headers,
      });

      setEditMode(false);
      // Optionally refresh data
      window.location.reload();
    } catch (error) {
      console.error("Error saving changes:", error);
      // Handle error (show notification, etc.)
    }
  };
  console.log("srData", state);
  // Update the edit mode button to use the save handler
  const editButton = editMode ? (
    <Button variant="contained" onClick={handleSave} startIcon={<SaveIcon />}>
      Save Changes
    </Button>
  ) : (
    <IconButton onClick={() => setEditMode(true)} sx={{ ml: 2 }}>
      <EditIcon />
    </IconButton>
  );

 
  return (
    <StyledContainer maxWidth="lg">
      {/* Breadcrumbs */}
      <Breadcrumbs separator={<NavigateNext fontSize="small" />} sx={{ mb: 3 }}>
        <MuiLink
          component={Link}
          to="/browse-talent"
          color="inherit"
          sx={{ display: "flex", alignItems: "center" }}
        >
          <ArrowBack sx={{ mr: 0.5 }} fontSize="small" />
          Find Talent
        </MuiLink>
        <Typography color="text.primary">Talent Details</Typography>
      </Breadcrumbs>

      {/* Profile Section */}
      <ProfileCard elevation={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3} display="flex" justifyContent="center">
            <Avatar
              src={
                state.profilePicture
                  ? `https://globldataprod.s3.amazonaws.com/${state.profilePicture}`
                  : defaultimg
              }
              sx={{ width: 150, height: 150 }}
            />
          </Grid>
          <Grid item xs={12} md={9}>
            <Stack spacing={2}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h4" fontWeight="bold">
                  {`${state.firstName} ${state.lastName}`}
                </Typography>
                {editMode ? (
                  <Button
                    variant="contained"
                    onClick={() => setEditMode(false)}
                  >
                    Save Changes
                  </Button>
                ) : (
                  <IconButton onClick={() => setEditMode(true)}>
                    <EditIcon />
                  </IconButton>
                )}
              </Box>
              <Stack direction="row" spacing={1}>
                <Chip
                  icon={<LocationOn />}
                  label={state.country}
                  variant="outlined"
                />
                <Chip
                  icon={<WorkOutline />}
                  label={state.work_position}
                  variant="outlined"
                />
              </Stack>
              <Typography variant="body1">{state.about}</Typography>
            </Stack>
          </Grid>
        </Grid>
      </ProfileCard>

      {/* Skills Section */}
      <SectionCard elevation={2}>
        <SectionTitle variant="h6">Skills & Expertise</SectionTitle>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
              Primary Skills
            </Typography>
            <Chip label={state.primaryskill} color="primary" sx={{ mr: 1 }} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
              Secondary Skills
            </Typography>
            <Chip
              label={state.secondaryskill}
              color="secondary"
              sx={{ mr: 1 }}
            />
          </Grid>
        </Grid>
      </SectionCard>

      {/* Experience Section */}
      <SectionCard elevation={2}>
        <SectionTitle variant="h6">Work Experience</SectionTitle>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Position</TableCell>
                <TableCell>Organization</TableCell>
                <TableCell>Duration</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {arrexp.experience.map((exp, index) => (
                <TableRow key={index}>
                  <TableCell>{exp.work_position}</TableCell>
                  <TableCell>{exp.work_organization}</TableCell>
                  <TableCell>
                    {`${exp.workStartdate} - ${
                      exp.present ? "Present" : exp.workEnddate
                    }`}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </SectionCard>

      {/* Reviews Section */}
      <SectionCard elevation={2}>
        <SectionTitle variant="h6">Reviews & Feedback</SectionTitle>
        <Stack spacing={2}>
          {reviews.map((review, index) => (
            <ReviewCard key={index}>
              <Stack direction="row" spacing={2} alignItems="center" mb={2}>
                <Avatar src={review.profilePicture} />
                <Box>
                  <Typography variant="subtitle1" fontWeight="bold">
                    Client Review
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    {moment(review.r_createdAt).fromNow()}
                  </Typography>
                </Box>
              </Stack>
              <Rating value={Number(review.rating)} readOnly />
              <Typography variant="body1" mt={1}>
                {review.review}
              </Typography>
            </ReviewCard>
          ))}
        </Stack>
      </SectionCard>

      {/* Portfolio Section */}
      <SectionCard elevation={2}>
        <SectionTitle variant="h6">Portfolio</SectionTitle>
        <PortfolioList candidateid={state.c_id} />
      </SectionCard>

      {/* Education Section */}
      <SectionCard elevation={2}>
        <SectionTitle variant="h6">
          {intl.formatMessage({ id: "education" })}
        </SectionTitle>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>Degree</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Institution</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Duration</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {arrexp.education.length > 0 ? (
                arrexp.education.map((edu, index) => (
                  <TableRow key={index}>
                    <TableCell>{edu.degree}</TableCell>
                    <TableCell>{edu.institution}</TableCell>
                    <TableCell>{`${edu.startDate} - ${edu.endDate}`}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    No education data available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </SectionCard>

      {/* Certifications Section */}
      <SectionCard elevation={2}>
        <SectionTitle variant="h6">
          {intl.formatMessage({ id: "certifications" })}
        </SectionTitle>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>
                  Certification Name
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Institution</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Valid Period</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {arrexp.certification.length > 0 ? (
                arrexp.certification.map((cert, index) => (
                  <TableRow key={index}>
                    <TableCell>{cert.cert_name}</TableCell>
                    <TableCell>{cert.certificationInstitution}</TableCell>
                    <TableCell>
                      {`${moment(cert.startDate).format(
                        "YYYY-MM-DD"
                      )} - ${moment(cert.endDate).format("YYYY-MM-DD")}`}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    No certification data available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </SectionCard>

      {/* Pod Experience Section */}
      <SectionCard elevation={2}>
        <SectionTitle variant="h6">
          {intl.formatMessage({ id: "podExperience" })}
        </SectionTitle>
        {pod.length > 0 ? (
          <Grid container spacing={2}>
            {pod.map((data, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Paper
                  elevation={1}
                  sx={{
                    p: 2,
                    textAlign: "center",
                    borderRadius: 2,
                    bgcolor: "background.neutral",
                  }}
                >
                  <Typography variant="subtitle1" fontWeight="medium">
                    {data.podName}
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography color="text.secondary" align="center">
            {intl.formatMessage({ id: "noExperience" })}
          </Typography>
        )}
      </SectionCard>

      {/* Other Information Section */}
      <SectionCard elevation={2}>
        <SectionTitle variant="h6">
          {intl.formatMessage({ id: "otherInformation" })}
        </SectionTitle>
        <Grid container spacing={3}>
          {/* LinkedIn Field */}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <Typography variant="subtitle2" fontWeight="bold" gutterBottom>
                {intl.formatMessage({ id: "linkedIn" })}
              </Typography>
              {editMode ? (
                <TextField
                  fullWidth
                  value={state.linkedin || ""}
                  onChange={(e) =>
                    handleInputChange("linkedin", e.target.value)
                  }
                  placeholder="LinkedIn URL"
                  size="small"
                />
              ) : (
                <MuiLink href={state.linkedin} target="_blank" rel="noopener">
                  {state.linkedin}
                </MuiLink>
              )}
            </FormControl>
          </Grid>

          {/* Preferred Work Hours Field */}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <Typography variant="subtitle2" fontWeight="bold" gutterBottom>
                {intl.formatMessage({ id: "preferredWorkHours" })}
              </Typography>
              {editMode ? (
                <Select
                  value={state.workhours || ""}
                  onChange={(e) =>
                    handleInputChange("workhours", e.target.value)
                  }
                  size="small"
                  displayEmpty
                >
                  <MenuItem value="">Select work hours</MenuItem>
                  <MenuItem value="Morning Shift, 9am - 6pm">
                    Morning Shift, 9am - 6pm
                  </MenuItem>
                  <MenuItem value="Evening Shift, 10pm - 7am">
                    Evening Shift, 10pm - 7am
                  </MenuItem>
                </Select>
              ) : (
                <Typography>{state.workhours}</Typography>
              )}
            </FormControl>
          </Grid>

          {/* Calendly URL Field */}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <Typography variant="subtitle2" fontWeight="bold" gutterBottom>
                {intl.formatMessage({ id: "calendlyUrl" })}
              </Typography>
              {editMode ? (
                <TextField
                  fullWidth
                  value={state.calendlyurl || ""}
                  onChange={(e) =>
                    handleInputChange("calendlyurl", e.target.value)
                  }
                  placeholder="Calendly URL"
                  size="small"
                />
              ) : (
                <MuiLink
                  href={state.calendlyurl}
                  target="_blank"
                  rel="noopener"
                >
                  {state.calendlyurl}
                </MuiLink>
              )}
            </FormControl>
          </Grid>

          {/* Rate Per Hour */}
          <Grid item xs={12} sm={6}>
            <Stack spacing={1}>
              <Typography variant="subtitle2" fontWeight="bold">
                {intl.formatMessage({ id: "ratePerHour" })}
              </Typography>
              <Typography>
                {state.currency} {state.ratePerhour}/hr
              </Typography>
            </Stack>
          </Grid>

          {/* Coding Assessment */}
          <Grid item xs={12} sm={6}>
        <Stack spacing={1}>
          <Typography variant="subtitle2" fontWeight="bold">
            Coding Assessment
          </Typography>
          {state.coding_assessment_url ? (
            <Button
              variant="outlined"
              startIcon={<Assessment />}
              href={assessment}
              target="_blank"
              rel="noopener noreferrer"
              disabled={!assessment}
            >
              View Assessment
            </Button>
          ) : (
            <Typography color="text.secondary">
              No assessment available
            </Typography>
          )}
        </Stack>
      </Grid>
     {/* Resume */}
<Grid item xs={12} sm={6}>
  <Stack spacing={1}>
    <Typography variant="subtitle2" fontWeight="bold">
      {intl.formatMessage({ id: 'resume' })}
    </Typography>
    {state.resume ? (
      <DocumentViewer 
        url={resume}
        buttonText={intl.formatMessage({ id: 'viewResume', defaultMessage: 'View Resume' })}
        filename={state.resume.split('/').pop()}
      />
    ) : (
      <Typography color="text.secondary">
        No resume available
      </Typography>
    )}
  </Stack>
</Grid>
        </Grid>
      </SectionCard>
    </StyledContainer>
  );
};

export default Candidatedetailsbyid;
