import React ,{Component}from "react";
import '../components/css/addcandidate.css';
import axios from 'axios';
// import FormikControl from './formikcontrol';
import Validate from "./utility/FormValidation";
import FormErrors from "./FormErrors";
// import Select from "react-select/src/Select";
import Select from 'react-select';
import { Link } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";
const area = [
   { 
     value: 'Node', label: 'Node'
    },
  { 
    value: 'Java', label: 'Java'
   },
  { 
    value: 'React', label: 'React'
   },
  { 
    value: 'Python', label: 'Python' 
  },
  { 
    value: 'AWS', label: 'AWS' 
  },
  { 
    value: 'C++', label: 'C++' 
  }
]

//for country
const country = [
  { value: 'usa', label: 'USA' },
  { value: 'canada', label: 'Canada' },
  { value: 'india', label: 'India' },
  { value: 'australia', label: 'Australia' },
  { value: 'united_kingdom', label: 'United Kingdom' },
  { value: 'germany', label: 'Germany' },
  { value: 'france', label: 'France' },
  { value: 'china', label: 'China' },
  { value: 'japan', label: 'Japan' },
  { value: 'brazil', label: 'Brazil' },
  { value: 'south_africa', label: 'South Africa' },
  { value: 'mexico', label: 'Mexico' },
  { value: 'italy', label: 'Italy' },
  { value: 'spain', label: 'Spain' },
  { value: 'russia', label: 'Russia' },
  { value: 'south_korea', label: 'South Korea' },
  { value: 'new_zealand', label: 'New Zealand' },
  { value: 'singapore', label: 'Singapore' },
  { value: 'united_arab_emirates', label: 'United Arab Emirates' },
  { value: 'saudi_arabia', label: 'Saudi Arabia' },
  { value: 'argentina', label: 'Argentina' },
  { value: 'netherlands', label: 'Netherlands' },
  { value: 'sweden', label: 'Sweden' },
  { value: 'switzerland', label: 'Switzerland' },
  { value: 'norway', label: 'Norway' },
  { value: 'denmark', label: 'Denmark' },
  { value: 'finland', label: 'Finland' },
  { value: 'portugal', label: 'Portugal' },
  { value: 'poland', label: 'Poland' },
  { value: 'turkey', label: 'Turkey' },
  { value: 'indonesia', label: 'Indonesia' },
  { value: 'malaysia', label: 'Malaysia' },
  { value: 'thailand', label: 'Thailand' },
  { value: 'vietnam', label: 'Vietnam' },
  { value: 'philippines', label: 'Philippines' },
  { value: 'egypt', label: 'Egypt' },
  { value: 'nigeria', label: 'Nigeria' },
  { value: 'kenya', label: 'Kenya' },
  { value: 'ghana', label: 'Ghana' },
  { value: 'colombia', label: 'Colombia' },
  { value: 'chile', label: 'Chile' },
  { value: 'peru', label: 'Peru' },
  { value: 'venezuela', label: 'Venezuela' },
  { value: 'pakistan', label: 'Pakistan' },
  { value: 'bangladesh', label: 'Bangladesh' },
  { value: 'sri_lanka', label: 'Sri Lanka' },
  { value: 'nepal', label: 'Nepal' },
  { value: 'bhutan', label: 'Bhutan' },
  { value: 'maldives', label: 'Maldives' },
  { value: 'iceland', label: 'Iceland' },
  { value: 'luxembourg', label: 'Luxembourg' },
  { value: 'qatar', label: 'Qatar' },
  { value: 'oman', label: 'Oman' },
  { value: 'kuwait', label: 'Kuwait' },
  { value: 'bahrain', label: 'Bahrain' },
  { value: 'iraq', label: 'Iraq' },
  { value: 'iran', label: 'Iran' },
  { value: 'afghanistan', label: 'Afghanistan' },
  { value: 'israel', label: 'Israel' },
  { value: 'jordan', label: 'Jordan' },
  { value: 'lebanon', label: 'Lebanon' },
  { value: 'morocco', label: 'Morocco' },
  { value: 'algeria', label: 'Algeria' },
  { value: 'tunisia', label: 'Tunisia' },
  { value: 'libya', label: 'Libya' },
  { value: 'ethiopia', label: 'Ethiopia' },
  { value: 'tanzania', label: 'Tanzania' },
  { value: 'uganda', label: 'Uganda' },
  { value: 'zimbabwe', label: 'Zimbabwe' },
  { value: 'zambia', label: 'Zambia' },
  { value: 'mozambique', label: 'Mozambique' },
  { value: 'botswana', label: 'Botswana' },
  { value: 'namibia', label: 'Namibia' },
  { value: 'angola', label: 'Angola' },
  { value: 'cuba', label: 'Cuba' },
  { value: 'jamaica', label: 'Jamaica' },
  { value: 'haiti', label: 'Haiti' },
  { value: 'dominican_republic', label: 'Dominican Republic' },
  { value: 'trinidad_and_tobago', label: 'Trinidad and Tobago' },
  { value: 'bahamas', label: 'Bahamas' },
  { value: 'barbados', label: 'Barbados' },
  { value: 'panama', label: 'Panama' },
  { value: 'costa_rica', label: 'Costa Rica' },
  { value: 'honduras', label: 'Honduras' },
  { value: 'guatemala', label: 'Guatemala' },
  { value: 'el_salvador', label: 'El Salvador' },
  { value: 'nicaragua', label: 'Nicaragua' },
  { value: 'belize', label: 'Belize' },
  { value: 'paraguay', label: 'Paraguay' },
  { value: 'uruguay', label: 'Uruguay' },
  { value: 'bolivia', label: 'Bolivia' },
  { value: 'ecuador', label: 'Ecuador' },
  { value: 'guinea', label: 'Guinea' },
  { value: 'ivory_coast', label: 'Ivory Coast' },
  { value: 'cameroon', label: 'Cameroon' },
  { value: 'senegal', label: 'Senegal' },
  { value: 'sudan', label: 'Sudan' },
  { value: 'south_sudan', label: 'South Sudan' },
  { value: 'congo', label: 'Congo' },
  { value: 'madagascar', label: 'Madagascar' },
  { value: 'malawi', label: 'Malawi' },
  { value: 'sierra_leone', label: 'Sierra Leone' },
  { value: 'chad', label: 'Chad' },
  { value: 'mali', label: 'Mali' },
  { value: 'burkina_faso', label: 'Burkina Faso' },
  { value: 'rwanda', label: 'Rwanda' },
  { value: 'burundi', label: 'Burundi' },
  { value: 'lesotho', label: 'Lesotho' },
  { value: 'eswatini', label: 'Eswatini' }
    
]
//new
const industry = [
  { 
    value: ' Healthcare', label: ' Healthcare'
   },
 { 
   value: ' Banking and Financia', label: ' Banking and Financia'
  },
 { 
   value: 'Services', label: 'Services'
  }
]

class Updatecandidate extends Component {

  imageHandler = (e) => {
    const reader = new FileReader();
    reader.onload = () =>{
      if(reader.readyState === 2){
        this.setState({profileImg: reader.result})
      }
    }
    reader.readAsDataURL(e.target.files[0])
  };
  state = {
  fname: "",
  mname:"",
  lname:"",
  candidateemail: "",
  area: "", 
  country:"",
  oexpertise: "",
  portfolio: "",
  linkedin:"",
  github:"",
  resetForm: "",
  profileImg:
	'/https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png',
    errors: {
      blankfield: false,
      passwordmatch: false
    }
  }

  handleChangeindustry = (event) => {
   
    this.setState({ industry : event.value });
  };

  handleChangearea = (event) => {
  
    this.setState({ area : event.value });
  };

  handleChangeexpertise = (event) => {
   
    this.setState({ oexpertise : event.value });
  };

    handleChangecountry = (event) => {
    
    this.setState({ country : event.value });
  };

  clearErrorState = () => {
    this.setState({
      errors: {
        blankfield: false,
        passwordmatch: false
      }
    });
  }

  handleSubmit = async event => {
    event.preventDefault();
    // Form validation
 
    const { fname, mname, lname, candidateemail, area, country, linkedin, github, oexpertise, portfolio  } = this.state;

    console.log("buviviyviyv")
    // Database insert here
    if (fname && mname) {
      // https://zfnddk64nb.execute-api.us-east-1.amazonaws.com/dev/Edit_Candidate
      axios.put(process.env.REACT_APP_TALENT_EDITCANDIDATE, {
        id : this.props.auth.user.c_id,
        firstName: fname, middleName : mname, lastName : lname, primaryskill : area, country : country, linkedinUrl : linkedin, githubUrl : github,
        secondaryskill: oexpertise
      
      }).then(res => {
          // console.log(res);
          // console.log(res.data);
          window.alert("Profile is updated successfully!");
          this.props.history.push("/");
      }, (error) => {
          console.log(error);
      });
  }
  };
  onInputChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }
render() {
  const { profileImg} = this.state
    return (

      <div>
        <h1 className="head">Create your profile to our talent database to be included<br></br> for various on-demand staffing or project opportunities</h1>
<br></br>
     <div className="container" style={{   "box-shadow": "1px 3px 6px #00000029",
  "border": "1px solid #D8D8D8", "paddingBottom" : "40px" }}>
      <br></br>
      <br></br>

      <div className="row">


        <div className="col-lg-3" ></div>
 
          <div className="col-lg-6" >
            
          <h1 className="headsmall">Update profile</h1>

          <br></br>
<form onSubmit={this.handleSubmit}>
            {/* For First Name */}


            <div className="email">
              <input
                className="input" 
                placeholder="Enter First Name"
                type="text"
                id="fname"
                value={this.state.fname}
                onChange={this.onInputChange}
                required
              />
            </div>

            {/* For Middle Name */}
            <div className="email">
              <input
                className="input" 
                placeholder="Enter Middle Name"
                type="text"
                id="mname"
                value={this.state.mname}
                onChange={this.onInputChange}
                required
              />
            </div>

             {/* For Last Name */}
             <div className="email">
              <input
                className="input" 
                placeholder="Enter Last Name"
                type="text"
                id="lname"
                value={this.state.lname}
                onChange={this.onInputChange}
                required
              />
            </div>

            {/* For Email */}
           

            {/* For Primary Expertise */}
            <div className="email">
              <Select
                onChange={this.handleChangearea}
                options={area}
                className="input2"
                id="area"
                placeholder="Primary Expertise"
                isMulti="true"
                value={this.state.area} 
                required
                />
            </div>

            {/* Other Expertise */}
            <div className="password">
              <input             
                className="input"
                placeholder="Any other expertise you would like to list?"
                type="text"
                value={this.state.oexpertise}
                onChange={this.onInputChange}
                id="oexpertise"
              />
            </div>

             {/* For Country */}
             <div className="email">
              <Select
                  //value={area}
                  options={country}
                  className="input2"
                  id="country"
                  placeholder="What's your country"
                  onChange={this.handleChangecountry}

                  />
            </div>

            {/* Portfolio */}
            <div className="password">
              <input
                className="input"
                placeholder="url of your portfolio work"
                type="url"
                value={this.state.portfolio}
                onChange={this.onInputChange}
                id="portfolio"
              />
            </div>

            {/* LinkedIn */}
            <div className="password">
              <input
                className="input"
                placeholder="paste your LinkedIn profile url"
                type="url"
                value={this.state.linkedin}
                onChange={this.onInputChange}
                id="linkedin"
              />
            </div>

            {/* GitHub */}
            <div className="password">
              <input
                className="input"
                placeholder="paste your GitHub url"
                type="url"
                value={this.state.github}
                onChange={this.onInputChange}
                id="github"
              />
            </div>
            
             <div className="email">
              <Select
                  //value={area}
                  onChange={this.handleChangeindustry}
                  options={industry}
                  className="input2"
                  id="industry"
                  placeholder="Industry"
                  />
            </div> 

            
            <div className="createAccount">
              <button className="button is-success" style={{ width : "50%"}} type="submit">Save</button>
            </div>
              <div className="col-sm-6">

           </div>
           </form>

        </div>
        <div className="col-lg-3"></div>

        
        </div>
      </div>
<br></br>
<Footer></Footer>

     </div>

    );
  }
}
export default Updatecandidate;
