import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Typography,
  Card,
  CardContent,
  Avatar,
  Grid,
  Button,
  Divider,
  Rating,
  Paper,
  Stack,
  Chip,
  List,
  ListItem,
  CircularProgress,
  Link as MuiLink,
  Breadcrumbs,
  ListItemAvatar,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  Email as EmailIcon,
  Description as DescriptionIcon,
  Group as GroupIcon,
  Warning as WarningIcon,
  NavigateNext,
  ArrowBack,
} from '@mui/icons-material';
import { useDispatch, useSelector } from "react-redux";
import {Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { useAuthToken } from "../TokenContext";
import axios from "axios";

// Styled components
const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  backgroundColor: 'rgba(142, 174, 188, 0.12)',
  marginBottom: theme.spacing(4)
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
  backgroundColor: theme.palette.background.default
}));

const MemberLink = styled('a')({
  textDecoration: 'none',
  color: 'inherit',
  '&:hover': {
    textDecoration: 'underline'
  }
});

const FindPodDetailsClient = () => {
  const token = useAuthToken();
  const { isLoggedIn, user } = useSelector(state => state.auth);
  const [podDetails, setPodDetails] = useState({});
  const [candidates, setCandidates] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    fetchPodDetails();
    return () => {
      setPodDetails({});
      setCandidates([]);
      setReviews([]);
    };
  }, [token]);

  const fetchPodDetails = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_CLIENT_GETPODID,
        { id },
        {
          headers: {
            Authorization: token,
            'Content-Type': 'application/json'
          }
        }
      );
      setPodDetails(response.data.data.podDetails);
      setCandidates(response.data.data.candidates);
      setReviews(response.data.data.podDetails.reviews)
      setLoading(false);
    } catch (error) {
      console.error("Error fetching pod details:", error);
      setLoading(false);
    }
  };

  const handleHirePod = async () => {
    if (!user?.subscriptiondata?.length || user.subscriptiondata[0].Status !== "active") {
      setDialogOpen(true);
      return;
    }

    try {
      const response = await axios.post(
        process.env.REACT_APP_CLIENT_HIREPOD,
        {
          podID: podDetails.id,
          clientID: user.data[0].emp_id
        },
        {
          headers: {
            Authorization: token,
            'Content-Type': 'application/json'
          }
        }
      );
      alert(response.data.message || 'Pod hired successfully!');
      window.location.reload();
    } catch (error) {
      console.error("Error hiring pod:", error);
      alert('Failed to hire pod. Please try again.');
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
        <CircularProgress />
      </Box>
    );
  }

  const TIME_INTERVALS = [
    { label: 'year', seconds: 31536000 },
    { label: 'month', seconds: 2592000 },
    { label: 'day', seconds: 86400 },
    { label: 'hour', seconds: 3600 },
    { label: 'minute', seconds: 60 },
    { label: 'second', seconds: 1 }
  ];

  // Function to calculate time since a given date
  const timeSince = (date) => {
    const seconds = Math.floor((Date.now() - new Date(date).getTime()) / 1000);
    const interval = TIME_INTERVALS.find(i => i.seconds < seconds);
    
    if (!interval) {
      return 'just now';
    }
    
    const count = Math.floor(seconds / interval.seconds);
    return `${count} ${interval.label}${count !== 1 ? 's' : ''} ago`;
  };

  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
       {/* Breadcrumbs */}
       <Breadcrumbs 
        separator={<NavigateNext fontSize="small" />} 
        sx={{ mb: 3 }}
      >
        <MuiLink
          component={Link}
          to="/FindPodsClient"
          color="inherit"
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <ArrowBack sx={{ mr: 0.5 }} fontSize="small" />
         Find Pods
        </MuiLink>
        <Typography color="text.primary"> Pod Details</Typography>
      </Breadcrumbs>
      <Typography variant="h4" align="center" gutterBottom sx={{ mb: 6 }}>
        Pod Details
      </Typography>

      <Grid container spacing={4}>
        {/* Pod Details Card */}
        <Grid item xs={12}>
          <StyledCard elevation={3}>
            <CardContent>
              <Box display="flex" alignItems="center" mb={3}>
                <Avatar
                  src={podDetails.picture ? `https://globldataprod.s3.amazonaws.com/${podDetails.picture}` : '/defaultimg.png'}
                  sx={{ width: 80, height: 80, mr: 2 }}
                />
                <Box>
                  <Typography variant="h5" gutterBottom>
                    {podDetails.podName}
                  </Typography>
                  <Rating value={Number(podDetails.avgrating) || 0} readOnly precision={0.5} />
                </Box>
              </Box>

              <Stack spacing={2}>
                <Box display="flex" alignItems="center">
                  <EmailIcon sx={{ mr: 2 }} />
                  <Typography>{podDetails.email}</Typography>
                </Box>

                <Box display="flex" alignItems="center">
                  <DescriptionIcon sx={{ mr: 2 }} />
                  <Typography>{podDetails.podDescription}</Typography>
                </Box>
              </Stack>

              {podDetails.status === "Hired" ? (
                <Chip 
                  label="Already Hired"
                  color="success"
                  sx={{ mt: 3 }}
                />
              ) : user?.data[0]?.type !== "PartnerGuest" && (
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handleHirePod}
                  sx={{ mt: 3 }}
                >
                  Hire Pod
                </Button>
              )}
            </CardContent>
          </StyledCard>
        </Grid>

        {/* Team Members Section */}
        <Grid item xs={12}>
          <StyledPaper elevation={3}>
            <Typography variant="h6" gutterBottom>
              <GroupIcon sx={{ mr: 1, verticalAlign: 'middle' }} />
              Pod Team Members
            </Typography>
            <Divider sx={{ my: 2 }} />
            
            <List>
              {candidates.map((member) => (
                <ListItem key={member.c_id}>
                  <ListItemAvatar>
                    <Avatar
                      src={member.profilePicture ? `https://globldataprod.s3.amazonaws.com/${member.profilePicture}` : '/defaultimg.png'}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Link 
                        to={{ 
                          pathname: '/Candidatedetailsbyid', 
                          state: { c_id: member.c_id } 
                        }}
                        style={{ textDecoration: 'none', color: 'inherit' }}
                      >
                        {member.firstName}
                      </Link>
                    }
                    secondary={member.work_position}
                  />
                </ListItem>
              ))}
            </List>
          </StyledPaper>
        </Grid>

        {/* Reviews Section */}
        <Grid item xs={12}>
          <StyledPaper elevation={3}>
            <Typography variant="h6" gutterBottom>
              Pod Reviews
            </Typography>
            <Divider sx={{ my: 2 }} />
            
            {reviews && reviews.length > 0 ? (
        reviews.map((review, index) => (
          <Box key={index} sx={{ mb: 3 }}>
            <Box display="flex" alignItems="center" mb={1}>
              <Avatar
                src={review.profilePicture}
                sx={{ mr: 2, width: 40, height: 40 }}
              />
              <Box>
                <Typography variant="subtitle1">Client</Typography>
                <Typography variant="caption" color="text.secondary">
                  {timeSince(new Date(review.r_createdAt))}
                </Typography>
              </Box>
            </Box>
            <Rating value={Number(review.rating)} readOnly />
            <Typography sx={{ mt: 1 }}>{review.review}</Typography>
            <Divider sx={{ my: 2 }} />
          </Box>
        ))
            ) : (
              <Typography color="text.secondary" align="center">
                No reviews yet
              </Typography>
            )}
          </StyledPaper>
        </Grid>
      </Grid>

      {/* Subscription Dialog */}
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>
          <WarningIcon color="warning" sx={{ mr: 1, verticalAlign: 'middle' }} />
          Subscription Required
        </DialogTitle>
        <DialogContent>
          <Typography>
            You need an active subscription to hire pods. Please subscribe to continue.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Close</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              // Add subscription page navigation here
              setDialogOpen(false);
            }}
          >
            Subscribe Now
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default FindPodDetailsClient;