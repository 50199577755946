import React, { useEffect, useState, useRef } from "react";
import { Chart, registerables } from "chart.js";
import "chartjs-plugin-annotation";
import "./BarGraph.css";
import { v4 as uuidv4 } from "uuid";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {Paper,useTheme} from "@mui/material";
import { useAuthToken } from "../TokenContext";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

const DetailbitbucketPRcountTalent = ({ initialPodIds }) => {
  const [metrics, setMetrics] = useState(null);
  const [talentUsers, setTalentUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const canvasId = useRef(`pr-chart-${uuidv4()}`);
  const chartInstanceRef = useRef(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const intl = useIntl();
const theme=useTheme();
  const { user } = useSelector((state) => state.auth);
  const token = useAuthToken();

  useEffect(() => {
    Chart.register(...registerables);
    fetchMetricsPod(initialPodIds);

    // Cleanup function
    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
        chartInstanceRef.current = null;
      }
    };
  }, [token, user?.data?.[0]?.type, initialPodIds]);

  const fetchMetricsPod = async (selectedPodIds) => {
    try {
      if (!token) {
        console.log("Token is missing. API requests will not be made.");
        return;
      }

      const response = await fetch(
        process.env.REACT_APP_TALENT_PULLREQUESTMETRICSTALENT,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({
            talent_id: user.data[0].c_id,
            pod_id: selectedPodIds || null,
          }),
        }
      );

      const data = await response.json();
      
      if (response.status === 500 || !data.body) {
        setErrorMessage("No Data Available");
      } else {
        const responseJSON = data.body;
        setMetrics(responseJSON);
        setTalentUsers(responseJSON.talent_users || []);
      }
    } catch (error) {
      console.log("Error fetching metrics:", error);
      setErrorMessage("Error fetching data");
    }
  };

  useEffect(() => {
    if (!metrics) return;

    const canvas = document.getElementById(canvasId.current);
    if (!canvas) return;

    // Clean up previous chart instance
    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
      chartInstanceRef.current = null;
    }

    let chartData;
    if (selectedUser) {
      const user = talentUsers.find((u) => u.bitbucket_user === selectedUser);
      if (!user) return;

      chartData = {
        labels: ["Submitted PRs", "Approved PRs"],
        datasets: [
          {
            data: [user.submitted_pr_count, user.approved_pr_count],
            backgroundColor: ["#D3D3D3", "#32CD32"],
          },
        ],
      };
    } else {
      const totalPrs = metrics.total_prs;
      if (!totalPrs) return;

      chartData = {
        labels: ["Total Submitted PRs", "Total Approved PRs"],
        datasets: [
          {
            data: [totalPrs.total_submitted_prs, totalPrs.total_Approved_prs],
            backgroundColor: ["#D3D3D3", "#32CD32"],
          },
        ],
      };
    }

    chartInstanceRef.current = new Chart(canvas, {
      type: "doughnut",
      data: chartData,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          annotation: {},
          legend: {
            display: false,
          },
        },
        layout: {
          padding: {
            top: 10,
            bottom: 10,
            left: 10,
            right: 10,
          },
        },
        onClick: () => {
          openModal();
        },
      },
    });
  }, [metrics, selectedUser, talentUsers]);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const hasData = metrics?.total_prs && 
    (metrics.total_prs.total_submitted_prs !== 0 || 
     metrics.total_prs.total_Approved_prs !== 0);

  const renderChartContent = () => {
    if (errorMessage) {
      return <p>{errorMessage}</p>;
    }

    if (!metrics) {
      return <p>{intl.formatMessage({ id: "noDataAvailable" })}</p>;
    }

    if (!hasData) {
      return <p>{intl.formatMessage({ id: "noDataAvailable" })}</p>;
    }

    return <canvas id={canvasId.current} width="150" height="150"></canvas>;
  };

  return (
    <div className="chart-container">
      <div style={{ display: "flex", flexDirection: "column" }}>
        <h2 className="chart-heading">
          {intl.formatMessage({ id: "prCount" })}
        </h2>
        <br />
        <select
          style={{ width: "12rem" , color: theme.palette.mode === 'dark' ? 'white' : 'black',      backgroundColor:  theme.palette.mode === 'dark' ? theme.palette.background.paper : 'white',}}
          value={selectedUser || ""}
          onChange={(e) => setSelectedUser(e.target.value || null)}
        >
          <option value="">
            {intl.formatMessage({ id: "allReporters" })}
          </option>
          {talentUsers.map((user) => (
            <option key={user.bitbucket_user} value={user.bitbucket_user}>
              {user.bitbucket_user}
            </option>
          ))}
        </select>
        <div className="chart-wrapper" style={{ width: "170px", height: "170px", margin: "auto", textAlign: "center", marginTop: "5px" }}>
          <br />
          {renderChartContent()}
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="PR Summary Modal"
        style={{
          content: {
            width: "50%",
            maxWidth: "800px",
            margin: "auto",
            marginTop: "5rem",
          },
        }}
      >
        <h6>{intl.formatMessage({ id: "prSummary" })}</h6>
        <TableContainer component={Paper}>
          <Table
            sx={{
              maxWidth: 900,
              fontFamily: "Poppins,Medium",
              borderRadius: "10px",
            }}
            aria-label="simple table"
          >
            <TableHead
              sx={{
                backgroundColor: "#00B4D8",
                "& td, & th": {
                  fontFamily: "Poppins,Medium",
                  fontSize: "15px",
                  color: "white",
                  fontWeight: "600",
                },
              }}
            >
              <TableRow>
                <TableCell align="right">
                  {intl.formatMessage({ id: "githubUser" })}
                </TableCell>
                <TableCell align="right">
                  {intl.formatMessage({ id: "submittedPRCount" })}
                </TableCell>
                <TableCell align="right">
                  {intl.formatMessage({ id: "approvedPRCount" })}
                </TableCell>
                <TableCell align="right">
                  {intl.formatMessage({ id: "totalPRCount" })}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {talentUsers.map((project, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "&:last-child td, &:last-child th": {
                      border: 0,
                      fontFamily: "Poppins,Medium",
                      fontSize: "15px",
                    },
                    "&:nth-child(even)": {
                      backgroundColor: "#90E0EF",
                    },
                    "&:nth-child(odd)": {
                      backgroundColor: "#caf0f8",
                    },
                    "& td, & th": {
                      fontFamily: "Poppins,Medium",
                      fontSize: "15px",
                      color: "black",
                    },
                  }}
                >
                  <TableCell align="right">{project.bitbucket_user}</TableCell>
                  <TableCell align="right">
                    {project.submitted_pr_count}
                  </TableCell>
                  <TableCell align="right">
                    {project.approved_pr_count}
                  </TableCell>
                  <TableCell align="right">
                    {project.total_pr_count}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <button onClick={closeModal}>
          {intl.formatMessage({ id: "done" })}
        </button>
      </Modal>
    </div>
  );
};

DetailbitbucketPRcountTalent.propTypes = {
  initialPodIds: PropTypes.array.isRequired,
};

export default DetailbitbucketPRcountTalent;