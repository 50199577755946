import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Container,
  Paper,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  Grid,
  Divider,
  IconButton,
  InputAdornment,
  Alert,
  Snackbar,
  Link
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Visibility, VisibilityOff, CloudUpload } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import AWS from 'aws-sdk';
import { useAuthToken } from "../TokenContext";

const config = {
  bucketName: 'globldataprod',
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESSKEYID,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY,
};

// Styled components

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  width: '100%',
}));



const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});


const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  borderRadius: '16px',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
  border: '1px solid #e0e0e0',
}));

const SectionContainer = styled(Box)(({ theme }) => ({
  
  borderRadius: '12px',
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  border: '1px solid #e0e0e0',
  backgroundColor: theme.palette.mode === 'dark' ? '#2B2B2B' : '#f8f9fa'

}));

const MainTitle = styled(Typography)(({ theme }) => ({
  color: '#1976d2',
  fontWeight: 600,
  position: 'relative',
  paddingBottom: theme.spacing(2),
  marginBottom: theme.spacing(4),
  '&:after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '60px',
    height: '4px',
    background: '#1976d2',
    borderRadius: '2px',
  }
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  color: '#2196f3',
  fontWeight: 600,
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2),
  borderRadius: '8px',
  background: 'linear-gradient(45deg, #e3f2fd 30%, #bbdefb 90%)',
  marginBottom: theme.spacing(3),
  boxShadow: '0 2px 8px rgba(33, 150, 243, 0.15)',
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(3, 0),
  background: 'linear-gradient(90deg, #2196f3 0%, #64b5f6 100%)',
  height: '2px',
}));

const ColoredButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #1976d2 30%, #2196f3 90%)',
  borderRadius: '8px',
  border: 0,
  color: 'white',
  padding: '12px 24px',
  boxShadow: '0 3px 5px 2px rgba(33, 150, 243, .3)',
  transition: 'all 0.3s ease',
  '&:hover': {
    background: 'linear-gradient(45deg, #1565c0 30%, #1976d2 90%)',
    transform: 'translateY(-2px)',
    boxShadow: '0 4px 8px 2px rgba(33, 150, 243, .4)',
  }
}));

function Setting() {
  const [showPassword, setShowPassword] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const token = useAuthToken();
  const { user } = useSelector((state) => state.auth);
  
  // Initial state setup
  const [fetchlist, setFetchlist] = useState([]);
  const [fetchlistid, setFetchlistid] = useState('');
  const [ARTsList, setARTsList] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedARTId, setSelectedARTId] = useState('');

  const [formData, setFormData] = useState({
    Jira_URL: '',
    Jira_admin_login_email_address: '',
    Jira_admin_API_Token: '',
    Github_username: '',
    Github_API_Token: '',
    Github_Repo_name: '',
    ART_id: '',
    Organizational_Code: user?.data?.[0]?.client_code || '',
    Jira_Kanben_Project_name: '',
    circleci_github_username: '',
    circleci_github_repo: '',
    open_ai_token: '',
    circleci_api_token: '',
    sonarqube_cloud_url: '',
    sonarqube_api_token: '',
    aws_access_key_id: '',
    aws_secret_access_key: '',
    bitbucket_username: '',
    bitbucket_app_password: '',
    bitbucket_workspace: '',
    aws_region: '',
    jira_open_story_bug_status: [],
    jira_closed_story_bug_status: [],
    jira_story_points_calc_file: '',
  });

  const [openStatusOptions] = useState([
    'To Do',
    'In Progress',
    'Testing',
    'In Review',
    'Blocked',
    'Ready for QA',
  ]);

  const [closedStatusOptions] = useState([
    'Done',
    'Closed',
    'Completed',
    'Resolved',
    'Verified',
  ]);

  // Handlers
  const handleTogglePassword = (field) => {
    setShowPassword(prev => ({
      ...prev,
      [field]: !prev[field]
    }));
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      handleSnackbarOpen('File selected successfully', 'success');
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleARTSelect = (event) => {
    const selectedARTId = event.target.value;
    setSelectedARTId(selectedARTId);
    setFormData(prev => ({
      ...prev,
      ART_id: selectedARTId
    }));
  };

  const handleOpenStatusChange = (event, value) => {
    setFormData(prev => ({
      ...prev,
      jira_open_story_bug_status: value
    }));
  };

  const handleClosedStatusChange = (event, value) => {
    setFormData(prev => ({
      ...prev,
      jira_closed_story_bug_status: value
    }));
  };

  const handleSnackbarOpen = (message, severity = 'success') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  // File upload to S3
  const uploadFileToS3 = async (file) => {
    if (!file) return null;

    AWS.config.update({
      region: config.region,
      accessKeyId: config.accessKeyId,
      secretAccessKey: config.secretAccessKey,
    });

    const s3 = new AWS.S3();
    const params = {
      Bucket: config.bucketName,
      Key: `${Date.now()}-${file.name}`,
      Body: file,
      ContentType: file.type,
      ACL: 'public-read',
    };

    try {
      const uploadResult = await s3.upload(params).promise();
      return uploadResult.Key;
    } catch (error) {
      console.error('Error uploading to S3:', error);
      handleSnackbarOpen('Failed to upload file', 'error');
      throw error;
    }
  };

  // Form submission handlers
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const fileName = await uploadFileToS3(selectedFile);
      
      const formattedData = {
        ...formData,
        jira_story_points_calc_file: fileName,
        jira_open_story_bug_status: Array.isArray(formData.jira_open_story_bug_status)
          ? formData.jira_open_story_bug_status
          : [formData.jira_open_story_bug_status],
        jira_closed_story_bug_status: Array.isArray(formData.jira_closed_story_bug_status)
          ? formData.jira_closed_story_bug_status
          : [formData.jira_closed_story_bug_status],
      };

      const response = await fetch(process.env.REACT_APP_CUSTOMERADMIN_AUTHORIZATIONTOKEN, {
        method: 'POST',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formattedData),
      });

      if (response.ok) {
        handleSnackbarOpen('Settings saved successfully');
        window.location.reload();
      } else {
        handleSnackbarOpen('Failed to save settings', 'error');
      }
    } catch (error) {
      console.error('Error:', error);
      handleSnackbarOpen('An error occurred', 'error');
    }
  };

  const handleUpdate = async (event) => {
    event.preventDefault();
    try {
      const fileName = selectedFile ? await uploadFileToS3(selectedFile) : formData.jira_story_points_calc_file;

      const formattedData = {
        ...formData,
        jira_story_points_calc_file: fileName,
        jira_open_story_bug_status: Array.isArray(formData.jira_open_story_bug_status)
          ? formData.jira_open_story_bug_status
          : [formData.jira_open_story_bug_status],
        jira_closed_story_bug_status: Array.isArray(formData.jira_closed_story_bug_status)
          ? formData.jira_closed_story_bug_status
          : [formData.jira_closed_story_bug_status],
      };

      const response = await axios.post(
        process.env.REACT_APP_CUSTOMERADMIN_UPDATEAUTHORIZATIONTOKENS,
        {
          id: fetchlistid.id,
          ...formattedData,
        },
        { 
          headers: {
            Authorization: token,
            'Content-Type': 'application/json',
          }
        }
      );

      if (response.status === 200) {
        handleSnackbarOpen('Settings updated successfully');
        window.location.reload();
      } else {
        handleSnackbarOpen('Failed to update settings', 'error');
      }
    } catch (error) {
      console.error('Error:', error);
      handleSnackbarOpen('An error occurred', 'error');
    }
  };

  // Data fetching
  const fetchARTsList = async () => {
    if (!token) return;
    try {
      const response = await axios.post(
        process.env.REACT_APP_CUSTOMERADMIN_GENERICGIGLIST,
        { Organizational_Code: user.data[0].client_code },
        { 
          headers: {
            Authorization: token,
            'Content-Type': 'application/json',
          }
        }
      );

      if (response.status === 200 && response.data?.data) {
        setARTsList(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching ARTs list:', error);
      handleSnackbarOpen('Failed to fetch ARTs list', 'error');
    }
  };

  const fetchauthorisationlist = async () => {
    if (!token) return;
    try {
      const response = await axios.post(
        process.env.REACT_APP_CUSTOMERADMIN_FETCHAUTHORIZATIONTOKENS,
        { Organizational_Code: user.data[0].client_code },
        { 
          headers: {
            Authorization: token,
            'Content-Type': 'application/json',
          }
        }
      );

      if (response.data?.body?.data?.length > 0) {
        const fetchData = response.data.body.data[0];
        setFetchlist(response.data.body.data);
        setFetchlistid(fetchData);
        setFormData(prev => ({
          ...prev,
          ...fetchData,
          jira_open_story_bug_status: fetchData.jira_open_story_bug_status?.[0]?.split(',') || [],
          jira_closed_story_bug_status: fetchData.jira_closed_story_bug_status?.[0]?.split(',') || [],
        }));
      }
    } catch (error) {
      console.error('Error fetching authorization list:', error);
      handleSnackbarOpen('Failed to fetch current settings', 'error');
    }
  };

  // Effects
  useEffect(() => {
    fetchARTsList();
    fetchauthorisationlist();
  }, [token]);

  // Render method
  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 8, mb: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Settings
        </Typography>
        
        <StyledPaper elevation={3}>
          <form onSubmit={fetchlist.length === 0 ? handleSubmit : handleUpdate}>
            <Grid container spacing={3}>
              {/* ART Selection */}
              <Grid item xs={12}>
              <SectionContainer>
                <StyledFormControl>
                  <InputLabel>Select ART</InputLabel>
                  <Select
                    value={formData.ART_id}
                    onChange={handleARTSelect}
                    label="Select ART"
                  >
                    <MenuItem value="">
                      <em>Select an ART</em>
                    </MenuItem>
                    {ARTsList.map((art) => (
                      <MenuItem key={art.ART_id} value={art.ART_id}>
                        {art.ART_Name}
                      </MenuItem>
                    ))}
                  </Select>
                </StyledFormControl>
                </SectionContainer>
              </Grid>

              {/* Jira Section */}
              <Grid item xs={12}>
                <SectionTitle variant="h6">Jira Configuration</SectionTitle>
                <Divider />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Jira Project Key"
                  name="Jira_Kanben_Project_name"
                  value={formData.Jira_Kanben_Project_name}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Jira URL"
                  name="Jira_URL"
                  value={formData.Jira_URL}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Jira Admin Email"
                  name="Jira_admin_login_email_address"
                  type="email"
                  value={formData.Jira_admin_login_email_address}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Jira Admin API Token"
                  name="Jira_admin_API_Token"
                  type={showPassword.Jira_admin_API_Token ? 'text' : 'password'}
                  value={formData.Jira_admin_API_Token}
                  onChange={handleChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => handleTogglePassword('Jira_admin_API_Token')}
                          edge="end"
                        >
                          {showPassword.Jira_admin_API_Token ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Autocomplete
                  multiple
                  options={openStatusOptions}
                  value={formData.jira_open_story_bug_status}
                  onChange={handleOpenStatusChange}
                  renderInput={(params) => (<TextField
                    {...params}
                    label="Jira Open Story/Bug Status"
                    variant="outlined"
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Autocomplete
                multiple
                options={closedStatusOptions}
                value={formData.jira_closed_story_bug_status}
                onChange={handleClosedStatusChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Jira Closed Story/Bug Status"
                    variant="outlined"
                  />
                )}
              />
            </Grid>

            {/* GitHub Section */}
            <Grid item xs={12}>
              <SectionTitle variant="h6">GitHub Configuration</SectionTitle>
              <Divider />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="GitHub Username"
                name="Github_username"
                value={formData.Github_username}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="GitHub API Token"
                name="Github_API_Token"
                type={showPassword.Github_API_Token ? 'text' : 'password'}
                value={formData.Github_API_Token}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => handleTogglePassword('Github_API_Token')}
                        edge="end"
                      >
                        {showPassword.Github_API_Token ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="GitHub Repository Name"
                name="Github_Repo_name"
                value={formData.Github_Repo_name}
                onChange={handleChange}
              />
            </Grid>

            {/* CircleCI Section */}
            <Grid item xs={12}>
              <SectionTitle variant="h6">CircleCI Configuration</SectionTitle>
              <Divider />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="CircleCI GitHub Username"
                name="circleci_github_username"
                value={formData.circleci_github_username}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="CircleCI GitHub Repository"
                name="circleci_github_repo"
                value={formData.circleci_github_repo}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="CircleCI API Token"
                name="circleci_api_token"
                type={showPassword.circleci_api_token ? 'text' : 'password'}
                value={formData.circleci_api_token}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => handleTogglePassword('circleci_api_token')}
                        edge="end"
                      >
                        {showPassword.circleci_api_token ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            {/* SonarQube Section */}
            <Grid item xs={12}>
              <SectionTitle variant="h6">SonarQube Configuration</SectionTitle>
              <Divider />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="SonarQube Cloud URL"
                name="sonarqube_cloud_url"
                value={formData.sonarqube_cloud_url}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="SonarQube API Token"
                name="sonarqube_api_token"
                type={showPassword.sonarqube_api_token ? 'text' : 'password'}
                value={formData.sonarqube_api_token}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => handleTogglePassword('sonarqube_api_token')}
                        edge="end"
                      >
                        {showPassword.sonarqube_api_token ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            {/* AWS Section */}
            <Grid item xs={12}>
              <SectionTitle variant="h6">AWS Configuration</SectionTitle>
              <Divider />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="AWS Access Key ID"
                name="aws_access_key_id"
                type={showPassword.aws_access_key_id ? 'text' : 'password'}
                value={formData.aws_access_key_id}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => handleTogglePassword('aws_access_key_id')}
                        edge="end"
                      >
                        {showPassword.aws_access_key_id ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="AWS Secret Access Key"
                name="aws_secret_access_key"
                type={showPassword.aws_secret_access_key ? 'text' : 'password'}
                value={formData.aws_secret_access_key}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => handleTogglePassword('aws_secret_access_key')}
                        edge="end"
                      >
                        {showPassword.aws_secret_access_key ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="AWS Region"
                name="aws_region"
                value={formData.aws_region}
                onChange={handleChange}
              />
            </Grid>

            {/* BitBucket Section */}
            <Grid item xs={12}>
              <SectionTitle variant="h6">BitBucket Configuration</SectionTitle>
              <Divider />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="BitBucket Username"
                name="bitbucket_username"
                value={formData.bitbucket_username}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="BitBucket App Password"
                name="bitbucket_app_password"
                type={showPassword.bitbucket_app_password ? 'text' : 'password'}
                value={formData.bitbucket_app_password}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => handleTogglePassword('bitbucket_app_password')}
                        edge="end"
                      >
                        {showPassword.bitbucket_app_password ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="BitBucket Workspace"
                name="bitbucket_workspace"
                value={formData.bitbucket_workspace}
                onChange={handleChange}
              />
            </Grid>

            {/* OpenAI Section */}
            <Grid item xs={12}>
              <SectionTitle variant="h6">OpenAI Configuration</SectionTitle>
              <Divider />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="OpenAI Token"
                name="open_ai_token"
                type={showPassword.open_ai_token ? 'text' : 'password'}
                value={formData.open_ai_token}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => handleTogglePassword('open_ai_token')}
                        edge="end"
                      >
                        {showPassword.open_ai_token ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            {/* File Upload Section */}
            <Grid item xs={12}>
              <SectionTitle variant="h6">Story Points Calculation</SectionTitle>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <Button
                component="label"
                variant="contained"
                startIcon={<CloudUpload />}
              >
                Upload Story Points Calculation File
                <VisuallyHiddenInput type="file" onChange={handleFileChange} />
              </Button>
              {formData.jira_story_points_calc_file && (
                <Box sx={{ mt: 2 }}>
                  <Typography variant="body2" component="span" sx={{ mr: 1 }}>
                    Current file:
                  </Typography>
                  <Link
                    href={`https://globldataprod.s3.amazonaws.com/${encodeURIComponent(formData.jira_story_points_calc_file)}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {formData.jira_story_points_calc_file}
                  </Link>
                </Box>
              )}
            </Grid>

            {/* Submit Button */}
            <Grid item xs={12}>
              <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  sx={{ minWidth: 200 }}
                >
                  {fetchlist.length === 0 ? 'Submit' : 'Update'}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </StyledPaper>
    </Box>

    {/* Snackbar for notifications */}
    <Snackbar
      open={openSnackbar}
      autoHideDuration={6000}
      onClose={handleSnackbarClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      <Alert
        onClose={handleSnackbarClose}
        severity={snackbarSeverity}
        sx={{ width: '100%' }}
      >
        {snackbarMessage}
      </Alert>
    </Snackbar>
  </Container>
);
}

export default Setting;