import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { useAuthToken } from '../TokenContext';
import { FaImage } from 'react-icons/fa';
import styled  from 'styled-components';

// Subcomponents
const PortfolioSlide = ({ item, onImageClick }) => (
  <SlideContent>
    <div>
      <h3>{item.project_objective}</h3>
      <p>{item.project_description}</p>
      <p>Industry: {item.industry}</p>
    </div>
    <ImageGallery>
      {item.project_files.map((image, imageIndex) => (
        <ImageCard key={imageIndex} image={image} onClick={() => onImageClick(image)} />
      ))}
    </ImageGallery>
  </SlideContent>
);

const ImageCard = ({ image, onClick }) => (
  <Card>
    {image ? (
     <button
     onClick={onClick}
     style={{ 
       width: '100%', 
       height: '100%', 
       padding: 0,
       border: 'none',
       background: 'none',
       cursor: 'pointer' 
     }}
   >
     <img
       src={image}
       alt="Portfolio item"
       style={{ width: '100%', height: '100%', borderRadius: '8px' }}
     />
   </button>
    ) : (
      <PlaceholderContainer>
        <FaImage style={{ fontSize: '50px', color: '#ccc' }} />
      </PlaceholderContainer>
    )}
  </Card>
);

const Slideshow = ({ items, currentIndex, onPrev, onNext, onImageClick }) => (
  <SlideshowContainer>
    <SlideshowContent>
      {items.map((item, index) => (
        <Slide key={index} visible={index === currentIndex}>
          <PortfolioSlide item={item} onImageClick={onImageClick} />
        </Slide>
      ))}
    </SlideshowContent>
    <NavigationButton prev onClick={onPrev} disabled={currentIndex === 0}>
      &#10094;
    </NavigationButton>
    <NavigationButton next onClick={onNext} disabled={currentIndex === items.length - 1}>
      &#10095;
    </NavigationButton>
  </SlideshowContainer>
);

const CreateprofilePortfolioView = () => {
  const { user } = useSelector(state => state.auth);
  const token = useAuthToken();
  const [portfolioItems, setPortfolioItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [modalImageUrl, setModalImageUrl] = useState(null);

  useEffect(() => {
    fetchPortfolioData();
  }, [token, user]);

  const fetchPortfolioData = async () => {
    try {
      setLoading(true);
      const portfolioUserId = user.data[0].type === 'Provider' 
        ? user.data[0].id 
        : user.data[0].c_id;

      const response = await fetch(process.env.REACT_APP_TALENT_LISTPORTFOLIO, {
        method: 'POST',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ portfolio_user_id: portfolioUserId }),
      });

      if (response.ok) {
        const responseData = await response.json();
        setPortfolioItems(responseData.body);
      } else {
        console.error('Error fetching portfolio data:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching portfolio data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handlePrevSlide = () => {
    setCurrentIndex(prev => (prev === 0 ? portfolioItems.length - 1 : prev - 1));
  };

  const handleNextSlide = () => {
    setCurrentIndex(prev => (prev === portfolioItems.length - 1 ? 0 : prev + 1));
  };

  const handleImageClick = (imageUrl) => {
    setModalImageUrl(imageUrl);
  };

  if (loading) {
    return <Container><p>Loading...</p></Container>;
  }

  if (!Array.isArray(portfolioItems) || portfolioItems.length === 0) {
    return <Container><p>No portfolio records found.</p></Container>;
  }

  return (
    <Container>
      <PortfolioList>
        <Slideshow
          items={portfolioItems}
          currentIndex={currentIndex}
          onPrev={handlePrevSlide}
          onNext={handleNextSlide}
          onImageClick={handleImageClick}
        />
      </PortfolioList>
      {modalImageUrl && (
        <Modal onClick={() => setModalImageUrl(null)}>
          <ModalContent src={modalImageUrl} alt="Portfolio item" />
        </Modal>
      )}
    </Container>
  );
};

// Styled components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2rem auto;
  width: 80%;
`;

const PortfolioList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.03);
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
`;

const SlideshowContainer = styled.div`
  position: relative;
  width: 100%;
`;

const SlideshowContent = styled.div`
  width: 100%;
  overflow: hidden;
`;

const Slide = styled.div`
  display: ${props => props.visible ? 'block' : 'none'};
  text-align: center;
  padding: 20px;
`;

const SlideContent = styled.div`
  h3 {
    color: #333;
    margin-bottom: 1rem;
  }
  
  p {
    color: #666;
    margin-bottom: 0.5rem;
  }
`;

const ImageGallery = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  margin-top: 1.5rem;
`;

const Card = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  padding: 10px;
  width: 45%;
  height: 200px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  overflow: hidden;
`;

const PlaceholderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NavigationButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  color: black;
  border: none;
  outline: none;
  font-size: 18px;
  padding: 15px;
  cursor: pointer;
  z-index: 1;
  ${props => props.prev ? 'left: 0;' : 'right: 0;'}
  opacity: ${props => props.disabled ? 0.5 : 1};
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.img`
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
`;

export default CreateprofilePortfolioView;