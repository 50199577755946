import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, Typography } from '@mui/material';

const LanguageSelector = ({ currentLocale, changeLocale }) => {
  const handleLanguageChange = (event) => {
    const newLocale = event.target.value;
    localStorage.setItem('locale', newLocale);
    changeLocale(newLocale);
  };

  return (
    <div style={{ marginBottom: '24px' }}>
      <Typography
        variant="caption"
        component="div"
        sx={{
          color: 'rgba(0, 0, 0, 0.6)',
          marginBottom: '8px',
          fontSize: '0.75rem',
          fontWeight: 400,
          lineHeight: 1.2
        }}
      >
        Preferred Language
      </Typography>
      <FormControl fullWidth variant="outlined" size="small">
        <Select
          value={currentLocale || 'en'}
          onChange={handleLanguageChange}
          displayEmpty
          sx={{
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(0, 0, 0, 0.23)'
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(0, 0, 0, 0.87)'
            }
          }}
        >
        <MenuItem value="ab">Abkhazian</MenuItem>
<MenuItem value="aa">Afar</MenuItem>
<MenuItem value="af">Afrikaans</MenuItem>
<MenuItem value="ak">Akan</MenuItem>
<MenuItem value="sq">Albanian</MenuItem>
<MenuItem value="am">Amharic</MenuItem>
<MenuItem value="ar">Arabic</MenuItem>
<MenuItem value="an">Aragonese</MenuItem>
<MenuItem value="hy">Armenian</MenuItem>
<MenuItem value="as">Assamese</MenuItem>
<MenuItem value="av">Avaric</MenuItem>
<MenuItem value="ae">Avestan</MenuItem>
<MenuItem value="ay">Aymara</MenuItem>
<MenuItem value="az">Azerbaijani</MenuItem>
<MenuItem value="bm">Bambara</MenuItem>
<MenuItem value="ba">Bashkir</MenuItem>
<MenuItem value="eu">Basque</MenuItem>
<MenuItem value="be">Belarusian</MenuItem>
<MenuItem value="bn">Bengali</MenuItem>
<MenuItem value="bi">Bislama</MenuItem>
<MenuItem value="bs">Bosnian</MenuItem>
<MenuItem value="br">Breton</MenuItem>
<MenuItem value="bg">Bulgarian</MenuItem>
<MenuItem value="my">Burmese</MenuItem>
<MenuItem value="ca">Catalan</MenuItem>
<MenuItem value="ch">Chamorro</MenuItem>
<MenuItem value="ce">Chechen</MenuItem>
<MenuItem value="ny">Chichewa</MenuItem>
<MenuItem value="zh">Chinese</MenuItem>
<MenuItem value="cv">Chuvash</MenuItem>
<MenuItem value="kw">Cornish</MenuItem>
<MenuItem value="co">Corsican</MenuItem>
<MenuItem value="cr">Cree</MenuItem>
<MenuItem value="hr">Croatian</MenuItem>
<MenuItem value="cs">Czech</MenuItem>
<MenuItem value="da">Danish</MenuItem>
<MenuItem value="dv">Divehi</MenuItem>
<MenuItem value="nl">Dutch</MenuItem>
<MenuItem value="dz">Dzongkha</MenuItem>
<MenuItem value="en">English</MenuItem>
<MenuItem value="eo">Esperanto</MenuItem>
<MenuItem value="et">Estonian</MenuItem>
<MenuItem value="ee">Ewe</MenuItem>
<MenuItem value="fo">Faroese</MenuItem>
<MenuItem value="fj">Fijian</MenuItem>
<MenuItem value="fi">Finnish</MenuItem>
<MenuItem value="fr">French</MenuItem>
<MenuItem value="ff">Fulah</MenuItem>
<MenuItem value="gl">Galician</MenuItem>
<MenuItem value="ka">Georgian</MenuItem>
<MenuItem value="de">German</MenuItem>
<MenuItem value="el">Greek</MenuItem>
<MenuItem value="gn">Guarani</MenuItem>
<MenuItem value="gu">Gujarati</MenuItem>
<MenuItem value="ht">Haitian</MenuItem>
<MenuItem value="ha">Hausa</MenuItem>
<MenuItem value="he">Hebrew</MenuItem>
<MenuItem value="hz">Herero</MenuItem>
<MenuItem value="hi">Hindi</MenuItem>
<MenuItem value="ho">Hiri Motu</MenuItem>
<MenuItem value="hu">Hungarian</MenuItem>
<MenuItem value="is">Icelandic</MenuItem>
<MenuItem value="io">Ido</MenuItem>
<MenuItem value="ig">Igbo</MenuItem>
<MenuItem value="id">Indonesian</MenuItem>
<MenuItem value="ia">Interlingua</MenuItem>
<MenuItem value="ie">Interlingue</MenuItem>
<MenuItem value="iu">Inuktitut</MenuItem>
<MenuItem value="ik">Inupiaq</MenuItem>
<MenuItem value="ga">Irish</MenuItem>
<MenuItem value="it">Italian</MenuItem>
<MenuItem value="ja">Japanese</MenuItem>
<MenuItem value="jv">Javanese</MenuItem>
<MenuItem value="kl">Kalaallisut</MenuItem>
<MenuItem value="kn">Kannada</MenuItem>
<MenuItem value="kr">Kanuri</MenuItem>
<MenuItem value="ks">Kashmiri</MenuItem>
<MenuItem value="kk">Kazakh</MenuItem>
<MenuItem value="km">Khmer</MenuItem>
<MenuItem value="ki">Kikuyu</MenuItem>
<MenuItem value="rw">Kinyarwanda</MenuItem>
<MenuItem value="ky">Kirghiz</MenuItem>
<MenuItem value="kv">Komi</MenuItem>
<MenuItem value="kg">Kongo</MenuItem>
<MenuItem value="ko">Korean</MenuItem>
<MenuItem value="ku">Kurdish</MenuItem>
<MenuItem value="kj">Kwanyama</MenuItem>
<MenuItem value="lo">Lao</MenuItem>
<MenuItem value="la">Latin</MenuItem>
<MenuItem value="lv">Latvian</MenuItem>
<MenuItem value="li">Limburgan</MenuItem>
<MenuItem value="ln">Lingala</MenuItem>
<MenuItem value="lt">Lithuanian</MenuItem>
<MenuItem value="lu">Luba-Katanga</MenuItem>
<MenuItem value="lg">Luganda</MenuItem>
<MenuItem value="lb">Luxembourgish</MenuItem>
<MenuItem value="mk">Macedonian</MenuItem>
<MenuItem value="mg">Malagasy</MenuItem>
<MenuItem value="ms">Malay</MenuItem>
<MenuItem value="ml">Malayalam</MenuItem>
<MenuItem value="mt">Maltese</MenuItem>
<MenuItem value="gv">Manx</MenuItem>
<MenuItem value="mi">Maori</MenuItem>
<MenuItem value="mr">Marathi</MenuItem>
<MenuItem value="mh">Marshallese</MenuItem>
<MenuItem value="mn">Mongolian</MenuItem>
<MenuItem value="na">Nauru</MenuItem>
<MenuItem value="nv">Navajo</MenuItem>
<MenuItem value="ng">Ndonga</MenuItem>
<MenuItem value="ne">Nepali</MenuItem>
<MenuItem value="nd">North Ndebele</MenuItem>
<MenuItem value="se">Northern Sami</MenuItem>
<MenuItem value="no">Norwegian</MenuItem>
<MenuItem value="nb">Norwegian Bokmål</MenuItem>
<MenuItem value="nn">Norwegian Nynorsk</MenuItem>
<MenuItem value="oc">Occitan</MenuItem>
<MenuItem value="oj">Ojibwa</MenuItem>
<MenuItem value="or">Oriya</MenuItem>
<MenuItem value="om">Oromo</MenuItem>
<MenuItem value="os">Ossetian</MenuItem>
<MenuItem value="pi">Pali</MenuItem>
<MenuItem value="ps">Pashto</MenuItem>
<MenuItem value="fa">Persian</MenuItem>
<MenuItem value="pl">Polish</MenuItem>
<MenuItem value="pt">Portuguese</MenuItem>
<MenuItem value="pa">Punjabi</MenuItem>
<MenuItem value="qu">Quechua</MenuItem>
<MenuItem value="ro">Romanian</MenuItem>
<MenuItem value="rm">Romansh</MenuItem>
<MenuItem value="rn">Rundi</MenuItem>
<MenuItem value="ru">Russian</MenuItem>
<MenuItem value="sm">Samoan</MenuItem>
<MenuItem value="sg">Sango</MenuItem>
<MenuItem value="sa">Sanskrit</MenuItem>
<MenuItem value="sc">Sardinian</MenuItem>
<MenuItem value="sr">Serbian</MenuItem>
<MenuItem value="sn">Shona</MenuItem>
<MenuItem value="sd">Sindhi</MenuItem>
<MenuItem value="si">Sinhala</MenuItem>
<MenuItem value="sk">Slovak</MenuItem>
<MenuItem value="sl">Slovenian</MenuItem>
<MenuItem value="so">Somali</MenuItem>
<MenuItem value="nr">South Ndebele</MenuItem>
<MenuItem value="st">Southern Sotho</MenuItem>
<MenuItem value="es">Spanish</MenuItem>
<MenuItem value="su">Sundanese</MenuItem>
<MenuItem value="sw">Swahili</MenuItem>
<MenuItem value="ss">Swati</MenuItem>
<MenuItem value="sv">Swedish</MenuItem>
<MenuItem value="tl">Tagalog</MenuItem>
<MenuItem value="ty">Tahitian</MenuItem>
<MenuItem value="tg">Tajik</MenuItem>
<MenuItem value="ta">Tamil</MenuItem>
<MenuItem value="tt">Tatar</MenuItem>
<MenuItem value="te">Telugu</MenuItem>
<MenuItem value="th">Thai</MenuItem>
<MenuItem value="bo">Tibetan</MenuItem>
<MenuItem value="ti">Tigrinya</MenuItem>
<MenuItem value="to">Tonga</MenuItem>
<MenuItem value="ts">Tsonga</MenuItem>
<MenuItem value="tn">Tswana</MenuItem>
<MenuItem value="tr">Turkish</MenuItem>
<MenuItem value="tk">Turkmen</MenuItem>
<MenuItem value="tw">Twi</MenuItem>
<MenuItem value="ug">Uighur</MenuItem>
<MenuItem value="uk">Ukrainian</MenuItem>
<MenuItem value="ur">Urdu</MenuItem>
<MenuItem value="uz">Uzbek</MenuItem>
<MenuItem value="ve">Venda</MenuItem>
<MenuItem value="vi">Vietnamese</MenuItem>
<MenuItem value="vo">Volapük</MenuItem>
<MenuItem value="wa">Walloon</MenuItem>
<MenuItem value="cy">Welsh</MenuItem>
<MenuItem value="fy">Western Frisian</MenuItem>
<MenuItem value="wo">Wolof</MenuItem>
<MenuItem value="xh">Xhosa</MenuItem>
<MenuItem value="yi">Yiddish</MenuItem>
<MenuItem value="yo">Yoruba</MenuItem>
<MenuItem value="za">Zhuang</MenuItem>
<MenuItem value="zu">Zulu</MenuItem>
         
        </Select>
      </FormControl>
    </div>
  );
};

export default LanguageSelector;