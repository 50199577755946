import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  CircularProgress,
  IconButton,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Stack,
  Chip,
  Grid,
  Drawer,
  Dialog,
  DialogTitle,
  DialogContent,
  Menu,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Checkbox,

  OutlinedInput,
  
  
  List,
  ListItem,
 
  ListItemAvatar,
  Avatar,

  Divider,
  Badge,
  Alert,
  Snackbar,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import FilterListIcon from "@mui/icons-material/FilterList";
import DownloadIcon from "@mui/icons-material/Download";
import SortIcon from "@mui/icons-material/Sort";
import DateRangeIcon from "@mui/icons-material/DateRange";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import TableRowsIcon from "@mui/icons-material/TableRows";
import DescriptionIcon from "@mui/icons-material/Description";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import BlockIcon from "@mui/icons-material/Block";
import { DateRangePicker } from "react-date-range";
import moment from "moment";
import axios from "axios";
import AddIcon from "@mui/icons-material/Add";
import { useLocation,Link } from "react-router-dom";
import CommentIcon from "@mui/icons-material/Comment";
  import AccountCircleIcon from "@mui/icons-material/AccountCircle";
  
import { useIntl } from "react-intl";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { useAuthToken } from "../TokenContext";
import { useDispatch, useSelector } from "react-redux";

import CloseIcon from '@mui/icons-material/Close';
import { formatDistanceToNow } from 'date-fns';
import CheckIcon from "@mui/icons-material/Check";
// Add new styled components
const CommentDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    width: '600px',
    maxHeight: '80vh',
  },
}));

const CommentListItem = styled(ListItem)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: theme.spacing(2),
  '& .MuiListItemText-root': {
    margin: 0,
  },
  '& .comment-header': {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
  },
  '& .comment-metadata': {
    color: theme.palette.text.secondary,
    fontSize: '0.875rem',
  },
}));

// Add new components
const CommentCell = ({ comments }) => {
  const [open, setOpen] = useState(false);
  
  // Parse comments string to array
  const parsedComments = comments ? JSON.parse(comments) : [];
  const commentCount = parsedComments.length;

  return (
    <>
      <Tooltip title={`${commentCount} comments`}>
        <IconButton 
          onClick={() => setOpen(true)}
          color={commentCount > 0 ? "primary" : "default"}
        >
          <Badge badgeContent={commentCount} color="primary">
            <CommentIcon />
          </Badge>
        </IconButton>
      </Tooltip>

      <CommentDialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">Comments History</Typography>
            <IconButton onClick={() => setOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          {parsedComments.length > 0 ? (
            <List>
              {parsedComments.map((comment, index) => (
                <React.Fragment key={comment.comment_id || index}>
                  <CommentListItem>
                    <div className="comment-header">
                      <Box display="flex" alignItems="center" gap={1}>
                        <Avatar sx={{ bgcolor: 'primary.main' }}>
                          {comment.author ? comment.author[0].toUpperCase() : 'U'}
                        </Avatar>
                        <Typography variant="subtitle2">
                          {comment.author || 'User'}
                        </Typography>
                      </Box>
                      <Typography variant="caption" className="comment-metadata">
                        {formatDistanceToNow(new Date(comment.timestamp), { addSuffix: true })}
                      </Typography>
                    </div>
                    <Typography variant="body1" style={{ whiteSpace: 'pre-wrap' }}>
                      {comment.text}
                    </Typography>
                    {comment.replies && comment.replies.length > 0 && (
                      <Box ml={4} mt={1}>
                        <Typography variant="subtitle2" color="textSecondary">
                          Replies:
                        </Typography>
                        {comment.replies.map((reply, replyIndex) => (
                          <Box key={replyIndex} mt={1} ml={2}>
                            <Typography variant="body2">{reply}</Typography>
                          </Box>
                        ))}
                      </Box>
                    )}
                  </CommentListItem>
                  {index < parsedComments.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </List>
          ) : (
            <Box p={3} textAlign="center">
              <Typography color="textSecondary">
                No comments yet
              </Typography>
            </Box>
          )}
        </DialogContent>
      </CommentDialog>
    </>
  );
};

// Add CommentDialog component

// Styled Components
const ContentWrapper = styled(Box)(({ theme, collapsed }) => ({

  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center', // Center content horizontally
  marginLeft: collapsed ? "60px" : "240px" , // Changed from marginLeft and marginTop to center the content
  // margin:'auto',
   // Changed from fixed widths to percentage
  // Added maxWidth to ensure content doesn't get too wide
  padding: theme.spacing(3),
  [theme.breakpoints.down("sm")]: {
    width: '90%',
    padding: theme.spacing(1),
    margin: '4rem auto', // Adjusted margin for mobile
  },
}));

const StyledCard = styled(Card)(({ theme ,}) => ({

  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  marginTop: theme.spacing(3),
  "& .MuiTableCell-head": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontWeight: "bold",
  },
  "& .MuiTableRow-root:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
}));
const LoaderWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '400px',
  gap: '16px'
});
const StyledFormControl = styled(FormControl)(({ theme }) => ({
  minWidth: 200,
  marginRight: theme.spacing(2),
}));

const StyledPagination = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  padding: theme.spacing(2),
  gap: theme.spacing(1),
}));

// Status Chip Styles
const getStatusColor = (status) => {
  switch (status.toLowerCase()) {
    case "in progress":
      return "primary";
    case "to do":
      return "warning";
    case "done":
      return "success";
    default:
      return "default";
  }
};

const getBlockerIcon = (hasBlocker) => {
  return hasBlocker ? (
    <BlockIcon color="error" fontSize="small" />
  ) : (
    <CheckCircleIcon color="success" fontSize="small" />
  );
};
const ViewPodTasksManagerbyid = ({ collapsed }) => {
  const token = useAuthToken();
  const intl = useIntl();
  const location = useLocation();
  
  const { isLoggedIn, user } = useSelector(state => state.auth);
  const [isLoading, setIsLoading] = useState(true);
  
  // State declarations
  const [podTasks, setPodTasks] = useState([]);
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [statusFilter, setStatusFilter] = useState("");
  const [blockerFilter, setBlockerFilter] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [downloadMenu, setDownloadMenu] = useState(null);
   // Filter tasks
   const [selectedPodData, setSelectedPodData] = useState([]); // New state to store selected pod details

   const [teamMemberFilter, setTeamMemberFilter] = useState(""); // Add new state
   const [teamMembers, setTeamMembers] = useState([]); // Add state for team members list
 
  const [dateRange, setDateRange] = useState([
    {
      startDate: moment().subtract(30, 'days').toDate(),
      endDate: moment().toDate(),
      key: 'selection'
    }
  ]);
  const [sorting, setSorting] = useState({
    field: 'due_date',
    order: 'desc'
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info'
  });

 

  const [isInitialLoading, setIsInitialLoading] = useState(true); // For initial data load only
  const [allTasks, setAllTasks] = useState([]); // Store all tasks
  
  // Fetch pod tasks
  const [selectedPods, setSelectedPods] = useState([]);
  const [allPods, setAllPods] = useState([]);

  // Fetch all pods - runs once
  useEffect(() => {
    const fetchPods = async () => {
      try {
        const response = await axios.post(
          process.env.REACT_APP_CLIENT_GETMULTIPLECLIENTPODS,
          { clientID: user.data[0].emp_id },
          {
            headers: {
              'Authorization': token,
              'Content-Type': 'application/json',
            }
          }
        );
        setAllPods(response.data.data.podDetails);
      } catch (error) {
        console.error("Error fetching pods:", error);
      }
    };
    fetchPods();
  }, [token, user]);

  // Fetch all tasks - runs once
  useEffect(() => {
    const fetchPodTasks = async () => {
      setIsInitialLoading(true);
      try {
        const response = await axios.post(
          process.env.REACT_APP_CLIENT_CLIENTVIEWMYPODTASK,
          { },
          {
            headers: {
              'Authorization': token,
              'Content-Type': 'application/json',
            }
          }
        );

        const sortedData = response.data.data.sort((a, b) => 
          moment(b.due_date).valueOf() - moment(a.due_date).valueOf()
        );

        setAllTasks(sortedData);
        setPodTasks(sortedData);

        if (sortedData.length > 0) {
          const dates = sortedData.map(task => moment(task.due_date));
          setDateRange([{
            startDate: moment.min(dates).toDate(),
            endDate: moment.max(dates).toDate(),
            key: 'selection'
          }]);
        }
      } catch (error) {
        console.error("Error fetching pod tasks:", error);
        setSnackbar({
          open: true,
          message: "Failed to fetch pod tasks",
          severity: "error"
        });
      } finally {
        setIsInitialLoading(false);
      }
    };

    if (token) {
      fetchPodTasks();
    }
  }, [token]); // Only runs once when component mounts

  // Handle pod selection - just updates filters
  const handlePodChange = (event) => {
    const selectedIds = event.target.value;
    
    // Get the full pod data for selected pods
    const selectedPodsData = selectedIds.map(id => {
      const pod = allPods.find(p => p.id === id);
      return {
        id: pod.id,
        podName: pod.podName
      };
    });
  
    setSelectedPods(selectedIds);
    setSelectedPodData(selectedPodsData);
  };
  // Combined filter effect - handles all filtering including pod selection
  useEffect(() => {
    console.log("Selected Pods:", selectedPods); // This contains 'id' values
    console.log("Sample Task pod_id:", allTasks[0]?.pod_id); // This contains 'pod_id' values
  }, [selectedPods, allTasks]);

  // Combined filter effect with fixed key matching
  useEffect(() => {
    let result = [...allTasks];

    // Filter by selected pods - Convert both to strings for comparison
    if (selectedPods.length > 0) {
      result = result.filter(task => 
        selectedPods.some(id => id.toString() === task.pod_id.toString())
      );
    }

    // Apply other filters...
    if (statusFilter) {
      result = result.filter(task => task.status === statusFilter);
    }

    if (blockerFilter !== "") {
      result = result.filter(task => 
        blockerFilter === "yes" ? task.blockercheck === 1 : task.blockercheck !== 1
      );
    }

    if (teamMemberFilter) {
      result = result.filter(task => task.Candidate_Name === teamMemberFilter);
    }

    if (dateRange[0]) {
      const { startDate, endDate } = dateRange[0];
      result = result.filter(task => {
        const taskDate = moment(task.due_date);
        return taskDate.isBetween(startDate, endDate, 'day', '[]');
      });
    }

    result.sort((a, b) => {
      const multiplier = sorting.order === 'asc' ? 1 : -1;
      if (sorting.field === 'due_date') {
        return multiplier * (moment(a.due_date).valueOf() - moment(b.due_date).valueOf());
      }
      return multiplier * (a[sorting.field] > b[sorting.field] ? 1 : -1);
    });

    setFilteredTasks(result);
    setPodTasks(result); // Update podTasks for statistics
    setCurrentPage(1);
  }, [allTasks, selectedPods, statusFilter, blockerFilter, teamMemberFilter, dateRange, sorting]);

  // Statistics calculation with filtered data
  const getStatistics = () => [
    {
      title: intl.formatMessage({ id: "totalTasks" }),
      value: filteredTasks.length,
      color: "primary.main"
    },
    {
      title: intl.formatMessage({ id: "inProgress" }),
      value: filteredTasks.filter(task => task.status === "In Progress").length,
      color: "info.main"
    },
    {
      title: intl.formatMessage({ id: "completed" }),
      value: filteredTasks.filter(task => task.status === "Done").length,
      color: "success.main"
    },
    {
      title: intl.formatMessage({ id: "blocked" }),
      value: filteredTasks.filter(task => task.blockercheck === 1).length,
      color: "error.main"
    }
  ];
  // Add useEffect to get unique team members
  useEffect(() => {
    const uniqueMembers = [...new Set(podTasks.map(task => task.Candidate_Name))];
    setTeamMembers(uniqueMembers);
  }, [podTasks]);
  // Handle sort
  const handleSort = (field) => {
    setSorting(prev => ({
      field,
      order: prev.field === field && prev.order === 'asc' ? 'desc' : 'asc'
    }));
  };

  // Handle download
  const handleDownload = async (format) => {
    try {
      if (filteredTasks.length === 0) {
        throw new Error('No data to export');
      }
    

     
      const data = filteredTasks.map(task => {
        // Find pod name for the task
        const taskPod = selectedPodData.find(pod => pod.id === task.pod_id);
        return {
          'Pod Name': taskPod ? taskPod.podName : 'Unknown Pod',
          'Task Name': task.pod_task_name,
          'Status': task.status,
          'Team Member': task.Candidate_Name,
          'Category': task.category,
          'Description': task.description,
          'Jira Item': task.jira_item,
          'Has Blocker': task.blockercheck === 1 ? 'Yes' : 'No',
          'Due Date': moment(task.due_date).format('MM/DD/YYYY')
        };
      });

      switch (format) {
        case 'csv':
          const headers = Object.keys(data[0]).join(',');
          const csvData = data.map(row => Object.values(row).join(',')).join('\n');
          const csvContent = `${headers}\n${csvData}`;
          const blob = new Blob([csvContent], { type: 'text/csv' });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = `pod_tasks_${moment().format('YYYY-MM-DD')}.csv`;
          link.click();
          break;

        case 'pdf':
          const doc = new jsPDF('landscape');
          doc.autoTable({
            head: [Object.keys(data[0])],
            body: data.map(Object.values),
            styles: { fontSize: 8, cellPadding: 2 },
            headStyles: { fillColor: [0, 180, 216] }
          });
          doc.save(`pod_tasks_${moment().format('YYYY-MM-DD')}.pdf`);
          break;

        default:
          throw new Error('Unsupported format');
      }

      setSnackbar({
        open: true,
        message: `Successfully exported to ${format.toUpperCase()}`,
        severity: 'success'
      });
    } catch (error) {
      console.error('Export error:', error);
      setSnackbar({
        open: true,
        message: `Failed to export: ${error.message}`,
        severity: 'error'
      });
    }
    setDownloadMenu(null);
  };

  const rowsPerPage = 10;
  const pageCount = Math.ceil(filteredTasks.length / rowsPerPage);
  const currentTasks = filteredTasks.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );






  

 
    return (
      <ContentWrapper collapsed={collapsed}>
        {/* Header and Stats */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="h4" component="h1" gutterBottom>
          {intl.formatMessage({ id: "podTasks" })}
          </Typography>
              {/* Pod Selection - Always visible */}
        <Stack direction="row" spacing={2} sx={{ mb: 3 }}>
        <FormControl sx={{ minWidth: 300 }}>
    <InputLabel>{intl.formatMessage({ id: "selectPods" })}</InputLabel>
    <Select
      multiple
      value={selectedPods}
      onChange={handlePodChange}
      input={<OutlinedInput label={intl.formatMessage({ id: "selectPods" })} />}
      renderValue={(selected) => (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
          {selectedPodData.map((pod) => (
            <Chip
              key={pod.id}
              label={pod.podName}
              size="small"
              onDelete={(e) => {
                e.stopPropagation();
                const newSelected = selectedPods.filter(id => id !== pod.id);
                handlePodChange({ target: { value: newSelected } });
              }}
              sx={{
                margin: '2px',
                maxWidth: '150px',
                '& .MuiChip-label': {
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap'
                }
              }}
            />
          ))}
        </Box>
      )}
      MenuProps={{
        PaperProps: {
          style: {
            maxHeight: 300
          }
        }
      }}
    >
      {allPods.map((pod) => (
        <MenuItem 
          key={pod.id} 
          value={pod.id}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            py: 1
          }}
        >
          <Typography
            sx={{
              maxWidth: '280px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            }}
          >
            {pod.podName}
          </Typography>
          {selectedPods.includes(pod.id) && (
            <CheckIcon sx={{ ml: 1, color: 'primary.main' }} />
          )}
        </MenuItem>
      ))}
    </Select>
  </FormControl>

          <Link 
            to="/TaskForm" 
            state={{ 
              podIds: selectedPods,
              podNames: selectedPodData.map(pod => pod.podName)
            }}
            style={{ textDecoration: 'none' }}
          >
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
          
            >
              {intl.formatMessage({ id: "createTask" })}
            </Button>
          </Link>
        </Stack>
        {isInitialLoading ? (
          <LoaderWrapper>
            <CircularProgress size={60} />
            <Typography variant="h6" color="textSecondary">
              {intl.formatMessage({ id: "loadingData" })}
            </Typography>
          </LoaderWrapper>
        ) : (
          <>
         

          <Grid container spacing={3} sx={{ mb: 4 }}>
            {getStatistics().map((stat, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <StyledCard>
                  <CardContent>
                    <Typography color="textSecondary" gutterBottom>
                      {stat.title}
                    </Typography>
                    <Typography variant="h4" sx={{ color: stat.color }}>
                      {stat.value}
                    </Typography>
                  </CardContent>
                </StyledCard>
              </Grid>
            ))}
          </Grid>
  
          {/* Action Buttons */}
          <Stack direction="row" spacing={2} sx={{ mb: 3 }}>
            <Button
              variant="contained"
              startIcon={<FilterListIcon />}
              onClick={() => setShowFilters(true)}
            >
              {intl.formatMessage({ id: "filters" })}
            </Button>
  
            <Button
              variant="contained"
              startIcon={<DateRangeIcon />}
              onClick={() => setShowDatePicker(true)}
            >
              {intl.formatMessage({ id: "dateRange" })}
            </Button>
  
            <Button
              variant="contained"
              startIcon={<DownloadIcon />}
              onClick={(e) => setDownloadMenu(e.currentTarget)}
            >
              {intl.formatMessage({ id: "export" })}
            </Button>
          </Stack>
  
          {/* Download Menu */}
          <Menu
            anchorEl={downloadMenu}
            open={Boolean(downloadMenu)}
            onClose={() => setDownloadMenu(null)}
          >
            <MenuItem onClick={() => handleDownload("csv")}>
              <ListItemIcon>
                <TableRowsIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>CSV</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => handleDownload("pdf")}>
              <ListItemIcon>
                <PictureAsPdfIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>PDF</ListItemText>
            </MenuItem>
          </Menu>
  
          {/* Filters Drawer */}
          <Drawer
        anchor="right"
        open={showFilters}
        onClose={() => setShowFilters(false)}
      >
        <Box sx={{ width: 300, p: 3 }}>
          <Typography variant="h6" gutterBottom>
            {intl.formatMessage({ id: "filters" })}
          </Typography>
          <Stack spacing={3}>
            <FormControl fullWidth>
              <InputLabel>{intl.formatMessage({ id: "status" })}</InputLabel>
              <Select
                value={statusFilter}
                onChange={(e) => setStatusFilter(e.target.value)}
                label={intl.formatMessage({ id: "status" })}
              >
                <MenuItem value="">
                  {intl.formatMessage({ id: "allStatus" })}
                </MenuItem>
                <MenuItem value="In Progress">In Progress</MenuItem>
                <MenuItem value="To Do">To Do</MenuItem>
                <MenuItem value="Done">Done</MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel>{intl.formatMessage({ id: "teamMember" })}</InputLabel>
              <Select
                value={teamMemberFilter}
                onChange={(e) => setTeamMemberFilter(e.target.value)}
                label={intl.formatMessage({ id: "teamMember" })}
              >
                <MenuItem value="">
                  {intl.formatMessage({ id: "allTeamMembers" })}
                </MenuItem>
                {teamMembers.map((member) => (
                  <MenuItem key={member} value={member}>
                    {member}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel>{intl.formatMessage({ id: "blockers" })}</InputLabel>
              <Select
                value={blockerFilter}
                onChange={(e) => setBlockerFilter(e.target.value)}
                label={intl.formatMessage({ id: "blockers" })}
              >
                <MenuItem value="">
                  {intl.formatMessage({ id: "allBlockers" })}
                </MenuItem>
                <MenuItem value="yes">Has Blockers</MenuItem>
                <MenuItem value="no">No Blockers</MenuItem>
              </Select>
            </FormControl>

            <Button
              variant="outlined"
              onClick={() => {
                setStatusFilter("");
                setBlockerFilter("");
                setTeamMemberFilter(""); // Clear team member filter
              }}
            >
              {intl.formatMessage({ id: "clearFilters" })}
            </Button>
          </Stack>
        </Box>
      </Drawer>
  
          {/* Date Range Picker Dialog */}
          <Dialog
            open={showDatePicker}
            onClose={() => setShowDatePicker(false)}
            maxWidth="md"
          >
            <DialogTitle>
              {intl.formatMessage({ id: "selectDateRange" })}
            </DialogTitle>
            <DialogContent>
              <DateRangePicker
                onChange={item => setDateRange([item.selection])}
                ranges={dateRange}
                months={2}
                direction="horizontal"
              />
            </DialogContent>
          </Dialog>
  
          {/* Main Table */}
          <StyledTableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{intl.formatMessage({ id: "taskName" })}</TableCell>
                  <TableCell>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      {intl.formatMessage({ id: "status" })}
                    </Stack>
                  </TableCell>
                  <TableCell>{intl.formatMessage({ id: "teamMember" })}</TableCell>
                  <TableCell>{intl.formatMessage({ id: "category" })}</TableCell>
                  <TableCell>{intl.formatMessage({ id: "description" })}</TableCell>
                  <TableCell>{intl.formatMessage({ id: "jiraItem" })}</TableCell>
                  <TableCell>{intl.formatMessage({ id: "blockers" })}</TableCell>
                  <TableCell>
              {intl.formatMessage({ id: "comments" })}
            </TableCell>
                  <TableCell>
                    <Stack 
                      direction="row" 
                      alignItems="center" 
                      spacing={1}
                      sx={{ cursor: 'pointer' }}
                      onClick={() => handleSort('due_date')}
                    >
                      {intl.formatMessage({ id: "dueDate" })}
                      <SortIcon 
                        fontSize="small"
                        sx={{
                          transform: sorting.field === 'due_date' && sorting.order === 'desc' 
                            ? 'rotate(180deg)' 
                            : 'none'
                        }}
                      />
                    </Stack>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentTasks.map((task) => (
                  <TableRow
                    key={task.pod_task_id}
                    sx={{
                      '&:hover': { backgroundColor: 'action.hover' },
                    }}
                  >
                    <TableCell>{task.pod_task_name}</TableCell>
                    <TableCell>
                      <Chip
                        label={task.status}
                        color={getStatusColor(task.status)}
                        size="small"
                      />
                    </TableCell>
                    <TableCell>{task.Candidate_Name}</TableCell>
                    <TableCell>{task.category}</TableCell>
                    <TableCell>
                      <Tooltip title={task.description}>
                        <Typography noWrap sx={{ maxWidth: 200 }}>
                          {task.description}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell>{task.jira_item}</TableCell>
                    <TableCell>
                      <Tooltip 
                        title={task.blockercheck === 1 ? "Has Blockers" : "No Blockers"}
                      >
                        {getBlockerIcon(task.blockercheck === 1)}
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                <CommentCell comments={task.comment} />
              </TableCell>
                    <TableCell>
                      {moment(task.due_date).format("MM/DD/YYYY")}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </StyledTableContainer>
  
          {/* Pagination */}
         
          <StyledPagination>
            <Button
              disabled={currentPage === 1}
              onClick={() => setCurrentPage(prev => prev - 1)}
            >
              {intl.formatMessage({ id: "prev" })}
            </Button>
            
            {(() => {
              const totalPages = pageCount;
              const maxVisiblePages = 5;
              let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
              let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);
              
              if (endPage - startPage + 1 < maxVisiblePages) {
                startPage = Math.max(1, endPage - maxVisiblePages + 1);
              }

              const pages = [];

              if (startPage > 1) {
                pages.push(
                  <Button key={1} variant="outlined" onClick={() => setCurrentPage(1)}>
                    1
                  </Button>
                );
                if (startPage > 2) {
                  pages.push(
                    <span key="ellipsis1" style={{ margin: '0 8px' }}>...</span>
                  );
                }
              }

              for (let i = startPage; i <= endPage; i++) {
                pages.push(
                  <Button
                    key={i}
                    variant={currentPage === i ? "contained" : "outlined"}
                    onClick={() => setCurrentPage(i)}
                  >
                    {i}
                  </Button>
                );
              }

              if (endPage < totalPages) {
                if (endPage < totalPages - 1) {
                  pages.push(
                    <span key="ellipsis2" style={{ margin: '0 8px' }}>...</span>
                  );
                }
                pages.push(
                  <Button 
                    key={totalPages} 
                    variant="outlined" 
                    onClick={() => setCurrentPage(totalPages)}
                  >
                    {totalPages}
                  </Button>
                );
              }

              return pages;
            })()}

            <Button
              disabled={currentPage === pageCount}
              onClick={() => setCurrentPage(prev => prev + 1)}
            >
              {intl.formatMessage({ id: "next" })}
            </Button>
          </StyledPagination>

          <Snackbar
            open={snackbar.open}
            autoHideDuration={6000}
            onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
          >
            <Alert
              onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
              severity={snackbar.severity}
            >
              {snackbar.message}
            </Alert>
          </Snackbar>
          </>)}
        </Box>
      </ContentWrapper>
    );
};

export default ViewPodTasksManagerbyid;