import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuthToken } from "../TokenContext";
import { useSelector } from 'react-redux';
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  IconButton,
  CircularProgress,
  Alert,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { styled } from '@mui/material/styles';

// Styled components
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  '&.MuiTableCell-head': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontWeight: 'bold',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

// Custom tooltip component for range information
const RangeTooltip = ({ range }) => (
  <div style={{ padding: '8px' }}>
    <div style={{ fontWeight: 'bold', marginBottom: '8px' }}>Reference Ranges:</div>
    <div style={{ display: 'grid', gridTemplateColumns: '1fr', gap: '4px' }}>
      <div>Elite: {range.Elite}</div>
      <div>High: {range.High}</div>
      <div>Medium: {range.Medium}</div>
      <div>Low: {range.Low}</div>
    </div>
  </div>
);

const Dorametricslinegraph = ({ initialPodIds }) => {
  const [metricsData, setMetricsData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const token = useAuthToken();
  const { user } = useSelector(state => state.auth);

  useEffect(() => {
    const fetchData = async () => {
      if (!token) {
        setError('Token is missing. API requests will not be made.');
        return;
      }

      setIsLoading(true);
      setError(null);

      try {
        const headers = {
          'Authorization': token,
          'Content-Type': 'application/json',
        };

        let response;
        if (user.data[0].type === "stakeholder") {
          const payload = {
            pod_id: initialPodIds || [],
            org_code: user.data[0].client_code
          };

          response = await axios.post(
            process.env.REACT_APP_STAKEHOLDER_JDORAMETRICSSTAKEHOLDER,
            payload,
            { headers }
          );
        } else {
          response = await axios.post(
            process.env.REACT_APP_CLIENT_DORAMETRICSMANAGER,
            {
              manager_id: user.data[0].emp_id,
              pod_id: initialPodIds,
            },
            { headers }
          );
        }

        const parsedData = typeof response.data.body === 'string' 
          ? JSON.parse(response.data.body) 
          : response.data.body;
          
        setMetricsData(parsedData);
      } catch (error) {
        setError(`Error fetching DORA metrics: ${error.message}`);
        console.error('Error fetching DORA metrics:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [token, user.data[0].type, initialPodIds]);

  // Get category color based on performance level
  const getCategoryColor = (category) => {
    switch (category.toLowerCase()) {
      case 'elite':
        return '#2e7d32'; // Green
      case 'high':
        return '#1976d2'; // Blue
      case 'medium':
        return '#ed6c02'; // Orange
      case 'low':
        return '#d32f2f'; // Red
      default:
        return 'inherit';
    }
  };

  if (error) {
    return <Alert severity="error" sx={{ margin: 2 }}>{error}</Alert>;
  }

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <TableContainer component={Paper} sx={{ margin: 2, maxWidth: 'calc(100% - 32px)' }}>
      <Table sx={{ minWidth: 650 }} aria-label="DORA metrics table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Metric Name</StyledTableCell>
            <StyledTableCell>Metric Value</StyledTableCell>
            <StyledTableCell>Category</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {metricsData && metricsData.map((metric) => (
            <StyledTableRow key={metric["Metric Name"]}>
              <TableCell>{metric["Metric Name"]}</TableCell>
              <TableCell>{metric["Metric Value"]}</TableCell>
              <TableCell>
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  <span style={{ color: getCategoryColor(metric.Category) }}>
                    {metric.Category}
                  </span>
                  <Tooltip 
                    title={<RangeTooltip range={metric.Range} />}
                    arrow
                    placement="right"
                  >
                    <IconButton size="small">
                      <InfoIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </div>
              </TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

Dorametricslinegraph.propTypes = {
  initialPodIds: PropTypes.array.isRequired,
};

export default Dorametricslinegraph;