import React, { useEffect, useState, useRef } from 'react';
import { Chart, registerables } from 'chart.js';
import 'chartjs-plugin-annotation';
import { useAuthToken } from "../TokenContext";
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { 
  Modal, 
  Box, 
  Table, 
  TableBody, 
  TableCell, 
  TableHead, 
  TableRow, 
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@mui/material';

Chart.register(...registerables);

// Constants
const CHART_DIMENSIONS = { width: 155, height: 155 };
const CHART_COLORS = {
  completed: 'lightgreen',
  open: 'red'
};

// Reusable styled components
const StyledTableHead = ({ children }) => (
  <TableHead
    sx={{
      backgroundColor: '#00B4D8',
      '& td, & th': {
        fontFamily: 'Poppins,Medium',
        fontSize: '15px',
        color: 'white',
        fontWeight: '600',
      },
    }}
  >
    {children}
  </TableHead>
);

const StyledTableRow = ({ children, isEven }) => (
  <TableRow
    sx={{
      '&:last-child td, &:last-child th': { 
        border: 0, 
        fontFamily: 'Poppins,Medium', 
        fontSize: '15px' 
      },
      backgroundColor: isEven ? '#90E0EF' : '#caf0f8',
      '& td, & th': {
        fontFamily: 'Poppins,Medium',
        fontSize: '15px',
        color: 'black',
      },
    }}
  >
    {children}
  </TableRow>
);

const TaskDetailsModal = ({ open, onClose, taskData, intl }) => {
  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    maxHeight: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: 'auto',
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={modalStyle}>
        <h2>{intl.formatMessage({ id: 'taskDetails' })}</h2>
        <Table component={Paper} sx={{ maxWidth: 900, fontFamily: 'Poppins,Medium', borderRadius: '10px' }}>
          <StyledTableHead>
            <TableRow>
              {['candidateName', 'projectKey', 'podTaskName', 'category', 'issueType', 'status'].map(key => (
                <TableCell key={key}>{intl.formatMessage({ id: key })}</TableCell>
              ))}
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {taskData.map((task, index) => (
              <StyledTableRow key={index} isEven={index % 2 === 0}>
                <TableCell>{task.candidate_name}</TableCell>
                <TableCell>{task.project_key}</TableCell>
                <TableCell>{task.pod_task_name}</TableCell>
                <TableCell>{task.category}</TableCell>
                <TableCell>{task.issuetype}</TableCell>
                <TableCell>{task.status}</TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Modal>
  );
};

const DoughnutChart = ({ chartId, chartData, onClick }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const ctx = document.getElementById(chartId);
    if (!ctx) return;

    if (chartRef.current) {
      chartRef.current.destroy();
    }

    chartRef.current = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: ['Completed Tasks', 'Open Tasks'],
        datasets: [{
          data: chartData,
          backgroundColor: [CHART_COLORS.completed, CHART_COLORS.open],
          borderWidth: 1,
        }],
      },
      options: {
        responsive: false,
        maintainAspectRatio: false,
        plugins: {
          annotation: {},
          legend: { display: false },
        },
        layout: { padding: 10 },
        tooltips: {
          enabled: true,
          mode: 'single',
          callbacks: {
            label: (tooltipItem, data) => data.datasets[0].data[tooltipItem.dataIndex],
          },
        },
        onClick,
      },
    });

    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, [chartData, chartId, onClick]);

  return <canvas id={chartId} />;
};

const DetailsTaskmetricsManager = ({ initialPodIds, selectedSprint }) => {
  const token = useAuthToken();
  const intl = useIntl();
  const { user } = useSelector((state) => state.auth);
  const chartId = useRef(`completedChart-${crypto.randomUUID()}`).current;
  const [metrics, setMetrics] = useState(null);
  const [open, setOpen] = useState(false);
  const [taskData, setTaskData] = useState([]);
  const [selectedTalent, setSelectedTalent] = useState('');

  const fetchMetrics = async () => {
    if (!token) return;

    try {
      const response = await fetch(process.env.REACT_APP_CLIENT_PODTASKSMETRICSMANAGER, {
        method: 'POST',
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          manager_id: user.data[0].emp_id,
          pod_id: initialPodIds,
        }),
      });

      const { body: parsedData } = await response.json();
      
      if (parsedData?.status_counts && parsedData?.pod_tasks_by_talent) {
        setMetrics(parsedData);
      } else {
        setMetrics(null);
      }
    } catch (error) {
      console.error('Error fetching metrics:', error);
      setMetrics(null);
    }
  };

  useEffect(() => {
    fetchMetrics();
  }, [initialPodIds, token, selectedSprint]);

  const getChartData = () => {
    if (!metrics) return [0, 0];
    
    if (selectedTalent) {
      const talentData = metrics.pod_tasks_by_talent.find(t => t.Talent_name === selectedTalent);
      return talentData ? [talentData.Complete || 0, talentData.Open || 0] : [0, 0];
    }
    
    return [metrics.status_counts.Complete, metrics.status_counts.Open];
  };

  const handleChartClick = () => {
    setTaskData(metrics.pod_tasks);
    setOpen(true);
  };

  return (
    <div className="chart-container">
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <h2 className="chart-heading">{intl.formatMessage({ id: 'Tasks' })}</h2>
        
        <FormControl sx={{ width: '12rem', mt: 2 }}>
         
          <select
            value={selectedTalent}
            onChange={(e) => setSelectedTalent(e.target.value)}
            label="Select Talent"
           style={{width:"12rem"}}
          >
            <option value="">All Reporters</option>
            {metrics?.pod_tasks_by_talent?.map((talent, index) => (
              <option key={index} value={talent.Talent_name}>
                {talent.Talent_name}
              </option>
            ))}
          </select>
        </FormControl>

        <div className="chart-wrapper" style={{ ...CHART_DIMENSIONS, margin: "30px auto 0" }}>
          {metrics && (metrics.status_counts.Open !== 0 || metrics.status_counts.Complete !== 0) ? (
            <DoughnutChart 
              chartId={chartId}
              chartData={getChartData()}
              onClick={handleChartClick}
            />
          ) : (
            <p>{intl.formatMessage({ id: 'noDataAvailable' })}</p>
          )}
        </div>
      </div>

      <TaskDetailsModal 
        open={open}
        onClose={() => setOpen(false)}
        taskData={taskData}
        intl={intl}
      />
    </div>
  );
};

export default DetailsTaskmetricsManager;