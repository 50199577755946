import React from 'react';
import styled from 'styled-components';
import { NavHashLink } from 'react-router-hash-link';
import { cardShadow, hoverEffect } from '../utils';
import Avatar from "./assets/MaskGroup.png";

const PostReqPodCreatedSuccessfully = () => {
  return (
    <Container>
      <Layout>
        <MainCard>
          <SuccessMessage>
            <p>Your Pod requirements have been</p>
            <p>posted successfully!</p>
          </SuccessMessage>
          <ActionButton as={NavHashLink} to='./PostRequirementCand'>
            Go Back
          </ActionButton>
        </MainCard>

        <SidePanel>
          <PanelTitle>Recommended Pods</PanelTitle>
          
          <RecommendationCard>
            <UserInfo>
              <Avatars src={Avatar} alt="" />
              <UserDetails>
                <Name>Thomas Martin</Name>
                <TimeStamp>10 days ago</TimeStamp>
              </UserDetails>
            </UserInfo>

            <Content>
              <Title>Need a designer to form branding essentials for my business</Title>
              <Label>Required Position: UX/UI Designer</Label>
            </Content>
          </RecommendationCard>

          <ViewAllButton>View All</ViewAllButton>

          <NavigationCard>
            <Title>Your Posted Pod Requirements</Title>
            <NavButton as={NavHashLink} to='/ViewPostedProReq' smooth>
              View
            </NavButton>
          </NavigationCard>
        </SidePanel>
      </Layout>
    </Container>
  );
};

const Container = styled.div`
  padding: 6rem 6.5rem;
  
  @media (max-width: 1080px) {
    padding: 2rem 1rem;
    width: 100%;
  }
`;

const Layout = styled.div`
  display: flex;
  gap: 2rem;
  
  @media (max-width: 1080px) {
    flex-direction: column;
  }
`;

const BaseCard = styled.div`
  background-color: #F2F4F5;
  border-radius: 20px;
  padding: 1rem;
  box-shadow: ${cardShadow};
  transition: 0.45s ease-in-out;
  font-family: 'Poppins', sans-serif;
  
  &:hover {
    box-shadow: ${hoverEffect};
  }
`;

const MainCard = styled(BaseCard)`
  width: 40rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  @media (max-width: 1080px) {
    width: 100%;
  }
`;

const SidePanel = styled(BaseCard)`
  width: 20rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  @media (max-width: 1080px) {
    width: 100%;
  }
`;

const SuccessMessage = styled.div`
  text-align: center;
  margin-bottom: 2rem;
  font-size: 1.2rem;
`;

const Button = styled.button`
  background-color: #1DBF73;
  color: white;
  border: none;
  border-radius: 10px;
  padding: 1rem 2rem;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  box-shadow: 1px 3px 6px #00000029;
  
  &:hover {
    opacity: 0.9;
  }
`;

const ActionButton = styled(Button)`
  width: 20rem;
  text-decoration: none;
  text-align: center;
  
  @media (max-width: 1080px) {
    width: 100%;
    max-width: 20rem;
  }
`;

const RecommendationCard = styled.div`
  background-color: #90E0EF;
  width: 100%;
  padding: 1rem;
  border-radius: 20px;
  margin: 1rem 0;
`;

const NavigationCard = styled(RecommendationCard)`
  height: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
`;

const Avatars = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
`;

const UserDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const Name = styled.h6`
  font-weight: 600;
  margin: 0;
`;

const TimeStamp = styled.span`
  font-size: 13px;
  font-weight: 600;
`;

const Content = styled.div`
  margin-top: 1rem;
`;

const Title = styled.h6`
  font-weight: 600;
  margin: 0 0 0.5rem 0;
`;

const Label = styled.p`
  background-color: white;
  padding: 0.5rem;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 600;
  margin: 0;
`;

const PanelTitle = styled.h1`
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 1.2rem;
`;

const ViewAllButton = styled(Button)`
  width: 5rem;
  height: 2rem;
  padding: 0;
  border: 2px solid #1DBF73;
  background: transparent;
  color: #1DBF73;
  margin: 1rem 0;
`;

const NavButton = styled(Button)`
  width: 8rem;
  height: 2rem;
  padding: 0;
  background-color: white;
  color: black;
`;

export default PostReqPodCreatedSuccessfully;