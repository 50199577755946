import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import "./Popup.css";

const DefectsTable = ({ defects }) => {
  const tableStyles = {
    maxWidth: 900,
    fontFamily: "Poppins,Medium",
    borderRadius: "10px",
  };

  const headerStyles = {
    backgroundColor: "#00B4D8",
    "& td, & th": {
      fontFamily: "Poppins,Medium",
      fontSize: "15px",
      color: "white",
      fontWeight: "600",
    },
  };

  const rowStyles = {
    "&:last-child td, &:last-child th": {
      border: 0,
      fontFamily: "Poppins,Medium",
      fontSize: "15px",
    },
    "&:nth-child(even)": {
      backgroundColor: "#90E0EF",
    },
    "&:nth-child(odd)": {
      backgroundColor: "#caf0f8",
    },
    "& td, & th": {
      fontFamily: "Poppins,Medium",
      fontSize: "15px",
      color: "black",
    },
  };

  return (
    <TableContainer component={Paper}>
      <Table component={Paper} sx={tableStyles}>
        <TableHead sx={headerStyles}>
          <TableRow>
            <TableCell align="right" sx={{ fontWeight: "bold" }}>
              Issue Key
            </TableCell>
            <TableCell align="right" sx={{ fontWeight: "bold" }}>
              Issue Summary
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {defects.map((defect) => (
            <TableRow key={defect.issue_key} sx={rowStyles}>
              <TableCell align="right">{defect.issue_key}</TableCell>
              <TableCell align="right">{defect.issue_summary}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const PopupDefectsDetailView = ({ openDefects, closedDefects, onClose }) => {
  return (
    <div className="popup">
      <div className="popup-inner">
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <h4>Open Defects</h4>
            {openDefects.length > 0 ? (
              <DefectsTable defects={openDefects} />
            ) : (
              <p>No open defects</p>
            )}
          </div>
          
          <div style={{ display: "flex", flexDirection: "column", marginLeft: "2rem" }}>
            <h4>Closed Defects</h4>
            {closedDefects.length > 0 ? (
              <DefectsTable defects={closedDefects} />
            ) : (
              <p>No closed defects</p>
            )}
          </div>
        </div>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default PopupDefectsDetailView;