import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Sidebarnew from './Sidebarnew';
import Dashboardnew from './Dashboardnew';
import AppBarIcons from './AppBarIcons';
import Talentdashboardpodactivity from './Talentdasboardpodactivity';
import InfoDummy from './Info';

const LayoutContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : '#f0f0f0',
  margin: 0,
  height: '100vh',
  width: '100vw',
  overflowY: 'auto',
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  transition: 'background-color 0.3s ease',
  '&::-webkit-scrollbar': {
    display: 'none'
  }
}));

const ContentContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'collapsed'
})(({ theme, collapsed }) => ({
  flexGrow: 1,
  overflowY: 'auto',
  padding: '20px',
  display: 'flex',
  flexDirection: 'column',
  transition: 'margin-left 0.3s, width 0.3s',
  marginLeft: collapsed ? '60px' : '240px',
  width: collapsed ? 'calc(100% - 60px)' : 'calc(100% - 240px)',
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : 'inherit',
  color: theme.palette.mode === 'dark' ? theme.palette.text.primary : 'inherit'
}));

const DashboardContainer = styled(Box)({
  marginBottom: '20px'
});

const RowContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: 'auto',
  padding: '20px',
  height: '100%',
  '@media (max-width: 768px)': {
    flexDirection: 'column',
    margin: 'auto',
    height: 'auto'
  }
}));

const ContainerWithSameHeight = styled(Box)(({ theme }) => ({
  width: '100%',
  overflowY: 'auto',
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : 'white',
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  height: '90%',
  transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
  color: theme.palette.mode === 'dark' ? theme.palette.text.primary : 'inherit',
  '&::-webkit-scrollbar': {
    display: 'none'
  },
  '@media (max-width: 768px)': {
    flexDirection: 'column',
    margin: 'auto',
    height: 'auto'
  }
}));

const TalentdashboardContainer = styled(ContainerWithSameHeight)(({ theme }) => ({
  width: '70%',
  marginRight: '10px',
  borderRadius: '15px',
  boxShadow: theme.palette.mode === 'dark' 
    ? '0 4px 8px rgba(255, 255, 255, 0.1)' 
    : '0 4px 8px rgba(0, 0, 0, 0.3)',
  '@media (max-width: 768px)': {
    width: '100%',
    marginBottom: '10px'
  }
}));

const InfoDummyContainer = styled(ContainerWithSameHeight)(({ theme }) => ({
  width: '30%',
  borderRadius: '15px',
  boxShadow: theme.palette.mode === 'dark' 
    ? '0 4px 8px rgba(255, 255, 255, 0.1)' 
    : '0 4px 8px rgba(0, 0, 0, 0.3)',
  '@media (max-width: 768px)': {
    width: '100%',
    marginBottom: '10px'
  }
}));

const MainLayout = ({ collapsed }) => {
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const handleSidebarToggle = () => {
    setSidebarOpen((prev) => !prev);
  };

  return (
    <LayoutContainer>
      <ContentContainer collapsed={collapsed}>
        <DashboardContainer>
          <Dashboardnew />
        </DashboardContainer>
        <RowContainer>
          <TalentdashboardContainer>
            <Talentdashboardpodactivity />
          </TalentdashboardContainer>
          <InfoDummyContainer>
            <InfoDummy />
          </InfoDummyContainer>
        </RowContainer>
      </ContentContainer>
    </LayoutContainer>
  );
};

export default MainLayout;