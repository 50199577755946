import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import 'bulma/css/bulma.min.css';
import './index.css';
import App from './App';
import { Amplify } from 'aws-amplify';
import config from './config';
import { Provider } from 'react-redux';
import store from './store';
import { AuthProvider } from './TokenContext';
import { IntlProvider } from 'react-intl';
import {  locales } from './components/intl';
import ErrorBoundary from './ErrorBoundary';
import { ThemeProvider } from './ThemeContext';

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
});

const Root = () => {
  const [locale, setLocale] = useState(() => {
    return localStorage.getItem('locale') || 'en'; // Initialize locale from local storage
  });

  const changeLocale = (newLocale) => {
    setLocale(newLocale);
  };

  return (
    <Provider store={store}>
    <AuthProvider>
      <ThemeProvider>
        <IntlProvider locale={locale} messages={locales[locale]}>
          <ErrorBoundary>
            <App changeLocale={changeLocale} currentLocale={locale} />
          </ErrorBoundary>
        </IntlProvider>
      </ThemeProvider>
    </AuthProvider>
  </Provider>
  );
};

ReactDOM.render(<Root />, document.getElementById('root'));
