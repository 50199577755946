import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button } from 'react-bootstrap';
import axios from 'axios';
import { Select, MenuItem } from '@mui/material';
import TextField from '@mui/material/TextField';

import { MdOutlineDeleteOutline } from 'react-icons/md';
import { AiOutlineEdit } from 'react-icons/ai';
import { RiNurseFill } from 'react-icons/ri';
import { useAuthToken } from "../TokenContext";
import { useIntl } from 'react-intl';

function Mastergithub(props) {
  const token = useAuthToken();
  const intl = useIntl();

  const [candidates, setCandidates] = useState([]);
  const [editingCandidateId, setEditingCandidateId] = useState(null);
  const [editedCandidateName, setEditedCandidateName] = useState('');
  const [candidateNames, setCandidateNames] = useState([]);
  const [editedCandidateID, setEditedCandidateID] = useState(null); 
  const [editedCandidates, setEditedCandidates] = useState({});
  const [editedCandidateData, setEditedCandidateData] = useState({
    Pod_Roles: '',
    time_zone:'', 
    region:'',
    github_username:'',
     jira_username:'',
    Primary_Skill: '',
    Secondary_Skill: '',
    Availability: '',
    github_repo:'',
    bitbucket_repo_slug:"" ,
     bitbucket_username:"",
    sonarqube_project_key :'',
  });




console.log('podsizeee',props.podsize)
useEffect((token) => {
  if(props.podid){
  fetchPodCandidates();
  }
}, [props.podid,token]);


const fetchPodCandidates = async () => {
  try {
    const response = await fetch(
      process.env.REACT_APP_CLIENT_FETCHCANDIDATESBASEDONPODS,
      {
        method: 'POST',
        body: JSON.stringify({ pod_id: props.podid||null }),
        headers: {
          'Content-Type': 'application/json',
          'Authorization' : token,
        },
      }
    );

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    setCandidateNames(data.data);
  
  } catch (error) {
    console.error('Error fetching candidates:', error);
  }
};



const emptyRowCount = props.podsize - (candidates ? candidates.length : 0);

const emptyRows = Array.from({ length: emptyRowCount }, (_, index) => ({
  id: `empty-${index}`, // You can generate unique IDs for each empty row if needed
  Candidate_Name: '', // You can set other properties to default empty values
  // Set other properties like Pod_Roles, Primary_Skill, etc. to empty values if needed
}));

// Merge candidates and emptyRows to render both
const mergedRows = [...candidates, ...emptyRows];

  useEffect((token) => {
    if(props.podid ){
    fetchCandidates();
    }
  }, [props.podid,token]);

  const fetchCandidates = async () => {
    const headers = {
      'Authorization' : token, // Replace with your actual token
      'Content-Type': 'application/json', // You can add more headers as needed
    };
    try {
      //'https://nxa3k1jn37.execute-api.us-east-1.amazonaws.com/dev/Pod_Candidates_Fetching'
      const response = await axios.post(process.env.REACT_APP_CLIENT_PODCANDIDATEFETCHING, {
        pods_id: props.podid ||null, // Replace with actual pods_id
      },{headers:headers});
     
      setCandidates(response.data.data);
    } catch (error) {
      console.error('Error fetching candidates:', error);
    }
  };

  const updateCandidate = async (candidateId, id) => {
    try {
      const candidateToUpdate = candidates.find(
        (candidate) => candidate.candidateID === candidateId
      );
     
      if (!candidateToUpdate) {
        // Candidate not found, indicating a new candidate is being created
        const newCandidatePayload = {
          podID: props.podid,
          candidateID: editedCandidateID || null, // Ensure editedCandidateID is null if empty
          Pod_Roles: editedCandidateData.Pod_Roles || '',
          time_zone:editedCandidateData.time_zone || '', 
          region:editedCandidateData.region || '',
          github_username:editedCandidateData.github_username || '',
           jira_username:editedCandidateData.jira_username || '',
          github_repo:editedCandidateData.github_repo || '',
          bitbucket_repo_slug:editedCandidateData.bitbucket_repo_slug || '',
          bitbucket_username:editedCandidateData.bitbucket_username || '',
          sonarqube_project_key:editedCandidateData.sonarqube_project_key || '',
          Primary_Skill: editedCandidateData.Primary_Skill || '',
          Secondary_Skill: editedCandidateData.Secondary_Skill || '',
          Availability: editedCandidateData.Availability || '',
          Candidate_Name: editedCandidateName || null, // Include the edited candidate name or null if empty
        };
  
        // Send a POST request to create a new candidate
        await axios.put(
         process.env.REACT_APP_CLIENT_PODROLES,
          newCandidatePayload,
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization' : token,
            },
          }
        );
      } else {
        // Existing candidate is being updated
       
        const editedCandidateIDToSend = editedCandidateID === "null" ? null : editedCandidateID;
        const editedCandidateNameToSend =editedCandidateName === "null" ?null :editedCandidateName;
        const payload = {
          podID: candidateToUpdate.podID,
          

// Then, set candidateID based on the condition


       candidateID:editedCandidateIDToSend, // Ensure editedCandidateID is null if empty
          id: id,
          status: candidateToUpdate.status,
          pod_status: candidateToUpdate.pod_status,
          pod_approved: candidateToUpdate.pod_approved,
          Pod_Roles: editedCandidateData.Pod_Roles || candidateToUpdate.Pod_Roles,
          time_zone:editedCandidateData.time_zone || candidateToUpdate.time_zone, 
          region:editedCandidateData.region || candidateToUpdate.region,
          github_username:editedCandidateData.github_username || candidateToUpdate.github_username,
          jira_username:editedCandidateData.jira_username || candidateToUpdate.jira_username,
          github_repo:editedCandidateData.github_repo || candidateToUpdate.github_repo,
          bitbucket_repo_slug:editedCandidateData.bitbucket_repo_slug || candidateToUpdate.bitbucket_repo_slug,
          bitbucket_username:editedCandidateData.bitbucket_username || candidateToUpdate.bitbucket_username,
         
          sonarqube_project_key:editedCandidateData.sonarqube_project_key || candidateToUpdate.sonarqube_project_key,
          Primary_Skill: editedCandidateData.Primary_Skill || candidateToUpdate.Primary_Skill,
          Secondary_Skill:
            editedCandidateData.Secondary_Skill || candidateToUpdate.Secondary_Skill,
          Availability: editedCandidateData.Availability || candidateToUpdate.Availability,
          Candidate_Name:editedCandidateNameToSend , // Include the edited candidate name or null if empty
        };
  
        // Send a PUT request to update the existing candidate
        await axios.put(
          process.env.REACT_APP_CLIENT_PODCANDIDATEUPDATE,
           JSON.stringify(payload),
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization' : token,
            },
          }
        );
      }
  
      fetchCandidates();
      setEditingCandidateId('');
      setEditedCandidateData({
        Pod_Roles: '',
        region:'',
        time_zone:'',
        github_username:'',
         jira_username:"",
        Primary_Skill: '',
        Secondary_Skill: '',
        Availability: '',
        github_repo:'',
        bitbucket_repo_slug:"",
        bitbucket_username:'',
       
        sonarqube_project_key:'',
      });
      setEditedCandidateName(''); // Reset the candidate name
    } catch (error) {
      console.error('Error updating/creating candidate:', error);
    }
  };
  
  
  
  
  
  
  
  
  

  const deleteCandidate = async (podID,candidateID) => {
    const headers = {
      'Authorization' : token, // Replace with your actual token
      'Content-Type': 'application/json', // You can add more headers as needed
    };
    try {
      //https://nxa3k1jn37.execute-api.us-east-1.amazonaws.com/dev/pod_candidate_delete
      const apiUrl = process.env.REACT_APP_CLIENT_PODCANDIDATEDELETE;
      const payload = {
        podID: podID,
        candidateId:candidateID
      };

      await axios.delete(apiUrl, {
        data: payload,
      },{headers:headers});

      fetchCandidates();
    } catch (error) {
      console.error('Error deleting candidate:', error);
    }
  };
        


  const podRolesOptions = ['Scrum Master', 'Lead', 'Product Owner','Developer','Tester','DevOps'];
  const timezone = [
    'Indian Standard Time',
    'Eastern Standard Time',
    'Central Standard Time',
    'Mountain Standard Time',
    'Pacific Standard Time',
    'Greenwich Mean Time',
    'Central European Time',
    'Western European Time',
    'Australian Eastern Standard Time',
    'Australian Central Standard Time',
    'Australian Western Standard Time',
    'New York Time (Eastern Daylight Time)',
    'Los Angeles Time (Pacific Daylight Time)',
    'London Time (British Summer Time)',
    'Paris Time (Central European Summer Time)',
    'Tokyo Time (Japan Standard Time)',
    'Sydney Time (Australian Eastern Daylight Time)',
    'Dubai Time (Gulf Standard Time)',
    'Moscow Time (Moscow Standard Time)',
    'Brazil Time (Brasilia Time)',
    'Chicago Time (Central Daylight Time)',
    'Houston Time (Central Daylight Time)',
    'Cape Town Time (South Africa Standard Time)'
];

  const region=['Asia Pacific','Latin America','North America'];
  const primarySkillOptions = ['html', 'css', 'JavaScript','Adobe Illustrator','ReactJS','AngularJS','Java','Node.js','Vue','Next','Typescript','Gatsby','Three.js'];
  const secondarySkillOptions = ['Java', 'Python', 'PHP','SQL','Git','c++','Perl','AngularJS','Kotlin','AWS','NodeJs','Azure'];
  const availabilityOptions = ['full time', 'part time'];

  useEffect(() => {
    if (editingCandidateId !== null) {
      const selectedCandidate = candidates.find(candidate => candidate.candidateID === editingCandidateId);
      if (selectedCandidate) {
        
        setEditedCandidateData({
          Pod_Roles: selectedCandidate.Pod_Roles,
          region:selectedCandidate.region,
          time_zone:selectedCandidate.time_zone,
          github_username:selectedCandidate.github_username,
          jira_username:selectedCandidate.jira_username,
          Primary_Skill: selectedCandidate.Primary_Skill,
          Secondary_Skill: selectedCandidate.Secondary_Skill,
          Availability: selectedCandidate.Availability,
          github_repo:selectedCandidate.github_repo,
          bitbucket_repo_slug:selectedCandidate.bitbucket_repo_slug ,
          bitbucket_username:selectedCandidate.bitbucket_username,
         
          sonarqube_project_key:selectedCandidate.sonarqube_project_key,
        });
        setEditedCandidateName(selectedCandidate.Candidate_Name || ''); // Set the candidate name
      }
    } else {
      setEditedCandidateData({
        Pod_Roles: '',
        region:'',
        time_zone:'',
        github_username:'',
         jira_username:'',
        Primary_Skill: '',
        Secondary_Skill: '',
        Availability: '',
        github_repo:'',
        bitbucket_repo_slug:'',
          bitbucket_username:'',
         
        sonarqube_project_key:"",
      });
      setEditedCandidateName(''); // Reset the candidate name
    }
  }, [editingCandidateId, candidates]);
  useEffect(() => {
    console.log('Selected Candidate Name:');
  }, [editedCandidateName]);
  console.log('candidateNames:');
  

  return (
    <div style={{ display: 'flex', justifyContent: 'center', height:"20rem",alignItems: 'center', margin: 'auto', marginTop: '10rem' }}>
      <TableContainer component={Paper}>
        <h6 style={{color:"#00B4D8"}}>{intl.formatMessage({ id: 'visitFindTalent' })}</h6>
        <Table component={Paper} sx={{ maxWidth: 900, fontFamily: 'Poppins,Medium', borderRadius: '10px' }} aria-label="simple table">
          <TableHead sx={{ backgroundColor: '#00B4D8', '& td, & th': { fontFamily: 'Poppins,Medium', fontSize: '15px', color: 'white', fontWeight: '600' } }}>
            <TableRow>
              <TableCell>{intl.formatMessage({ id: 'name' })}</TableCell>
           
              <TableCell>{intl.formatMessage({ id: 'gitHubRepo' })}</TableCell>
              <TableCell>{intl.formatMessage({ id: 'sonarqubeprojectkey' })}</TableCell>
              <TableCell>{intl.formatMessage({ id: 'gitHubUsername' })}</TableCell>
              <TableCell>{intl.formatMessage({ id: 'jiraUsername' })}</TableCell>
              <TableCell>Bitbucket Reposlug</TableCell>
              <TableCell>Bitbucket Username</TableCell>
              <TableCell>{intl.formatMessage({ id: 'actions' })}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {mergedRows.map(candidate => (
              <TableRow
                sx={{
                  '&:last-child td, &:last-child th': { border: 0, fontFamily: 'Poppins,Medium', fontSize: '15px' },
                  '&:nth-child(even)': { backgroundColor: '#90E0EF' },
                  '&:nth-child(odd)': { backgroundColor: '#caf0f8' },
                  '& td, & th': { fontFamily: 'Poppins,Medium', fontSize: '15px', color: 'black' },
                }}
                key={candidate.id}
              >
 <TableCell>
                  {editingCandidateId === candidate.id ? (
                    // Inside the select element where you handle candidate selection
<Select
  value={editedCandidates[candidate.id]?.editedCandidateID || ''}
  onChange={(e) => {
    const selectedCandidateID = parseInt(e.target.value, 10) || null;
    const selectedCandidate = candidateNames.find(
      (item) => item.candidateID === selectedCandidateID
    );

    setEditedCandidates((prevEditedCandidates) => ({
      ...prevEditedCandidates,
      [candidate.id]: {
        editedCandidateID: selectedCandidateID,
        editedCandidateName: selectedCandidate
          ? selectedCandidate.Candidate_Name
          : '',
      },
    }));

    // Set both editedCandidateID and editedCandidateName
    setEditedCandidateID(selectedCandidateID);
    setEditedCandidateName(selectedCandidate ? selectedCandidate.Candidate_Name : '');
  }}
  displayEmpty
>
<MenuItem value="" disabled={! candidate.Candidate_Name}>
                    { candidate.Candidate_Name ?  candidate.Candidate_Name : "Edit Teammember"}
                  </MenuItem>
  {candidateNames && candidateNames.map((candidate) => (
    <MenuItem key={candidate.candidateID} value={candidate.candidateID}>
      {candidate.Candidate_Name}
    </MenuItem>
  ))}
</Select>



                  ) : (
                    <MenuItem value="" disabled={! candidate.Candidate_Name}>
                    { candidate.Candidate_Name && candidate.Candidate_Name !=='null'?  candidate.Candidate_Name : "Edit Teammember"}
                  </MenuItem>// Display the selected candidate's name here
                  
                  )}
                </TableCell>
             
               





                
                <TableCell>
                  {editingCandidateId === candidate.id ? (
                      <TextField
                      label="GitHub Repository"
                      variant="outlined"
                      fullWidth
                      name="github_repo"
                      value={editedCandidateData.github_repo}
                      onChange={(e) =>
                        setEditedCandidateData({ ...editedCandidateData, github_repo: e.target.value })
                      }
                    />
                  ) : (
                    candidate.github_repo
                  )}
                </TableCell>
                <TableCell>
                  {editingCandidateId === candidate.id ? (
                      <TextField
                      label="Sonarqube Projectkey"
                      variant="outlined"
                      fullWidth
                      name="sonarqube_project_key"
                      value={editedCandidateData.sonarqube_project_key}
                      onChange={(e) =>
                        setEditedCandidateData({ ...editedCandidateData, sonarqube_project_key: e.target.value })
                      }
                    />
                  ) : (
                    candidate.sonarqube_project_key
                  )}
                </TableCell>
                
                <TableCell>
                  {editingCandidateId === candidate.id ? (
                      <TextField
                      label="GitHub Username"
                      variant="outlined"
                      fullWidth
                      name="github_username"
                      value={editedCandidateData.github_username}
                      onChange={(e) =>
                        setEditedCandidateData({ ...editedCandidateData, github_username: e.target.value })
                      }
                    />
                  ) : (
                    candidate.github_username
                  )}
                </TableCell> 
                <TableCell>
                  {editingCandidateId === candidate.id ? (
                      <TextField
                      label="Jira Username"
                      variant="outlined"
                      fullWidth
                      name="jira_username"
                      value={editedCandidateData.jira_username}
                      onChange={(e) =>
                        setEditedCandidateData({ ...editedCandidateData, jira_username: e.target.value })
                      }
                    />
                  ) : (
                    candidate.jira_username
                  )}
                </TableCell>
                
                <TableCell>
                  {editingCandidateId === candidate.id ? (
                      <TextField
                      label="Bitbucket Reposlug"
                      variant="outlined"
                      fullWidth
                      name="bitbucket_repo_slug"
                      value={editedCandidateData.bitbucket_repo_slug}
                      onChange={(e) =>
                        setEditedCandidateData({ ...editedCandidateData, bitbucket_repo_slug: e.target.value })
                      }
                    />
                  ) : (
                    candidate.bitbucket_repo_slug
                  )}
                </TableCell>
                <TableCell>
                  {editingCandidateId === candidate.id ? (
                      <TextField
                      label="Bitbucket Username"
                      variant="outlined"
                      fullWidth
                      name="bitbucket_username"
                      value={editedCandidateData.bitbucket_username}
                      onChange={(e) =>
                        setEditedCandidateData({ ...editedCandidateData, bitbucket_username: e.target.value })
                      }
                    />
                  ) : (
                    candidate.bitbucket_username
                  )}
                </TableCell>
                
          
                
                <TableCell>
                  {editingCandidateId === candidate.id ? (
                    <Button variant="contained" color="primary" onClick={() => updateCandidate(candidate.candidateID,candidate.id)}>
                      {intl.formatMessage({ id: 'save' })}
                    </Button>
                  ) : (
                    <>
                      <Button
  variant="contained"
  color="primary"
  onClick={() => {
    console.log("Editing candidate ID:");
    console.log("editedCandidateData:");

    setEditingCandidateId(candidate.id);
    setEditedCandidateData({
      Pod_Roles: candidate.Pod_Roles,
      Primary_Skill: candidate.Primary_Skill,
      time_zone:candidate.time_zone, 
      region:candidate.region,
      Secondary_Skill: candidate.Secondary_Skill,
      Availability: candidate.Availability,
      github_username:candidate.github_username,
      jira_username:candidate.jira_username,
      github_repo:candidate.github_repo,
      sonarqube_project_key:candidate.sonarqube_project_key,
      bitbucket_repo_slug:candidate.bitbucket_repo_slug ,
      bitbucket_username:candidate.bitbucket_username,

    });
  }}
>
  <AiOutlineEdit />
</Button>


                      <Button variant="contained" color="secondary" onClick={() => deleteCandidate(candidate.podID,candidate.candidateID,candidate.Candidate_Name)}>
                        <MdOutlineDeleteOutline />
                      </Button>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default Mastergithub;
