import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Card, CardContent, Typography, Box } from '@mui/material';
import { Assignment, BugReport, CheckBoxOutlineBlank, Timer, Speed as SpeedIcon, Code as CodeIcon } from '@mui/icons-material';
import { BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList } from 'recharts';
import { useAuthToken } from '../TokenContext';
import { useSelector } from "react-redux";
import { FormattedMessage, useIntl } from 'react-intl';

// Styled Components using MUI
const DashboardContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(2.5),
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : '#f0f0f0',
  marginTop: '1rem',
  fontFamily: 'Poppins, sans-serif',
  [theme.breakpoints.down('md')]: {
    height: 'auto'
  }
}));

const CardsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2.5),
  marginBottom: theme.spacing(2.5),
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column'
  }
}));

const CardWrapper = styled(Box)({
  position: 'relative',
  flex: 1
});

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  position: 'relative',
  padding: theme.spacing(2.5),
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#fff',
  boxShadow: theme.palette.mode === 'dark' 
    ? '0 4px 8px rgba(0, 0, 0, 0.5)' 
    : '0 4px 8px rgba(0, 0, 0, 0.3)',
  height: '10rem',
  fontSize: '18px',
  fontFamily: 'Poppins, Medium',
  color: theme.palette.mode === 'dark' ? theme.palette.text.primary : 'inherit',
  overflow: 'auto',
  '&::-webkit-scrollbar': {
    display: 'none'
  },
  borderRadius: '20px',
  transition: 'background-color 0.3s ease, box-shadow 0.3s ease'
}));

const CardIcon = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '-30px',
  left: '20px',
  width: '60px',
  height: '60px',
  borderRadius: '1rem',
  boxShadow: theme.palette.mode === 'dark' 
    ? '0 4px 8px rgba(0, 0, 0, 0.5)' 
    : '0 4px 8px rgba(0, 0, 0, 0.3)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '18px',
  color: 'white',
  zIndex: 10,
  transition: 'box-shadow 0.3s ease'
}));

const GraphsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2.5),
  marginTop: '5rem',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    gap: theme.spacing(4)
  }
}));

const GraphCard = styled(Card)(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#fff',
  boxShadow: theme.palette.mode === 'dark' 
    ? '0 4px 8px rgba(0, 0, 0, 0.5)' 
    : '0 4px 8px rgba(0, 0, 0, 0.3)',
  borderRadius: '8px',
  height: '300px',
  width: '100%',
  transition: 'background-color 0.3s ease, box-shadow 0.3s ease'
}));

const ForegroundCard = styled(Card)(({ theme }) => ({
  position: 'absolute',
  top: '-30px',
  left: '50%',
  width: '90%',
  height: '80%',
  borderRadius: '8px',
  zIndex: 10,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transform: 'translateX(-50%)',
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : '#fff',
  boxShadow: theme.palette.mode === 'dark' 
    ? '0 4px 8px rgba(0, 0, 0, 0.5)' 
    : '0 4px 8px rgba(0, 0, 0, 0.3)',
  transition: 'background-color 0.3s ease, box-shadow 0.3s ease'
}));

const NameLabel = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '10px',
  fontWeight: 600,
  color: theme.palette.mode === 'dark' ? theme.palette.text.primary : '#333',
  borderBottomLeftRadius: '20px',
  borderBottomRightRadius: '20px',
  gap: '10px',
  transition: 'color 0.3s ease'
}));

// API configuration
const API_CONFIG = {
  stories: {
    endpoint: process.env.REACT_APP_TALENT_JIRASTORIESDASHBOARDTALENT,
    transform: (data) => {
      const { open_user_stories, completed_user_stories } = JSON.parse(data.body);
      return { open: open_user_stories, completed: completed_user_stories };
    }
  },
  defects: {
    endpoint: process.env.REACT_APP_TALENT_JIRADEFECTSDASHBOARDTALENT,
    transform: (data) => {
      const { open_defects, resolved_defects } = JSON.parse(data.body);
      return { open: open_defects, resolved: resolved_defects };
    }
  },
  tasks: {
    endpoint: process.env.REACT_APP_TALENT_PODTASKSDASHBOARDTALENT,
    transform: (data) => ({ open: data.body.Open, completed: data.body.Complete })
  },
  timeLog: {
    endpoint: process.env.REACT_APP_TALENT_TIMLOGDASHBOARDTALENT,
    transform: (data) => ({
      last_week: data.body.last_week || 'N/A',
      this_week: data.body.this_week || 'N/A'
    })
  },
  velocity: {
    endpoint: process.env.REACT_APP_TALENT_SPRINTVELOCITYMETRISTALENT,
    transform: (data) => JSON.parse(data.body)
  },
  activity: {
    endpoint: process.env.REACT_APP_TALENT_DASHBOARDVIEWTALENT,
    transform: (data) => {
      const { jira_issues, pod_tasks } = data.body;
      return [
        {
          name: 'Previous Week',
          stories: jira_issues.last_week_completed_stories || 0,
          defects: jira_issues.last_week_completed_defects || 0,
          pod_tasks: pod_tasks.last_week_completed_pod_tasks || 0,
        },
        {
          name: 'Current Week',
          stories: jira_issues.this_week_completed_stories || 0,
          defects: jira_issues.this_week_completed_defects || 0,
          pod_tasks: pod_tasks.this_week_completed_pod_tasks || 0,
        }
      ];
    }
  }
};

// Custom hook for data fetching
const useDataFetcher = (config, token, userId) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!token) return;
      try {
        const response = await fetch(config.endpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token,
          },
          body: JSON.stringify({
            c_id: userId,
            pod_id: [],
            sprint_name: ""
          })
        });
        const result = await response.json();
        setData(config.transform(result));
      } catch (error) {
        console.error('Error fetching data:', error);
        setData(null);
      }
    };
    fetchData();
  }, [config, token, userId]);

  return data;
};

// Reusable components
const DataCardComponent = ({ icon, title, data, color, intl }) => (
  <CardWrapper>
    <CardIcon sx={{ backgroundColor: color }}>
      {icon}
    </CardIcon>
    <StyledCard>
      <CardContent>
        <Typography variant="h6">
          <FormattedMessage id={title.toLowerCase()} defaultMessage={title} />
        </Typography>
        {Object.entries(data).map(([key, value]) => (
          <Typography key={key} variant="body2">
            {intl.formatMessage({ id: key === 'open' ? 'toDo' : 'Done' })}: {value}
          </Typography>
        ))}
      </CardContent>
    </StyledCard>
  </CardWrapper>
);

const CustomXAxisTick = ({ x, y, payload }) => {
  const [isMobile] = useState(window.innerWidth <= 768);
  const nameMapping = {
    "Previous Week": "PW",
    "Current Week": "CW"
  };
  const displayName = isMobile ? nameMapping[payload.value] || payload.value : payload.value;

  return (
    <text x={x} y={y + 10} textAnchor="middle" fill="white" fontSize={16} fontWeight="bold">
      {displayName}
    </text>
  );
};

// Main component
const DashboardNew = () => {
  const token = useAuthToken();
  const { user } = useSelector(state => state.auth);
  const intl = useIntl();
  const userId = user?.data?.[0]?.c_id;

  // Fetch data using custom hook
  const storiesData = useDataFetcher(API_CONFIG.stories, token, userId);
  const defectsData = useDataFetcher(API_CONFIG.defects, token, userId);
  const tasksData = useDataFetcher(API_CONFIG.tasks, token, userId);
  const timeLogData = useDataFetcher(API_CONFIG.timeLog, token, userId);
  const velocityData = useDataFetcher(API_CONFIG.velocity, token, userId);
  const activityData = useDataFetcher(API_CONFIG.activity, token, userId);

  return (
    <DashboardContainer>
      <CardsContainer>
        <DataCardComponent
          icon={<Assignment />}
          title="Stories"
          data={storiesData || { open: 0, completed: 0 }}
          color="black"
          intl={intl}
        />
        <DataCardComponent
          icon={<BugReport />}
          title="Defects"
          data={defectsData || { open: 0, completed: 0 }}
          color="red"
          intl={intl}
        />
        <DataCardComponent
          icon={<CheckBoxOutlineBlank />}
          title="Pod Tasks"
          data={tasksData || { open: 0, completed: 0 }}
          color="#87CEFA"
          intl={intl}
        />
        <DataCardComponent
          icon={<Timer />}
          title="Time Log"
          data={timeLogData || { last_week: 'N/A', this_week: 'N/A' }}
          color="#90ee90"
          intl={intl}
        />
      </CardsContainer>

      <GraphsContainer>
        <CardWrapper>
          <GraphCard>
            <NameLabel>
              <SpeedIcon sx={{ color: "blue" }} />
              <FormattedMessage id="sprintVelocity" defaultMessage="Sprint Velocity" />
            </NameLabel>
          </GraphCard>
          <ForegroundCard sx={{ backgroundColor: "#1E90FF" }}>
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={velocityData || []}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="sprint_name" tick={{ fill: 'white', fontSize: '16px', fontWeight: 'bold' }} />
                <YAxis tick={{ fill: 'white', fontSize: '16px', fontWeight: 'bold' }} />
                <Tooltip contentStyle={{ backgroundColor: '#000', border: '1px solid #ccc', color: '#fff' }} />
                <Legend />
                <Line type="monotone" dataKey="sprint_velocities" stroke="white" strokeWidth={3} />
              </LineChart>
            </ResponsiveContainer>
          </ForegroundCard>
        </CardWrapper>

        <CardWrapper>
          <GraphCard>
            <NameLabel>
              <CodeIcon sx={{ color: "#3CB371" }} />
              <FormattedMessage id="podActivity" defaultMessage="Pod Activity" />
            </NameLabel>
          </GraphCard>
          <ForegroundCard sx={{ backgroundColor: "#003F5C" }}>
            {!activityData || activityData.length === 0 ? (
              <Typography variant="body2" sx={{ color: 'white', padding: '20px' }}>
                <FormattedMessage id="noData" defaultMessage="No data available" />
              </Typography>
            ) : (
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={activityData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" tick={<CustomXAxisTick />} tickLine={false} interval={0} />
                  <YAxis tick={{ fill: 'white', fontSize: '16px', fontWeight: 'bold' }} />
                  <Tooltip contentStyle={{ backgroundColor: '#000', border: '1px solid #ccc', color: '#fff' }} />
                  <Legend
                    wrapperStyle={{ color: 'white' }}
                    formatter={(value) => <span style={{ color: 'white', fontWeight: "500" }}>{value}</span>}
                  />
                  <Bar dataKey="stories" stackId="a" fill="#7A5195" name={intl.formatMessage({ id: 'stories' })}>
                    <LabelList dataKey="stories" position="inside" fill="#FFF" />
                  </Bar>
                  <Bar dataKey="defects" stackId="a" fill="#EF5675" name={intl.formatMessage({ id: 'defects' })}>
                    <LabelList dataKey="defects" position="inside" fill="#FFF" />
                  </Bar>
                  <Bar dataKey="pod_tasks" stackId="a" fill="#FFA600" name={intl.formatMessage({ id: 'podTasks' })}>
                    <LabelList dataKey="pod_tasks" position="inside" fill="#FFF" />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            )}
          </ForegroundCard>
        </CardWrapper>
      </GraphsContainer>
    </DashboardContainer>
  );
};

export default DashboardNew;