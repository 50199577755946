import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useAuthToken } from "../TokenContext";

const AddPodactivityLead = () => {
  const token = useAuthToken();
  const { user } = useSelector((state) => state.auth);
  const [candidateList, setCandidateList] = useState([]);
  const [podData, setPodData] = useState([
    {
      categories: "",
      talent_id: 0,
      today_update: "",
      blockers: "",
    },
  ]);

  const podId = window.location.pathname.split(",")[0].split("/")[2];

  const handleInputChange = (index, field, value) => {
    const updatedData = [...podData];
    updatedData[index][field] = value;
    setPodData(updatedData);
  };

  const handleAddField = () => {
    if (podData.length >= 10) {
      alert("Maximum limit reached");
      return;
    }
    setPodData([
      ...podData,
      {
        categories: "",
        talent_id: 0,
        today_update: "",
        blockers: "",
      },
    ]);
  };

  const handleRemove = (index) => {
    if (podData.length === 1) {
      alert("There should be at least one update field");
      return;
    }
    const list = [...podData];
    list.splice(index, 1);
    setPodData(list);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const uniqueTalents = new Set(podData.map((item) => item.talent_id));
    if (uniqueTalents.size !== podData.length) {
      alert("Pod candidate names should be unique");
      return;
    }

    try {
      const response = await fetch(
        "https://ut38fhz97i.execute-api.us-east-1.amazonaws.com/dev/pod_activity_talent_by_lead",
        {
          method: "POST",
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ poddata: podData, podID: podId }),
        }
      );

      const result = await response.json();
      if (result.status === 200) {
        alert("Added Successfully.");
      } else if (result.status === 400) {
        alert(result.data);
      }
    } catch (error) {
      console.error("Error submitting pod data:", error);
      alert("Failed to submit pod data");
    }
  };

  useEffect(() => {
    const fetchCandidates = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_TALENT_GETCANDIDATELIST,
          {
            method: "POST",
            headers: {
              Authorization: token,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ lead_id: user.data[0].c_id }),
          }
        );

        const result = await response.json();
        const formattedList = result.data.data.map((candidate) => ({
          value: candidate.c_id,
          talent_id: candidate.firstName,
        }));
        setCandidateList(formattedList);
      } catch (error) {
        console.error("Error fetching candidates:", error);
      }
    };

    fetchCandidates();
  }, [token, user.data]);

  return (
    <Container>
      <Title>Post Pod Activity</Title>
      <Form onSubmit={handleSubmit}>
        {podData.map((item, index) => (
          <ActivitySection key={index}>
            <FormRow>
              <Label>Pod team member:</Label>
              <Select
                name="talent_id"
                value={item.talent_id}
                onChange={(e) =>
                  handleInputChange(index, "talent_id", e.target.value)
                }
                required
              >
                <option value="">Select member</option>
                {candidateList.map((candidate) => (
                  <option key={candidate.value} value={candidate.value}>
                    {candidate.talent_id}
                  </option>
                ))}
              </Select>
            </FormRow>

            <FormRow>
              <Label>Categories:</Label>
              <Select
                name="categories"
                value={item.categories}
                onChange={(e) =>
                  handleInputChange(index, "categories", e.target.value)
                }
                required
              >
                <option value="">Select category</option>
                {[
                  "Design",
                  "Coding",
                  "Testing",
                  "Resolving Bugs",
                  "Deployment",
                ].map((cat) => (
                  <option key={cat} value={cat}>
                    {cat}
                  </option>
                ))}
              </Select>
            </FormRow>

            <TextArea
              name="today_update"
              value={item.today_update}
              onChange={(e) =>
                handleInputChange(index, "today_update", e.target.value)
              }
              placeholder="Today's Focus..."
              required
            />

            <TextArea
              name="blockers"
              value={item.blockers}
              onChange={(e) =>
                handleInputChange(index, "blockers", e.target.value)
              }
              placeholder="Blockers..."
            />

            <ButtonGroup>
              {index === podData.length - 1 && (
                <ActionButton type="button" onClick={handleAddField}>
                  Add +
                </ActionButton>
              )}
              {podData.length > 1 && (
                <ActionButton type="button" onClick={() => handleRemove(index)}>
                  Remove
                </ActionButton>
              )}
            </ButtonGroup>
          </ActivitySection>
        ))}

        <SubmitButton type="submit">Add</SubmitButton>
      </Form>
    </Container>
  );
};

const Container = styled.div`
  margin: 6rem 6.5rem;
  @media (max-width: 1080px) {
    margin: auto;
    width: 90%;
    max-width: 25rem;
  }
`;

const Title = styled.h4`
  font-weight: 600;
  @media (max-width: 1080px) {
    margin-top: 2rem;
  }
`;

const Form = styled.form`
  background-color: #f2f4f5;
  border-radius: 20px;
  padding: 1rem;
  margin-top: 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 40rem;
  @media (max-width: 1080px) {
    width: 100%;
  }
`;

const ActivitySection = styled.div`
  margin-bottom: 2rem;
`;

const FormRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
`;

const Label = styled.label`
  font-weight: 600;
  color: grey;
  margin-bottom: 0.5rem;
`;

const Select = styled.select`
  border: 1px solid #cccccc;
  border-radius: 5px;
  height: 3.1rem;
  padding: 0 1rem;
  font-weight: 600;
  color: grey;
  width: 100%;
  max-width: 20rem;
`;

const TextArea = styled.textarea`
  width: 100%;
  margin-top: 1rem;
  border: 1px solid #cccccc;
  border-radius: 5px;
  padding: 1rem;
  font-size: 14px;
  font-weight: 600;
  min-height: 100px;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
`;

const ActionButton = styled.button`
  border: none;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  font-weight: 600;
  color: white;
  background-color: #00b4d8;
  cursor: pointer;
`;

const SubmitButton = styled.button`
  border: none;
  border-radius: 10px;
  background-color: #1dbf73;
  padding: 0.5rem 2rem;
  font-size: 18px;
  font-weight: 600;
  color: white;
  margin-top: 2rem;
  float: right;
  cursor: pointer;
`;

export default AddPodactivityLead;
