import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { NavHashLink } from "react-router-hash-link";
import { useSelector } from "react-redux";
import { useAuthToken } from "../TokenContext";
import { useIntl } from "react-intl";
import star from "./assets/star.png";
import defaultimg from './assets/defaultimge.png';

// Constants
const ITEMS_PER_PAGE = 3;
const PAGE_NUMBER_LIMIT = 5;

const FILTER_OPTIONS = {
  timezone: [
    [
      { value: 'ist', label: 'Indian Standard Time' },
      { value: 'est', label: 'Eastern Standard Time' },
      { value: 'cst', label: 'Central Standard Time' },
      { value: 'mst', label: 'Mountain Standard Time' },
      { value: 'pst', label: 'Pacific Standard Time' },
      { value: 'gmt', label: 'Greenwich Mean Time' },
      { value: 'cet', label: 'Central European Time' },
      { value: 'wett', label: 'Western European Time' },
      { value: 'aest', label: 'Australian Eastern Standard Time' },
      { value: 'acst', label: 'Australian Central Standard Time' },
      { value: 'awst', label: 'Australian Western Standard Time' },
      { value: 'edt', label: 'New York Time (Eastern Daylight Time)' },
      { value: 'pdt', label: 'Los Angeles Time (Pacific Daylight Time)' },
      { value: 'bst', label: 'London Time (British Summer Time)' },
      { value: 'cest', label: 'Paris Time (Central European Summer Time)' },
      { value: 'jst', label: 'Tokyo Time (Japan Standard Time)' },
      { value: 'aedt', label: 'Sydney Time (Australian Eastern Daylight Time)' },
      { value: 'gst', label: 'Dubai Time (Gulf Standard Time)' },
      { value: 'msk', label: 'Moscow Time (Moscow Standard Time)' },
      { value: 'brt', label: 'Brazil Time (Brasilia Time)' },
      { value: 'cdt', label: 'Chicago Time (Central Daylight Time)' },
      { value: 'hdt', label: 'Houston Time (Central Daylight Time)' },
      { value: 'sast', label: 'Cape Town Time (South Africa Standard Time)' }
  ]
  
  ],
  podSize: ["7", "5", "2"],
  podType: [
    { value: "Saas", label: "Saas" },
    { value: "Web app", label: "Web app" },
    { value: "Java Developer", label: "Java Developer" }
  ],
  location: [
    { value: 'usa', label: 'USA' },
      { value: 'canada', label: 'Canada' },
      { value: 'india', label: 'India' },
      { value: 'australia', label: 'Australia' },
      { value: 'united_kingdom', label: 'United Kingdom' },
      { value: 'germany', label: 'Germany' },
      { value: 'france', label: 'France' },
      { value: 'china', label: 'China' },
      { value: 'japan', label: 'Japan' },
      { value: 'brazil', label: 'Brazil' },
      { value: 'south_africa', label: 'South Africa' },
      { value: 'mexico', label: 'Mexico' },
      { value: 'italy', label: 'Italy' },
      { value: 'spain', label: 'Spain' },
      { value: 'russia', label: 'Russia' },
      { value: 'south_korea', label: 'South Korea' },
      { value: 'new_zealand', label: 'New Zealand' },
      { value: 'singapore', label: 'Singapore' },
      { value: 'united_arab_emirates', label: 'United Arab Emirates' },
      { value: 'saudi_arabia', label: 'Saudi Arabia' },
      { value: 'argentina', label: 'Argentina' },
      { value: 'netherlands', label: 'Netherlands' },
      { value: 'sweden', label: 'Sweden' },
      { value: 'switzerland', label: 'Switzerland' },
      { value: 'norway', label: 'Norway' },
      { value: 'denmark', label: 'Denmark' },
      { value: 'finland', label: 'Finland' },
      { value: 'portugal', label: 'Portugal' },
      { value: 'poland', label: 'Poland' },
      { value: 'turkey', label: 'Turkey' },
      { value: 'indonesia', label: 'Indonesia' },
      { value: 'malaysia', label: 'Malaysia' },
      { value: 'thailand', label: 'Thailand' },
      { value: 'vietnam', label: 'Vietnam' },
      { value: 'philippines', label: 'Philippines' },
      { value: 'egypt', label: 'Egypt' },
      { value: 'nigeria', label: 'Nigeria' },
      { value: 'kenya', label: 'Kenya' },
      { value: 'ghana', label: 'Ghana' },
      { value: 'colombia', label: 'Colombia' },
      { value: 'chile', label: 'Chile' },
      { value: 'peru', label: 'Peru' },
      { value: 'venezuela', label: 'Venezuela' },
      { value: 'pakistan', label: 'Pakistan' },
      { value: 'bangladesh', label: 'Bangladesh' },
      { value: 'sri_lanka', label: 'Sri Lanka' },
      { value: 'nepal', label: 'Nepal' },
      { value: 'bhutan', label: 'Bhutan' },
      { value: 'maldives', label: 'Maldives' },
      { value: 'iceland', label: 'Iceland' },
      { value: 'luxembourg', label: 'Luxembourg' },
      { value: 'qatar', label: 'Qatar' },
      { value: 'oman', label: 'Oman' },
      { value: 'kuwait', label: 'Kuwait' },
      { value: 'bahrain', label: 'Bahrain' },
      { value: 'iraq', label: 'Iraq' },
      { value: 'iran', label: 'Iran' },
      { value: 'afghanistan', label: 'Afghanistan' },
      { value: 'israel', label: 'Israel' },
      { value: 'jordan', label: 'Jordan' },
      { value: 'lebanon', label: 'Lebanon' },
      { value: 'morocco', label: 'Morocco' },
      { value: 'algeria', label: 'Algeria' },
      { value: 'tunisia', label: 'Tunisia' },
      { value: 'libya', label: 'Libya' },
      { value: 'ethiopia', label: 'Ethiopia' },
      { value: 'tanzania', label: 'Tanzania' },
      { value: 'uganda', label: 'Uganda' },
      { value: 'zimbabwe', label: 'Zimbabwe' },
      { value: 'zambia', label: 'Zambia' },
      { value: 'mozambique', label: 'Mozambique' },
      { value: 'botswana', label: 'Botswana' },
      { value: 'namibia', label: 'Namibia' },
      { value: 'angola', label: 'Angola' },
      { value: 'cuba', label: 'Cuba' },
      { value: 'jamaica', label: 'Jamaica' },
      { value: 'haiti', label: 'Haiti' },
      { value: 'dominican_republic', label: 'Dominican Republic' },
      { value: 'trinidad_and_tobago', label: 'Trinidad and Tobago' },
      { value: 'bahamas', label: 'Bahamas' },
      { value: 'barbados', label: 'Barbados' },
      { value: 'panama', label: 'Panama' },
      { value: 'costa_rica', label: 'Costa Rica' },
      { value: 'honduras', label: 'Honduras' },
      { value: 'guatemala', label: 'Guatemala' },
      { value: 'el_salvador', label: 'El Salvador' },
      { value: 'nicaragua', label: 'Nicaragua' },
      { value: 'belize', label: 'Belize' },
      { value: 'paraguay', label: 'Paraguay' },
      { value: 'uruguay', label: 'Uruguay' },
      { value: 'bolivia', label: 'Bolivia' },
      { value: 'ecuador', label: 'Ecuador' },
      { value: 'guinea', label: 'Guinea' },
      { value: 'ivory_coast', label: 'Ivory Coast' },
      { value: 'cameroon', label: 'Cameroon' },
      { value: 'senegal', label: 'Senegal' },
      { value: 'sudan', label: 'Sudan' },
      { value: 'south_sudan', label: 'South Sudan' },
      { value: 'congo', label: 'Congo' },
      { value: 'madagascar', label: 'Madagascar' },
      { value: 'malawi', label: 'Malawi' },
      { value: 'sierra_leone', label: 'Sierra Leone' },
      { value: 'chad', label: 'Chad' },
      { value: 'mali', label: 'Mali' },
      { value: 'burkina_faso', label: 'Burkina Faso' },
      { value: 'rwanda', label: 'Rwanda' },
      { value: 'burundi', label: 'Burundi' },
      { value: 'lesotho', label: 'Lesotho' },
      { value: 'eswatini', label: 'Eswatini' }
        
  ]
};

// Reusable Filter Select Component
const FilterSelect = ({ value, onChange, options, defaultLabel }) => (
  <td>
    <select
      className="options"
      value={value}
      onChange={(e) => onChange(e.target.value)}
    >
      <option value="default">{defaultLabel}</option>
      {options.map((option, index) => (
        <option 
          key={index} 
          value={typeof option === 'string' ? option : option.value}
          style={{ color: "black" }}
        >
          {typeof option === 'string' ? option : option.label}
        </option>
      ))}
    </select>
  </td>
);

// Pod Card Component
const PodCard = ({ data, user, intl }) => (
  <Div>
    <li>
      <div className="pod-header">
        <div className="name">
          <img
            src={data.picture ? `https://globldataprod.s3.amazonaws.com/${data.picture}` : defaultimg}
            alt=""
            style={{ width: 50, height: 50, borderRadius: "50px" }}
          />
          <p className="firstname">
            <img width="18px" height="18px" src={star} alt="star" />
            {data.avgrating ?? 0}
          </p>
        </div>
        <div>
          <TD1 style={{ color: data.partner_code === user.data[0].partner_code ? "blue" : "red" }}>
            {intl.formatMessage({ id: data.partner_code === user.data[0].partner_code ? "internalPod" : "externalPod" })}
          </TD1>
        </div>
      </div>

      <PodInfoRow label={intl.formatMessage({ id: "podName" })} value={data.podName} />
      <PodInfoRow label={intl.formatMessage({ id: "email" })} value={data.email} />
      <PodInfoRow label={intl.formatMessage({ id: "country" })} value={data.location} />
      <PodInfoRow label={intl.formatMessage({ id: "podSize" })} value={data.podsize} />
      <PodInfoRow 
        label={intl.formatMessage({ id: "podrate" })} 
        value={user.data[0].manager_type !== "PartnerClient" ? data.pototalrate : data.pod_rate} 
      />
      <PodInfoRow label={intl.formatMessage({ id: "podType" })} value={data.podtype} />

      <NavHashLink to={`/FindPodDetailsClient/${data.id}`} smooth>
        <DetailsButton>{intl.formatMessage({ id: "viewPodDetails" })}</DetailsButton>
      </NavHashLink>
    </li>
  </Div>
);

// Pod Info Row Component
const PodInfoRow = ({ label, value }) => (
  <div className="name2">
    <tr className="TR">
      <td className="TD">{label}</td>
      <td>:</td>
      <TD1>{value}</TD1>
    </tr>
  </div>
);

// Pagination Component
const Pagination = ({ currentPage, pages, handlePrevbtn, handleNextbtn, renderPageNumbers, intl }) => (
  <ol className="numbers">
    <Num2>
      <li>
        <button onClick={handlePrevbtn} disabled={currentPage === pages[0]}>
          {intl.formatMessage({ id: "prev" })}
        </button>
      </li>
      <Numbers>{renderPageNumbers}</Numbers>
      <li>
        <button onClick={handleNextbtn} disabled={currentPage === pages[pages.length - 1]}>
          {intl.formatMessage({ id: "next" })}
        </button>
      </li>
    </Num2>
  </ol>
);

const FindPodsPartnerGuest = () => {
  const token = useAuthToken();
  const intl = useIntl();
  const { user } = useSelector((state) => state.auth);

  // State Management
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(PAGE_NUMBER_LIMIT);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
  const [getData, setGetData] = useState([]);
  const [filters, setFilters] = useState({
    timezone: "default",
    podSize: "default",
    podType: "default",
    location: "default"
  });

  // Fetch Data
  const fetchPods = async () => {
    const body = {
      partner_code: user.data[0].partner_code,
      podlocation: filters.location !== "default" ? filters.location : "",
      podtype: filters.podType !== "default" ? filters.podType : "",
      podSize: filters.podSize !== "default" ? filters.podSize : "",
      timezone: filters.timezone !== "default" ? filters.timezone : ""
    };

    try {
      const response = await fetch(
        process.env.REACT_APP_PARTNER_FINDPODINPARTNERGUEST,
        {
          method: 'POST',
          headers: {
            Authorization: token,
            "Content-Type": "application/json"
          },
          body: JSON.stringify(body)
        }
      );
      const data = await response.json();
      setGetData(data.data);
    } catch (err) {
      console.error("Error fetching pods:", err);
    }
  };

  useEffect(() => {
    fetchPods();
  }, [token]);

  // Pagination Logic
  const pages = [...Array(Math.ceil(getData.length / ITEMS_PER_PAGE))].map((_, i) => i + 1);
  const currentItems = getData.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const handleClick = (event) => {
    setCurrentPage(Number(event.target.id));
  };

  const handleNextbtn = () => {
    setCurrentPage(curr => curr + 1);
    if (currentPage + 1 > maxPageNumberLimit) {
      setMaxPageNumberLimit(max => max + PAGE_NUMBER_LIMIT);
      setMinPageNumberLimit(min => min + PAGE_NUMBER_LIMIT);
    }
  };

  const handlePrevbtn = () => {
    setCurrentPage(curr => curr - 1);
    if ((currentPage - 1) % PAGE_NUMBER_LIMIT === 0) {
      setMaxPageNumberLimit(max => max - PAGE_NUMBER_LIMIT);
      setMinPageNumberLimit(min => min - PAGE_NUMBER_LIMIT);
    }
  };

  const renderPageNumbers = pages.map(number => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li key={number}>
        <button
          id={number}
          onClick={handleClick}
          className={currentPage === number ? "active" : null}
        >
          {number}
        </button>
      </li>
      );
    }
    return null;
  });

  return (
    <Return>
      <div>
        <Headingtext1>{intl.formatMessage({ id: "findBestPod" })}</Headingtext1>

        <Pagination
          currentPage={currentPage}
          pages={pages}
          handlePrevbtn={handlePrevbtn}
          handleNextbtn={handleNextbtn}
          renderPageNumbers={renderPageNumbers}
          intl={intl}
        />

        <Main>
          <Table>
            <Row>
              <Row1>
                <FilterSelect
                  value={filters.timezone.value}
                  onChange={(value) => setFilters(prev => ({ ...prev, timezone: value }))}
                  options={FILTER_OPTIONS.timezone.label}
                  defaultLabel="Time Zone"
                />
                <FilterSelect
                  value={filters.podSize}
                  onChange={(value) => setFilters(prev => ({ ...prev, podSize: value }))}
                  options={FILTER_OPTIONS.podSize}
                  defaultLabel="Pod Size"
                />
                <FilterSelect
                  value={filters.podType}
                  onChange={(value) => setFilters(prev => ({ ...prev, podType: value }))}
                  options={FILTER_OPTIONS.podType}
                  defaultLabel="Pod Type"
                />
              </Row1>

              <Row2>
                <FilterSelect
                  value={filters.location.value}
                  onChange={(value) => setFilters(prev => ({ ...prev, location: value }))}
                  options={FILTER_OPTIONS.location.label}
                  defaultLabel="Location"
                />
              </Row2>

              <tr>
                <td>
                  <button
                    className="optionsaplly"
                    onClick={fetchPods}
                    style={{
                      width: "10rem",
                      height: "2rem",
                      color: "white",
                      border: "none",
                      borderRadius: "5px",
                      cursor: "pointer",
                      backgroundColor: "#1DBF73",
                      boxShadow: "1px 6px 9px #00000029",
                      fontFamily: "Poppins,Regular",
                      fontWeight: "500",
                    }}
                  >
                    Apply Filters
                  </button>
                </td>
              </tr>
            </Row>
          </Table>
        </Main>

        <Cards>
          {currentItems && currentItems.length > 0 ? (
            currentItems.map((data, i) => (
              <OL1 key={i}>
                <PodCard data={data} user={user} intl={intl} />
              </OL1>
            ))
          ) : (
            <p style={{ textAlign: "center" }}>
              {intl.formatMessage({ id: "noPods" })}
            </p>
          )}
        </Cards>
      </div>
    </Return>
  );
};

// Styled Components remain the same...

export default FindPodsPartnerGuest;


const OL1 = styled.ol`
  list-style: none;
  margin: auto;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;
  }
`;

const Return = styled.ol`
  margin: auto;

  margin-top: 5rem;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;
  }
`;

const Div = styled.div`
  margin-top: 5rem;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  margin-left: 4rem;
  height: 38rem;
  width: 25rem;
  border-radius: 20px;
  margin-bottom: 5rem;
  background-color: #caf0f8;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    margin: auto;
    width: 20rem;
    height: max-height;
    margin-top: 5rem;
  }
`;

const Cards = styled.div`
  display: flex;
  flex-direction: row;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;

    flex-direction: column;
    align-items: center;
    margin: auto;
    justify-content: center;
  }
`;

const TD1 = styled.td`
  margin-left: 2rem;
  width: 12rem;
  overflow-wrap: anywhere;
  font-weight: 600;
  font-family: Poppins, medium;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    margin: auto;
    margin-left: 2rem;
    width: 7rem;
    overflow-wrap: anywhere;
  }
`;

const Num2 = styled.li`
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;

    flex-direction: column;

    justify-content: center;
    align-items: center;
    margin: auto;
    width: 100%;
  }
`;

const Numbers = styled.li`
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 7rem;
  }
`;

const DetailsButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 5px;

  color: white;
  border: none;
  border-radius: 5px;
  background-color: #1dbf73;
  box-shadow: 1px 3px 6px #00000029;

  margin-top: 4rem;
  margin-bottom: 2rem;
`;
const CreateButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  padding: 5px;
  width: 10rem;
  height: 3rem;
  font-size: 18px;
  font-weight: 600;
  color: white;
  border: none;
  border-radius: 5px;
  background-color: #1dbf73;
  box-shadow: 1px 3px 6px #00000029;

  margin-top: 4rem;
  margin-bottom: 2rem;
`;

const Headingtext1 = styled.h3`
  font-family: Poppins, Medium;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 5rem;
  font-weight: 600;
  font-size: 1.5rem;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    margin: auto;
    width: 20rem;

    padding-bottom: 2rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 7rem;
  }
`;

const Main = styled.tr`
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    width: 100%;
  }
`;
const Table = styled.table`
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    width: 20rem;
    height: max-content;
  }
`;
const Row = styled.div`
  background-color: white;
  height: max-content;
  padding-bottom: 1rem;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`;
const Row1 = styled.tr`
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    // margin:auto;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`;
const Row2 = styled.tr`
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    // margin:auto;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`;
