import React, { useState, useEffect, useRef } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { styled, useTheme } from "@mui/material/styles";
import { 
  Box, 
  Typography, 
  Button, 
  Drawer, 
  TextField, 
  CircularProgress,
  alpha 
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { FaBug, FaJira } from 'react-icons/fa';
import Select from "react-select";
import moment from "moment";
import axios from "axios";
import { useAuthToken } from "../TokenContext";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

// Styled Components
const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'collapsed',
})(({ theme, collapsed }) => ({
  position: 'relative',
  width: `calc(100vw - ${collapsed ? '60px' : '240px'} - 48px)`,
  marginLeft: collapsed ? '60px' : '240px',
  overflowX: 'hidden',
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  [theme.breakpoints.down('md')]: {
    marginLeft: 0,
    width: '100vw'
  },
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : '#F5F5F5',
  color: theme.palette.text.primary,
  minHeight: 'calc(100vh - 64px)', // Account for AppBar height
  padding: theme.spacing(3)
}));

const SelectWrapper = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  '& .select__control': {
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#fff',
    borderColor: theme.palette.divider,
    minHeight: 56,
    marginTop: 10,
    '&:hover': {
      borderColor: theme.palette.primary.main,
    }
  },
  '& .select__menu': {
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#fff',
    color: theme.palette.text.primary,
    zIndex: 999,
    boxShadow: theme.shadows[3]
  },
  '& .select__option': {
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#fff',
    color: theme.palette.text.primary,
    padding: theme.spacing(1),
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.1)
    }
  },
  '& .select__multi-value': {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    borderRadius: theme.shape.borderRadius / 2,
    '& .select__multi-value__label': {
      color: theme.palette.text.primary
    }
  }
}));

const StyledColumn = styled(Box)(({ theme, collapsed }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#fff',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  minWidth: '280px',
  width: '280px',
  marginRight: theme.spacing(2),
  maxHeight: 'calc(100vh - 220px)', // Account for header, controls and padding
  overflowY: 'auto',
  scrollbarWidth: 'none',  // Firefox
  msOverflowStyle: 'none',  // IE and Edge
  '&::-webkit-scrollbar': {
    display: 'none'  // Chrome, Safari, Opera
  },
  boxShadow: theme.shadows[1],
  '& .column-header': {
    borderBottom: '3px solid',
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(2),
    color: theme.palette.text.primary,
    fontSize: '0.875rem',
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  }
}));

const StyledTask = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : '#fff',
  border: `1px solid ${theme.palette.divider}`,
  borderLeft: `6px solid ${theme.palette.primary.main}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1.5),
  marginBottom: theme.spacing(1),
  cursor: 'pointer',
  transition: 'all 0.2s ease',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    transform: 'translateY(-2px)',
    boxShadow: theme.shadows[2]
  },
  '& .task-content': {
    width: '100%',
    textAlign: 'left'
  },
  '& .task-header': {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  '& .task-title': {
    fontSize: '0.875rem',
    fontWeight: 500,
    color: theme.palette.text.primary
  },
  '& .task-id': {
    fontSize: '0.75rem',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: '2px 8px',
    borderRadius: '4px',
    display: 'inline-block',
    marginBottom: theme.spacing(1)
  },
  '& .task-assignee': {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    fontSize: '0.75rem',
    color: theme.palette.text.secondary
  },
  '& .task-due-date': {
    fontSize: '0.75rem',
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(0.5)
  }
}));

const ScrollButton = styled(Button)(({ theme }) => ({
  position: 'fixed',
  top: '50%',
  transform: 'translateY(-50%)',
  width: 40,
  height: 40,
  minWidth: 40,
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#fff',
  color: theme.palette.text.primary,
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: '50%',
  boxShadow: theme.shadows[2],
  zIndex: 1,
  '&:hover': {
    backgroundColor: theme.palette.action.hover
  }
}));

const KanbanboardTalent = ({ collapsed }) => {
  const theme = useTheme();
  const token = useAuthToken();
  const intl = useIntl();
  const { user } = useSelector((state) => state.auth);
  const scrollContainerRef = useRef(null);

  // State declarations
  const [tasks, setTasks] = useState([]);
  const [columns, setColumns] = useState({
    "To Do": { title: "To Do", items: [], type: "open" },
    "In Progress": { title: "In Progress", items: [], type: "open" },
    Done: { title: "Done", items: [], type: "closed" },
  });
  const [selectedPodId, setSelectedPodId] = useState([]);
  const [authorizationDetails, setAuthorizationDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [candidates, setCandidates] = useState([]);
  const [selectedCandidate, setSelectedCandidate] = useState([]);
  const [podNames, setPodNames] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const [newComment, setNewComment] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);

  // Effects and handlers from your original code...
  // [Previous implementation of effects and handlers remains the same]

  // Handle drag and drop
  const onDragEnd = async (result) => {
    if (!result.destination || isUpdating) return;

    const { source, destination, draggableId } = result;

    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }

    const task = Object.values(columns).reduce((found, column) => {
      if (found) return found;
      return column.items.find(
        (task) => (task.key || task.pod_task_id).toString() === draggableId
      );
    }, null);

    if (!task) return;

    const oldColumns = columns;
    const newColumns = { ...columns };
    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];

    const sourceItems = [...sourceColumn.items];
    const destItems = [...destColumn.items];

    const [removed] = sourceItems.splice(source.index, 1);
    const movedTask = { ...removed, status: destination.droppableId };
    destItems.splice(destination.index, 0, movedTask);

    newColumns[source.droppableId] = {
      ...sourceColumn,
      items: sourceItems,
    };
    newColumns[destination.droppableId] = {
      ...destColumn,
      items: destItems,
    };

    setColumns(newColumns);
    setIsUpdating(true);

    try {
      if (task.pod_task_id) {
        // Update POD task
        const response = await axios.put(
          process.env.REACT_APP_CLIENT_UPDATEKANBANBOARDPODTASK,
          {
            pod_task_id: task.pod_task_id,
            status: destination.droppableId,
          },
          { headers: { Authorization: token, "Content-Type": "application/json" } }
        );

        if (response.status !== 200) throw new Error("Failed to update POD task status");
      } else {
        // Update JIRA task
        const response = await axios.put(
          process.env.REACT_APP_CLIENT_KANBANBOARDUPDATE,
          {
            Jira_URL: authorizationDetails.Jira_URL,
            Jira_admin_login_email_address: authorizationDetails.Jira_admin_login_email_address,
            Jira_admin_API_Token: authorizationDetails.Jira_admin_API_Token,
            key: task.key,
            status: destination.droppableId,
          },
          { 
            headers: { Authorization: token, "Content-Type": "application/json" },
            timeout: 10000
          }
        );

        if (response.status !== 200) throw new Error("Failed to update JIRA task status");
      }
    } catch (error) {
      console.error("Error updating task status:", error);
      setColumns(oldColumns);
      alert("Failed to update task status. Please try again.");
    } finally {
      setIsUpdating(false);
    }
  };

  // Handle task selection
  const handleTaskClick = async (task) => {
    const fullTask = tasks.find(
      (t) => (t.key || t.pod_task_id) === (task.key || task.pod_task_id)
    );
    
    if (fullTask) {
      setSelectedTask(fullTask);
      if (fullTask.pod_task_id) {
        try {
          const response = await axios.post(
            "https://6w88fzkr0j.execute-api.us-east-1.amazonaws.com/prod/fetchpodtaskComment",
            { pod_task_id: parseInt(fullTask.pod_task_id) },
            { headers: { Authorization: token, "Content-Type": "application/json" } }
          );
        } catch (error) {
          console.error("Error fetching pod task comments:", error);
        }
      }
    } else {
      setSelectedTask({
        ...task,
        comments: [],
      });
    }
  };

  // Handle adding comments
  const handleAddComment = async () => {
    if (!newComment.trim() || !selectedTask) return;

    const headers = {
      Authorization: token,
      "Content-Type": "application/json",
    };

    try {
      if (selectedTask.pod_task_id) {
        // Handle POD task comment
        let existingComments = [];
        if (selectedTask.comment) {
          try {
            existingComments = JSON.parse(selectedTask.comment);
          } catch (error) {
            console.error("Error parsing existing comments:", error);
          }
        }

        const response = await axios.post(
          "https://6w88fzkr0j.execute-api.us-east-1.amazonaws.com/prod/addCommentpodtask",
          [{
            pod_task_id: parseInt(selectedTask.pod_task_id),
            user_id: parseInt(user.data[0].c_id),
            new_text: newComment,
            is_reply: false,
            author: user.data[0].firstname
          }],
          { headers }
        );

        if (response.status === 200) {
          setSelectedTask(prevTask => ({
            ...prevTask,
            comment: JSON.stringify([
              ...existingComments,
              {
                text: newComment,
                user_id: parseInt(user.data[0].c_id),
                timestamp: new Date().toISOString(),
                replies: []
              }
            ])
          }));
        }
      } else if (selectedTask.key) {
        // Handle Jira comment
        const response = await axios.post(
          "https://o0quulyyma.execute-api.us-east-1.amazonaws.com/prod/addCommentjirapodboard",
          {
            jiraUrl: authorizationDetails.Jira_URL,
            issueKey: selectedTask.key,
            commentText: newComment,
            username: authorizationDetails.Jira_admin_login_email_address,
            password: authorizationDetails.Jira_admin_API_Token,
          },
          { headers }
        );

        if (response.status === 200) {
          setSelectedTask(prevTask => ({
            ...prevTask,
            comments: [
              ...(prevTask.comments || []),
              {
                author: user.data[0].firstname || "current_user",
                body: {
                  type: "doc",
                  version: 1,
                  content: [
                    {
                      type: "paragraph",
                      content: [{ type: "text", text: newComment }],
                    },
                  ],
                },
                created: new Date().toISOString(),
              },
            ],
          }));
        }
      }
      setNewComment("");
    } catch (error) {
      console.error("Error adding comment:", error);
      alert("Failed to add comment. Please try again.");
    }
  };

  // Fetch pod names and initial data
  useEffect(() => {
    const fetchPodNames = async () => {
      try {
        const response = await axios.post(
          process.env.REACT_APP_TALENT_GETPODSFORTALENT,
          { c_id: user.data[0].c_id },
          { headers: { Authorization: token, "Content-Type": "application/json" } }
        );
        const podNameOptions = response.data.body.map(pod => ({
          label: pod.pod_name,
          value: pod.pod_id,
        }));
        setPodNames(podNameOptions);
      } catch (error) {
        console.error("Error fetching pod names:", error);
      }
    };
    fetchPodNames();
  }, [token, user.data]);

  useEffect(() => {
    if (selectedPodId && selectedPodId.length > 0) {
      fetchAuthorizationTokensAndList();
    } else {
      // Reset states when no pod is selected
      setCandidates([]);
      setTasks([]);
      setColumns({
        "To Do": { title: "To Do", items: [], type: "open" },
        "In Progress": { title: "In Progress", items: [], type: "open" },
        Done: { title: "Done", items: [], type: "closed" },
      });
    }
  }, [selectedPodId, selectedCandidate]);

  // Fetch authorization tokens and task list
  const fetchAuthorizationTokensAndList = async () => {
    setLoading(true);
    
    if (!selectedPodId || selectedPodId.length === 0) {
      setLoading(false);
      return;
    }

    try {
      // Fetch authorization tokens
      const authorizationResponse = await axios.post(
        process.env.REACT_APP_CUSTOMERADMIN_FETCHAUTHORIZATIONTOKENS,
        { Organizational_Code: user.data[0].talent_code },
        { headers: { Authorization: token, "Content-Type": "application/json" } }
      );

      const authorizationData = authorizationResponse.data.body.data[0];
      setAuthorizationDetails(authorizationData);

      // Initialize columns based on authorization data
      const initialColumns = {};
      if (authorizationData?.jira_open_story_bug_status) {
        const openStatuses = authorizationData.jira_open_story_bug_status[0].split(",").map(s => s.trim());
        openStatuses.forEach(status => {
          initialColumns[status] = { title: status, items: [], type: "open" };
        });
      }
      if (authorizationData?.jira_closed_story_bug_status) {
        const closedStatuses = authorizationData.jira_closed_story_bug_status[0].split(",").map(s => s.trim());
        closedStatuses.forEach(status => {
          initialColumns[status] = { title: status, items: [], type: "closed" };
        });
      }
      setColumns(initialColumns);

      // Fetch candidates
      const emailFetchingResponse = await axios.post(
        process.env.REACT_APP_CLIENT_FETCHPODCANDIDATES,
        { podID: selectedPodId },
        { headers: { Authorization: token, "Content-Type": "application/json" } }
      );

      const allCandidates = emailFetchingResponse?.data?.data || [];
      setCandidates(allCandidates);
      const candidateNames = selectedCandidate.map(option => option.value);

      if (allCandidates.length > 0) {
        // Fetch tasks
        const listPayload = {
          pod_Id: selectedPodId,
          username: authorizationData.Jira_admin_login_email_address,
          password: authorizationData.Jira_admin_API_Token,
          projectKey: authorizationData.Jira_Kanben_Project_name,
          jira_url: authorizationData.Jira_URL,
          assignees: allCandidates.map(candidate => candidate.email),
          candidateName: candidateNames || [],
        };

        const response = await axios.post(
          "https://irl3zjh84e.execute-api.us-east-1.amazonaws.com/prod/testSingleviewtask",
          listPayload,
          { headers: { Authorization: token, "Content-Type": "application/json" } }
        );

        // Combine POD and Jira tasks
        const combinedTasks = [
          ...(response.data.data.podMembers || []).map(task => ({
            ...task,
            comments: task.comments || [],
          })),
          ...(response.data.data.jiraIssues || []).map(task => ({
            ...task,
            comments: task.comments || [],
          })),
        ];

        setTasks(combinedTasks);

        // Group tasks by status
        const groupedTasks = { ...initialColumns };
        combinedTasks.forEach(task => {
          const status = task.status || "To Do";
          if (groupedTasks[status]) {
            groupedTasks[status].items.push(task);
          }
        });
        setColumns(groupedTasks);
      }
    } catch (error) {
      console.error("Error in fetchAuthorizationTokensAndList:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleScroll = (direction) => {
    if (scrollContainerRef.current) {
      const scrollAmount = 300;
      const currentScroll = scrollContainerRef.current.scrollLeft;
      scrollContainerRef.current.scrollTo({
        left: currentScroll + (direction === "left" ? -scrollAmount : scrollAmount),
        behavior: "smooth"
      });
    }
  };

  return (
    <StyledBox collapsed={collapsed}>
      <Typography variant="h4" sx={{ textAlign: 'center', mb: 4 }}>
        {intl.formatMessage({ id: "podBoard" })}
      </Typography>

      {/* Selection Controls */}
       <Box>
      <SelectWrapper>
        <Select
          isMulti
          options={podNames}
          value={podNames.filter(option => selectedPodId.includes(option.value))}
          onChange={selectedOptions => setSelectedPodId(selectedOptions.map(opt => opt.value))}
          placeholder={intl.formatMessage({ id: 'selectPod' })}
          classNamePrefix="select"
        />
        <Select
          isMulti
          options={candidates.map(candidate => ({
            value: candidate.Candidate_Name,
            label: candidate.Candidate_Name
          }))}
          value={selectedCandidate}
          onChange={selectedOptions => setSelectedCandidate(selectedOptions || [])}
          placeholder="Select Candidates"
          classNamePrefix="select"
        />
      </SelectWrapper>
 </Box>
      {/* Kanban Board */}
      <Box sx={{ position: 'relative', width: '100%' }}>
        <ScrollButton
          onClick={() => handleScroll("left")}
          sx={{ left: theme.spacing(2) }}
        >
          ←
        </ScrollButton>

        <DragDropContext onDragEnd={onDragEnd}>
          <Box 
            ref={scrollContainerRef}
            sx={{
              display: 'flex',
              overflowX: 'auto',
              height: 'calc(100vh - 200px)', // Account for header and controls
              padding: theme.spacing(2),
              gap: theme.spacing(2),
              scrollbarWidth: 'none',  // Firefox
              msOverflowStyle: 'none',  // IE and Edge
              '&::-webkit-scrollbar': {
                display: 'none'  // Chrome, Safari, Opera
              }
            }}
            
          >
            {Object.entries(columns).map(([columnId, column]) => (
              <Droppable key={columnId} droppableId={columnId}>
                {(provided) => (
                  <StyledColumn
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    collapsed={collapsed}
                  >
                    <Typography 
                      
                      variant="h6" sx={{ 
                        borderBottom: 3,
                        borderColor: column.type === "closed" ? "success.main" : "warning.main",
                        pb: 1,
                        mb: 2
                      }}>
                      {column.title} ({column.items.length})
                    </Typography>

                    {loading ? (
                      <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                        <CircularProgress size={24} />
                      </Box>
                    ) : (
                      <Box sx={{ minHeight: 100 }}>
                        {column.items.map((task, index) => (
                          <Draggable
                            key={task.key || task.pod_task_id}
                            draggableId={(task.key || task.pod_task_id).toString()}
                            index={index}
                          >
                            {(provided) => (
                              <StyledTask
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <Button
                                  onClick={() => handleTaskClick(task)}
                                  sx={{ 
                                    width: '100%', 
                                    textAlign: 'left',
                                    color: 'text.primary',
                                    textTransform: 'none',
                                    p: 0
                                  }}
                                >
                                  <Box className="task-content">
                                    <Box className="task-header">
                                      {task.key ? (
                                        <FaJira size={16} color={theme.palette.primary.main} />
                                      ) : (
                                        <FaBug size={16} color={theme.palette.success.main} />
                                      )}
                                      <Typography className="task-title">
                                        {task.summary || task.pod_task_name}
                                      </Typography>
                                    </Box>
                                    <Typography className="task-id">
                                      {task.key}
                                    </Typography>
                                    <Box className="task-assignee">
                                      <PersonIcon sx={{ color: 'success.main' }} />
                                      <Typography>{task.Candidate_Name}</Typography>
                                    </Box>
                                    <Typography className="task-due-date">
                                      Due: {moment(task.due_date).format("MM-DD-YYYY") || "Not Set"}
                                    </Typography>
                                  </Box>
                                </Button>
                              </StyledTask>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </Box>
                    )}
                  </StyledColumn>
                )}
              </Droppable>
            ))}
          </Box>
        </DragDropContext>

        <ScrollButton
          onClick={() => handleScroll("right")}
          sx={{ right: theme.spacing(2) }}
        >
          →
        </ScrollButton>
      </Box>

      {/* Comment Drawer */}
      <Drawer
        anchor="right"
        open={!!selectedTask}
        onClose={() => {
          setSelectedTask(null);
          setNewComment("");
        }}
        sx={{
          '& .MuiDrawer-paper': {
            width: 400,
            bgcolor: theme.palette.mode === 'dark' ? 'background.default' : 'background.paper',
            p: 3
          }
        }}
      >
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', pb: 2, mb: 2 }}>
            <Typography variant="h6">
              {selectedTask?.key || selectedTask?.pod_task_id} - {selectedTask?.summary || selectedTask?.pod_task_name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Status: {selectedTask?.status}
            </Typography>
          </Box>

          <Box sx={{ 
            flexGrow: 1, 
            overflowY: 'auto',
            mb: 2,
            scrollbarWidth: 'none',  // Firefox
            msOverflowStyle: 'none',  // IE and Edge
            '&::-webkit-scrollbar': {
              display: 'none'  // Chrome, Safari, Opera
            }
          
          }}>
            {selectedTask?.comments?.map((comment, index) => (
              <Box
                key={index}
                sx={{
                  bgcolor: theme.palette.mode === 'dark' 
                    ? alpha(theme.palette.background.paper, 0.6) 
                    : 'background.paper',
                  p: 2,
                  borderRadius: 1,
                  mb: 2,
                  boxShadow: 1,
                  '&:hover': {
                    bgcolor: theme.palette.action.hover
                  }
                }}
              >
                <Box sx={{ 
                  display: 'flex', 
                  justifyContent: 'space-between', 
                  alignItems: 'center',
                  mb: 1 
                }}>
                  <Typography
                    sx={{
                      bgcolor: 'primary.main',
                      color: 'primary.contrastText',
                      px: 1,
                      py: 0.5,
                      borderRadius: 1,
                      fontSize: '0.875rem',
                      fontWeight: 500
                    }}
                  >
                    {comment.author}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    {moment(comment.created || comment.timestamp).format("MMM DD, YYYY HH:mm")}
                  </Typography>
                </Box>
                <Typography variant="body2">
                  {comment.body ? renderJiraComment(comment.body?.content) : comment.text}
                </Typography>
              </Box>
            ))}
          </Box>

          <Box sx={{ 
            borderTop: 1, 
            borderColor: 'divider',
            pt: 2,
            bgcolor: 'background.paper'
          }}>
            <TextField
              label="Add a comment"
              multiline
              rows={3}
              fullWidth
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              variant="outlined"
              size="small"
              sx={{ mb: 1 }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddComment}
              disabled={!newComment.trim()}
              sx={{ float: 'right' }}
            >
              Add Comment
            </Button>
          </Box>
        </Box>
      </Drawer>
    </StyledBox>
  );
};

// Utils
const renderJiraComment = (content) => {
  if (!content) return null;

  return content.map((item, index) => {
    if (item.type === 'paragraph') {
      return (
        <div key={index} style={{ marginBottom: '8px' }}>
          {item.content?.map((contentItem, cIndex) => {
            switch (contentItem.type) {
              case 'text':
                return <span key={cIndex}>{contentItem.text}</span>;
              case 'mention':
                return (
                  <span 
                    key={cIndex} 
                    style={{ 
                      color: '#0052CC',
                      fontWeight: 500,
                      backgroundColor: '#EAE6FF',
                      padding: '0 4px',
                      borderRadius: '3px',
                      margin: '0 2px'
                    }}
                  >
                    {contentItem.attrs.text}
                  </span>
                );
              case 'hardBreak':
                return <br key={cIndex} />;
              default:
                return null;
            }
          })}
        </div>
      );
    }
    return null;
  });
};

export default KanbanboardTalent;