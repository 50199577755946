import React, { createContext, useState, useContext, useEffect } from 'react';
import { ThemeProvider as MUIThemeProvider, createTheme } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';

const ThemeContext = createContext({
  darkMode: false,
  toggleDarkMode: () => {},
});

export const useTheme = () => useContext(ThemeContext);

export const ThemeProvider = ({ children }) => {
  const [darkMode, setDarkMode] = useState(() => {
    const savedMode = localStorage.getItem('darkMode');
    return savedMode ? JSON.parse(savedMode) : false;
  });

  useEffect(() => {
    localStorage.setItem('darkMode', JSON.stringify(darkMode));
  }, [darkMode]);

  const theme = createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
      primary: {
        main: darkMode ? '#90caf9' : '#1976d2',
        contrastText: darkMode ? '#000' : '#fff',
      },
      secondary: {
        main: darkMode ? '#f48fb1' : '#dc004e',
        contrastText: darkMode ? '#000' : '#fff',
      },
      background: {
        default: darkMode ? '#121212' : '#fff',
        paper: darkMode ? '#1e1e1e' : '#fff',
      },
      text: {
        primary: darkMode ? '#fff' : '#000',
        secondary: darkMode ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.6)',
      },
      action: {
        active: darkMode ? '#fff' : '#000',
        hover: darkMode ? 'rgba(255, 255, 255, 0.08)' : 'rgba(0, 0, 0, 0.04)',
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          'html, body': {
            margin: 0,
            padding: 0,
            height: '100%',
            backgroundColor: darkMode ? '#121212' : '#fff',
          },
          '#root': {
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100%',
            backgroundColor: darkMode ? '#121212' : '#fff',
          },
          'main': {
            flex: '1 0 auto',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: darkMode ? '#121212' : '#fff',
            minHeight: '100%',
          },
          'footer': {
            flexShrink: 0,
            backgroundColor: darkMode ? '#121212' : '#fff',
          },
          '.content-wrapper': {
            flex: '1 0 auto',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: darkMode ? '#121212' : '#fff',
          },
          // Add specific styles for your app layout
          '.app-container': {
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            backgroundColor: darkMode ? '#121212' : '#fff',
          },
          '.main-content': {
            flex: '1 0 auto',
            backgroundColor: darkMode ? '#121212' : '#fff',
          },
          '.footer': {
            flexShrink: 0,
            backgroundColor: darkMode ? '#121212' : '#fff',
            marginTop: 'auto',
          }
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            color: darkMode ? '#fff' : '#000',
            '&:hover': {
              backgroundColor: darkMode ? 'rgba(255, 255, 255, 0.08)' : 'rgba(0, 0, 0, 0.04)',
            },
          },
        },
      },
    },
  });

  const toggleDarkMode = () => {
    setDarkMode(prev => !prev);
  };

  return (
    <ThemeContext.Provider value={{ darkMode, toggleDarkMode }}>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </ThemeContext.Provider>
  );
};