import React, { useState } from 'react'
import styled from 'styled-components'
import profile from './assets/profile_img.png';
import "./FindPods.css";
import { useEffect } from 'react';
import axios from 'axios';
import userEvent from '@testing-library/user-event';
import { useDispatch, useSelector } from "react-redux";
import { data } from 'jquery';
import FindPodsDetails from './FindPodsDetails'
import { NavHashLink } from 'react-router-hash-link';
import star from './assets/star.png';
import Createpod from './Createpod';
import AWS from 'aws-sdk';
import defaultimg from './assets/defaultimge.png';
import { useAuthToken } from "../TokenContext";
import { useIntl } from 'react-intl';
const FindPods = () => {
  const token = useAuthToken();
  const intl = useIntl();
  const [Subscription, setSubscription] = useState({});
  const [subscriptionFetch, setSubscriptionFetch] = useState([]);
  const [loading, setLoading] = useState(true);

  const config = {
    bucketName: process.env.REACT_APP_BUCKETNAME,
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_ACCESSKEYID,
    secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY,
  };

  const S3Config = {
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_ACCESSKEYID,
    secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY,
  };

  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(2);
  const [AuthImage, setAuthImage] = useState([]);
  const [pageNumberLimit, setPageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
  const [getData, setGetData] = useState([]);
  const [GetCandidates, setGetCandidates] = useState([]);
  const [requirement, setRequirement] = useState([]);

  useEffect(() => {
    if (user && user.data && user.data.length > 0) {
     
      const body = {
        client_code: user.data[0].talent_code,
      };

      const headers = {
        Authorization: token, // Replace with your actual token
        'Content-Type': 'application/json', // You can add more headers as needed
      };

      axios
        .post(process.env.REACT_APP_FETCHSUBSCRIPTIONDATA, body, { headers })
        .then((res) => {
          setSubscription(res.data.data);
          setSubscriptionFetch(res.data.data[0]?.find_pods || []);
        })
        .catch((err) => {
          console.log('catch', err);
        })
        .finally(() => {
          setLoading(false); // Set loading to false once the API call is completed.
        });
    }
  }, [token, user]);

  const myBucket = new AWS.S3(S3Config);

  useEffect(() => {
    const fetchData = async () => {
      if (user && user.data && user.data.length > 0) {
        const headers = {
          Authorization: token, // Replace with your actual token
          'Content-Type': 'application/json', // You can add more headers as needed
        };

        try {
          const body = {
            primary_skills: user.data[0].primaryskill,
            secondary_skills: user.data[0].secondaryskill,
            Client_Code: user.data[0].talent_code,
          };

          let findPod = await axios.post(
            process.env.REACT_APP_TALENT_FINDPODS,
            body,
            { headers }
          );

        
          if (findPod.data && findPod.data.data && findPod.data.data.length > 0) {
            setGetData(findPod.data.data);
            const URL_EXPIRATION_TIME = 900;

            const params = {
              Bucket: process.env.REACT_APP_BUCKETNAME,
              Key: findPod.data.data[0].picture,
              Expires: URL_EXPIRATION_TIME,
            };

            myBucket.getSignedUrl('getObject', params, (err, url) => {
              if (err) {
                console.log(err);
              } else {
                setAuthImage(url);
              }
            });
          } else {
            console.error('findPod.data.data is undefined or empty');
          }

          const getPodBody = {
            candidateID: user.data[0].c_id,
          };

          let getPod = await axios.post(
            process.env.REACT_APP_CLIENT_GETCANDIDATEPODS,
            getPodBody,
            { headers }
          );

          setGetCandidates(getPod.data.data.candidates);
        } catch (err) {
          console.log(err);
        }
      }
    };

    fetchData();
  }, [token, user]);

  const handleClick = (event) => {
    setCurrentPage(Number(event.target.id));
  };

  const pages = [];
  if (getData && getData.length > 0) {
    for (let i = 1; i <= Math.ceil(getData.length / itemsPerPage); i++) {
      pages.push(i);
    }
  }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = getData.slice(indexOfFirstItem, indexOfLastItem);

  const renderPageNumbers = pages.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li 
        key={number}
        className={currentPage === number ? 'active' : null}
      >
        <button
          id={number}
          onClick={handleClick}
          className="page-button" // Add appropriate styling
          aria-label={`Go to page ${number}`}
        >
          {number}
        </button>
      </li>
      );
    } else {
      return null;
    }
  });

  const handleNextBtn = () => {
    setCurrentPage(currentPage + 1);
    if (currentPage + 1 > maxPageNumberLimit) {
      setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const handlePrevBtn = () => {
    setCurrentPage(currentPage - 1);
    if ((currentPage - 1) % pageNumberLimit === 0) {
      setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  const renderData = (getData) => {
    return (
      <>
        {loading ? (
          <div>{intl.formatMessage({ id: 'loading' })}...</div> // Show a loading indicator while waiting for the API call to complete.
        ) : Subscription === "There Are No subscription data." || subscriptionFetch === "False" ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              margin: 'auto',
              marginTop: '8rem',
            }}
          >
            {intl.formatMessage({ id: 'noSubscription' })}
          </div>
        ) : (
          <div>
            <Headingtext1>
              {intl.formatMessage({ id: 'findBestPod' })}
            </Headingtext1>

            <ol className="pageNumbers">
              <Num2>
                <li>
                  <button
                    onClick={handlePrevBtn}
                    disabled={currentPage === pages[0] ? true : false}
                  >
                    {intl.formatMessage({ id: 'prev' })}
                  </button>
                </li>
                <Numbers>{renderPageNumbers}</Numbers>
                <li>
                  <button
                    onClick={handleNextBtn}
                    disabled={
                      currentPage === pages[pages.length - 1] ? true : false
                    }
                  >
                    {intl.formatMessage({ id: 'next' })}
                  </button>
                </li>
              </Num2>
            </ol>

            <OL1 style={{ display: 'flex', flexDirection: 'row' }}>
              {getData.length > 0 ? (
                getData.map((data, i) => (
                  <Div key={i}>
                    <li>
                      <div className="name">
                        {data.picture == null || data.picture === "" ? (
                          <img
                            src={defaultimg}
                            alt=""
                            style={{
                              width: 50,
                              height: 50,
                              borderRadius: '50px',
                            }}
                          ></img>
                        ) : (
                          <img
                            style={{ width: '50px', height: '50px' }}
                            src={
                              'https://globldataprod.s3.amazonaws.com/' +
                              data.picture
                            }
                          ></img>
                        )}
                        <p className="firstname">{data.rating}</p>
                      </div>
                      <div className="name2">
                        <tr className="TR">
                          <td className="TD">
                            {intl.formatMessage({ id: 'podName' })}
                          </td>
                          <td>:</td>
                          <TD1>{data.podName}</TD1>
                        </tr>
                      </div>
                      <div className="name2">
                        <tr className="TR">
                          <td className="TD">
                            {intl.formatMessage({ id: 'email' })}
                          </td>
                          <td>:</td>
                          <TD1>{data.email}</TD1>
                        </tr>
                      </div>
                      <div className="name2">
                        <tr className="TR">
                          <td className="TD">
                            {intl.formatMessage({ id: 'phone' })}
                          </td>
                          <td>:</td>
                          <TD1>{data.phone}</TD1>
                        </tr>
                      </div>
                      <div className="name2">
                        <tr className="TR">
                          <td className="TD">
                            {intl.formatMessage({ id: 'podSize' })}
                          </td>
                          <td>:</td>
                          <TD1>{data.podSize}</TD1>
                        </tr>
                      </div>
                    </li>
                  </Div>
                ))
              ) : (
                <div>{intl.formatMessage({ id: 'noData' })}</div>
              )}
            </OL1>
          </div>
        )}
      </>
    );
  };

  return <OL1>{renderData(getData)}</OL1>;
};

export default FindPods;





const OL1 = styled.ol`
display:flex;
flex-direction:row;
align-items:center;
justify-content:center;
list-style: none;
margin:auto;


@media screen and (min-width:320px) and (max-width:1080px){
    display:flex;
flex-direction:column;
margin-left:2rem;
align-items:center;
justify-content:center;


}
`;

const Return = styled.div`

margin:auto;

margin-top:5rem;
@media screen and (min-width:320px) and (max-width:1080px){
    display:flex;
flex-direction:column;

align-items:center;
justify-content:center;


}
`;

const Div = styled.div`


margin-top:5rem;
align-items:center;
justify-content:center;
padding-left:20px;
margin-left:4rem;
height:50rem;
width:29rem;
border-radius:20px;
background-color:rgb(142, 174, 188,32%);
@media screen and (min-width:320px) and (max-width:1080px){
 
  margin:auto;
  width:95%;
 height:max-height;
margin-top:5rem;
}
`;

const Cards = styled.div`
display:flex;
flex-direction:row;

@media screen and (min-width:320px) and (max-width:1080px){
    display:flex;

  flex-direction:column;
  align-items:center;
  margin:auto;
  justify-content:center;
 
}
`;


const TD1 = styled.td`
margin-left: 2rem;
width: 12rem;
overflow-wrap: anywhere;
font-weight: 600;
font-family: Poppins,medium;
@media screen and (min-width:320px) and (max-width:1080px){
 margin:auto;
 margin-left: 2rem;
    width: 7rem;
    overflow-wrap: anywhere;
}
`;

const Num2 = styled.li`
        @media screen and (min-width:320px) and (max-width:1080px){
          display:flex;
        margin:auto;
        flex-direction:column;
        align-items:center;
        justify-content:center;
        width:100%;
}
        `;


const Numbers = styled.li`
        @media screen and (min-width:320px) and (max-width:1080px){
          display:flex;
        align-items:center;
        justify-content:center;
        width:7rem;
}
        `;


const DetailsButton = styled.button`
        display:flex;
        justify-content:center;
        align-items:center;
        margin:auto;
        padding:5px;
      
        color:white;
      border:none;
        border-radius:5px  ;
        background-color:#1DBF73;
        box-shadow: 1px 3px 6px #00000029;

        margin-top:4rem;
        margin-bottom:2rem;
        
        `;
const CreateButton = styled.button`
        display:flex;
        justify-content:center;
        align-items:center;
        margin-left:auto;
        padding:5px;
      width:10rem;
      height:3rem;
      font-size:18px;
      font-weight:600;
        color:white;
      border:none;
        border-radius:5px  ;
        background-color:#1DBF73;
        box-shadow: 1px 3px 6px #00000029;

        margin-top:4rem;
        margin-bottom:2rem;
        
        `;


const Headingtext1 = styled.h3`
font-family:Poppins,Medium;
display:flex;
justify-content:center;
align-items:center;
margin:auto;
margin-top: 5rem;
font-weight: 600;
font-size: 1.5rem;
@media screen and (min-width:320px) and (max-width:1080px){
    display:flex;
    justify-content:center;
    align-items:center;
    margin:auto;
    margin-left:2rem;
    margin-top: 7rem;
    width:95%;
    }
`;