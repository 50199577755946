import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Circles } from "react-loader-spinner";
import AWS from "aws-sdk";
import { useAuthToken } from "../TokenContext";
import { Buffer } from "buffer";
window.Buffer = window.Buffer || require("buffer").Buffer;

const S3Config = {
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESSKEYID,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY,
};

const Candidateapprove = () => {
  const token = useAuthToken();
  const { id } = useParams();
  const { user } = useSelector((state) => state.auth);
  const myBucket = new AWS.S3(S3Config);

  const [state, setState] = useState({});
  const [authImage, setAuthImage] = useState("");
  const [resume, setResume] = useState("");
  const [arrexp, setArrexp] = useState({
    experience: [],
    education: [],
    certification: [],
  });

  const generatePresignedUrl = (key, setUrlCallback) => {
    const params = {
      Bucket: process.env.REACT_APP_BUCKETNAME,
      Key: key,
      Expires: 900,
    };

    myBucket.getSignedUrl("getObject", params, (err, url) => {
      if (!err) setUrlCallback(url);
    });
  };

  const sendEmail = async (subject, htmlContent) => {
    const params = {
      Source: "ppod@digitivy.com",
      Destination: { ToAddresses: ["ppod@digitivy.com"] },
      ReplyToAddresses: [state.email],
      Message: {
        Body: {
          Html: { Charset: "UTF-8", Data: htmlContent },
          Text: {
            Charset: "UTF-8",
            Data: "Profile status update from PrismPod",
          },
        },
        Subject: { Charset: "UTF-8", Data: subject },
      },
    };

    try {
      await new AWS.SES({
        region: "us-east-1",
        ...S3Config,
      })
        .sendEmail(params)
        .promise();

      alert("Profile status updated successfully!");
      window.location.href = "/candidateadmin-dashboard";
    } catch (err) {
      console.error(err);
      alert("Status updated but email notification failed");
      window.location.href = "/candidateadmin-dashboard";
    }
  };

  const rejectCandidate = async () => {
    const reason = prompt("Please Enter Reason of Rejection");
    if (!reason) {
      if (reason === "") alert("Please Enter The Reason.");
      return;
    }

    try {
      await axios.post(
        process.env.REACT_APP_TALENTADMIN_REJECTUSER,
        { table: "candidate", id: state.c_id },
        {
          headers: { Authorization: token, "Content-Type": "application/json" },
        }
      );

      const htmlContent = `
        <p>Hi, ${state.firstName}</p><br/><br/>
        Your Candidate Profile is Rejected in PrismPod.<br/><br/>
        <b>Reason of Rejection:</b> ${reason}<br/><br/>
        <b>Please login to <a href='www.prismpod.com'>PrismPOD.com</a> to update your profile.</b>
        <br/><br/><p>Thanks.</p>
      `;

      await sendEmail(
        "Your Candidate Profile is Rejected in PrismPod",
        htmlContent
      );
    } catch (err) {
      console.error(err);
      alert("Failed to reject candidate");
    }
  };

  const approveCandidate = async () => {
    try {
      await axios.post(
        process.env.REACT_APP_TALENTADMIN_APPROVEUSER,
        { table: "candidate", id: state.c_id },
        {
          headers: { Authorization: token, "Content-Type": "application/json" },
        }
      );

      const htmlContent = `
        <p>Hi, ${state.firstName}</p><br/><br/>
        Congrats! Your Candidate Profile is Approved in PrismPod.<br/><br/>
        <b>You Can login to <a href='www.prismpod.com'>PrismPOD.com</a> to access your profile now.</b>
        <br/><br/><p>Thanks.</p>
      `;

      await sendEmail(
        "Congrats! Your Candidate Profile is Approved in PrismPod",
        htmlContent
      );
    } catch (err) {
      console.error(err);
      alert("Failed to approve candidate");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const headers = {
          Authorization: token,
          "Content-Type": "application/json",
        };

        const [candidateResponse, expResponse] = await Promise.all([
          axios.post(
            process.env.REACT_APP_TALENTLEAD_GETCANDIDATEBYID,
            { id, table: "candidate" },
            { headers }
          ),
          axios.post(
            process.env.REACT_APP_CLIENT_GETCANDIDATEDATA,
            { id },
            { headers }
          ),
        ]);

        const candidateData = candidateResponse.data.data[0];
        setState(candidateData);
        generatePresignedUrl(candidateData.profilePicture, setAuthImage);
        generatePresignedUrl(candidateData.resume, setResume);

        setArrexp({
          experience: expResponse.data.data.exp,
          education: expResponse.data.data.education,
          certification: expResponse.data.data.certification,
        });
      } catch (err) {
        console.error(err);
        alert("Failed to fetch candidate data");
      }
    };

    fetchData();
  }, [token, id]);

  if (!state.email) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "140px",
        }}
      >
        <Circles height="50" width="50" color="black" visible={true} />
      </div>
    );
  }

  const InfoSection = ({ title, content, isHeader = false, style = {} }) => (
    <p
      style={{
        color: isHeader ? "#8EAEBC" : content ? "black" : "grey",
        fontSize: isHeader ? "18px" : "16px",
        fontWeight: isHeader ? "bold" : "normal",
        ...style,
      }}
    >
      {content || title}
    </p>
  );

  return (
    <div className="container" style={{ marginTop: "140px" }}>
      <div className="row">
        <div className="col-lg-2" />
        <div
          className="col-lg-6"
          style={{
            boxShadow: "1px 3px 6px #00000029",
            border: "1px solid #D8D8D8",
            borderRadius: "16px",
            padding: "60px",
          }}
        >
          {/* Profile Header */}
          <div className="text-center">
            <img
              src={`https://globldataprod.s3.amazonaws.com/${state.profilePicture}`}
              style={{
                width: 100,
                height: 100,
                marginTop: 50,
                borderRadius: 50,
              }}
              alt="Profile"
            />
            <InfoSection
              content={`${state.firstName} ${state.lastName}`}
              style={{ marginTop: 20 }}
            />
            <InfoSection content={state.email} />
            <InfoSection content={state.country} />
          </div>

          {/* Basic Information */}
          <InfoSection title="Basic information" isHeader />
          <InfoSection content={state.about} />

          {/* Skills & Expertise */}
          <InfoSection title="Skills & Expertise" isHeader />
          <InfoSection
            content={`${state.primaryskill}, ${state.secondaryskill}`}
          />
          <InfoSection content={state.industry} />

          {/* Education */}
          <InfoSection title="Education & Certifications" isHeader />
          {arrexp.education.map((edu, index) => (
            <div key={`edu-${index}`}>
              <InfoSection content={edu.degree} />
              <InfoSection content={edu.institution} />
              <InfoSection content={edu.startDate} />
            </div>
          ))}

          {/* Work Experience */}
          <InfoSection title="Work Experience" isHeader />
          {arrexp.experience.map((exp, index) => (
            <div key={`exp-${index}`}>
              <InfoSection content={exp.work_position} />
              <InfoSection content={exp.work_organization} />
              <InfoSection content={exp.workStartdate} />
            </div>
          ))}

          {/* Action Buttons */}
          <div className="mt-4">
            {(state.isApproved === "Rejected" ||
              state.isApproved === "Unapproved") && (
              <button
                className="button is-success"
                onClick={approveCandidate}
                style={{ width: "40%", marginRight: "20px" }}
              >
                Approve Candidate
              </button>
            )}
            {(state.isApproved === "Approved" ||
              state.isApproved === "Unapproved") && (
              <button
                className="button is-danger"
                onClick={rejectCandidate}
                style={{ width: "40%" }}
              >
                Reject Candidate
              </button>
            )}
          </div>
        </div>
        <div className="col-lg-4" />
      </div>
    </div>
  );
};

export default Candidateapprove;
