import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  Paper,
  Button,
  Typography,
  Container,
  TextField,
  MenuItem,
  IconButton,
  Chip,
  Stack,
  useTheme,
  CircularProgress
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import Select from 'react-select';
import { useAuthToken } from "../TokenContext";
import { useIntl } from 'react-intl';
import axios from 'axios';
import allergens from './allergens';

// Styled Components
const RootContainer = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(4),
  paddingTop: theme.spacing(12)
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius * 2,
  padding: theme.spacing(4),
  width: '100%',
  maxWidth: '800px',
  margin: '0 auto',
  boxShadow: theme.shadows[3],
  transition: 'box-shadow 0.3s ease-in-out',
  '&:hover': {
    boxShadow: theme.shadows[6]
  }
}));

const FormContainer = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3)
}));

const PageTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  textAlign: 'center',
  marginBottom: theme.spacing(4),
  fontWeight: 600,
  fontSize: '2rem',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.5rem'
  }
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:hover': {
      borderColor: theme.palette.primary.main
    },
    '&.Mui-focused': {
      boxShadow: `0 0 0 2px ${theme.palette.primary.main}25`
    }
  }
}));

const CandidateChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.primary.contrastText,
  '& .MuiChip-deleteIcon': {
    color: theme.palette.primary.contrastText,
    '&:hover': {
      color: theme.palette.error.main
    }
  }
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1.5),
  fontSize: '1.1rem',
  marginTop: theme.spacing(2),
  borderRadius: theme.shape.borderRadius * 1.5
}));

// React-Select custom styles
const selectStyles = (theme) => ({
  control: (base) => ({
    ...base,
    backgroundColor: theme.palette.mode === 'dark' ? '#2a2a2a' : theme.palette.background.paper,
    borderColor: theme.palette.mode === 'dark' ? '#404040' : theme.palette.divider,
    borderRadius: theme.shape.borderRadius,
    boxShadow: 'none',
    '&:hover': {
      borderColor: theme.palette.mode === 'dark' ? '#505050' : theme.palette.primary.main
    },
    minHeight: '56px'
  }),
  menu: (base) => ({
    ...base,
    backgroundColor: theme.palette.mode === 'dark' ? '#2a2a2a' : theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    padding: 4
  }),
  menuList: (base) => ({
    ...base,
    padding: 0
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected 
      ? theme.palette.mode === 'dark' ? '#3b82f6' : theme.palette.primary.main
      : state.isFocused 
        ? theme.palette.mode === 'dark' ? '#404040' : theme.palette.action.hover
        : 'transparent',
    color: state.isSelected
      ? '#ffffff'
      : theme.palette.mode === 'dark' ? '#ffffff' : theme.palette.text.primary,
    '&:hover': {
      backgroundColor: theme.palette.mode === 'dark' ? '#404040' : theme.palette.action.hover
    },
    cursor: 'pointer',
    padding: '8px 12px'
  }),
  input: (base) => ({
    ...base,
    color: theme.palette.mode === 'dark' ? '#ffffff' : theme.palette.text.primary
  }),
  placeholder: (base) => ({
    ...base,
    color: theme.palette.mode === 'dark' ? '#808080' : theme.palette.text.secondary
  }),
  singleValue: (base) => ({
    ...base,
    color: theme.palette.mode === 'dark' ? '#ffffff' : theme.palette.text.primary
  }),
  multiValue: (base) => ({
    ...base,
    backgroundColor: theme.palette.mode === 'dark' ? '#404040' : theme.palette.grey[200]
  }),
  multiValueLabel: (base) => ({
    ...base,
    color: theme.palette.mode === 'dark' ? '#ffffff' : theme.palette.text.primary
  }),
  multiValueRemove: (base) => ({
    ...base,
    color: theme.palette.mode === 'dark' ? '#808080' : theme.palette.grey[600],
    '&:hover': {
      backgroundColor: theme.palette.mode === 'dark' ? '#505050' : theme.palette.grey[300],
      color: theme.palette.mode === 'dark' ? '#ffffff' : theme.palette.error.main
    }
  })
});

const ClientCreatePod = () => {
  const intl = useIntl();
  const token = useAuthToken();
  const history = useHistory();
  const { user } = useSelector(state => state.auth);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const [podData, setPodData] = useState({
    podName: '',
    pod_rate: '',
    ART_id: '',
    ART_Name: '',
    skills: [],
    podDescription: '',
    podsize: '',
    podtype: '',
    techStack: '',
    primary_language: '',
    primary_timezone: '',
    clientID: user?.data[0]?.emp_id || '',
    candidatesid: [],
    Client_Code: user?.data[0]?.client_code || ''
  });

  const [artOptions, setARTOptions] = useState([]);

  useEffect(() => {
    fetchARTOptions();
  }, [token]);

  const fetchARTOptions = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_CLIENT_FETCHGIGSLIST,
        { Organization_Code: user.data[0].client_code },
        {
          headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.data?.data) {
        setARTOptions(Array.isArray(response.data.data) ? response.data.data : []);
      }
    } catch (error) {
      console.error('Error fetching ART options:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(
        process.env.REACT_APP_CLIENT_CLIENTCREATEPOD,
        podData,
        {
          headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.data.status === 200) {
        alert("Pod created successfully!");
        history.push('/client-dashboard');
      } else {
        alert(response.data.data || 'Failed to create pod');
      }
    } catch (error) {
      console.error('Error creating pod:', error);
      alert('An error occurred while creating the pod');
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    setPodData(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const handleSkillsChange = (selectedOptions) => {
    setPodData(prev => ({
      ...prev,
      skills: selectedOptions?.map(option => ({ skill: option.label })) || []
    }));
  };
  const TIMEZONES = [
    { value: 'africa_abidjan', label: 'Africa/Abidjan' },
    { value: 'africa_accra', label: 'Africa/Accra' },
    { value: 'africa_addis_ababa', label: 'Africa/Addis Ababa' },
    { value: 'africa_algiers', label: 'Africa/Algiers' },
    { value: 'africa_asmara', label: 'Africa/Asmara' },
    { value: 'africa_bamako', label: 'Africa/Bamako' },
    { value: 'africa_bangui', label: 'Africa/Bangui' },
    { value: 'africa_banjul', label: 'Africa/Banjul' },
    { value: 'africa_bissau', label: 'Africa/Bissau' },
    { value: 'africa_blantyre', label: 'Africa/Blantyre' },
    { value: 'africa_brazzaville', label: 'Africa/Brazzaville' },
    { value: 'africa_bujumbura', label: 'Africa/Bujumbura' },
    { value: 'africa_casablanca', label: 'Africa/Casablanca' },
    { value: 'africa_ceuta', label: 'Africa/Ceuta' },
    { value: 'africa_conakry', label: 'Africa/Conakry' },
    { value: 'africa_dakar', label: 'Africa/Dakar' },
    { value: 'africa_dar_es_salaam', label: 'Africa/Dar es Salaam' },
    { value: 'africa_djibouti', label: 'Africa/Djibouti' },
    { value: 'africa_douala', label: 'Africa/Douala' },
    { value: 'africa_el_aaiun', label: 'Africa/El Aaiun' },
    { value: 'africa_freetown', label: 'Africa/Freetown' },
    { value: 'africa_gaborone', label: 'Africa/Gaborone' },
    { value: 'africa_harare', label: 'Africa/Harare' },
    { value: 'africa_johannesburg', label: 'Africa/Johannesburg' },
    { value: 'africa_juba', label: 'Africa/Juba' },
    { value: 'africa_kampala', label: 'Africa/Kampala' },
    { value: 'africa_khartoum', label: 'Africa/Khartoum' },
    { value: 'africa_kigali', label: 'Africa/Kigali' },
    { value: 'africa_kinshasa', label: 'Africa/Kinshasa' },
    { value: 'africa_lagos', label: 'Africa/Lagos' },
    { value: 'africa_libreville', label: 'Africa/Libreville' },
    { value: 'africa_lome', label: 'Africa/Lome' },
    { value: 'africa_luanda', label: 'Africa/Luanda' },
    { value: 'africa_lubumbashi', label: 'Africa/Lubumbashi' },
    { value: 'africa_lusaka', label: 'Africa/Lusaka' },
    { value: 'africa_malabo', label: 'Africa/Malabo' },
    { value: 'africa_maputo', label: 'Africa/Maputo' },
    { value: 'africa_maseru', label: 'Africa/Maseru' },
    { value: 'africa_mbabane', label: 'Africa/Mbabane' },
    { value: 'africa_mogadishu', label: 'Africa/Mogadishu' },
    { value: 'africa_monrovia', label: 'Africa/Monrovia' },
    { value: 'africa_nairobi', label: 'Africa/Nairobi' },
    { value: 'africa_ndjamena', label: 'Africa/Ndjamena' },
    { value: 'africa_niamey', label: 'Africa/Niamey' },
    { value: 'africa_nouakchott', label: 'Africa/Nouakchott' },
    { value: 'africa_ouagadougou', label: 'Africa/Ouagadougou' },
    { value: 'africa_porto-novo', label: 'Africa/Porto-Novo' },
    { value: 'africa_sao_tome', label: 'Africa/Sao Tome' },
    { value: 'africa_tripoli', label: 'Africa/Tripoli' },
    { value: 'africa_tunis', label: 'Africa/Tunis' },
    { value: 'africa_windhoek', label: 'Africa/Windhoek' },
    { value: 'america_adak', label: 'America/Adak' },
    { value: 'america_anchorage', label: 'America/Anchorage' },
    { value: 'america_anguilla', label: 'America/Anguilla' },
    { value: 'america_antigua', label: 'America/Antigua' },
    { value: 'america_araguaina', label: 'America/Araguaina' },
    { value: 'america_argentina_buenos_aires', label: 'America/Argentina/Buenos Aires' },
    { value: 'america_argentina_catamarca', label: 'America/Argentina/Catamarca' },
    { value: 'america_argentina_comodrivadavia', label: 'America/Argentina/Comodrivadavia' },
    { value: 'america_argentina_cordoba', label: 'America/Argentina/Cordoba' },
    { value: 'america_argentina_jujuy', label: 'America/Argentina/Jujuy' },
    { value: 'america_argentina_la_rioja', label: 'America/Argentina/La Rioja' },
    { value: 'america_argentina_mendoza', label: 'America/Argentina/Mendoza' },
    { value: 'america_argentina_rio_gallegos', label: 'America/Argentina/Rio Gallegos' },
    { value: 'america_argentina_salta', label: 'America/Argentina/Salta' },
    { value: 'america_argentina_san_juan', label: 'America/Argentina/San Juan' },
    { value: 'america_argentina_san_luis', label: 'America/Argentina/San Luis' },
    { value: 'america_argentina_tucuman', label: 'America/Argentina/Tucuman' },
    { value: 'america_argentina_ushuaia', label: 'America/Argentina/Ushuaia' },
    { value: 'america_aruba', label: 'America/Aruba' },
    { value: 'america_asuncion', label: 'America/Asuncion' },
    { value: 'america_atikokan', label: 'America/Atikokan' },
    { value: 'america_bahia', label: 'America/Bahia' },
    { value: 'america_bahia_banderas', label: 'America/Bahia Banderas' },
    { value: 'america_barbados', label: 'America/Barbados' },
    { value: 'america_belem', label: 'America/Belem' },
    { value: 'america_belize', label: 'America/Belize' },
    { value: 'america_blanc-sablon', label: 'America/Blanc-Sablon' },
    { value: 'america_boa_vista', label: 'America/Boa Vista' },
    { value: 'america_bogota', label: 'America/Bogota' },
    { value: 'america_boise', label: 'America/Boise' },
    { value: 'america_cambridge_bay', label: 'America/Cambridge Bay' },
    { value: 'america_campo_grande', label: 'America/Campo Grande' },
    { value: 'america_cancun', label: 'America/Cancun' },
    { value: 'america_caracas', label: 'America/Caracas' },
    { value: 'america_cayenne', label: 'America/Cayenne' },
    { value: 'america_cayman', label: 'America/Cayman' },
    { value: 'america_chicago', label: 'America/Chicago' },
    { value: 'america_chihuahua', label: 'America/Chihuahua' },
    { value: 'america_coral_harbour', label: 'America/Coral Harbour' },
    { value: 'america_costa_rica', label: 'America/Costa Rica' },
    { value: 'america_creston', label: 'America/Creston' },
    { value: 'america_cuiaba', label: 'America/Cuiaba' },
    { value: 'america_curacao', label: 'America/Curacao' },
    { value: 'america_danmarkshavn', label: 'America/Danmarkshavn' },
    { value: 'america_dawson', label: 'America/Dawson' },
    { value: 'america_dawson_creek', label: 'America/Dawson Creek' },
    { value: 'america_denver', label: 'America/Denver' },
    { value: 'america_detroit', label: 'America/Detroit' },
    { value: 'america_dominica', label: 'America/Dominica' },
    { value: 'america_edmonton', label: 'America/Edmonton' },
    { value: 'america_eirunepe', label: 'America/Eirunepe' },
    { value: 'america_el_salvador', label: 'America/El Salvador' },
    { value: 'america_fort_nelson', label: 'America/Fort Nelson' },
    { value: 'america_fortaleza', label: 'America/Fortaleza' },
    { value: 'america_glace_bay', label: 'America/Glace Bay' },
    { value: 'america_godthab', label: 'America/Godthab' },
    { value: 'america_goose_bay', label: 'America/Goose Bay' },
    { value: 'america_grand_turk', label: 'America/Grand Turk' },
    { value: 'america_grenada', label: 'America/Grenada' },
    { value: 'america_guadeloupe', label: 'America/Guadeloupe' },
    { value: 'america_guatemala', label: 'America/Guatemala' },
    { value: 'america_guayaquil', label: 'America/Guayaquil' },
    { value: 'america_guyana', label: 'America/Guyana' },
    { value: 'america_halifax', label: 'America/Halifax' },
    { value: 'america_havana', label: 'America/Havana' },
    { value: 'america_hermosillo', label: 'America/Hermosillo' },
    { value: 'america_indiana_indianapolis', label: 'America/Indiana/Indianapolis' },
    { value: 'america_indiana_knox', label: 'America/Indiana/Knox' },
    { value: 'america_indiana_marengo', label: 'America/Indiana/Marengo' },
    { value: 'america_indiana_petersburg', label: 'America/Indiana/Petersburg' },
    { value: 'america_indiana_tell_city', label: 'America/Indiana/Tell City' },
    { value: 'america_indiana_vevay', label: 'America/Indiana/Vevay' },
    { value: 'america_indiana_vincennes', label: 'America/Indiana/Vincennes' },
    { value: 'america_indiana_winamac', label: 'America/Indiana/Winamac' },
    { value: 'america_inuvik', label: 'America/Inuvik' },
    { value: 'america_iqaluit', label: 'America/Iqaluit' },
    { value: 'america_jamaica', label: 'America/Jamaica' },
    { value: 'america_juneau', label: 'America/Juneau' },
    { value: 'america_kentucky_louisville', label: 'America/Kentucky/Louisville' },
    { value: 'america_kentucky_monticello', label: 'America/Kentucky/Monticello' },
    { value: 'america_kralendijk', label: 'America/Kralendijk' },
    { value: 'america_la_paz', label: 'America/La Paz' },
    { value: 'america_lima', label: 'America/Lima' },
    { value: 'america_los_angeles', label: 'America/Los Angeles' },
  
];

const languages = [
  { value: 'aa', label: 'Afar' },
  { value: 'ab', label: 'Abkhazian' },
  { value: 'ae', label: 'Avestan' },
  { value: 'af', label: 'Afrikaans' },
  { value: 'ak', label: 'Akan' },
  { value: 'am', label: 'Amharic' },
  { value: 'an', label: 'Aragonese' },
  { value: 'ar', label: 'Arabic' },
  { value: 'as', label: 'Assamese' },
  { value: 'av', label: 'Avaric' },
  { value: 'ay', label: 'Aymara' },
  { value: 'az', label: 'Azerbaijani' },
  { value: 'ba', label: 'Bashkir' },
  { value: 'be', label: 'Belarusian' },
  { value: 'bg', label: 'Bulgarian' },
  { value: 'bh', label: 'Bihari languages' },
  { value: 'bi', label: 'Bislama' },
  { value: 'bm', label: 'Bambara' },
  { value: 'bn', label: 'Bengali' },
  { value: 'bo', label: 'Tibetan' },
  { value: 'br', label: 'Breton' },
  { value: 'bs', label: 'Bosnian' },
  { value: 'ca', label: 'Catalan' },
  { value: 'ce', label: 'Chechen' },
  { value: 'ch', label: 'Chamorro' },
  { value: 'co', label: 'Corsican' },
  { value: 'cr', label: 'Cree' },
  { value: 'cs', label: 'Czech' },
  { value: 'cu', label: 'Church Slavic' },
  { value: 'cv', label: 'Chuvash' },
  { value: 'cy', label: 'Welsh' },
  { value: 'da', label: 'Danish' },
  { value: 'de', label: 'German' },
  { value: 'dv', label: 'Divehi' },
  { value: 'dz', label: 'Dzongkha' },
  { value: 'ee', label: 'Ewe' },
  { value: 'el', label: 'Greek' },
  { value: 'en', label: 'English' },
  { value: 'eo', label: 'Esperanto' },
  { value: 'es', label: 'Spanish' },
  { value: 'et', label: 'Estonian' },
  { value: 'eu', label: 'Basque' },
  { value: 'fa', label: 'Persian' },
  { value: 'ff', label: 'Fulah' },
  { value: 'fi', label: 'Finnish' },
  { value: 'fj', label: 'Fijian' },
  { value: 'fo', label: 'Faroese' },
  { value: 'fr', label: 'French' },
  { value: 'fy', label: 'Western Frisian' },
  { value: 'ga', label: 'Irish' },
  { value: 'gd', label: 'Gaelic' },
  { value: 'gl', label: 'Galician' },
  { value: 'gn', label: 'Guarani' },
  { value: 'gu', label: 'Gujarati' },
  { value: 'gv', label: 'Manx' },
  { value: 'ha', label: 'Hausa' },
  { value: 'he', label: 'Hebrew' },
  { value: 'hi', label: 'Hindi' },
  { value: 'ho', label: 'Hiri Motu' },
  { value: 'hr', label: 'Croatian' },
  { value: 'ht', label: 'Haitian' },
  { value: 'hu', label: 'Hungarian' },
  { value: 'hy', label: 'Armenian' },
  { value: 'hz', label: 'Herero' },
  { value: 'ia', label: 'Interlingua' },
  { value: 'id', label: 'Indonesian' },
  { value: 'ie', label: 'Interlingue' },
  { value: 'ig', label: 'Igbo' },
  { value: 'ii', label: 'Sichuan Yi' },
  { value: 'ik', label: 'Inupiaq' },
  { value: 'io', label: 'Ido' },
  { value: 'is', label: 'Icelandic' },
  { value: 'it', label: 'Italian' },
  { value: 'iu', label: 'Inuktitut' },
  { value: 'ja', label: 'Japanese' },
  { value: 'jv', label: 'Javanese' },
  { value: 'ka', label: 'Georgian' },
  { value: 'kg', label: 'Kongo' },
  { value: 'ki', label: 'Kikuyu' },
  { value: 'kj', label: 'Kuanyama' },
  { value: 'kk', label: 'Kazakh' },
  { value: 'kl', label: 'Kalaallisut' },
  { value: 'km', label: 'Central Khmer' },
  { value: 'kn', label: 'Kannada' },
  { value: 'ko', label: 'Korean' },
  { value: 'kr', label: 'Kanuri' },
  { value: 'ks', label: 'Kashmiri' },
  { value: 'ku', label: 'Kurdish' },
  { value: 'kv', label: 'Komi' },
  { value: 'kw', label: 'Cornish' },
  { value: 'ky', label: 'Kirghiz' },
  { value: 'la', label: 'Latin' },
  { value: 'lb', label: 'Luxembourgish' },
  { value: 'lg', label: 'Ganda' },
  { value: 'li', label: 'Limburgan' },
  { value: 'ln', label: 'Lingala' },
  { value: 'lo', label: 'Lao' },
  { value: 'lt', label: 'Lithuanian' },
  { value: 'lu', label: 'Luba-Katanga' },
  { value: 'lv', label: 'Latvian' },
  { value: 'mg', label: 'Malagasy' },
  { value: 'mh', label: 'Marshallese' },
  { value: 'mi', label: 'Maori' },
  { value: 'mk', label: 'Macedonian' },
  { value: 'ml', label: 'Malayalam' },
  { value: 'mn', label: 'Mongolian' },
  { value: 'mr', label: 'Marathi' },
  { value: 'ms', label: 'Malay' },
  { value: 'mt', label: 'Maltese' },
  { value: 'my', label: 'Burmese' },
  { value: 'na', label: 'Nauru' },
  { value: 'nb', label: 'Norwegian Bokmål' },
  { value: 'nd', label: 'North Ndebele' },
  { value: 'ne', label: 'Nepali' },
  { value: 'ng', label: 'Ndonga' },
  { value: 'nl', label: 'Dutch' },
  { value: 'nn', label: 'Norwegian Nynorsk' },
  { value: 'no', label: 'Norwegian' },
  { value: 'nr', label: 'South Ndebele' },
  { value: 'nv', label: 'Navajo' },
  { value: 'ny', label: 'Nyanja' },
  { value: 'oc', label: 'Occitan' },
  { value: 'oj', label: 'Ojibwa' },
  { value: 'om', label: 'Oromo' },
  { value: 'or', label: 'Oriya' },
  { value: 'os', label: 'Ossetian' },
  { value: 'pa', label: 'Panjabi' },
  { value: 'pi', label: 'Pali' },
  { value: 'pl', label: 'Polish' },
  { value: 'ps', label: 'Pushto' },
  { value: 'pt', label: 'Portuguese' },
  { value: 'qu', label: 'Quechua' },
  { value: 'rm', label: 'Romansh' },
  { value: 'rn', label: 'Rundi' },
  { value: 'ro', label: 'Romanian' },
  { value: 'ru', label: 'Russian' },
  { value: 'rw', label: 'Kinyarwanda' },
  { value: 'sa', label: 'Sanskrit' },
  { value: 'sc', label: 'Sardinian' },
  { value: 'sd', label: 'Sindhi' },
  { value: 'se', label: 'Northern Sami' },
  { value: 'sg', label: 'Sango' },
  { value: 'si', label: 'Sinhala' },
  { value: 'sk', label: 'Slovak' },
  { value: 'sl', label: 'Slovenian' },
  { value: 'sm', label: 'Samoan' },
  { value: 'sn', label: 'Shona' },
  { value: 'so', label: 'Somali' },
  { value: 'sq', label: 'Albanian' },
  { value: 'sr', label: 'Serbian' },
  { value: 'ss', label: 'Swati' },
  { value: 'st', label: 'Southern Sotho' },
  { value: 'su', label: 'Sundanese' },
  { value: 'sv', label: 'Swedish' },
  { value: 'sw', label: 'Swahili' },
  { value: 'ta', label: 'Tamil' },
  { value: 'te', label: 'Telugu' },
  { value: 'tg', label: 'Tajik' },
  { value: 'th', label: 'Thai' },
  { value: 'ti', label: 'Tigrinya' },
  { value: 'tk', label: 'Turkmen' },
  { value: 'tl', label: 'Tagalog' },
  { value: 'tn', label: 'Tswana' },
  { value: 'to', label: 'Tonga' },
  { value: 'tr', label: 'Turkish' },
  { value: 'ts', label: 'Tsonga' },
  { value: 'tt', label: 'Tatar' },
  { value: 'tw', label: 'Twi' },
  { value: 'ty', label: 'Tahitian' },
  { value: 'ug', label: 'Uighur' },
  { value: 'uk', label: 'Ukrainian' },
  { value: 'ur', label: 'Urdu' },
  { value: 'uz', label: 'Uzbek' },
  { value: 've', label: 'Venda' },
  { value: 'vi', label: 'Vietnamese' },
  { value: 'vo', label: 'Volapük' },
  { value: 'wa', label: 'Walloon' },
  { value: 'wo', label: 'Wolof' },
  { value: 'xh', label: 'Xhosa' },
  { value: 'yi', label: 'Yiddish' },
  { value: 'yo', label: 'Yoruba' },
  { value: 'za', label: 'Zhuang' },
  { value: 'zh', label: 'Chinese' },
  { value: 'zu', label: 'Zulu' }
];
  return (
    <RootContainer>
      <Container maxWidth="lg">
        <StyledPaper elevation={3}>
          <PageTitle variant="h1">
            {intl.formatMessage({ id: 'createPod' })}
          </PageTitle>

          <FormContainer onSubmit={handleSubmit}>
            <StyledTextField
              fullWidth
              name="podName"
              label={intl.formatMessage({ id: 'podName' })}
              value={podData.podName}
              onChange={handleInputChange}
              required
              variant="outlined"
            />

            <StyledTextField
              select
              fullWidth
              name="ART_id"
              label={intl.formatMessage({ id: 'selectArtName' })}
              value={podData.ART_id}
              onChange={handleInputChange}
              required
            >
              {artOptions.map((option) => (
                <MenuItem key={option.ART_id} value={option.ART_id.toString()}>
                  {option.ART_Name}
                </MenuItem>
              ))}
            </StyledTextField>

            <StyledTextField
              select
              fullWidth
              name="podsize"
              label={intl.formatMessage({ id: 'podSize' })}
              value={podData.podsize}
              onChange={handleInputChange}
              required
            >
              {[3, 5, 7, 9].map((size) => (
                <MenuItem key={size} value={size.toString()}>
                  {size}
                </MenuItem>
              ))}
            </StyledTextField>

            <StyledTextField
              select
              fullWidth
              name="podtype"
              label={intl.formatMessage({ id: 'podType' })}
              value={podData.podtype}
              onChange={handleInputChange}
              required
            >
              {TIMEZONES.map((type) => (
                <MenuItem key={type.value} value={type.value}>
                  {type.label}
                </MenuItem>
              ))}
            </StyledTextField>

            <StyledTextField
              select
              fullWidth
              name="techStack"
              label={intl.formatMessage({ id: 'techStack' })}
              value={podData.techStack}
              onChange={handleInputChange}
              required
            >
              {['MEAN', 'MERN', 'LAMP', 'MEVN'].map((stack) => (
                <MenuItem key={stack} value={stack}>
                  {stack}
                </MenuItem>
              ))}
            </StyledTextField>

            <StyledTextField
              select
              fullWidth
              name="primary_language"
              label="Primary Language"
              value={podData.primary_language}
              onChange={handleInputChange}
            >
             {languages.map((language) => (
                <MenuItem key={language.value} value={language.value}>
                  {language.label}
                </MenuItem>
              ))}
            </StyledTextField>

            <StyledTextField
              select
              fullWidth
              name="primary_timezone"
              label="Time Zone"
              value={podData.primary_timezone}
              onChange={handleInputChange}
            >
              {TIMEZONES.map((timezone) => (
                <MenuItem key={timezone.value} value={timezone.value}>
                  {timezone.label}
                </MenuItem>
              ))}
            </StyledTextField>

            {user.data[0].manager_type === "PartnerClient" && (
              <StyledTextField
                fullWidth
                type="number"
                name="pod_rate"
                label={intl.formatMessage({ id: 'podRate' })}
                value={podData.pod_rate}
                onChange={handleInputChange}
                required
              />
            )}

            <Select
              isMulti
              options={allergens}
              value={podData.skills.map(skill => ({ 
                label: skill.skill, 
                value: skill.skill 
              }))}
              onChange={handleSkillsChange}
              placeholder="Select Skills"
              styles={selectStyles(theme)}
            />

            {podData.candidatesid.length > 0 && (
              <Stack direction="row" flexWrap="wrap" gap={1}>
                {podData.candidatesid.map((candidate, index) => (
                  <CandidateChip
                    key={index}
                    label={candidate.name}
                    onDelete={() => {
                      const newCandidates = [...podData.candidatesid];
                      newCandidates.splice(index, 1);
                      setPodData(prev => ({ 
                        ...prev, 
                        candidatesid: newCandidates 
                      }));
                    }}
                  />
                ))}
              </Stack>
            )}

            <SubmitButton
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              disabled={loading}
              fullWidth
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                intl.formatMessage({ id: 'create' })
              )}
            </SubmitButton>
          </FormContainer>
        </StyledPaper>
      </Container>
    </RootContainer>
  );
};

export default ClientCreatePod;