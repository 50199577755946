import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { useAuthToken } from '../TokenContext';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

const ChartContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '20px',
  height: '30rem',
  width: '100%',
  padding: '10px',
  backgroundColor: theme?.palette?.mode === 'dark' 
    ? theme.palette.background.default 
    : theme.palette.background.paper
}));

const ChartWrapper = styled(Box)(({ theme }) => ({
  height: '25rem',
  width: '100%',
  backgroundColor: theme?.palette?.mode === 'dark' ? '#1a237e' : 'blue',
  color: 'white',
  borderRadius: '8px',
  boxShadow: theme?.palette?.mode === 'dark'
    ? '0 4px 8px rgba(255, 255, 255, 0.1)'
    : '0 4px 8px rgba(0, 0, 0, 0.1)',
  padding: '20px',
  boxSizing: 'border-box'
}));

const ChartHeading = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  color: theme?.palette?.mode === 'dark' ? theme.palette.text.primary : 'black',
  marginBottom: '1rem'
}));

const SprintVelocitiesChart = ({ initialPodIds }) => {
  const [sprintVelocities, setSprintVelocities] = useState([]);
  const [data, setData] = useState([]);
  const { user } = useSelector((state) => state.auth);
  const token = useAuthToken();
  const intl = useIntl();

  useEffect(() => {
    const fetchData = async () => {
      const headers = {
        Authorization: token,
        'Content-Type': 'application/json',
      };

      if (!token) {
        console.log('Token is missing. API requests will not be made.');
        return;
      }

      let response;
      const payload = {};

      if (user.data[0].type === "stakeholder") {
        payload.pod_id = initialPodIds || [];
        payload.org_code = user.data[0].client_code;
        response = await axios.post(
          process.env.REACT_APP_STAKEHOLDER_SPRINTVELMETRICSSTAKEHOLDER,
          payload,
          { headers: headers }
        );
      } else {
        payload.pod_id = initialPodIds || null;
        payload.manager_id = user.data[0].emp_id;
        payload.org_code = user.data[0].client_code;
        response = await axios.post(
          process.env.REACT_APP_CLIENT_SPRINTVELOCITY,
          payload,
          { headers: headers }
        );
      }

      const responseData = response.data.body;
      const transformedData = responseData.replace(/NaN/g, 'null');
      const data = JSON.parse(transformedData);

      const velocities = data.map((item) => {
        const velocity = parseFloat(item.sprint_velocities);
        return isNaN(velocity) ? null : velocity;
      });
      setSprintVelocities(velocities);
      setData(data);
    };

    fetchData();
  }, [token, user.data[0].type, initialPodIds]);

  const chartData = {
    labels: data.map((item) => item.sprint_name),
    datasets: [
      {
        label: intl.formatMessage({ id: 'sprintVelocity' }),
        data: sprintVelocities,
        fill: 'white',
        borderColor: 'white',
        backgroundColor: 'white',
        tension: 0.4,
        pointRadius: 5,
        pointHoverRadius: 8,
      },
    ],
  };

  const chartOptions = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: intl.formatMessage({ id: 'sprint' }),
          color: 'white',
        },
        ticks: {
          color: 'white',
        },
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        display: true,
        title: {
          display: true,
          text: intl.formatMessage({ id: 'velocity' }),
          color: 'white',
        },
        ticks: {
          color: 'white',
        },
        grid: {
          color: 'white',
        },
      },
    },
  };

  return (
    <ChartContainer>
      <ChartHeading variant="h6">
        {intl.formatMessage({ id: 'sprintVelocity' })}
      </ChartHeading>
      <ChartWrapper>
        {sprintVelocities.length > 0 ? (
          <Line data={chartData} options={chartOptions} />
        ) : (
          <Typography variant="body1" color="white">
            {intl.formatMessage({ id: 'noDataAvailable' })}...
          </Typography>
        )}
      </ChartWrapper>
    </ChartContainer>
  );
};

SprintVelocitiesChart.propTypes = {
  initialPodIds: PropTypes.array.isRequired,
};

export default SprintVelocitiesChart;