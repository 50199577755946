// ThemeToggle.js
import React from 'react';
import { IconButton } from '@mui/material';
import Brightness4Icon from '@mui/icons-material/Brightness4';  // Moon icon
import Brightness7Icon from '@mui/icons-material/Brightness7';  // Sun icon
import { useTheme } from './ThemeContext';

const ThemeToggle = () => {
  const { darkMode, toggleDarkMode } = useTheme();

  return (
    <IconButton
      onClick={toggleDarkMode}
      sx={{
        width: '2.5rem',
        height: '2.5rem',
        borderRadius: '9999px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: darkMode ? '#fff' : '#000',
        '&:hover': {
          backgroundColor: darkMode 
            ? 'rgba(255, 255, 255, 0.1)' // Subtle light hover in dark mode
            : 'rgba(0, 0, 0, 0.1)',      // Subtle dark hover in light mode
        }
      }}
      aria-label="Toggle dark mode"
    >
      {darkMode ? (
        <Brightness7Icon sx={{ color: 'inherit' }} />
      ) : (
        <Brightness4Icon sx={{ color: 'inherit' }} />
      )}
    </IconButton>
  );
};

export default ThemeToggle;