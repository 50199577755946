import React, {useEffect, useState} from 'react'
import Badge from './Badge';
import Avatar from "./assets/MaskGroup.png";
import {cardShadow,hoverEffect,themeColor} from '../utils';
import AWS from 'aws-sdk';
import Papa from 'papaparse';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {IoIosNotificationsOutline} from 'react-icons/io'
import { useDispatch, useSelector } from "react-redux";

import moment from 'moment';

import { useAuthToken } from "../TokenContext";
import { useIntl } from 'react-intl';
import { Assignment, HourglassEmpty, CheckCircle, Cancel } from '@mui/icons-material'; // Import relevant icons
import { styled } from '@mui/material/styles';
import { Box, Typography, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// Convert styled components to MUI styled components
const InfoCard = styled(Box)(({ theme }) => ({
 
}));

const RecommendProject = styled(Paper)(({ theme }) => ({
  padding: '20px',

}));

const TitleText1 = styled(Typography)(({ theme }) => ({
  fontFamily: 'Poppins, sans-serif',
  fontSize: '20px',
  color: theme.palette.mode === 'dark' ? theme.palette.text.primary : '#000',
  fontWeight: 600,
  marginBottom: '20px'
}));

const CardContent1 = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '20px',
  position: 'relative',
  padding: '20px',
  paddingLeft: '80px',
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : '#fff',
  
  '&:before': {
    content: '""',
    position: 'absolute',
    left: '50px',
    top: '50%',
    bottom: 0,
    width: '2px',
    background: theme.palette.mode === 'dark' ? theme.palette.divider : 'grey',
    zIndex: -1
  },
  
  '&:last-child:before': {
    display: 'none'
  }
}));

const ProjectInfo1 = styled(Typography)(({ theme }) => ({
  flex: 1,
  fontFamily: 'Poppins, sans-serif',
  fontSize: '16px',
  color: theme.palette.mode === 'dark' ? theme.palette.text.primary : '#333',
  fontWeight: 600,
  marginBottom: '10px'
}));

const PriceContainer1 = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%'
});

const Price1 = styled(Typography)(({ theme }) => ({
  fontFamily: 'Poppins, sans-serif',
  fontSize: '16px',
  color: theme.palette.mode === 'dark' ? theme.palette.text.primary : '#333',
  fontWeight: 600
}));

const Line = styled(Box)(({ theme }) => ({
  flex: 1,
  height: '0.3px',
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.divider : 'grey',
  margin: '0 10px',
  alignSelf: 'center'
}));

const IconContainer = styled(Box)(({ theme, bgColor }) => ({
  position: 'absolute',
  left: '20px',
  width: '50px',
  height: '50px',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: bgColor,
  color: theme.palette.common.white,
  fontSize: '24px',
  fontWeight: 'bold'
}));
function InfoDummy() {
const[details,setDetails]=useState([])
const { isLoggedIn, user } = useSelector(state => state.auth);
const [tasks, setTasks] = useState([]);
const [authorizationDetails, setAuthorizationDetails] = useState({});
const theme=useTheme();
const [errorState, setErrorState] = useState(false);
const intl = useIntl();

const token = useAuthToken();

const notificationdetails = async () => {
  if (!token) {
    return; // Exit early if there is no token
  }
  const headers = {
    'Authorization': token, // Ensure token is in the correct format
    'Content-Type': 'application/json',
  };
  try {
    const response = await axios.post(
      process.env.REACT_APP_NOTIFICATIONLIMIT,
      { user_id: user.data[0].c_id },
      { headers: headers }
    );
    
    setDetails(response.data.data);
  } catch (error) {
    console.error('Error:', error); // Debugging statement
  }
};

useEffect(() => {
  notificationdetails();
}, [token]);


  useEffect(() => {
    const fetchData = async () => {
      // Fetch authorization tokens
      if (!token) {
        return; // Exit early if there is no token
      }
      const authorizationPayload = {
        Organizational_Code: user.data[0].talent_code,
      };
  
      const headers = {
        'Authorization': token,
        'Content-Type': 'application/json',
      };
  
      try {
        const authorizationResponse = await axios.post(
          process.env.REACT_APP_CUSTOMERADMIN_FETCHAUTHORIZATIONTOKENS,
          authorizationPayload, { headers: headers }
        );
  
        const authorizationData = authorizationResponse.data.body.data[0];
  
        // Store authorization details in state
        setAuthorizationDetails(authorizationData);
  
        // Extract the necessary tokens and URLs from the authorization response
        const jiraUrl = authorizationData.Jira_URL;
        const jiraAdminEmail = authorizationData.Jira_admin_login_email_address;
        const jiraAdminToken = authorizationData.Jira_admin_API_Token;
        const githubUsername = authorizationData.Github_username;
        const githubApiToken = authorizationData.Github_API_Token;
        const projectKey = authorizationData.Jira_Kanben_Project_name;
  
        // Construct the dynamic payload
        const dynamicPayload = {
          "candidate_id": user.data[0].c_id,
          "username": jiraAdminEmail,
          "password": jiraAdminToken,
          "projectKey": projectKey,
          "jira_url": jiraUrl,
        };
  
        const response = await axios.post(
          process.env.REACT_APP_CLIENT_TALENTDASHBOARDPODTASKSJIRAISSUES,
          dynamicPayload,
          { headers: headers }
        );
  
        if (response.status === 200) {
          // Update state with the response data
          setTasks(response.data.data.mergedData);
        } else {
          console.error('Error fetching data. Unexpected status:', response.status);
          // Handle the error, for example, by setting an error state
          setErrorState(true);
        }
      } catch (error) {
        // Handle different types of errors, log to console, and set error state
        console.error('Error fetching data:', error);

        // Check if it's a 400 Bad Request response from the Jira API
        if (error.response && error.response.status === 400) {
          console.error('Bad Request. Check your request payload or Jira configuration.');
          // Handle 400 Bad Request, for example, by setting an error state with a specific message
          setErrorState(true);
          // setErrorMessage('Bad Request. Check your request payload or Jira configuration.');
        } else {
          // Handle other types of errors
          setErrorState(true);
          // setErrorMessage('An unexpected error occurred. Please try again later.');
        }
      }
    };

  
  
    fetchData();
  }, [token, user.data]);
  
  const colors = ['#f94144', '#f3722c', '#f8961e', '#f9844a', '#f9c74f', '#90be6d', '#43aa8b', '#577590'];

  const iconMap = {
    'To Do': <Assignment />,
    'In Progress': <HourglassEmpty />,
    'Done': <CheckCircle />,
    'Canceled': <Cancel />,
    default: <Cancel /> // Default icon if status doesn't match any predefined statuses
  };
 
  return (
    <InfoCard>
      <RecommendProject>
        <TitleText1>
          {intl.formatMessage({ id: 'assignedItems' })}
        </TitleText1>
        {tasks && tasks.length === 0 ? (
          <Typography color={theme.palette.text.primary}>
            {intl.formatMessage({ id: 'noDataAvailable' })}
          </Typography>
        ) : (
          tasks?.map((task, index) => (
            <CardContent1 key={task.key}>
              <IconContainer bgColor={colors[index % colors.length]}>
                {iconMap[task.status] || iconMap.default}
              </IconContainer>
              <Box flex={1}>
                <ProjectInfo1>
                  <Box component="span" sx={{ 
                    fontWeight: 600, 
                    fontSize: '16px', 
                    paddingRight: '0.3rem',
                    color: theme.palette.mode === 'dark' ? '#5ccce4' : '#17a2b8'
                  }}>
                    {intl.formatMessage({ id: 'taskName' })} :
                  </Box>
                  {task.summary}
                </ProjectInfo1>
                <PriceContainer1>
                  <Price1 sx={{ color: theme.palette.mode === 'dark' ? '#5ccce4' : '#007bff' }}>
                    {task.status}
                  </Price1>
                  <Line />
                  <Price1 sx={{ color: theme.palette.mode === 'dark' ? '#4caf50' : '#28a745' }}>
                    {task.key}
                  </Price1>
                </PriceContainer1>
              </Box>
            </CardContent1>
          ))
        )}
      </RecommendProject>
    </InfoCard>
  );
};



export default InfoDummy