import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography ,useTheme,Box,styled} from '@mui/material';
import { Assignment, BugReport, Timer,CheckBoxOutlineBlank, Speed as SpeedIcon, Code as CodeIcon } from '@mui/icons-material';
import { BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,LabelList  } from 'recharts';
import { useAuthToken } from '../TokenContext';
import { useSelector } from "react-redux";
import { FormattedMessage, useIntl } from 'react-intl';


// Convert styled.div to styled(Box)
const DashboardContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '20px',
  backgroundColor: theme.palette.mode === 'dark' 
    ? '#121212'
    : '#f0f0f0',
  marginTop: '1rem',
  fontFamily: '\'Poppins\', sans-serif',
  '@media (max-width: 768px)': {
    height: 'auto'
  }
}));


const DataCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  position: 'relative',
  padding: '20px',
  backgroundColor: theme.palette.mode === 'dark' 
    ? '#1E1E1E' 
    : '#fff',
  boxShadow: theme.palette.mode === 'dark'
    ? '0 4px 8px rgba(0, 0, 0, 0.5)'
    : '0 4px 8px rgba(0, 0, 0, 0.3)',
  height: '10rem',
  fontSize: '18px',
  fontFamily: 'Poppins, Medium',
  overflow: 'auto !important',
  msOverflowStyle: 'none !important',
  scrollbarWidth: 'none !important',
  '&::-webkit-scrollbar': {
    display: 'none !important'
  }
}));

const BackgroundCard = styled(Card)(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.mode === 'dark' 
    ? '#1E1E1E' 
    : '#fff',
  boxShadow: theme.palette.mode === 'dark'
    ? '0 4px 8px rgba(0, 0, 0, 0.5)'
    : '0 4px 8px rgba(0, 0, 0, 0.3)',
  borderRadius: '8px',
  height: '300px',
  width: '100%'
}));

const ForegroundCard = styled(Card)(({ theme }) => ({
  position: 'absolute',
  top: '-30px',
  left: '50%',
  width: '90%',
  height: '80%',
  backgroundColor: props => props.backgroundColor || (theme.palette.mode === 'dark' ? '#2C3E50' : '#4caf50'),
  boxShadow: theme.palette.mode === 'dark'
    ? '0 4px 8px rgba(0, 0, 0, 0.5)'
    : '0 4px 8px rgba(0, 0, 0, 0.3)',
  borderRadius: '8px',
  zIndex: 10,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transform: 'translateX(-50%)'
}));

const CardsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '20px',
  marginBottom: '20px',
  '@media (max-width: 768px)': {
    flexDirection: 'column'
  }
}));

const CardWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  flex: 1
}));

const GraphsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '20px',
  marginTop: '5rem',
  '@media (max-width: 768px)': {
    flexDirection: 'column',
    gap: '2rem'
  }
}));

const GraphCardContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  flex: 1,
  width: '100%'
}));

const CardIcon = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '-30px',
  left: '20px',
  width: '60px',
  height: '60px',
  backgroundColor: 'black',
  borderRadius: '1rem',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '18px',
  color: 'white',
  zIndex: 10
}));

const GraphLabel = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '-30px',
  width: '100%',
  textAlign: 'center',
  color: theme.palette.mode === 'dark' ? '#E0E0E0' : '#333',
  fontWeight: 'bold',
  backgroundColor: theme.palette.mode === 'dark' ? '#1E1E1E' : '#fff',
  padding: '5px',
  borderRadius: '8px'
}));

const NameLabel = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '10px',
  fontWeight: 600,
  color: theme.palette.mode === 'dark' ? '#E0E0E0' : '#333',
  borderBottomLeftRadius: '20px',
  borderBottomRightRadius: '20px',
  gap: '10px'
}));

const StyledXAxisTick = styled(Box)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 'bold',
  fill: 'white',
  cursor: 'pointer',
  '@media (max-width: 768px)': {
    fontSize: '12px'
  }
}));

const TooltipWrapper = styled(Box)(({ theme }) => ({
  background: 'rgba(0, 0, 0, 0.75)',
  color: '#fff',
  padding: '5px',
  borderRadius: '4px',
  whiteSpace: 'nowrap'
}));

const CustomXAxisTick = (props) => {
  const { x, y, payload } = props;

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Map full names to abbreviated forms
  const nameMapping = {
    "Previous Week": "PW",
    "Current Week": "CW"
  };

  // Determine display name based on screen size
  const displayName = isMobile ? nameMapping[payload.value] || payload.value : payload.value;

  return (
    <StyledXAxisTick x={x} y={y + 10} textAnchor="middle" data-fullname={payload.value}>
      {displayName}
    </StyledXAxisTick>
  );
};

// Custom Tooltip Component
const CustomTooltip = ({ payload }) => {
  if (!payload || !payload.length) return null;

  const data = payload[0].payload;

  return (
    <TooltipWrapper>
      {data.name}
    </TooltipWrapper>
  );
};

const Dashboardnewclient = () => {
  const [storiesData, setStoriesData] = useState({ open: 0, completed: 0 });
  const [defectsData, setDefectsData] = useState({ open: 0, resolved: 0 });
  const [showTimeData, setShowTimeData] = useState({ last_week: "", this_week: "" });
  const [codeByDayData, setCodeByDayData] = useState([]);
  const [velocityData, setVelocityData] = useState([]);
  const [tasksData, setTasksData] = useState({ open: 0, completed: 0 });
  const token = useAuthToken();
  const { user } = useSelector(state => state.auth);
  const intl = useIntl();
  const theme = useTheme();
  useEffect(() => {
    if (!token) return;

    fetchStoriesAndDefectsData();
    fetchShowTimeData();
    fetchCodeByDayData();
    fetchTasksData();
    fetchVelocityData();
  }, [token]);

  const fetchStoriesAndDefectsData = async () => {
    try {
      const url = user.data[0].type === "stakeholder"
        ? process.env.REACT_APP_STAKEHOLDER_JIRASTATSFORSTAKEHOLDER 
        : process.env.REACT_APP_CLIENT_JIRASTATS;
  
      const payload = user.data[0].type === "stakeholder"
        ? {
            "pod_id": [],
            "org_code": user.data[0].client_code,
            "sprint_name": ""
          }
        : {
            "manager_id": user.data[0].emp_id,
            "sprint_name": ""
          };
  
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify(payload)
      });
      const data = await response.json();
      const { open_user_stories, completed_user_stories, open_defects, resolved_defects } = JSON.parse(data?.body);
      setStoriesData({ open: open_user_stories, completed: completed_user_stories });
      setDefectsData({ open: open_defects, resolved: resolved_defects });
    } catch (error) {
      console.error('Error fetching stories and defects data:', error);
    }
  };
  
  
  const fetchTasksData = async () => {
    try {
      const url = user.data[0].type === "stakeholder"
        ? process.env.REACT_APP_CLIENT_PODTASKSDASHBOARD_STAKEHOLDER 
        : process.env.REACT_APP_CLIENT_PODTASKSDASHBOARDMANAGER;
  
      const payload = user.data[0].type === "stakeholder"
        ? {
            "pod_id": [],
            "org_code": "CLIENT#c4a7530851b09f06caef227637a2fbcd"
          }
        : {
            "manager_id": user.data[0].emp_id,
            "pod_id": []
          };
  
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify(payload)
      });
      const data = await response.json();
      const { Open, Complete } = data?.body ||{};
      setTasksData({ open: Open, completed: Complete });
    } catch (error) {
      console.error('Error fetching tasks data:', error);
    }
  };
  
  const fetchShowTimeData = async () => {
    try {
      const url = user.data[0].type === "stakeholder"
        ? process.env.REACT_APP_CLIENT_TIMLOGDASHBOARDSTAKEHOLDER 
        : process.env.REACT_APP_CLIENT_TIMLOGDASHBOARDMANAGER;
  
      const payload = user.data[0].type === "stakeholder"
        ? {
          "org_code": user.data[0].client_code,
          "sprint_name": "",
          "sprint_start_date": "",
          "sprint_end_date": ""
        }
        : {
            "manager_id": user.data[0].emp_id,
            "pod_id": [],
            "sprint_name": "",
            "sprint_start_date": "",
            "sprint_end_date": ""
          };
  
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify(payload)
      });
      const data = await response.json();
      setShowTimeData({
        last_week: data?.body?.last_week ?? 'Data not available',
        this_week: data?.body?.this_week ?? 'Data not available'
      });
      
    } catch (error) {
      console.error('Error fetching show time data:', error);
    }
  };

  
  const fetchCodeByDayData = async () => {
    try {
      const url = user.data[0].type === "stakeholder"
        ? process.env.REACT_APP_STAKEHOLDER_DASHBOARDVIEWSTAKEHOLDER 
        : process.env.REACT_APP_CLIENT_DASHBOARDBIEWMANAGER
  
      const payload = user.data[0].type === "stakeholder"
        ? {
          "pod_id": [],
          "org_code": user.data[0].client_code,
          
      }
        : {
            "manager_id": user.data[0].emp_id,
           "pod_id": [],
          };
  
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify(payload)
      });
     
      // Update state based on the new response structure
      const result = await response.json();

      // Transform the data into a format suitable for the chart
      const data = [
        {
          name: 'Previous Week',
          stories: result.body.jira_issues.last_week_completed_stories || 0,
          defects: result.body.jira_issues.last_week_completed_defects || 0,
          pod_tasks: result.body.pod_tasks.last_week_completed_pod_tasks || 0,
        },
        {
          name: 'Current Week',
          stories: result.body.jira_issues.this_week_completed_stories || 0,
          defects: result.body.jira_issues.this_week_completed_defects || 0,
          pod_tasks: result.body.pod_tasks.this_week_completed_pod_tasks || 0,
        }
      ];
  
      setCodeByDayData(data);
    } catch (error) {
      console.error('Error fetching code by day data:', error);
      setCodeByDayData([]);
    }
  };
  
  const fetchVelocityData = async () => {
    try {
      const url = user.data[0].type === "stakeholder"
        ? process.env.REACT_APP_STAKEHOLDER_SPRINTVELMETRICSSTAKEHOLDER 
        : process.env.REACT_APP_CLIENT_SPRINTVELOCITY;
  
      const payload = user.data[0].type === "stakeholder"
        ? {
            "pod_id": [],
            "org_code": user.data[0].client_code,
           
          }
        : {
            "manager_id": user.data[0].emp_id,
            "sprint_name": "",
            "sprint_start_date": "",
            "sprint_end_date": ""
          };
  
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify(payload)
      });
      const data = await response.json();
      const parsedData = JSON.parse(data?.body);
      setVelocityData(parsedData?.map(sprint => ({
        sprint: sprint.sprint_name,
        velocity: sprint.sprint_velocities,
      })));
    } catch (error) {
      console.error('Error fetching velocity data:', error);
    }
  };
  

  return (
    <DashboardContainer>
        <CardsContainer>
              <CardWrapper>
                <CardIcon style={{ backgroundColor: "black", borderRadius: '20px' }}>
                  <Assignment />
                </CardIcon>
                <DataCard style={{ borderRadius: '20px' }}>
                  <CardContent>
                    <Typography variant="h6">
                      <FormattedMessage id="stories" defaultMessage="Stories" />
                    </Typography>
                    <Typography variant="body2">
                      {intl.formatMessage({ id: 'toDo' })}: {storiesData.open}
                    </Typography>
                    <Typography variant="body2">
                      {intl.formatMessage({ id: 'Done' })}: {storiesData.completed}
                    </Typography>
                  </CardContent>
                </DataCard>
              </CardWrapper>
      
              <CardWrapper>
                <CardIcon style={{ backgroundColor: "red", borderRadius: '20px' }}>
                  <BugReport />
                </CardIcon>
                <DataCard style={{ borderRadius: '20px' }}>
                  <CardContent style={{ borderRadius: '20px' }}>
                    <Typography variant="h6">
                      <FormattedMessage id="defects" defaultMessage="Defects" />
                    </Typography>
                    <Typography variant="body2">
                      {intl.formatMessage({ id: 'toDo' })}: {defectsData.open}
                    </Typography>
                    <Typography variant="body2">
                      {intl.formatMessage({ id: 'Done' })}: {defectsData.resolved}
                    </Typography>
                  </CardContent>
                </DataCard>
              </CardWrapper>
      
              <CardWrapper>
                <CardIcon style={{ backgroundColor: "#87CEFA", borderRadius: '20px' }}>
                  <CheckBoxOutlineBlank />
                </CardIcon>
                <DataCard style={{ borderRadius: '20px' }}>
                  <CardContent style={{ borderRadius: '20px' }}>
                    <Typography variant="h6">
                      <FormattedMessage id="podTasks" defaultMessage="Pod Tasks" />
                    </Typography>
                    <Typography variant="body2">
                      {intl.formatMessage({ id: 'toDo' })}: {tasksData.open}
                    </Typography>
                    <Typography variant="body2">
                      {intl.formatMessage({ id: 'Done' })}: {tasksData.completed}
                    </Typography>
                  </CardContent>
                </DataCard>
              </CardWrapper>
      
              <CardWrapper>
                <CardIcon style={{ backgroundColor: "#90ee90", borderRadius: '20px' }}>
                  <Timer />
                </CardIcon>
                <DataCard style={{ borderRadius: '20px' }}>
                  <CardContent style={{ borderRadius: '20px' }}>
                    <Typography variant="h6">
                      <FormattedMessage id="podTime" defaultMessage="POD Time" />
                    </Typography>
                    <Typography variant="body2">
                      {intl.formatMessage({ id: 'previousWeek' })}: {showTimeData.last_week}
                    </Typography>
                    <Typography variant="body2">
                      {intl.formatMessage({ id: 'currentWeek' })}: {showTimeData.this_week}
                    </Typography>
                  </CardContent>
                </DataCard>
              </CardWrapper>
            </CardsContainer>
      
      <GraphsContainer>
      
      <GraphCardContainer>
          <BackgroundCard style={{ borderRadius: '20px' }}>
            <NameLabel><CodeIcon style={{ color: "#3CB371" }} />POD Productivity</NameLabel>
          </BackgroundCard>
          <ForegroundCard style={{ backgroundColor: "#003F5C", borderRadius: '20px' }}>
            {codeByDayData.length === 0 ? (
              <Typography variant="body2" style={{ color: 'white', padding: '20px' }}>
                No items completed recently.
              </Typography>
            ) : (
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={codeByDayData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" tick={<CustomXAxisTick />} tickLine={false} interval={0} />
                  <YAxis tick={{ fill: 'white', fontSize: '16px', fontWeight: 'bold' }} />
                  <Tooltip contentStyle={{ backgroundColor: '#000', border: '1px solid #ccc', color: '#fff' }} />
                  <Legend
                    wrapperStyle={{ color: 'white' }}
                    formatter={(value, entry) => (
                      <span style={{ color: 'white', fontWeight: "500" }}>
                        {value}
                      </span>
                    )}
                  />
                  {/* Stacked Bars for Previous Week and Current Week */}
                  <Bar dataKey="stories" stackId="a" fill="#7A5195" name="Stories">
                    <LabelList dataKey="stories" position="inside" fill="#FFF" />
                  </Bar>
                  <Bar dataKey="defects" stackId="a" fill="#EF5675" name="Defects">
                    <LabelList dataKey="defects" position="inside" fill="#FFF" />
                  </Bar>
                  <Bar dataKey="pod_tasks" stackId="a" fill="#FFA600" name="Tasks">
                    <LabelList dataKey="pod_tasks" position="inside" fill="#FFF" />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            )}
          </ForegroundCard>
        </GraphCardContainer>
        <GraphCardContainer>
        <BackgroundCard  style={{ borderRadius:'20px' }}>
           <NameLabel>  <SpeedIcon style={{color:"blue"}}/>  
                                <FormattedMessage id="podVelocity" defaultMessage="POD Velocity" />
           </NameLabel>
          </BackgroundCard>
          <ForegroundCard style={{ backgroundColor: "#1E90FF", borderRadius: '20px' }}>
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={velocityData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="sprint" tick={{ 
    fill: 'white', 
    fontSize: '16px', 
    fontWeight: 'bold' 
  }} />
                <YAxis  tick={{ 
    fill: 'white', 
    fontSize: '16px', 
    fontWeight: 'bold' 
  }}/>
                <Tooltip ontentStyle={{ 
          backgroundColor: '#000', 
          border: '1px solid #ccc', 
          color: '#fff' // Tooltip text color
        }} 
        itemStyle={{ color: '#fff' }}/>
                <Legend />
                <Line type="monotone" dataKey="velocity" stroke="white" strokeWidth={3} />
              </LineChart>
            </ResponsiveContainer>
            </ForegroundCard>
        </GraphCardContainer>
      </GraphsContainer>
     
    </DashboardContainer>
  );
};

export default Dashboardnewclient;
