import React ,{Component, useState, useEffect}from "react";
import '../components/css/addcandidate.css';
import axios from 'axios';
// import FormikControl from './formikcontrol';
import Validate from "./utility/FormValidation";
import FormErrors from "./FormErrors";
// import select from "react-select/src/select";
import { Link, useHistory } from "react-router-dom";
import Navbar from "./Navbar";
import { login, PartnerguestupdateProfile} from "./action/auth";
import { useDispatch, useSelector } from "react-redux";
import Footer from "./Footer";
import { updateProfilecandidate } from "./action/auth";
import PersonIcon from '@mui/icons-material/Person';
import star from './assets/star.png';
import EditIcon from '@mui/icons-material/Edit';
import S3FileUpload from 'react-s3';
import AWS from 'aws-sdk';
import logo from './assets/programmer.png';
import defaultimg from './assets/defaultimge.png';
import { useAuthToken } from "../TokenContext";
import LanguageSelector from '../LanguageSelector'
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

// import { Margin } from '@mui/icons-material';
// import ManageProfile from '../pages2/ManageProfile';
const config = {
  bucketName: process.env.REACT_APP_BUCKETNAME,
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESSKEYID,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY
}

const S3Config = {
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESSKEYID,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY
}


const area = [
   { 
     value: 'Node', label: 'Node'
    },
  { 
    value: 'Java', label: 'Java'
   },
  { 
    value: 'React', label: 'React'
   },
  { 
    value: 'Python', label: 'Python' 
  },
  { 
    value: 'AWS', label: 'AWS' 
  },
  { 
    value: 'C++', label: 'C++' 
  }
]

//for country
const country = [

    
  
  { value: 'usa', label: 'USA' },
  { value: 'canada', label: 'Canada' },
  { value: 'india', label: 'India' },
  { value: 'australia', label: 'Australia' },
  { value: 'united_kingdom', label: 'United Kingdom' },
  { value: 'germany', label: 'Germany' },
  { value: 'france', label: 'France' },
  { value: 'china', label: 'China' },
  { value: 'japan', label: 'Japan' },
  { value: 'brazil', label: 'Brazil' },
  { value: 'south_africa', label: 'South Africa' },
  { value: 'mexico', label: 'Mexico' },
  { value: 'italy', label: 'Italy' },
  { value: 'spain', label: 'Spain' },
  { value: 'russia', label: 'Russia' },
  { value: 'south_korea', label: 'South Korea' },
  { value: 'new_zealand', label: 'New Zealand' },
  { value: 'singapore', label: 'Singapore' },
  { value: 'united_arab_emirates', label: 'United Arab Emirates' },
  { value: 'saudi_arabia', label: 'Saudi Arabia' },
  { value: 'argentina', label: 'Argentina' },
  { value: 'netherlands', label: 'Netherlands' },
  { value: 'sweden', label: 'Sweden' },
  { value: 'switzerland', label: 'Switzerland' },
  { value: 'norway', label: 'Norway' },
  { value: 'denmark', label: 'Denmark' },
  { value: 'finland', label: 'Finland' },
  { value: 'portugal', label: 'Portugal' },
  { value: 'poland', label: 'Poland' },
  { value: 'turkey', label: 'Turkey' },
  { value: 'indonesia', label: 'Indonesia' },
  { value: 'malaysia', label: 'Malaysia' },
  { value: 'thailand', label: 'Thailand' },
  { value: 'vietnam', label: 'Vietnam' },
  { value: 'philippines', label: 'Philippines' },
  { value: 'egypt', label: 'Egypt' },
  { value: 'nigeria', label: 'Nigeria' },
  { value: 'kenya', label: 'Kenya' },
  { value: 'ghana', label: 'Ghana' },
  { value: 'colombia', label: 'Colombia' },
  { value: 'chile', label: 'Chile' },
  { value: 'peru', label: 'Peru' },
  { value: 'venezuela', label: 'Venezuela' },
  { value: 'pakistan', label: 'Pakistan' },
  { value: 'bangladesh', label: 'Bangladesh' },
  { value: 'sri_lanka', label: 'Sri Lanka' },
  { value: 'nepal', label: 'Nepal' },
  { value: 'bhutan', label: 'Bhutan' },
  { value: 'maldives', label: 'Maldives' },
  { value: 'iceland', label: 'Iceland' },
  { value: 'luxembourg', label: 'Luxembourg' },
  { value: 'qatar', label: 'Qatar' },
  { value: 'oman', label: 'Oman' },
  { value: 'kuwait', label: 'Kuwait' },
  { value: 'bahrain', label: 'Bahrain' },
  { value: 'iraq', label: 'Iraq' },
  { value: 'iran', label: 'Iran' },
  { value: 'afghanistan', label: 'Afghanistan' },
  { value: 'israel', label: 'Israel' },
  { value: 'jordan', label: 'Jordan' },
  { value: 'lebanon', label: 'Lebanon' },
  { value: 'morocco', label: 'Morocco' },
  { value: 'algeria', label: 'Algeria' },
  { value: 'tunisia', label: 'Tunisia' },
  { value: 'libya', label: 'Libya' },
  { value: 'ethiopia', label: 'Ethiopia' },
  { value: 'tanzania', label: 'Tanzania' },
  { value: 'uganda', label: 'Uganda' },
  { value: 'zimbabwe', label: 'Zimbabwe' },
  { value: 'zambia', label: 'Zambia' },
  { value: 'mozambique', label: 'Mozambique' },
  { value: 'botswana', label: 'Botswana' },
  { value: 'namibia', label: 'Namibia' },
  { value: 'angola', label: 'Angola' },
  { value: 'cuba', label: 'Cuba' },
  { value: 'jamaica', label: 'Jamaica' },
  { value: 'haiti', label: 'Haiti' },
  { value: 'dominican_republic', label: 'Dominican Republic' },
  { value: 'trinidad_and_tobago', label: 'Trinidad and Tobago' },
  { value: 'bahamas', label: 'Bahamas' },
  { value: 'barbados', label: 'Barbados' },
  { value: 'panama', label: 'Panama' },
  { value: 'costa_rica', label: 'Costa Rica' },
  { value: 'honduras', label: 'Honduras' },
  { value: 'guatemala', label: 'Guatemala' },
  { value: 'el_salvador', label: 'El Salvador' },
  { value: 'nicaragua', label: 'Nicaragua' },
  { value: 'belize', label: 'Belize' },
  { value: 'paraguay', label: 'Paraguay' },
  { value: 'uruguay', label: 'Uruguay' },
  { value: 'bolivia', label: 'Bolivia' },
  { value: 'ecuador', label: 'Ecuador' },
  { value: 'guinea', label: 'Guinea' },
  { value: 'ivory_coast', label: 'Ivory Coast' },
  { value: 'cameroon', label: 'Cameroon' },
  { value: 'senegal', label: 'Senegal' },
  { value: 'sudan', label: 'Sudan' },
  { value: 'south_sudan', label: 'South Sudan' },
  { value: 'congo', label: 'Congo' },
  { value: 'madagascar', label: 'Madagascar' },
  { value: 'malawi', label: 'Malawi' },
  { value: 'sierra_leone', label: 'Sierra Leone' },
  { value: 'chad', label: 'Chad' },
  { value: 'mali', label: 'Mali' },
  { value: 'burkina_faso', label: 'Burkina Faso' },
  { value: 'rwanda', label: 'Rwanda' },
  { value: 'burundi', label: 'Burundi' },
  { value: 'lesotho', label: 'Lesotho' },
  { value: 'eswatini', label: 'Eswatini' }
    
]
//new
const industry = [
  { 
    value: ' Healthcare', label: ' Healthcare'
   },
   { 
    value: 'IT & Softwear', label: 'IT & Softwear'
   },
 { 
   value: ' Banking and Financial', label: ' Banking and Financial'
  },
 { 
   value: 'Services', label: 'Services'
  }
]

const Partnerguest = ({ currentLocale, changeLocale }) => {
  const token = useAuthToken();
  const intl = useIntl();

  const imageHandler = (e) => {
    const reader = new FileReader();
    reader.onload = () =>{
      if(reader.readyState === 2){
        setState({profileImg: reader.result})
      }
    }
    reader.readAsDataURL(e.target.files[0])
  };

  const { isLoggedIn, user } = useSelector(state => state.auth);

  const [Imagedata, setImagedata] = useState(""); 
  const [AuthImage, setAuthImage] = useState(""); 


  
  const dispatch = useDispatch();

  const myBucket = new AWS.S3(S3Config);


  const history = useHistory();

  const [num, setNum] = useState({
    country: '+1', // Default country code, you can change it to the desired default value
    phone: '',
  });

  const onInputChangenum = (event) => {
    const { id, value } = event.target;
    setNum((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const countryCodes = ['+1', '+44', '+91', '+81', '+86']; // Add more country codes as needed



  const [Edit, setEdit] = useState(false); 

  const [Image, setImage] = useState(""); 
  const [Uploading, setUploading] = useState(false)
 


  const [State, setState] = useState({
    fname: user.data[0].partnerguest_firstname,
    mname: user.data[0].partnerguest_middlename,
    lname: user.data[0].partnerguest_lastname,
 
    country : user.data[0].partnerguest_country,
    about:  user.data[0].about,
    email:  user.data[0].email,   
    phone:  user.data[0].phone_number,     
      
    
    profilePicture : user.data[0].profilePicture
    })
  
    const onImageUpload = e => {

      const file = e.target.files[0];
  

  if (file) {
    setImage(URL.createObjectURL(file));
    
  }



      S3FileUpload.uploadFile(file, config)
      .then(data => {

        
         const location = data.key;

        setState((prevState) => {
         return { ...prevState, [e.target.id]: location };
      });
      setUploading(false)
    })    
    .catch(err => {
    

          setUploading(false)
    })
      
 
  }



  const clearErrorState = () => {
    setState({
      errors: {
        blankfield: false,
        passwordmatch: false
      }
    });
  }

  const handleSubmit = async event => {
    event.preventDefault();
    // Form validation
 
    const headers = {
      'Authorization' : token, // Replace with your actual token
      'Content-Type': 'application/json', // You can add more headers as needed
    };
   

    const { fname, mname, lname, email, country,about, profilePicture,phone} = State;

    const data = {
        partnerguest_id : user.data[0].partnerguest_id,
        partnerguest_firstname: fname, phone_number: phone,partnerguest_middlename : mname,  partnerguest_lastname : lname, partnerguest_country : country, about: about, profilePicture : profilePicture ,
  }



//   console.log(data)

    dispatch(PartnerguestupdateProfile(data,headers)).then((err, datas)=>{

      alert("PartnerGuest Details Successfully Updated");
      window.location.reload();
    //   window.location.href('./StakeholderDashboard')
     

   
    });             
  };



  const onInputChange = e => {
  
    setState((prevState) => {
      return { ...prevState, [e.target.id]: e.target.value };
    });
  }

  const onInputChangeselect = e => {
   
    setState((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  }

  function generatePreSignedPutUrl11() {
   
    const URL_EXPIRATION_TIME = 900;
  
                  const params = {
                    Bucket: "globldataprod",
                    Key: user.data[0].profilePicture,
                    Expires: URL_EXPIRATION_TIME,
                  }
                 
               myBucket.getSignedUrl('getObject', params, (err, url) => {
  
                setAuthImage(url);
  
              });
  }

  useEffect(() => {

    user.data[0].profile == 0 ? setEdit(true) : setEdit(false); 

    generatePreSignedPutUrl11();
  
  }, [])
  
  


  const { profileImg} = State
    return (

     <div className="container">


      
    <br></br>
    <br></br>
      <div className="row">




      <div className="col-lg-3" >

      <span style={{ 
   marginLeft: '20px', 
   color: '#03045e', 
   fontSize: "18px", 
   fontFamily: 'Poppins,Medium', 
   fontWeight: "600", 
   cursor: 'pointer' 
}}>
   <button 
       onClick={() => setEdit(false)}
       onKeyDown={(e) => {
           if (e.key === 'Enter' || e.key === ' ') {
               setEdit(false)
           }
       }}
       style={{
           background: 'none',
           border: 'none',
           padding: 0,
           cursor: 'inherit',
           font: 'inherit',
           color: 'inherit'
       }}
   >      <a 
          style={{  color: '#03045e', fontSize: "18px", fontFamily: 'Poppins,Medium', fontWeight: "600", cursor: 'pointer' 
        }} href="/StakeholderDashboard" >{intl.formatMessage({ id: 'dashboard' })}</a>  > 
         {intl.formatMessage({ id: 'manageProfile' })} >   </button> </span>

      </div>
 
      <div className="col-lg-7" style={{      border: '2px solid #ccc',
        borderRadius: '8px',
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.2)',
        padding: '20px', opacity: 1 }}>
      {Edit == false ? (

<div className="email" style={{ padding: "0px", border: "1px solid #8EAEBC", width: "50px", height : "50px" , borderRadius : "50px", backgroundColor: "#8EAEBC", float: "right"}}>
<EditIcon  style={{ color :  "white", marginLeft : "12px", marginTop: "12px" }} onClick={() => { setEdit(true) }} />

  </div>
): (<></>)}

<br></br>
<br></br>
<br></br>
<br></br>
<br></br> 

       {user.data[0].profile == 0 ? (  <h1 className="headsmall">{intl.formatMessage({id:"createProfile"})}</h1>  ) : ('')}


      

      



{Edit == false ? (

<>
<h1 className="headsmall"  style={{ "margin-top": "40px" }}>{intl.formatMessage({id:"manageProfile"})}</h1>

<center>



          
{   user.data[0].profilePicture == "" ? (<div className="email" style={{ padding: "0px", border: "1px solid #8EAEBC", width: "100px", height : "100px" , borderRadius : "50px", textAlign: "center"}}>
             
            <PersonIcon  style={{ color :  "#8EAEBC", textAlign :'center'}}/>


            </div>) : (  <img src={AuthImage}   style={{width:'100px',height:'100px',marginTop:"50px", borderRadius : "50px"}}></img> )}
            
             <br></br>
             <p style={{ marginLeft : "-1px", color :  "black", width: "100%", fontSize: "18px", fontWeight: "bold", textAlign: "center" }} >{State.fname + " " + State.lname}</p>

 
 
  <div> <LanguageSelector currentLocale={currentLocale} changeLocale={changeLocale} />
   </div>
 
 
             </center>

             <div style={{ border: "2px solid #E7E7E7",boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)', borderRadius: "5px", padding: "40px", height: 'max-height', overflow: "auto",display:'flex',flexDirection:"column"}}>
             <p style={{ marginLeft: "5px", color :  "#8EAEBC", fontWeight: "bold" , fontSize: "18px", float: "left", maxWidth: "100%", }} >{intl.formatMessage({ id: 'basicInformation' })}</p>
       <p style={{ color :  "black", maxWidth: "100%", height: "auto", fontWeight: "bold", fontSize: "16px", float: "left",marginLeft: "5px"  }} >{intl.formatMessage({ id: 'email' })}</p>

<p style={{ color :  "grey", maxWidth: "100%", height: "auto", fontSize: "16px", float: "left",marginLeft: "5px" }}>{State.email}</p>
<p style={{  color :  "black", maxWidth: "100%", height: "auto", fontWeight: "bold", fontSize: "16px", float: "left",marginLeft: "5px"  }} >{intl.formatMessage({ id: 'country' })}</p>

<p style={{ color :  "grey", maxWidth: "100%", height: "auto", fontSize: "16px", float: "left",marginLeft: "5px" }}>{State.country}  
</p>
<p style={{color :  "black", maxWidth: "100%", height: "auto", fontWeight: "bold", fontSize: "16px", float: "left",marginLeft: "5px" }} >{intl.formatMessage({ id: 'about' })}</p>

<p style={{ color :  "grey", maxWidth: "100%", height: "auto", fontSize: "16px", float: "left",marginLeft: "5px" }} >{State.about}</p>
<p style={{color :  "black", maxWidth: "100%", height: "auto", fontWeight: "bold", fontSize: "16px", float: "left",marginLeft: "5px" }} >{intl.formatMessage({ id: 'phone' })}</p>

<p style={{ color :  "grey", maxWidth: "100%", height: "auto", fontSize: "16px", float: "left",marginLeft: "5px" }} >{State.phone}</p>

</div></>) : ( <>
  {/* <h1 className="headsmall"  style={{ "margin-bottom": "60px" }}>Update Profile</h1> */}

     
  <center>
                    {Image == "" ? (<div  style={{ padding: "0px", border: "1px solid #8EAEBC", width: "100px", height: "100px", borderRadius: "50px", textAlign: "center" }}>
                    
                        {AuthImage == null ? (
                          <img
                            src={defaultimg}
                            alt=""
                            style={{ width: '100px', height: '100px', borderRadius: "50px",textAlign: "center" }}
                          ></img>
                        )


                          : (
                          
                            <img src={AuthImage} alt='' style={{ width: '100px', height: '100px',textAlign: "center", borderRadius: "50px" }} id="profilePicture" ></img>

                          )}

<span>
                        <input type="file" style={{ marginLeft: '40px', width: '5rem', height: '3rem', borderRadius: "50px"  }} className="profileclass" onChange={onImageUpload} id="profilePicture" />
                        <EditIcon style={{color: 'white', width:'25px', height: '25px', marginLeft:"-4rem",marginTop:"-1rem", backgroundColor: '#03045e', boxShadow: 'rgb(255, 255, 255) -15px -15px 25px', borderRadius: '50px' }} onChange={onImageUpload}
              />
                      </span>
                      {/* <span style={{marginTop:"-10px",marginLeft:"20px"}}> hhgjh</span> */}

                    </div>) : (<img src={Image} alt='' style={{ width: '100px', height: '100px', marginTop: "30px", borderRadius: "50px" }} id="profilePicture" ></img>)}</center>
            
     <br></br>
     
          <form onSubmit={handleSubmit}>
            {/* For First Name */}


            <div className="email">
              <label  className="Label">{intl.formatMessage({ id: 'firstName' })}  : </label>
              <input
                className="input2" 
                placeholder="Enter First Name"
                type="text"
                id="fname"
value={State.fname}  onChange={onInputChange}
required
              />
            </div>

            <div className="email">
              <label className="Label">{intl.formatMessage({ id: 'middleName' })} : </label>
              <input
                className="input2" 
                placeholder="Enter Middle Name"
                type="text"
                id="mname"
                value={State.mname}
                onChange={onInputChange}
              />
            </div>

                <div className="email">
                    <label className="Label">{intl.formatMessage({ id: 'lastName' })}  : </label>
              <input
                className="input2" 
                placeholder="Enter Last Name"
                type="text"
                id="lname"
                value={State.lname}
                onChange={onInputChange}
                required
              />
            </div>
            <div className="email">
      <label className="Label">{intl.formatMessage({ id: 'phoneNumber' })}:</label>
      
      <select
        style={{width:'6rem'}}
          className="country-code"
          id="country"
          value={num.country}
          onChange={onInputChangeselect}
        >
          {countryCodes.map((code) => (
            <option key={code} value={code}>
              {code}
            </option>
          ))}
        </select>
      <input
        className="inputphone"
        placeholder="Enter phone number"
        type="tel"
        id="phone"
        value={State.phone}
        onChange={onInputChange}
        required
      />
    </div>

            {/* For Middle Name */}
           

            {/* For Email */}

            
            <div className="email" >
           <label  className="Label">{intl.formatMessage({ id: 'country' })}   : </label>
   <select
    //value={area}
      className="input2" 
      name="country"
      placeholder="What's your country*"
      onChange={onInputChangeselect}       
      value={State.country}     
   
      required    
      >
              <option  style={{color:'black'}} value='' defaultValue >Country</option>


{country.map((data) => (
 
<option> {data.value} </option>
))}

</select>






            </div>

            {/* <div className="email">
              <label  className="Label">About : </label>
              <textarea
                  //value={area}
                  type="text"
                  className="input2"
                  id="about"
                  placeholder="About"
                  onChange={onInputChange}
                  value={State.about}
               
                  required
                  />
            </div> */}

            <div className="createAccount">
              <button className="button is-success" style={{ width : "50%"}} type="submit"> {user.data[0].profile == 0 ? ( intl.formatMessage({ id: 'create' })) : ( intl.formatMessage({ id: 'save' }))}</button>
            </div>
         
           </form>

           </>)}

</div>        

           <div className="col-lg-3"></div>


        </div>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
      </div>


    );
  }
  Partnerguest.propTypes = {
    changeLocale: PropTypes.func.isRequired,
    currentLocale: PropTypes.string.isRequired,
  };
export default Partnerguest;
