import React, { useEffect, useState } from 'react';
import { 
  Box, 
  Typography, 
  Card, 
  CardContent, 
  CircularProgress,
  Avatar,
  Divider,
  Paper,
  styled
} from '@mui/material';
import BlockIcon from '@mui/icons-material/Block';
import { useSelector } from "react-redux";
import { useAuthToken } from "../../TokenContext";
import { useIntl } from 'react-intl';
import axios from 'axios';

// Styled components using MUI
const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.spacing(2),
  height: '100%',
  overflow: 'hidden',
}));

const HeaderTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: '1.25rem',
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(3),
  padding: theme.spacing(2),
  paddingBottom: 0
}));

const BlockerAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.error.main,
  width: 40,
  height: 40,
  boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
}));

const TimelineConnector = styled(Box)(({ theme }) => ({
  width: 2,
  backgroundColor: theme.palette.divider,
  height: 100,
  margin: '8px auto',
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    transition: 'background-color 0.3s'
  }
}));

const InfoLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.info.main,
  fontWeight: 600,
  fontSize: '0.875rem',
  width: '30%'
}));

const InfoValue = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 500,
  fontSize: '0.875rem',
  width: '70%'
}));

const ScrollableContent = styled(Box)({
  overflowY: 'auto',
  height: 'calc(100% - 60px)', // Adjust based on header height
  '&::-webkit-scrollbar': {
    width: '8px'
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1'
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#888',
    borderRadius: '4px'
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#555'
  }
});

const ClientProjectInfo = ({ expanded }) => {
  const [blockers, setBlockers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const { user } = useSelector(state => state.auth);
  const intl = useIntl();
  const token = useAuthToken();

  useEffect(() => {
    const fetchBlockers = async () => {
      if (!token) {
        console.log('Token is missing. API requests will not be made.');
        return;
      }

      const headers = {
        'Authorization': token,
        'Content-Type': 'application/json',
      };

      try {
        const endpoint = user.data[0].type === "stakeholder"
          ? process.env.REACT_APP_STAKEHOLDER_FTCHLATESTBLOCKERSSTAKEHOLDER
          : process.env.REACT_APP_CLIENT_FETCHLATESTBLOCKERS;

        const payload = user.data[0].type === "stakeholder"
          ? { client_code: user.data[0].client_code }
          : { manager_id: user.data[0].emp_id };

        const response = await axios.post(endpoint, payload, { headers });
        setBlockers(response.data.data);
      } catch (error) {
        console.error('Error fetching blockers:', error);
        setErrorMessage("Failed to fetch data. Please try again later.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchBlockers();
  }, [token, user]);

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <StyledCard elevation={3}>
      <HeaderTypography variant="h6">
        {intl.formatMessage({ id: 'podBlockers' })}
      </HeaderTypography>

      <ScrollableContent>
        {!Array.isArray(blockers) || blockers.length === 0 ? (
          <Box p={3}>
            <Typography color="textSecondary">
              {intl.formatMessage({ id: 'noDataAvailable' })}
            </Typography>
          </Box>
        ) : (
          blockers.map((data, index) => (
            <Box key={data.key}>
              <ContentWrapper>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <BlockerAvatar>
                    <BlockIcon />
                  </BlockerAvatar>
                  {index < blockers.length - 1 && <TimelineConnector />}
                </Box>
                
                <Box flex={1}>
                  <Box display="flex" alignItems="center" mb={1}>
                    <InfoLabel>Team Member:</InfoLabel>
                    <InfoValue>{data.Candidate_Name}</InfoValue>
                  </Box>
                  <Box display="flex">
                    <InfoLabel>Blocker:</InfoLabel>
                    <InfoValue>{data.description}</InfoValue>
                  </Box>
                </Box>
              </ContentWrapper>
              {index < blockers.length - 1 && <Divider />}
            </Box>
          ))
        )}
      </ScrollableContent>
    </StyledCard>
  );
};

export default ClientProjectInfo;