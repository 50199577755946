import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Paper,
  Avatar,
  Typography,
  TextField,
  Button,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Divider,
  Breadcrumbs,
  Card,
  CardContent,
  styled,
  CircularProgress,
  Snackbar,
  Alert,
  FormHelperText
} from '@mui/material';
import {
  Edit as EditIcon,
  ArrowBack as ArrowBackIcon,
  Person as PersonIcon,
  PhotoCamera as PhotoCameraIcon,
  Save as SaveIcon,
  Close as CloseIcon,
  Phone as PhoneIcon,
  Public as PublicIcon,
  Info as InfoIcon
} from '@mui/icons-material';
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import S3FileUpload from 'react-s3';
import AWS from 'aws-sdk';
import { StakeholderupdateProfile } from "./action/auth";
import { useAuthToken } from "../TokenContext";
import LanguageSelector from '../LanguageSelector';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import defaultimg from './assets/defaultimge.png';

// AWS Configs
const config = {
  bucketName: process.env.REACT_APP_BUCKETNAME,
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESSKEYID,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY
};

const S3Config = {
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESSKEYID,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY
};

// Country and Industry Data
const countries = [
  { value: 'usa', label: 'USA' },
  { value: 'canada', label: 'Canada' },
  { value: 'india', label: 'India' },
  { value: 'australia', label: 'Australia' },
  { value: 'united_kingdom', label: 'United Kingdom' },
  { value: 'germany', label: 'Germany' },
  { value: 'france', label: 'France' },
  { value: 'china', label: 'China' },
  { value: 'japan', label: 'Japan' },
  { value: 'brazil', label: 'Brazil' },
  { value: 'south_africa', label: 'South Africa' },
  { value: 'mexico', label: 'Mexico' },
  { value: 'italy', label: 'Italy' },
  { value: 'spain', label: 'Spain' },
  { value: 'russia', label: 'Russia' },
  { value: 'south_korea', label: 'South Korea' },
  { value: 'new_zealand', label: 'New Zealand' },
  { value: 'singapore', label: 'Singapore' },
  { value: 'united_arab_emirates', label: 'United Arab Emirates' },
  { value: 'saudi_arabia', label: 'Saudi Arabia' },
  { value: 'argentina', label: 'Argentina' },
  { value: 'netherlands', label: 'Netherlands' },
  { value: 'sweden', label: 'Sweden' },
  { value: 'switzerland', label: 'Switzerland' },
  { value: 'norway', label: 'Norway' },
  { value: 'denmark', label: 'Denmark' },
  { value: 'finland', label: 'Finland' },
  { value: 'portugal', label: 'Portugal' },
  { value: 'poland', label: 'Poland' },
  { value: 'turkey', label: 'Turkey' },
  { value: 'indonesia', label: 'Indonesia' },
  { value: 'malaysia', label: 'Malaysia' },
  { value: 'thailand', label: 'Thailand' },
  { value: 'vietnam', label: 'Vietnam' },
  { value: 'philippines', label: 'Philippines' },
  { value: 'egypt', label: 'Egypt' },
  { value: 'nigeria', label: 'Nigeria' },
  { value: 'kenya', label: 'Kenya' },
  { value: 'ghana', label: 'Ghana' },
  { value: 'colombia', label: 'Colombia' },
  { value: 'chile', label: 'Chile' },
  { value: 'peru', label: 'Peru' },
  { value: 'venezuela', label: 'Venezuela' },
  { value: 'pakistan', label: 'Pakistan' },
  { value: 'bangladesh', label: 'Bangladesh' },
  { value: 'sri_lanka', label: 'Sri Lanka' },
  { value: 'nepal', label: 'Nepal' },
  { value: 'bhutan', label: 'Bhutan' },
  { value: 'maldives', label: 'Maldives' },
  { value: 'iceland', label: 'Iceland' },
  { value: 'luxembourg', label: 'Luxembourg' },
  { value: 'qatar', label: 'Qatar' },
  { value: 'oman', label: 'Oman' },
  { value: 'kuwait', label: 'Kuwait' },
  { value: 'bahrain', label: 'Bahrain' },
  { value: 'iraq', label: 'Iraq' },
  { value: 'iran', label: 'Iran' },
  { value: 'afghanistan', label: 'Afghanistan' },
  { value: 'israel', label: 'Israel' },
  { value: 'jordan', label: 'Jordan' },
  { value: 'lebanon', label: 'Lebanon' },
  { value: 'morocco', label: 'Morocco' },
  { value: 'algeria', label: 'Algeria' },
  { value: 'tunisia', label: 'Tunisia' },
  { value: 'libya', label: 'Libya' },
  { value: 'ethiopia', label: 'Ethiopia' },
  { value: 'tanzania', label: 'Tanzania' },
  { value: 'uganda', label: 'Uganda' },
  { value: 'zimbabwe', label: 'Zimbabwe' },
  { value: 'zambia', label: 'Zambia' },
  { value: 'mozambique', label: 'Mozambique' },
  { value: 'botswana', label: 'Botswana' },
  { value: 'namibia', label: 'Namibia' },
  { value: 'angola', label: 'Angola' },
  { value: 'cuba', label: 'Cuba' },
  { value: 'jamaica', label: 'Jamaica' },
  { value: 'haiti', label: 'Haiti' },
  { value: 'dominican_republic', label: 'Dominican Republic' },
  { value: 'trinidad_and_tobago', label: 'Trinidad and Tobago' },
  { value: 'bahamas', label: 'Bahamas' },
  { value: 'barbados', label: 'Barbados' },
  { value: 'panama', label: 'Panama' },
  { value: 'costa_rica', label: 'Costa Rica' },
  { value: 'honduras', label: 'Honduras' },
  { value: 'guatemala', label: 'Guatemala' },
  { value: 'el_salvador', label: 'El Salvador' },
  { value: 'nicaragua', label: 'Nicaragua' },
  { value: 'belize', label: 'Belize' },
  { value: 'paraguay', label: 'Paraguay' },
  { value: 'uruguay', label: 'Uruguay' },
  { value: 'bolivia', label: 'Bolivia' },
  { value: 'ecuador', label: 'Ecuador' },
  { value: 'guinea', label: 'Guinea' },
  { value: 'ivory_coast', label: 'Ivory Coast' },
  { value: 'cameroon', label: 'Cameroon' },
  { value: 'senegal', label: 'Senegal' },
  { value: 'sudan', label: 'Sudan' },
  { value: 'south_sudan', label: 'South Sudan' },
  { value: 'congo', label: 'Congo' },
  { value: 'madagascar', label: 'Madagascar' },
  { value: 'malawi', label: 'Malawi' },
  { value: 'sierra_leone', label: 'Sierra Leone' },
  { value: 'chad', label: 'Chad' },
  { value: 'mali', label: 'Mali' },
  { value: 'burkina_faso', label: 'Burkina Faso' },
  { value: 'rwanda', label: 'Rwanda' },
  { value: 'burundi', label: 'Burundi' },
  { value: 'lesotho', label: 'Lesotho' },
  { value: 'eswatini', label: 'Eswatini' }
    
];

const countryCodes = ['+1', '+44', '+91', '+81', '+86'];

// Styled Components
const ProfileContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(10),
  marginBottom: theme.spacing(8)
}));

const ProfileCard = styled(Card)(({ theme }) => ({
  borderRadius: 16,
  boxShadow: '0 4px 24px 0 rgba(34, 41, 47, 0.1)',
  overflow: 'visible',
  position: 'relative'
}));

const ProfileHeader = styled(Box)(({ theme }) => ({
  height: 200,
  background: 'linear-gradient(135deg, #03045e 0%, #0077b6 100%)',
  borderRadius: '16px 16px 0 0',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  padding: '16px',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: '40%',
    background: 'linear-gradient(to top, rgba(0,0,0,0.4), transparent)'
  }
}));

const ProfileAvatarWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: '50%',
  bottom: -60,
  transform: 'translateX(-50%)',
  zIndex: 2
}));

const ProfileAvatar = styled(Avatar)(({ theme }) => ({
  width: 120,
  height: 120,
  border: `4px solid ${theme.palette.background.paper}`,
  boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
  transition: 'transform 0.3s ease',
  '&:hover': {
    transform: 'scale(1.05)',
  }
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  '& .MuiOutlinedInput-root': {
    borderRadius: 8,
    backgroundColor: theme.palette.background.paper,
    transition: 'all 0.2s ease',
    '&:hover': {
      backgroundColor: '#f8fafc'
    },
    '&.Mui-focused': {
      backgroundColor: '#fff',
      boxShadow: '0 0 0 2px rgba(0, 180, 216, 0.2)'
    }
  }
}));

const PhoneInputWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(3)
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  minWidth: 120,
  '& .MuiOutlinedInput-root': {
    borderRadius: 8
  }
}));

const ActionButton = styled(Button)(({ theme }) => ({
  borderRadius: 8,
  padding: '10px 24px',
  textTransform: 'none',
  fontWeight: 600,
  boxShadow: '0 4px 12px rgba(0, 180, 216, 0.2)',
  '&:hover': {
    boxShadow: '0 6px 16px rgba(0, 180, 216, 0.3)'
  }
}));

const InfoSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: theme.palette.grey[50],
  borderRadius: 12,
  marginBottom: theme.spacing(3)
}));

  const Stakeholder = ({ currentLocale, changeLocale }) => {
  const token = useAuthToken();
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector(state => state.auth);
  const myBucket = new AWS.S3(S3Config);

  // State Management
  const [state, setState] = useState({
    fname: user.data[0].stakeholder_firstname || '',
    mname: user.data[0].customer_middlename || '',
    lname: user.data[0].stakeholder_lastname || '',
    country: user.data[0].stakeholder_country || '',
    about: user.data[0].about || '',
    email: user.data[0].email || '',
    phone: user.data[0].phone_number || '',
    profilePicture: user.data[0].profilePicture || ''
  });

  const [phoneState, setPhoneState] = useState({
    countryCode: '+1',
    number: state.phone || ''
  });

  const [edit, setEdit] = useState(user.data[0].profile === 0);
  const [image, setImage] = useState('');
  const [uploading, setUploading] = useState(false);
  const [authImage, setAuthImage] = useState('');
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  useEffect(() => {
    generatePreSignedUrl();
  }, []);

  const generatePreSignedUrl = async () => {
    if (!state.profilePicture) return;

    try {
      const response = await fetch(process.env.REACT_APP_TALENT_CLOUDFRONTAPI, {
        method: 'POST',
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          url: process.env.REACT_APP_CLOUDFRONT + state.profilePicture
        })
      });
      
      const data = await response.json();
      if (data.body) {
        const signedUrl = JSON.parse(data.body).signedUrl;
        setAuthImage(signedUrl);
      }
    } catch (error) {
      console.error("Error generating signed URL:", error);
      setSnackbar({
        open: true,
        message: intl.formatMessage({ id: 'errorLoadingProfile' }),
        severity: 'error'
      });
    }
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files?.[0];
    if (!file) return;

    if (file.size > 5 * 1024 * 1024) {
      setSnackbar({
        open: true,
        message: intl.formatMessage({ id: 'fileSizeError' }),
        severity: 'error'
      });
      return;
    }

    setUploading(true);
    try {
      const preview = URL.createObjectURL(file);
      setImage(preview);

      const uploadResult = await S3FileUpload.uploadFile(file, config);
      setState(prev => ({
        ...prev,
        profilePicture: uploadResult.key
      }));

      setSnackbar({
        open: true,
        message: intl.formatMessage({ id: 'imageUploadSuccess' }),
        severity: 'success'
      });
    } catch (error) {
      console.error('Upload error:', error);
      setSnackbar({
        open: true,
        message: intl.formatMessage({ id: 'imageUploadError' }),
        severity: 'error'
      });
      setImage('');
    } finally {
      setUploading(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    try {
      const data = {
        stakeholder_id: user.data[0].stakeholder_id,
        stakeholder_firstname: state.fname,
        phone_number: `${phoneState.countryCode}${phoneState.number}`,
        stakeholder_middlename: state.mname,
        stakeholder_lastname: state.lname,
        stakeholder_country: state.country,
        about: state.about,
        profilePicture: state.profilePicture
      };

      await dispatch(StakeholderupdateProfile(data, {
        'Authorization': token,
        'Content-Type': 'application/json'
      }));

      setSnackbar({
        open: true,
        message: intl.formatMessage({ id: 'profileUpdateSuccess' }),
        severity: 'success'
      });

      setTimeout(() => {
        window.location.href = '/StakeholderDashboard';
      }, 1500);
    } catch (error) {
      setSnackbar({
        open: true,
        message: intl.formatMessage({ id: 'profileUpdateError' }),
        severity: 'error'
      });
    }
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setState(prev => ({
      ...prev,
      [id]: value
    }));
  };

  return (
    <ProfileContainer maxWidth="lg">
    <Box sx={{ mb: 4 }}>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Link 
          to="/StakeholderDashboard"
          style={{
            color: '#03045e',
            textDecoration: 'none',
            fontSize: '16px',
            fontWeight: 600
          }}
        >
          {intl.formatMessage({ id: 'dashboard' })}
        </Link>
        <Typography color="textSecondary" fontWeight={600}>
          {intl.formatMessage({ id: 'manageProfile' })}
        </Typography>
      </Breadcrumbs>
    </Box>

    <ProfileCard>
      <ProfileHeader>
        <Box position="absolute" top={16} right={16} zIndex={2}>
          <LanguageSelector 
            currentLocale={currentLocale} 
            changeLocale={changeLocale}
          />
        </Box>
        
        <ProfileAvatarWrapper>
          <ProfileAvatar
            src={image || authImage || defaultimg}
            alt={state.fname}
          />
          {edit && (
            <Box
              position="absolute"
              top={0}
              left={0}
              right={0}
              bottom={0}
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{
                opacity: 0,
                transition: '0.3s',
                borderRadius: '50%',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                '&:hover': { opacity: 1 }
              }}
            >
              <label htmlFor="profile-image">
                <input
                  accept="image/*"
                  type="file"
                  hidden
                  id="profile-image"
                  onChange={handleImageUpload}
                />
                <IconButton 
                  component="span"
                  sx={{ 
                    color: 'white',
                    '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.1)' }
                  }}
                >
                  <PhotoCameraIcon />
                </IconButton>
              </label>
            </Box>
          )}
        </ProfileAvatarWrapper>
      </ProfileHeader>

      {!edit && (
        <IconButton
          sx={{
            position: 'absolute',
            right: 16,
            top: 180,
            backgroundColor: 'white',
            boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
            '&:hover': { backgroundColor: '#f0f0f0' }
          }}
          onClick={() => setEdit(true)}
        >
          <EditIcon />
        </IconButton>
      )}

      <CardContent sx={{ pt: 10 }}>
        {edit ? (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <StyledTextField
                  fullWidth
                  label={intl.formatMessage({ id: 'firstName' })}
                  id="fname"
                  value={state.fname}
                  onChange={handleInputChange}
                  required
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <StyledTextField
                  fullWidth
                  label={intl.formatMessage({ id: 'middleName' })}
                  id="mname"
                  value={state.mname}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <StyledTextField
                  fullWidth
                  label={intl.formatMessage({ id: 'lastName' })}
                  id="lname"
                  value={state.lname}
                  onChange={handleInputChange}
                  required
                />
              </Grid>
              
              <Grid item xs={12} md={6}>
                <PhoneInputWrapper>
                  <FormControl sx={{ width: '30%' }}>
                    <InputLabel>{intl.formatMessage({ id: 'countryCode' })}</InputLabel>
                    <Select
                      value={phoneState.countryCode}
                      onChange={(e) => setPhoneState(prev => ({ ...prev, countryCode: e.target.value }))}
                      label={intl.formatMessage({ id: 'countryCode' })}
                    >
                      {countryCodes.map(code => (
                        <MenuItem key={code} value={code}>{code}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <StyledTextField
                    sx={{ width: '70%' }}
                    label={intl.formatMessage({ id: 'phoneNumber' })}
                    value={phoneState.number}
                    onChange={(e) => setPhoneState(prev => ({ ...prev, number: e.target.value }))}
                    required
                  />
                </PhoneInputWrapper>
              </Grid>
              
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>{intl.formatMessage({ id: 'country' })}</InputLabel>
                  <Select
                    value={state.country}
                    onChange={(e) => setState(prev => ({ ...prev, country: e.target.value }))}
                    label={intl.formatMessage({ id: 'country' })}
                    required
                  >
                    {countries.map(country => (
                      <MenuItem key={country.value} value={country.value}>
                        {country.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <StyledTextField
                  fullWidth
                  multiline
                  rows={4}
                  label={intl.formatMessage({ id: 'about' })}
                  id="about"
                  value={state.about}
                  onChange={handleInputChange}
                  required
                />
              </Grid>
            </Grid>

            <Box sx={{ mt: 4, display: 'flex', gap: 2, justifyContent: 'center' }}>
              <ActionButton
                variant="outlined"
                onClick={() => setEdit(false)}
                startIcon={<CloseIcon />}
              >
                {intl.formatMessage({ id: 'cancel' })}
              </ActionButton>
              <ActionButton
                type="submit"
                variant="contained"
                startIcon={<SaveIcon />}
                disabled={uploading}
              >
                {uploading ? 
                  <CircularProgress size={24} /> : 
                  intl.formatMessage({ id: user.data[0].profile === 0 ? 'create' : 'save' })}
              </ActionButton>
            </Box>
          </form>
        ) : (
          <Box>
            <Typography variant="h5" align="center" gutterBottom>
              {`${state.fname} ${state.lname}`}
            </Typography>
            <Typography 
              variant="body1" 
              color="textSecondary" 
              align="center" 
              sx={{ mb: 2 }}
            >
              {state.email}
            </Typography>
            
            <InfoSection>
              <Typography 
                variant="h6" 
                color="primary" 
                gutterBottom
                sx={{ fontWeight: 600 }}
              >
                {intl.formatMessage({ id: 'basicInformation' })}
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <PhoneIcon color="action" />
                    <Box>
                      <Typography variant="subtitle2" color="textSecondary">
                        {intl.formatMessage({ id: 'phoneNumber' })}
                      </Typography>
                      <Typography variant="body1">{state.phone}</Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <PublicIcon color="action" />
                    <Box>
                      <Typography variant="subtitle2" color="textSecondary">
                        {intl.formatMessage({ id: 'country' })}
                      </Typography>
                      <Typography variant="body1">{state.country}</Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1 }}>
                    <InfoIcon color="action" sx={{ mt: 0.5 }} />
                    <Box>
                      <Typography variant="subtitle2" color="textSecondary">
                        {intl.formatMessage({ id: 'about' })}
                      </Typography>
                      <Typography variant="body1">{state.about}</Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </InfoSection>
          </Box>
        )}
      </CardContent>
    </ProfileCard>

    <Snackbar
      open={snackbar.open}
      autoHideDuration={6000}
      onClose={() => setSnackbar({ ...snackbar, open: false })}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert 
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        severity={snackbar.severity}
        elevation={6}
        variant="filled"
      >
        {snackbar.message}
      </Alert>
    </Snackbar>

    {uploading && (
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 9999,
          backdropFilter: 'blur(4px)'
        }}
      >
        <Box sx={{ textAlign: 'center' }}>
          <CircularProgress />
          <Typography sx={{ mt: 2 }}>
            {intl.formatMessage({ id: 'uploading' })}
          </Typography>
        </Box>
      </Box>
    )}
  </ProfileContainer>
);
};

Stakeholder.propTypes = {
changeLocale: PropTypes.func.isRequired,
currentLocale: PropTypes.string.isRequired,
};

export default Stakeholder;