import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { NavHashLink } from 'react-router-hash-link';
import { useSelector } from "react-redux";
import axios from 'axios';
import { useAuthToken } from "../TokenContext";
import {cardShadow, hoverEffect} from '../utils';

const CATEGORIES = [
  { value: "Design", label: "Design" },
  { value: "Coding", label: "Coding" },
  { value: "Testing", label: "Testing" },
  { value: "Resolving Bugs", label: "Resolving Bugs" },
  { value: "Deployment", label: "Deployment" }
];

const PodactivityviewalltalentEdit = () => {
  const token = useAuthToken();
  const podId = window.location.pathname.split(',')[0].split('/')[2];

  const [formData, setFormData] = useState({
    categories: '',
    today_focus: '',
    loom_url: '',
    accomplishments: '',
    blockers: ''
  });

  const handleInputChange = (field, value) => {
    setFormData(prev => ({ ...prev, [field]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const poddata = [{
      ...formData,
      id: podId
    }];

    try {
      await fetch(process.env.REACT_APP_TALENT_PODACTIVITYUPDATETALENT, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token
        },
        body: JSON.stringify({ poddata })
      });

      alert("Edited Details successfully.");
      window.location.href = "/Podactivityviewalltalent";
    } catch (error) {
      console.error('Failed to update activity:', error);
      alert('Failed to update activity');
    }
  };

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const { data } = await axios.post(
          process.env.REACT_APP_TALENT_PODACTIVITYBYIDTALENT,
          { id: podId },
          {
            headers: {
              Authorization: token,
              'Content-Type': 'application/json'
            }
          }
        );

        const activityData = data.data[0].items[0];
        setFormData({
          categories: activityData.categories,
          today_focus: activityData.today_focus,
          loom_url: activityData.loom_url,
          accomplishments: activityData.accomplishments,
          blockers: activityData.blockers
        });
      } catch (error) {
        console.error('Failed to fetch activity details:', error);
      }
    };

    fetchDetails();
  }, [podId, token]);

  return (
    <Container>
      <Title>Edit Requirement</Title>
      
      <MainContent>
        <FormCard>
          <FormSection>
            <FormGroup>
              <Label>Categories:</Label>
              <Select
                value={formData.categories}
                onChange={(e) => handleInputChange('categories', e.target.value)}
                required
              >
                <option value="">Select Category</option>
                {CATEGORIES.map(cat => (
                  <option key={cat.value} value={cat.value}>{cat.label}</option>
                ))}
              </Select>
            </FormGroup>

            <FormGroup>
              <Label>Video URL:</Label>
              <Input
                type="url"
                placeholder="https://www.example.com"
                value={formData.loom_url}
                onChange={(e) => handleInputChange('loom_url', e.target.value)}
              />
            </FormGroup>

            <FormGroup>
              <Label>Today's Focus:</Label>
              <TextArea
                value={formData.today_focus}
                onChange={(e) => handleInputChange('today_focus', e.target.value)}
                placeholder="Today's Focus..."
                required
              />
            </FormGroup>

            <FormGroup>
              <Label>Accomplishments:</Label>
              <TextArea
                value={formData.accomplishments}
                onChange={(e) => handleInputChange('accomplishments', e.target.value)}
                placeholder="Accomplishments..."
                required
              />
            </FormGroup>

            <FormGroup>
              <Label>Blockers:</Label>
              <TextArea
                value={formData.blockers}
                onChange={(e) => handleInputChange('blockers', e.target.value)}
                placeholder="Blockers..."
              />
            </FormGroup>

            <SubmitButton onClick={handleSubmit}>Save</SubmitButton>
          </FormSection>
        </FormCard>

        <SidePanel>
          <PanelTitle>Your Posted Pod Activity</PanelTitle>
          <ViewButton>
            <NavHashLink to="/Editsavedraft">View</NavHashLink>
          </ViewButton>
        </SidePanel>
      </MainContent>
    </Container>
  );
};

const Container = styled.div`
  padding: 6rem 6.5rem;
  @media (max-width: 1080px) {
    padding: 2rem 1rem;
    width: 100%;
  }
`;

const Title = styled.h4`
  font-weight: 600;
  margin-bottom: 2rem;
`;

const MainContent = styled.div`
  display: flex;
  gap: 2rem;
  
  @media (max-width: 1080px) {
    flex-direction: column;
  }
`;

const FormCard = styled.div`
  background-color: #F2F4F5;
  border-radius: 15px;
  padding: 2rem;
  box-shadow: ${cardShadow};
  flex: 1;
  max-width: 40rem;
  
  &:hover {
    box-shadow: ${hoverEffect};
  }
`;

const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Label = styled.label`
  font-weight: 600;
  color: black;
`;

const Input = styled.input`
  padding: 0.8rem;
  border: 1px solid #cccccc;
  border-radius: 5px;
  font-weight: 600;
  width: 100%;
`;

const Select = styled.select`
  padding: 0.8rem;
  border: 1px solid #cccccc;
  border-radius: 5px;
  font-weight: 600;
  color: grey;
  width: 100%;
`;

const TextArea = styled.textarea`
  padding: 0.8rem;
  border: 1px solid #cccccc;
  border-radius: 5px;
  min-height: 100px;
  width: 100%;
  font-family: inherit;
  font-weight: 600;
`;

const SubmitButton = styled.button`
  background-color: #1DBF73;
  color: white;
  border: none;
  border-radius: 10px;
  padding: 1rem;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  margin-top: 1rem;
`;

const SidePanel = styled.div`
  background-color: #F2F4F5;
  border-radius: 20px;
  padding: 2rem;
  height: fit-content;
  width: 20rem;
  box-shadow: ${cardShadow};
  
  &:hover {
    box-shadow: ${hoverEffect};
  }
  
  @media (max-width: 1080px) {
    width: 100%;
  }
`;

const PanelTitle = styled.h6`
  font-weight: 600;
  margin-bottom: 1rem;
`;

const ViewButton = styled.button`
  width: 8rem;
  font-weight: 600;
  padding: 0.5rem;
  color: black;
  background-color: white;
  border: none;
  border-radius: 5px;
  box-shadow: 1px 3px 6px #00000029;
  cursor: pointer;
  
  a {
    text-decoration: none;
    color: inherit;
  }
`;

export default PodactivityviewalltalentEdit;