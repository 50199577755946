import React, { useState, useEffect } from 'react';
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Menu, MenuItem } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Auth } from "aws-amplify";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { logout } from "./action/auth";
import { DiAptana } from "react-icons/di";
import { useIntl } from 'react-intl';
import { styled } from '@mui/material/styles'; // Use this instead of styled-components

import NotificationDropdown from "./Notificationtalent";
import Notificationmanagericon from "./Notificationmanagericon";
import globllogo from "./assets/Globlai-Logo-Clear.png";
import MobileNavigation from './MobileNavigation';
import ThemeToggle from '../ThemeToggle';
import { useTheme as useMUITheme } from '@mui/material/styles';


// Import the styled utility from MUI

// Use styled with proper syntax for HTML elements
const NavContainer = styled('nav')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  height: '64px',
  backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#f3f3f3',
  marginLeft: 'auto',
  gap: '10px'
}));

const StyledLink = styled('a')(({ theme, isActive }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '64px',
  color: isActive 
    ? '#1DBF73' // Green color for active state
    : (theme.palette.mode === 'dark' ? '#fff' : '#000'), // White in dark mode, black in light mode
  borderBottom: isActive ? "2px solid #1DBF73" : "2px solid transparent",
  textDecoration: isActive ? 'none' : 'none',
  padding: '0 15px',
  transition: 'all 0.2s ease',
  fontSize: '16px',
  whiteSpace: 'nowrap',
  
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(29, 191, 115, 0.08)' : 'rgba(29, 191, 115, 0.08)',
    color: '#1DBF73',
    textDecoration: 'none'
  },

  '&:active': {
    color: '#1DBF73',
    borderBottom: '2px solid #1DBF73'
  }
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#f3f3f3'
}));

const NavigationWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginLeft: 'auto',
  gap: '10px'
}));

const LogoContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginRight: 'auto'
});

const NavSection = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  marginLeft: 'auto',
  padding: '0 15px',

  '@media (max-width: 1200px)': {
    gap: '8px',
    padding: '0 10px'
  }
}));



const StyledTypography = styled(Typography)`
  margin-right: 16px;
  font-family: monospace;
  font-weight: 700;
  letter-spacing: 0.3rem;
  color: ${({ theme }) => theme.palette.mode === 'dark' ? '#fff' : '#000'};
  text-decoration: none;
  margin-left: 15px;
`;





const StyledLogo = styled('img')(({ theme }) => ({
  flex: 1,
  marginLeft: 30,
  width: "13rem",
  height: "3.5rem",
  filter: theme.palette.mode === 'dark' 
    ? 'invert(80%) sepia(40%) saturate(500%) hue-rotate(175deg) brightness(95%) contrast(95%)'
    : 'none',
  transition: 'filter 0.3s ease'
}));
const StyledAppBar = styled(AppBar)`
  background-color: ${({ theme }) => theme.palette.mode === 'dark' ? '#121212' : '#f3f3f3'};
  box-shadow: none;
  color: ${({ theme }) => theme.palette.mode === 'dark' ? '#fff' : '#000'};
  transition: background-color 0.3s ease, color 0.3s ease;
`;

const StyledNav = styled(Box)`
  display: flex;
  align-items: center;
`;

const StyledToolbarContainer = styled(StyledToolbar)`
  background-color: ${({ theme }) => theme.palette.mode === 'dark' ? '#121212' : '#f3f3f3'};
  border-bottom: ${({ theme }) => theme.palette.mode === 'dark' ? '1px solid rgba(255, 255, 255, 0.1)' : 'none'};
`;




// Also update the NavigationItem component to properly handle theme
const NavigationItem = ({ href, label, isActive, marginLeft = "20px" }) => {
  const theme = useMUITheme() || { palette: { mode: 'light' }}; // Provide fallback theme
  return (
    <StyledLink 
      href={href}
      isActive={isActive}
      marginLeft={marginLeft}
      theme={theme}
    >
      {label}
    </StyledLink>
  );
};
const UserNavigation = ({ type, path, intl }) => {
  const navigationConfig = {
    Candidate: [
      { path: "/", label: intl.formatMessage({ id: 'home' }) },
      { path: "/candidate-dashboard", label: intl.formatMessage({ id: 'dashboard' }) },
      { path: "/FindPods", label: intl.formatMessage({ id: 'findPods' }) },
      { component: <ThemeToggle />, isComponent: true },
      { path: "/EditTalentSettingsForm", label: <DiAptana /> }
    ],
    Client: [
      { path: "/", label: intl.formatMessage({ id: 'home' })  },
      { path: "/client-dashboard", label: intl.formatMessage({ id: 'dashboard' }) },
      { path: "/AIAgentSearch", label: intl.formatMessage({ id: 'findAgents' }) },
      { path: "/browse-talent", label: intl.formatMessage({ id: 'findTalent' }) },
      
      { path: "/FindPodsClient", label: intl.formatMessage({ id: 'findPods' }) },
      { path: "/Team", label: intl.formatMessage({ id: 'myPods' }) },
      { path: "/Popup", label: intl.formatMessage({ id: 'invite' }) },
      { component: <ThemeToggle />, isComponent: true },
      { path: "/Setting", label: <DiAptana /> }
    ],
    PartnerGuest: [
      { path: "/", label: intl.formatMessage({ id: 'home' })  },
      { path: "/FindPodsPartnerGuest", label: intl.formatMessage({ id: 'findPods' }) },
      { path: "/FindTalentPartnerGuest", label: intl.formatMessage({ id: 'findTalent' }) },
      { component: <ThemeToggle />, isComponent: true }
    ],
    stakeholder: [
      { path: "/", label: intl.formatMessage({ id: 'home' })  },
      { path: "/StakeholderDashboard", label: intl.formatMessage({ id: 'dashboard' }) },
      { component: <ThemeToggle />, isComponent: true }
    ],
    Provider: [
      { path: "/PartnerSubscription", label: intl.formatMessage({ id: 'subscription' }) },
      { path: "/PartnerInvite", label: intl.formatMessage({ id: 'invite' }) },
      { path: "/PartnerInvoices", label: intl.formatMessage({ id: 'invoices' }) },
      { component: <ThemeToggle />, isComponent: true },
      { path: "/PartnerSettings", label: <DiAptana /> }
    ],
    ClientAdmin: [
      { path: "/subscriptions", label: intl.formatMessage({ id: 'subscription' }) },
      { path: "/Clientinvite", label: intl.formatMessage({ id: 'invite' }) },
      { path: "/InvoicesClient", label: intl.formatMessage({ id: 'invoices' }) },
      { component: <ThemeToggle />, isComponent: true }
    ]
  };

  const items = navigationConfig[type] || [];
  return items.map((item, index) => {
    if (item.isComponent) {
      return (
        <Box key={index} sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
          {item.component}
        </Box>
      );
    }
    return (
      <NavigationItem
        key={index}
        href={item.path}
        label={item.label}
        isActive={path === item.path}
        marginLeft={item.marginLeft}
      />
    );
  });
};

const UserDropdown = ({ username, handleLogOut, userType, intl }) => {
  const theme = useMUITheme();
  
  const getProfileUrl = () => {
    const profileUrls = {
      Candidate: "/Updateyourprofile",
      Client: "/client-profile",
      stakeholder: "/stakeholder-profile",
      PartnerGuest: "/partnerguest-profile",
      Provider: "/provider-profile",
      ClientAdmin: "/costomer-profile"
    };
    return profileUrls[userType] || "/profile";
  };

  const dropdownItemStyle = {
    color: theme.palette.mode === 'dark' ? '#fff' : '#000',
    fontWeight: "bold",
    backgroundColor: 'transparent',
    padding: '8px 16px',
    '&:hover': {
      backgroundColor: theme.palette.mode === 'dark' ? 'rgba(29, 191, 115, 0.1)' : 'rgba(29, 191, 115, 0.1)',
      color: '#1DBF73'
    }
  };

  const toggleStyle = {
    backgroundColor: 'transparent',
    border: 'none',
    boxShadow: 'none',
    color: theme.palette.mode === 'dark' ? '#fff' : '#000',
    '&:hover': {
      color: '#1DBF73'
    }
  };
  return (
    <Dropdown style={{ marginLeft: "20px", borderColor: "transparent" }}>
    <Dropdown.Toggle 
  variant="Secondary" 
  style={toggleStyle}
>
<strong style={{ color: theme.palette.mode === 'dark' ? '#fff' : '#000' }}>Hi, {username}</strong>
      </Dropdown.Toggle>
      <Dropdown.Menu style={{ 
        padding: "8px",
        backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#fff',
        border: `1px solid ${theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'}`,
        borderRadius: '8px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
      }}>
       <Dropdown.Item href={getProfileUrl()} style={dropdownItemStyle}>
          {intl.formatMessage({ id: 'manageProfile' })}
        </Dropdown.Item>
        <Dropdown.Item href="/forgotpassword" style={dropdownItemStyle}>
          {intl.formatMessage({ id: 'resetPassword' })}
        </Dropdown.Item>
        <Dropdown.Item onClick={handleLogOut} style={dropdownItemStyle}>
          {intl.formatMessage({ id: 'logOut' })}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const Header = () => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [username, setUsername] = useState("");
  const theme = useMUITheme();
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const currentPath = window.location.pathname;
  const userType = user?.data?.[0]?.type;

  useEffect(() => {
    let mounted = true;
    
    const fetchUser = async () => {
      try {
        if (mounted && isLoggedIn) {
          const user = await Auth.currentAuthenticatedUser();
          setUsername(user.username);
        }
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    };
    
    if (isLoggedIn) {
      fetchUser();
    }

    return () => {
      mounted = false;
    };
  }, [isLoggedIn]);


  const handleLogOut = async (event) => {
    event.preventDefault();
    try {
      await Auth.signOut();
      await dispatch(logout());
      sessionStorage.setItem("cprofile", "");
      history.push(userType === "ClientAdmin" ? "/admin-login" : "/login");
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const handleOpenNavMenu = (event) => setAnchorElNav(event.currentTarget);
  const handleCloseNavMenu = () => setAnchorElNav(null);

  const renderNotifications = () => {
    if (!isLoggedIn) return null;
    
    return userType === "Client" ? (
      <Box sx={{ color: theme.palette.mode === 'dark' ? '#fff' : '#000' }}>
        <Notificationmanagericon sx={{ color: 'inherit' }} />
      </Box>
    ) : userType === "Candidate" ? (
      <NotificationDropdown />
    ) : null;
  };
  const renderPublicNavigation = () => (
    <NavContainer>
      <NavigationItem 
        href="/" 
        label={intl.formatMessage({ id: 'home' })} 
        isActive={currentPath === "/"} 
        marginLeft="950px"
      />
      <NavigationItem 
        href="/login" 
        label={intl.formatMessage({ id: 'login' })} 
        isActive={currentPath === "/login"}
      />
      <NavigationItem 
        href="/register" 
        label={intl.formatMessage({ id: 'register' })} 
        isActive={currentPath === "/register"}
      />
      <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
        <ThemeToggle />
      </Box>
    </NavContainer>
  );

  return (
    <StyledAppBar position="static">
    <StyledToolbarContainer>
      <LogoContainer>
        <StyledTypography
          variant="h6"
          component="a"
          href="/"
          sx={{
            display: { xs: "none", md: "flex" }
          }}
        >
          <StyledLogo src={globllogo} alt="Globl.ai logo" />
        </StyledTypography>
      </LogoContainer>

      <StyledNav sx={{ flexGrow: 1, display: { xs: "flex", md: "none" }, alignItems: "center" }}>
        <MobileNavigation intl={intl} handleLogOut={handleLogOut} />
        <StyledLogo
          src={globllogo}
          alt="Globl.ai logo"
        />
      </StyledNav>

      <NavSection sx={{ display: { xs: "none", md: "flex" } }}>
        {isLoggedIn ? (
          <NavigationWrapper>
            <UserNavigation type={userType} path={currentPath} intl={intl} />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {renderNotifications()}
            </div>
            <UserDropdown 
              username={username}
              handleLogOut={handleLogOut}
              userType={userType}
              intl={intl}
            />
          </NavigationWrapper>
        ) : (
          <NavigationWrapper>
            {renderPublicNavigation()}
          </NavigationWrapper>
        )}
      </NavSection>
    </StyledToolbarContainer>
  </StyledAppBar>
  );
};



export default Header;