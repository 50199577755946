import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAuthToken } from '../TokenContext';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import {
  Box,
  Card,
  Select,
  MenuItem,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const ChartContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '20px',
  height: '30rem',
  width: '100%',
  padding: '10px',
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.background.paper
}));

const ChartWrapper = styled(Box)(({ theme }) => ({
  height: '25rem',
  width: '100%',
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : 'white',
  color: theme.palette.mode === 'dark' ? theme.palette.text.primary : 'black',
  borderRadius: '8px',
  boxShadow: theme.palette.mode === 'dark' 
    ? '0 4px 8px rgba(255, 255, 255, 0.1)'
    : '0 4px 8px rgba(0, 0, 0, 0.1)',
  padding: '20px',
  boxSizing: 'border-box'
}));

const ChartHeading = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  color: theme.palette.mode === 'dark' ? theme.palette.text.primary : 'black',
  marginBottom: '1rem'
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  marginBottom: '1rem',
  width: '200px',
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : 'white',
  '& .MuiSelect-select': {
    color: theme.palette.mode === 'dark' ? theme.palette.text.primary : 'inherit'
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.23)' : 'rgba(0, 0, 0, 0.23)'
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.23)' : 'rgba(0, 0, 0, 0.23)'
  }
}));

const DetailviewGithublinesofcode = ({ initialPodIds, selectedSprint, sprintStartDate, sprintEndDate }) => {
  const [metrics, setMetrics] = useState(null);
  const [selectedUser, setSelectedUser] = useState('');
  const { user } = useSelector((state) => state.auth);
  const token = useAuthToken();
  const intl = useIntl();

  const fetchData = async () => {
    if (!token) return;
    
    try {
      const response = await fetch(
        user.data[0].type === 'stakeholder' 
          ? process.env.REACT_APP_STAKEHOLDER_CODEMETRICSSTAKEHOLDER
          : process.env.REACT_APP_CLIENT_CODEMETRICSMANAGER,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token,
          },
          body: JSON.stringify({
            ...(user.data[0].type === 'stakeholder' 
              ? { org_code: user.data[0].client_code }
              : { manager_id: user.data[0].emp_id }),
            pod_id: initialPodIds || [],
            sprint_name: selectedSprint || '',
            sprint_start_date: sprintStartDate || '',
            sprint_end_date: sprintEndDate || '',
          }),
        }
      );

      const data = await response.json();
      const responseData = typeof data.body === 'string' ? JSON.parse(data.body) : data.body;
      setMetrics(responseData);
    } catch (error) {
      console.error('Error fetching metrics:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [token, initialPodIds, selectedSprint, sprintStartDate, sprintEndDate]);

  const getBarData = () => {
    if (!metrics?.talent_users) return [];
    
    let users = metrics.talent_users;
    if (selectedUser) {
      users = users.filter(user => user.talent_user === selectedUser);
    }
    
    return users.map(user => ({
      name: user.talent_user,
      lastWeek: user.total_lines_changed_last_week || 0,
      thisWeek: user.total_lines_changed_this_week || 0
    }));
  };

  return (
    <ChartContainer>
      <ChartHeading variant="h6">{intl.formatMessage({ id: 'code' })}</ChartHeading>
      
      <StyledSelect
        value={selectedUser}
        onChange={(e) => setSelectedUser(e.target.value)}
        displayEmpty
        variant="outlined"
        size="small"
      >
        <MenuItem value="">
          {intl.formatMessage({ id: 'allReporters' })}
        </MenuItem>
        {metrics?.talent_users?.map((user) => (
          <MenuItem key={user.talent_user} value={user.talent_user}>
            {user.talent_user}
          </MenuItem>
        ))}
      </StyledSelect>

      <ChartWrapper>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart 
            data={getBarData()} 
            margin={{ top: 5, right: 30, left: 20, bottom: 90 }}
          >
            <CartesianGrid strokeDasharray="3 3" stroke="rgba(0, 0, 0, 0.1)" />
            <XAxis 
              dataKey="name"
              height={80}
              tick={{ 
                fontSize: 12,
                angle: -45,
                textAnchor: 'end',
                fill: 'currentColor'
              }}
              stroke="rgba(0, 0, 0, 0.1)"
              interval={0}
            />
            <YAxis 
              stroke="rgba(0, 0, 0, 0.1)" 
              tick={{ fill: 'currentColor' }} 
            />
            <Tooltip 
              contentStyle={({ theme }) => ({
                backgroundColor: theme?.palette?.mode === 'dark' ? '#424242' : 'white',
                color: theme?.palette?.mode === 'dark' ? '#fff' : 'black',
                border: 'none',
                borderRadius: '4px',
                boxShadow: '0 2px 8px rgba(0,0,0,0.15)'
              })}
            />
            <Legend
              verticalAlign="top"
              height={36}
              wrapperStyle={{ color: 'currentColor' }}
            />
            <Bar 
              dataKey="lastWeek" 
              fill="#4CAF50" 
              name={intl.formatMessage({ id: 'lastWeek' })} 
            />
            <Bar 
              dataKey="thisWeek" 
              fill="#FF5252" 
              name={intl.formatMessage({ id: 'thisWeek' })} 
            />
          </BarChart>
        </ResponsiveContainer>
      </ChartWrapper>
    </ChartContainer>
  );
};

DetailviewGithublinesofcode.propTypes = {
  initialPodIds: PropTypes.array.isRequired,
  selectedSprint: PropTypes.string,
  sprintStartDate: PropTypes.string,
  sprintEndDate: PropTypes.string
};

export default DetailviewGithublinesofcode;