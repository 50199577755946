import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useParams, useHistory } from "react-router-dom";
import { FiSearch } from "react-icons/fi";
import axios from "axios";
import OptionsOutsideSelect from "../OptionsOutsideSelect";
import { useAuthToken } from "../TokenContext";
import allergens from "./allergens";


const EditPostRequirementCand = () => {
  const token = useAuthToken();
  const { id } = useParams();
  const history = useHistory();

  const [formData, setFormData] = useState({
    position: "",
    workhours: "",
    timezone: "",
    budget: "",
  });
  const [selected, setSelected] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const handleInputChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const handleSelectChange = (values) => {
    setSelected(values);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const selectedSkills = selected.map((item) => ({ skill: item.label }));
    const payload = {
      ...formData,
      skills: selectedSkills,
      id,
    };

    try {
      await fetch(process.env.REACT_APP_TALENT_EDITPOSTREQ, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(payload),
      });

      alert("Project Details Added.");
      history.push("/ViewPostedProReq");
    } catch (error) {
      console.error("Failed to update requirement:", error);
      alert("Failed to update project details");
    }
  };

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const response = await axios.post(
          process.env.REACT_APP_TALENT_GETPOSTREQBYID,
          { id },
          {
            headers: {
              Authorization: token,
              "Content-Type": "application/json",
            },
          }
        );

        const data = response.data.data[0];
        const skills = JSON.parse(data.req_skills);

        const selectedSkills = skills
          .map((item) =>
            allergens.find((element) => element.label === item.skill)
          )
          .filter(Boolean);

        setSelected(selectedSkills);
        setFormData({
          position: data.position,
          budget: data.budget,
          workhours: data.workhours,
          timezone: data.req_timezone,
        });
      } catch (error) {
        console.error("Failed to fetch requirement details:", error);
      }
    };

    fetchDetails();
  }, [id, token]);


   const TIMEZONES = [
    { value: 'indian_standard_time', label: 'Indian Standard Time' },
    { value: 'eastern_standard_time', label: 'Eastern Standard Time' },
    { value: 'central_standard_time', label: 'Central Standard Time' },
    { value: 'mountain_standard_time', label: 'Mountain Standard Time' },
    { value: 'pacific_standard_time', label: 'Pacific Standard Time' },
    { value: 'greenwich_mean_time', label: 'Greenwich Mean Time' },
    { value: 'central_european_time', label: 'Central European Time' },
    { value: 'western_european_time', label: 'Western European Time' },
    { value: 'australian_eastern_standard_time', label: 'Australian Eastern Standard Time' },
    { value: 'australian_central_standard_time', label: 'Australian Central Standard Time' },
    { value: 'australian_western_standard_time', label: 'Australian Western Standard Time' },
    { value: 'new_york_time', label: 'New York Time (EDT)' },
    { value: 'los_angeles_time', label: 'Los Angeles Time (PDT)' },
    { value: 'london_time', label: 'London Time (BST)' },
    { value: 'paris_time', label: 'Paris Time (CEST)' },
    { value: 'tokyo_time', label: 'Tokyo Time (JST)' },
    { value: 'sydney_time', label: 'Sydney Time (AEDT)' },
    { value: 'dubai_time', label: 'Dubai Time (GST)' },
    { value: 'moscow_time', label: 'Moscow Time (MST)' },
    { value: 'brazil_time', label: 'Brazil Time (BRT)' },
    { value: 'chicago_time', label: 'Chicago Time (CDT)' },
    { value: 'houston_time', label: 'Houston Time (CDT)' },
    { value: 'cape_town_time', label: 'Cape Town Time (SAST)' }
  ];

  return (
    <Container>
      <Title>Edit Requirement</Title>

      <SearchBar>
        <SearchIcon>
          <FiSearch />
        </SearchIcon>
        <SearchInput
          type="text"
          placeholder="Search for projects"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </SearchBar>

      <MainContent>
        <FormCard>
          <Form onSubmit={handleSubmit}>
            <FormField
              type="text"
              placeholder="Required Position*"
              value={formData.position}
              onChange={(e) => handleInputChange("position", e.target.value)}
              required
            />

            <OptionsOutsideSelect
              onChange={handleSelectChange}
              isMulti
              options={allergens}
              placeholder="Skills"
              value={selected}
              styles={selectStyles}
            />

            <Select
              value={formData.workhours}
              onChange={(e) => handleInputChange("workhours", e.target.value)}
              required
            >
              <option value="">Preferred Work Hours</option>
              <option value="Full Time">Full Time</option>
              <option value="Part Time">Part Time</option>
            </Select>

            <Select
              value={formData.timezone}
              onChange={(e) => handleInputChange("timezone", e.target.value)}
              required
            >
              <option value="">Time Zone</option>
              {TIMEZONES.map((tz) => (
                <option key={tz.value} value={tz.value}>
                  {tz.label}
                </option>
              ))}
            </Select>

            <SubmitButton type="submit">Post</SubmitButton>
          </Form>
        </FormCard>
      </MainContent>
    </Container>
  );
};

const selectStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    height: "45px",
    marginTop: "1rem",
    color: "grey",
    fontWeight: "600",
    paddingLeft: "12px",
  }),
};

const Container = styled.div`
  padding: 6rem 6.5rem;

  @media (max-width: 1080px) {
    padding: 2rem 1rem;
    width: 100%;
  }
`;

const Title = styled.h4`
  font-weight: 600;
  margin-bottom: 2rem;
`;

const SearchBar = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #4e8fae;
  border-radius: 10px;
  width: 25rem;
  margin-bottom: 2rem;

  @media (max-width: 1080px) {
    width: 100%;
    max-width: 18rem;
  }
`;

const SearchIcon = styled.div`
  padding: 0.5rem;
  background-color: #f2f3f4;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
`;

const SearchInput = styled.input`
  flex: 1;
  border: none;
  padding: 0.5rem;
  font-weight: 600;
  background-color: #f2f3f4;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;

  &:focus {
    outline: none;
  }
`;

const MainContent = styled.div`
  display: flex;
  gap: 2rem;

  @media (max-width: 1080px) {
    flex-direction: column;
  }
`;

const FormCard = styled.div`
  background-color: #f2f4f5;
  border-radius: 15px;
  padding: 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 40rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const FormField = styled.input`
  padding: 0.8rem;
  border: 1px solid #cccccc;
  border-radius: 5px;
  font-weight: 600;
  width: 100%;
`;

const Select = styled.select`
  padding: 0.8rem;
  border: 1px solid #cccccc;
  border-radius: 5px;
  font-weight: 600;
  color: grey;
  width: 100%;
`;

const SubmitButton = styled.button`
  background-color: #1dbf73;
  color: white;
  border: none;
  border-radius: 10px;
  padding: 1rem;
  font-size: 18px;
  font-weight: 600;
  margin-top: 2rem;
  cursor: pointer;
`;

export default EditPostRequirementCand;
