// fr/messages.js
const messagesFr = {
  about: 'À propos',
  acceptanceCriteria: 'Critères d\'acceptation',
  accomplishments: 'Réalisations',
  actions: 'Actions',
  activatePod: 'Activer le Pod',
  activePods: 'Pods actifs',
  activity: 'Activité',
  add: 'Ajouter',
  addBlocker: 'Ajouter des Informations de Blocage',
  addingCandidateWait: 'Ajout du candidat en cours...',
  addPortfolio: 'Ajouter un portfolio',
  addStakeholder: 'Ajouter un intervenant',
  addVideoUpdate: 'Ajouter une Mise à Jour Vidéo',
  all: 'Tous',
  allReporters: 'Tous les reporters',
  allRepoCheckins: 'Toutes les validations de dépôt',
  approve: 'Approuver',
  findAgents:"Trouver des agents",
  approved: 'Approuvé',
  approvalRequests: 'Demandes d\'approbation',
  approvedPRCount: 'Nombre de PR approuvés',
  applyFilters: 'Appliquer les filtres',
  assignedItems: 'Éléments attribués',
  availability: 'Disponibilité',
  availableDate: 'Date de disponibilité',
  backToPods: 'Retour à Mes Pods',
  basicInformation: 'Informations de base',
  blocker: 'Obstacle',
  blockerDetails: 'Détails du Blocage',
  blockers: 'Obstacles',
  board: 'Tableau',
  browseTalent: 'Parcourir les talents',
  bugs: 'Bugs',
  calendlyUrl: 'URL Calendly',
  cancel: 'Annuler',
  candidate: 'Candidat',
  candidates: 'Candidats',
  categories: 'Catégories',
  certifications: 'Certifications',
  certificationUrl: 'URL de certification',
  checkBoxBlocker: 'Cochez cette case s\'il s\'agit d\'un bloqueur',
  ciCdPipeline: 'Pipeline CI/CD',
  clear: 'Effacer',
  clickHereUploadVideoClip: 'Cliquez ici pour télécharger un extrait vidéo !',
  client: 'Client',
  cloudCost: 'Coût Cloud',
  code: 'Lignes de Code',
  codeSmells: 'Code Smells',
  commentHere: 'commentaire ici...',
  companyName: 'Nom de l\'entreprise',
  copied: 'Copié !',
  copyright: 'Copyright © 2023-présent Brilli Global, Inc. Tous droits réservés.',
  copyToClipboard: 'Copier dans le presse-papiers',
  cost: 'Coût',
  costDetails: 'Détails du coût',
  country: 'Pays',
  create: 'Créer',
  createArt: 'Créer de l\'ART',
  createGig: 'Créer un projet',
  createPod: 'Créer un POD',
  createProfile: 'Créer un profil',
  createProfileOpportunity: 'Créez votre profil dans notre base de talents pour être inclus dans diverses opportunités de travail à distance.',
  createTask: 'Créer une tâche',
  createYourOwnPod: 'Créez votre propre Pod et parcourez les projets',
  creatingProfile: 'Création de profil',
  currency: 'Devise',
  currentlyAvailable: 'Actuellement disponible',
  currentWeek: 'Semaine en cours',
  dashboard: 'Tableau de bord',
  date: 'Date',
  dateRangeFilter: 'Filtre de plage de dates',
  deactivatePod: 'Désactiver le Pod',
  deactivateToUpdate: 'Désactivez le pod pour le mettre à jour !',
  defects: 'Défauts',
  delete: 'Supprimer',
  deletePod: 'Supprimer le Pod',
  description: 'Description',
  detailsForGitHubRepo: 'Détails du dépôt GitHub',
  done: 'Terminé',
  Done: 'Terminé',
  doraMetrics: 'Métriques Dora',
  dueDate: 'Date limite',
  duplicatedLinesDensity: 'Densité des lignes dupliquées',
  edit: 'Modifier',
  editPod: 'Modifiez votre pod et parcourez les projets',
  education: 'Éducation',
  educationAndCertifications: 'Éducation et Certifications',
  email: 'E-mail',
  endDate: 'Date de fin',
  enterEmailToInvite: 'Entrez une adresse e-mail pour inviter',
  enterPodMission: 'Entrer la mission du Pod',
  enterPodName: 'Entrer le nom du Pod',
  enterStoryDescription: 'Entrer une description de l\'histoire',
  executionDate: 'Date d\'exécution',
  executionID: 'ID d\'exécution',
  externalPod: 'Pod externe',
  fileNameNoSpaces: 'Le nom du fichier ne doit pas inclure d\'espaces entre tous les éléments ou caractères',
  fileUpload: 'Téléchargement de fichier',
  findBestFreelancers: 'Trouvez les meilleurs freelancers ou pods de talents pour obtenir la livraison de vos projets plus rapidement !',
  findBestPod: 'Trouvez des groupes correspondant à votre expérience/ensemble de compétences',
  findPods: 'Trouver des Pods',
  findTalent: 'Trouver du talent',
  findYourBestTalentPod: 'Trouvez votre meilleur POD de talent',
  firstName: 'Prénom',
  focus: 'Focus',
  getStarted: 'Commencer',
  gitHubRepo: 'Dépôt GitHub',
  gitHubUsername: 'Nom d\'utilisateur GitHub',
  github: 'Github',
  githubSummary: 'Résumé GitHub',
  githubUrl: 'URL Github',
  githubUser: 'Utilisateur GitHub',
  giveRating: 'Donner une note',
  globalWorkPlatform: 'Plateforme de travail mondiale',
  home: 'Accueil',
  hoursPerWeek: 'Heures par semaine',
  inactivePod: 'Pod Inactif',
  individual: 'Individuel',
  industry: 'Industrie',
  inProgress: 'En cours',
  institute: 'Institut',
  internalPod: 'Pod interne',
  invite: 'Inviter',
  invoices: 'Factures',
  issueKey: 'Clé du problème',
  issueStatus: 'État du problème',
  issueSummary: 'Résumé du problème',
  jiraIssue: 'Problème Jira',
  jiraIssues: 'Problèmes Jira',
  jiraItem: 'Élément Jira',
  jiraTimeDetails: 'Journal du Temps',
  jiraUsername: 'Nom d\'utilisateur Jira',
  joinPod: 'Rejoindre le Pod',
  keepDoing: 'Continuer à faire',
  keyword: 'Mot-clé',
  languageSpoken: 'Langue parlée',
  lastName: 'Nom de famille',
  latestSprintVelocity: 'Dernière vélocité du sprint',
  linkedIn: 'LinkedIn',
  linkedInUrl: 'URL LinkedIn',
  linesOfCodeByDay: 'Lignes de code par jour',
  listOfTalents: 'Liste des talents',
  loading: 'Chargement',
  loadingAcceptanceCriteria: 'Chargement des critères d\'acceptation',
  loadingStoryDescription: 'Chargement de la description de l\'histoire',
  loadingTestCases: 'Chargement des cas de test',
  logOut: 'Se déconnecter',
  login: 'Connexion',
  manage: 'Gérer',
  manageActivities: 'Gérez vos activités et mises à jour quotidiennes du Pod',
  manageProfile: 'Gérer le profil',
  manageProfileHere: 'Gérez votre profil ici',
  managePod: 'Gérer le Pod',
  managePods: 'Gérez tous vos pods et leurs activités',
  markComplete: 'Marquer comme complet',
  middleName: 'Deuxième prénom',
  myPods: 'Mes Pods',
  myTasks: 'Mes tâches',
  name: 'Nom',
  ncloc: 'Ncloc',
  newActivityUpdate: 'Nouvelle Mise à Jour d\'Activité',
  next: 'Suivant',
  noArtOptionsAvailable: 'Aucune option ART disponible',
  noBlockers: 'Il n\'y a pas de blocage',
  noCandidates: 'Aucun candidat ajouté',
  noCandidatesAddedToPod: 'Aucun candidat ajouté au Pod !',
  noCandidateReviews: 'Il n\'y a pas d\'avis de candidat.',
  noData: 'Aucune donnée',
  noDataAvailable: 'Aucune donnée disponible',
  noExperience: 'Aucune expérience',
  noMatchingPods: 'Aucun pod correspondant disponible',
  noNotifications: 'Il n\'y a pas de notifications',
  noPodActivity: 'Vous n\'avez aucune activité de POD',
  noPods: 'Vous n\'avez aucun Pod',
  noPodTasks: 'Vous n\'avez aucune tâche de Pod',
  noReviews: 'Il n\'y a pas de critiques',
  noStakeholders: 'Il n\'y a pas d\'intervenants pour ce Pod',
  noSubscription: 'Vous n\'avez aucun plan d\'abonnement',
  noVideoAvailable: 'Aucune vidéo disponible',
  notifications: 'Notifications',
  notificationRecipient: 'Destinataire de la notification',
  otherInformation: 'Autres informations',
  pending: 'En attente',
  pendingPods: 'Pods en attente',
  phoneNumber: 'Numéro de téléphone',
  pipelineExecutionDetails: 'Détails de l\'exécution du pipeline',
  pipelineName: 'Nom du pipeline',
  pipelineVersion: 'Version du pipeline',
  pleaseEnterStoryDescription: 'Veuillez saisir une description d\'histoire et cliquer sur soumettre',
  pod: 'Pod',
  podActivities: 'Activités du Pod',
  podActivity: 'Activité du Pod',
  podAnalytics: 'Analytiques de POD',
  podAssist: 'Assistance POD',
  podAutomation: 'Automation de POD',
  podBoard: 'Tableau du Pod',
  podCategories: 'Catégories de Pod',
  podDescription: 'Description du Pod',
  podExperience: 'Expérience de Pod',
  podFeedback: 'Feedback de Pod',
  podMetrics: 'Métriques de Pod',
  podName: 'Nom du Pod',
  podQuery: 'Requête de POD',
  podrate: 'Taux de Reproduction',
  podRequests: 'DEMANDES DE POD',
  podRetro: 'Rétrospective du Pod',
  podSize: 'Taille du Pod',
  podTask: 'Tâche du Pod',
  podTasks: 'Tâches du Pod',
  podTaskDescription: 'Description de la tâche de Pod',
  podTaskDueDate: 'Date d\'échéance de la tâche de Pod',
  podTaskName: 'Nom de la tâche de Pod',
  podTaskOwner: 'Propriétaire de la tâche de Pod',
  podTaskStatus: 'Statut de la tâche de Pod',
  podTechStack: 'Tech Stack du Pod',
  PodTeamMember: 'un membre de l\'équipe du POD',
  podType: 'Type de Pod',
  portfolio: 'Portfolio',
  portfolioUrl: 'URL du portfolio',
  post: 'Publier',
  postActivity: 'Publier une Activité',
  postPodActivity: 'Publier l\'activité du Pod',
  postRequirement: 'Publier une exigence',
  posting: 'Publication en cours...',
  postedDate: 'Date de publication',
  postedRequirements: 'Vos exigences Pod publiées',
  positionName: 'Nom du poste',
  prCount: 'Nombre de PR',
  preferredWorkHours: 'Heures de travail préférées',
  preferredWorkHoursMonthly: 'Heures de travail préférées /mois',
  prev: 'Précédent',
  previousPods: 'Pods précédents',
  previousWeek: 'Semaine précédente',
  primarySkill: 'Compétence principale',
  privacyPolicy: 'Politique de confidentialité',
  privatePods: 'Pods privés',
  prSummary: 'Résumé des PR',
  projectKey: 'Clé du projet',
  profileLink: 'Lien du Profil',
  profileVisibility: 'Visibilité du Profil',
  public: 'Public',
  qualification: 'Qualification',
  ratePerHour: 'Taux /heure',
  ratePerMonth: 'Taux /mois',
  recentSearches: 'Recherches récentes',
  region: 'Région',
  Register: 'S\'inscrire',
  resetPassword: 'Réinitialiser le mot de passe',
  restricted: 'Restreint',
  retro: 'Rétro',
  reviewAndRating: 'Évaluation & Note',
  revisionSummary: 'Résumé de la révision',
  revisionURL: 'URL de la révision',
  resume: 'Curriculum vitae',
  resumeLink: 'Voir le CV',
  rowsPerPage: 'Lignes par page',
  save: 'Enregistrer',
  search: 'Rechercher',
  searchProjects: 'Rechercher des projets',
  secondarySkill: 'Compétence secondaire',
  select: 'Sélectionner',
  selectArtName: 'Sélectionnez le nom de l\'ART',
  selectAUser: 'Sélectionner un utilisateur',
  selectFilter: 'Sélectionner un filtre',
  selectItemsPerPage: 'Sélectionner les éléments par page',
  selectJiraIssues: 'Sélectionner les Problèmes Jira',
  selectPod: 'Sélectionnez un Pod',
  selectPodTeamMember: 'Sélectionnez un membre de l\'équipe du POD',
  selectPods: 'Sélectionner des PODs',
  services: 'Services',
  setting: 'Paramètre',
  shareableLink: 'Lien Partageable',
  signUpToGetStarted: '"Inscrivez-vous" ci-dessous pour commencer!',
  skills: 'Compétences',
  skillsAndExpertise: 'Compétences et Expertise',
  sonarqubeprojectkey: 'Clé du Projet Sonarqube',
  sonarqubeStats: 'Statistiques Sonarqube',
  specialization: 'Spécialisation',
  sprintVelocity: 'Vélocité de sprint',
  sprint: 'Sprint',
  spotsLeft: 'Places disponibles',
  startDate: 'Date de début',
  startDoing: 'Commencer à faire',
  status: 'Statut',
  stopDoing: 'Arrêter de faire',
  storyDescription: 'Description de l\'histoire',
  stories: 'Histoires',
  submit: 'Soumettre',
  submittedDate: 'Date de soumission',
  submittedPRCount: 'Nombre de PR soumis',
  subscription: 'Abonnement',
  talentApprovalRequests: 'Demandes d\'approbation de talent',
  talentCode: 'Code Talent',
  talent: 'Talent',
  taskForm: 'Formulaire de tâche',
  taskManager: 'Gestionnaire de tâches',
  taskName: 'Nom de la tâche',
  tasks: 'Tâches',
  teamMember: 'Membre de l\'équipe',
  techStack: 'TechStack',
  termsOfService: 'Conditions d\'utilisation',
  testCases: 'Cas de test',
  time: 'Temps',
  timeLog: 'Journal du temps',
  timeSubmit: 'Soumettre le Temps',
  timeZone: 'Fuseau horaire',
  toDo: 'À faire',
  todaysFocus: 'Focus du Jour',
  totalLinesChangedLastWeek: 'Total des lignes modifiées la semaine dernière',
  totalLinesChangedThisWeek: 'Total des lignes modifiées cette semaine',
  totalPRCount: 'Nombre total de PR',
  university: 'Université',
  update: 'Mettre à jour',
  updateProfileHere: 'Mettez à jour votre profil ici',
  updatingVisibility: 'Mise à jour de la visibilité...',
  upload: 'Télécharger',
  userStory: 'Récit utilisateur',
  validFrom: 'Valide à partir de',
  validTill: 'Valide jusqu\'à',
  videoUrl: 'URL de la vidéo',
  view: 'Voir',
  viewActivities: 'Voir les Activités',
  viewActivity: 'Voir l\'activité',
  viewDetails: 'Voir les détails',
  viewPodActivities: 'Voir les activités du Pod',
  viewPodDetails: 'Voir les détails du Pod',
  viewProfile: 'Voir le profil',
  viewTasks: 'Voir les tâches',
  viewTimeEntries: 'Voir les entrées de temps',
  viewVideo: 'Voir la vidéo',
  visitFindTalent: 'Veuillez visiter la page \'Trouver des talents\' et ajouter des individus talentueux à votre pod.',
  vulnerabilities: 'Vulnérabilités',
  welcome: 'Bienvenue sur Globl.ai!',
  workExperience: 'Expérience professionnelle',
  yourPod: 'Votre Pod',
  yourPods: 'Vos Pods',
  podTime: "Temps du POD",
  podsync:"Synchronisation POD",
  podVelocity: "Vélocité du POD",
   podBlockers: "Bloqueurs du POD",
  yourPostedPodActivity: 'Votre activité de Pod publiée'
};
export default messagesFr;
