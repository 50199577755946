import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  IconButton,
  Badge,
  Popover,
  List,
  ListItem,
  ListItemText,
  Typography,
  Button,
  Box
} from '@mui/material';
import { NotificationsNone } from '@mui/icons-material';
import { useAuthToken } from "../TokenContext";
import moment from 'moment';

// Constants
const API_ENDPOINTS = {
  NOTIFICATIONS: process.env.REACT_APP_TALENT_CANDIDATENOTIFICATIONS,
  MARK_READ: process.env.REACT_APP_TALENT_NOTIFICATIONREAD
};

// Notification Item Component
const NotificationItem = ({ notification, onMarkAsRead, isRemoved }) => {
  const handleClick = (e) => {
    onMarkAsRead(notification.id);
    e.target.classList.add('clicked');
    setTimeout(() => e.target.classList.remove('clicked'), 300);
  };

  return (
    <ListItem
      sx={{
        transition: 'all 0.3s ease-in-out',
        transform: isRemoved ? 'translateX(100%)' : 'translateX(0)',
        opacity: isRemoved ? 0 : 1,
        maxHeight: isRemoved ? 0 : 'auto',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 1
      }}
    >
      <ListItemText
        primary={
          <Typography
            variant="body1"
            sx={{
              color: 'text.primary',
              overflowWrap: 'break-word',
              wordWrap: 'break-word'
            }}
          >
            {notification.message}
          </Typography>
        }
        secondary={
          <Typography variant="caption" color="text.secondary">
            {notification.timeAgo}
          </Typography>
        }
      />
      <Button
        variant="contained"
        size="small"
        sx={{
          bgcolor: '#90E0EF',
          '&:hover': {
            bgcolor: '#4a90e2'
          }
        }}
        onClick={handleClick}
      >
        Read
      </Button>
    </ListItem>
  );
};

const NotificationDropdown = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [removedIds, setRemovedIds] = useState([]);
  const { user } = useSelector((state) => state.auth);
  const token = useAuthToken();

  const fetchNotifications = useCallback(async () => {
    if (!token || !user?.data?.[0]?.c_id) {
      console.log('Token or user data is missing');
      return;
    }

    try {
      const response = await fetch(API_ENDPOINTS.NOTIFICATIONS, {
        method: 'POST',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ candidate_id: user.data[0].c_id }),
      });

      const { data } = await response.json();
      const processedNotifications = data
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        .map(notification => ({
          ...notification,
          timeAgo: moment(notification.createdAt).fromNow(),
        }));

      setNotifications(processedNotifications);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  }, [token, user]);

  useEffect(() => {
    fetchNotifications();
  }, [fetchNotifications]);

  const handleMarkAsRead = async (notificationId) => {
    try {
      const response = await fetch(API_ENDPOINTS.MARK_READ, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ notification_ids: [notificationId] }),
      });

      if (response.ok) {
        await fetchNotifications();
        setRemovedIds(prev => [...prev, notificationId]);
      }
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box>
      <IconButton onClick={handleClick} sx={{ color: 'black', ml: 1 }}>
        <Badge badgeContent={notifications.length} color="error">
          <NotificationsNone />
        </Badge>
      </IconButton>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <List sx={{ width: 300, maxHeight: 400, overflow: 'auto' }}>
          {notifications.length > 0 ? (
            notifications.map((notification) => (
              <NotificationItem
                key={notification.id}
                notification={notification}
                onMarkAsRead={handleMarkAsRead}
                isRemoved={removedIds.includes(notification.id)}
              />
            ))
          ) : (
            <ListItem>
              <ListItemText
                primary={
                  <Typography align="center">No new notifications</Typography>
                }
              />
            </ListItem>
          )}
        </List>
      </Popover>
    </Box>
  );
};

export default NotificationDropdown;