import React, { useEffect, useState } from 'react';
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { FiDownload } from 'react-icons/fi';

import { GrFormEdit,GrFilter } from 'react-icons/gr';
import { NavHashLink } from 'react-router-hash-link';
import moment from 'moment';
import { useAuthToken } from "../TokenContext";
import styled from "styled-components";
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {jsPDF} from 'jspdf';

const ViewTimeSubmit = ({ Podid }) => {
  const [records, setRecords] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [defaultDate, _setDefaultDate] = useState(moment().format("MM-DD-YYYY"));
  const token = useAuthToken();
  const [sortBy, setSortBy] = useState('asc');
  const [filterExpanded, setFilterExpanded] = useState(false);
  const [applyFilter, setApplyFilter] = useState(false);

  const [isOpen, setIsOpen] = useState(false);


  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  const [initialLoad, setInitialLoad] = useState(false);

  const handleFilterIconClick = () => {
    setFilterExpanded((prevState) => !prevState);
  };

  useEffect(() => {
    fetchData();
  }, [Podid,token, applyFilter]); // Update the dependency array to include applyFilter

  const fetchData = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_CLIENT_GETJIRAISSUESTIMELOGS, {
        method: 'POST',
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ pod_id: Podid || null }),
      });
  
      const result = await response.json();
  
      if (result.status === 200) {
        setRecords(result.data);
        setInitialLoad(true); // Set initialLoad to true after loading data
      } else {
        console.error('Error fetching data:', result);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  

  const handleCheckboxChange = (event, id) => {
    if (event.target.checked) {
      setSelectedIds((prevIds) => [...prevIds, id]);
    } else {
      setSelectedIds((prevIds) => prevIds.filter((prevId) => prevId !== id));
    }
  };

  const handleSubmit = () => {
    // Handle form submission (Approve button click)
    // Implement your logic here
  };

  // Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = records.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleChangeItemsPerPage = (event) => {
    setItemsPerPage(event.target.value);
    setCurrentPage(1); // Reset to the first page when changing items per page
  };
  const handleSort = (column) => {
    setSortBy((prevSort) => (prevSort === 'asc' ? 'desc' : 'asc'));
    setRecords((prevRecords) => sortRecords(prevRecords, column, sortBy));
  };

  useEffect(() => {
    if (initialLoad && applyFilter) {
      // Filter records only after the initial data load
      setRecords(filterRecordsByDate(records));
    }
  }, [dateRange]); // Update records when dateRange changes

  const handleDateRangeChange = (ranges) => {
    setDateRange([ranges.selection]);
    setApplyFilter(true); // Apply filter when date range changes
  };
  const filterRecordsByDate = (data) => {
    // Filter records based on selected date range
    const filteredData = data.filter((record) => {
      const workedDate = moment(record.workeddate);
      const startDate = moment(dateRange[0].startDate).startOf('day');
      const endDate = moment(dateRange[0].endDate).endOf('day');
      return workedDate.isBetween(startDate, endDate, null, '[]');
    });
    return filteredData;
  };

  const sortRecords = (data, column, order) => {
    return data.sort((a, b) => {
      const aValue = moment(a[column]);
      const bValue = moment(b[column]);
  
      if (order === 'asc') {
        return aValue - bValue;
      } else {
        return bValue - aValue;
      }
    });
  };
  
  
  const handleDownload = async (format) => {
    if (format === 'csv') {
        // Convert records to downloadable format, for example, CSV
        let csvContent = "data:text/csv;charset=utf-8," +
            "Posted Date, Pod Team Member, Category, Sprint Name, Jira Issue ID, Jira Summary, Hrs/Min\n";
        
        records.forEach((item) => {
            const row = `${moment(item.workeddate).format("MM-DD-YYYY") || ''},${item.candidate_name || ''},${item.category || ''},${item.sprint_name || ''},${item.jira_issue_id || ''},${item.jira_summary || ''},${item.time || ''}\n`;
            csvContent += row;
        });

        // Create a temporary link element and trigger the download
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "time_entries.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } else if (format === 'pdf') {
        // If PDF format is requested
        if (records.length > 0) {
            const doc = new jsPDF();
            let yPos = 10; // Initial y position
            records.forEach(item => {
                const activityString = `${moment(item.workeddate).format("MM-DD-YYYY") || ''}, ${item.candidate_name || ''}, ${item.category || ''}, ${item.sprint_name || ''}, ${item.jira_issue_id || ''}, ${item.jira_summary || ''}, ${item.time || ''}`;
                doc.text(activityString, 10, yPos);
                yPos += 10; // Increment y position for next activity
                if (yPos >= 280) {
                    doc.addPage();
                    yPos = 10;
                }
            });
            doc.save('time_entries.pdf');
        } else {
            console.error('No time entries to generate PDF.');
        }
    } else if (format === 'docx') {
        // If DOCX format is requested
        if (records.length > 0) {
            let docContent = "<html><body><table border='1'>";
            docContent += "<tr><th>Posted Date</th><th>Pod Team Member</th><th>Category</th><th>Sprint Name</th><th>Jira Issue ID</th><th>Jira Summary</th><th>Hrs/Min</th></tr>";

            records.forEach(item => {
                docContent += `<tr><td>${moment(item.workeddate).format("MM-DD-YYYY") || ''}</td><td>${item.candidate_name || ''}</td><td>${item.category || ''}</td><td>${item.sprint_name || ''}</td><td>${item.jira_issue_id || ''}</td><td>${item.jira_summary || ''}</td><td>${item.time || ''}</td></tr>`;
            });

            docContent += "</table></body></html>";

            const blob = new Blob([docContent], { type: 'application/msword' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'time_entries.doc');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            console.error('No time entries to generate Word document.');
        }
    } else {
        console.error('Invalid format specified.');
    }
};


const toggleDropdown = () => {
  setIsOpen(!isOpen);
};

const handleSelection = (format) => {
  handleDownload(format);
  setIsOpen(false); // Close the dropdown after selection
};
  
  

  return (
    <Div1>
      <div style={{display: "flex", marginRight: "auto"}}>
  <button 
    type="button" 
    onClick={handleFilterIconClick} 
    onKeyDown={(e) => (e.key === 'Enter' || e.key === ' ') && handleFilterIconClick()} 
    aria-label="Filter"
    style={{border: 'none', background: 'none', padding: 0, width: '100%', height: '100%'}}
  >
  </button>
       <GrFilter title='Date Range Filter' cursor='pointer'  style={{width:"25px",height:"25px"}}  />
      {filterExpanded ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
    </div>
    <div style={{display:"flex",marginLeft:"auto",width:'15rem' }}>
     <div style={{ position: 'relative' }}>
      <FiDownload
        cursor="pointer"
        style={{ width: '25px', height: '25px' }}
        title="Download Activities"
        onClick={toggleDropdown}
      />
      {isOpen && (
        <div
          style={{
            position: 'absolute',
            top: '100%',
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: '1',
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            borderRadius: '4px',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            marginTop: '5px',
          }}
        >
          <select style={{width:'15rem',border:'none',textDecoration:'none'}} onChange={(e) => handleSelection(e.target.value)}>
            <option value="">Download as...</option>
            <option value="csv">CSV</option>
            <option value="pdf">PDF</option>
            <option value="docx">Word</option>
          </select>
        </div>
      )}
    </div>
 
</div>

    {filterExpanded && (
      <DateRangePicker
        onChange={handleDateRangeChange}
        ranges={dateRange}
        direction="horizontal"
        rangeColors={["#00B4D8"]}
      />
    )}
       
      <TableContainer component={Paper} sx={{ marginTop: '2rem' }}>
        <Table component={Paper} sx={{ maxWidth: 1000,  fontFamily: 'Poppins,Medium', borderRadius: '10px' }} aria-label="simple table">
          <TableHead sx={{ backgroundColor: '#00B4D8', '& td, & th': { fontFamily: 'Poppins,Medium', fontSize: '15px', color: 'white', fontWeight: '600' } }}>
            <TableRow>
            <TableCell align="right" onClick={() => handleSort('workeddate')}>
  Posted Date {sortBy === 'asc' ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
</TableCell>
              <TableCell align="right">Pod Team Member</TableCell>
              <TableCell align="right" sx={{ fontWeight: 'bold' }}>Category</TableCell>
              <TableCell align="right" sx={{ fontWeight: 'bold' }}>sprint_name</TableCell>
              <TableCell align="right" sx={{ fontWeight: 'bold' }}>jira_issue_id</TableCell>
              <TableCell align="right" sx={{ fontWeight: 'bold' }}>jira_summary</TableCell>
              <TableCell align="right" sx={{ fontWeight: 'bold' }}>Hrs/Min</TableCell>
            </TableRow>
          </TableHead>

          {/* records */}
          {currentItems && currentItems.length > 0 ?
            currentItems.map((item, i) => (
              <TableBody  >
                <TableRow
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0, fontFamily: 'Poppins,Medium', fontSize: '15px' },
                    '&:nth-child(even)': { backgroundColor: '#90E0EF' },
                    '&:nth-child(odd)': { backgroundColor: '#caf0f8' },
                    '& td, & th': { fontFamily: 'Poppins,Medium', fontSize: '15px', color: 'black',fontWeight:"600" },
                  }}
                  key={i}
                >
                  <TableCell align="right">{moment(item.workeddate).format("MM-DD-YYYY") || defaultDate}</TableCell>

                  <TableCell align="right">{item.candidate_name} </TableCell>
                  <TableCell align="right">{item.category} </TableCell>
                  <TableCell align="right"> {item.sprint_name}</TableCell>
                  <TableCell align="right"> {item.jira_issue_id}</TableCell>
                  <TableCell align="right"> {item.jira_summary}</TableCell>
                  <TableCell align="right"> {item.time}</TableCell>
                </TableRow>
              </TableBody>
            )) : (
              <>There are no Time Entries. </>
            )}
        </Table>
        <Div>
        {records.length > itemsPerPage && (
          <div>
            <Button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>Previous</Button>
            {Array.from({ length: Math.ceil(records.length / itemsPerPage) }, (_, index) => (
              <Button key={index} onClick={() => paginate(index + 1)} disabled={currentPage === index + 1}>{index + 1}</Button>
            ))}
            <Button onClick={() => paginate(currentPage + 1)} disabled={currentPage === Math.ceil(records.length / itemsPerPage)}>Next</Button>
          </div>
        )}
      </Div>
      <div>
        <FormControl>
          <InputLabel id="items-per-page-label">Rows per page</InputLabel>
          <Select
            labelId="items-per-page-label"
            id="items-per-page"
            value={itemsPerPage}
            onChange={handleChangeItemsPerPage}
          >
             <MenuItem value='' disabled> Select items per page</MenuItem>
            <MenuItem value={5}>5 Rows</MenuItem>
            <MenuItem value={10}>10 Rows</MenuItem>
            <MenuItem value={20}>20 Rows</MenuItem>
            <MenuItem value={30}>30 Rows</MenuItem>
            <MenuItem value={40}>40 Rows</MenuItem>
            <MenuItem value={50}>50 Rows</MenuItem>
          </Select>
        </FormControl>
      </div>
      </TableContainer>

      {/* Pagination */}
      
    </Div1>
  );
};


export default ViewTimeSubmit;
const Div = styled.div`
display:flex;
margin:auto;
justify-content: center;
align-items:center;
width: 25rem;

@media screen and (min-width:320px) and (max-width:1080px){
  width: 18rem;
}
`;

const Div1 = styled.div`
  display: flex;

  jutify-content: center;
  align-items: center;
width:80%;
  flex-direction: column;
  margin: auto;
  margin-top: 6rem;

  
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 25rem;
  }
`;