import React, { Component } from 'react';
import FormErrors from "./FormErrors";
import Validate from "./utility/FormValidation";
import axios from 'axios';
import './css/register.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Select from 'react-select';
import { Link } from "react-router-dom";
import { useAuthToken } from "../TokenContext";

import MultiSelect from "react-multi-select-component";

const remoteEngineerPreference = [
  { value: 'usa', label: 'USA' },
  { value: 'canada', label: 'Canada' },
  { value: 'india', label: 'India' },
  { value: 'australia', label: 'Australia' },
  { value: 'united_kingdom', label: 'United Kingdom' },
  { value: 'germany', label: 'Germany' },
  { value: 'france', label: 'France' },
  { value: 'china', label: 'China' },
  { value: 'japan', label: 'Japan' },
  { value: 'brazil', label: 'Brazil' },
  { value: 'south_africa', label: 'South Africa' },
  { value: 'mexico', label: 'Mexico' },
  { value: 'italy', label: 'Italy' },
  { value: 'spain', label: 'Spain' },
  { value: 'russia', label: 'Russia' },
  { value: 'south_korea', label: 'South Korea' },
  { value: 'new_zealand', label: 'New Zealand' },
  { value: 'singapore', label: 'Singapore' },
  { value: 'united_arab_emirates', label: 'United Arab Emirates' },
  { value: 'saudi_arabia', label: 'Saudi Arabia' },
  { value: 'argentina', label: 'Argentina' },
  { value: 'netherlands', label: 'Netherlands' },
  { value: 'sweden', label: 'Sweden' },
  { value: 'switzerland', label: 'Switzerland' },
  { value: 'norway', label: 'Norway' },
  { value: 'denmark', label: 'Denmark' },
  { value: 'finland', label: 'Finland' },
  { value: 'portugal', label: 'Portugal' },
  { value: 'poland', label: 'Poland' },
  { value: 'turkey', label: 'Turkey' },
  { value: 'indonesia', label: 'Indonesia' },
  { value: 'malaysia', label: 'Malaysia' },
  { value: 'thailand', label: 'Thailand' },
  { value: 'vietnam', label: 'Vietnam' },
  { value: 'philippines', label: 'Philippines' },
  { value: 'egypt', label: 'Egypt' },
  { value: 'nigeria', label: 'Nigeria' },
  { value: 'kenya', label: 'Kenya' },
  { value: 'ghana', label: 'Ghana' },
  { value: 'colombia', label: 'Colombia' },
  { value: 'chile', label: 'Chile' },
  { value: 'peru', label: 'Peru' },
  { value: 'venezuela', label: 'Venezuela' },
  { value: 'pakistan', label: 'Pakistan' },
  { value: 'bangladesh', label: 'Bangladesh' },
  { value: 'sri_lanka', label: 'Sri Lanka' },
  { value: 'nepal', label: 'Nepal' },
  { value: 'bhutan', label: 'Bhutan' },
  { value: 'maldives', label: 'Maldives' },
  { value: 'iceland', label: 'Iceland' },
  { value: 'luxembourg', label: 'Luxembourg' },
  { value: 'qatar', label: 'Qatar' },
  { value: 'oman', label: 'Oman' },
  { value: 'kuwait', label: 'Kuwait' },
  { value: 'bahrain', label: 'Bahrain' },
  { value: 'iraq', label: 'Iraq' },
  { value: 'iran', label: 'Iran' },
  { value: 'afghanistan', label: 'Afghanistan' },
  { value: 'israel', label: 'Israel' },
  { value: 'jordan', label: 'Jordan' },
  { value: 'lebanon', label: 'Lebanon' },
  { value: 'morocco', label: 'Morocco' },
  { value: 'algeria', label: 'Algeria' },
  { value: 'tunisia', label: 'Tunisia' },
  { value: 'libya', label: 'Libya' },
  { value: 'ethiopia', label: 'Ethiopia' },
  { value: 'tanzania', label: 'Tanzania' },
  { value: 'uganda', label: 'Uganda' },
  { value: 'zimbabwe', label: 'Zimbabwe' },
  { value: 'zambia', label: 'Zambia' },
  { value: 'mozambique', label: 'Mozambique' },
  { value: 'botswana', label: 'Botswana' },
  { value: 'namibia', label: 'Namibia' },
  { value: 'angola', label: 'Angola' },
  { value: 'cuba', label: 'Cuba' },
  { value: 'jamaica', label: 'Jamaica' },
  { value: 'haiti', label: 'Haiti' },
  { value: 'dominican_republic', label: 'Dominican Republic' },
  { value: 'trinidad_and_tobago', label: 'Trinidad and Tobago' },
  { value: 'bahamas', label: 'Bahamas' },
  { value: 'barbados', label: 'Barbados' },
  { value: 'panama', label: 'Panama' },
  { value: 'costa_rica', label: 'Costa Rica' },
  { value: 'honduras', label: 'Honduras' },
  { value: 'guatemala', label: 'Guatemala' },
  { value: 'el_salvador', label: 'El Salvador' },
  { value: 'nicaragua', label: 'Nicaragua' },
  { value: 'belize', label: 'Belize' },
  { value: 'paraguay', label: 'Paraguay' },
  { value: 'uruguay', label: 'Uruguay' },
  { value: 'bolivia', label: 'Bolivia' },
  { value: 'ecuador', label: 'Ecuador' },
  { value: 'guinea', label: 'Guinea' },
  { value: 'ivory_coast', label: 'Ivory Coast' },
  { value: 'cameroon', label: 'Cameroon' },
  { value: 'senegal', label: 'Senegal' },
  { value: 'sudan', label: 'Sudan' },
  { value: 'south_sudan', label: 'South Sudan' },
  { value: 'congo', label: 'Congo' },
  { value: 'madagascar', label: 'Madagascar' },
  { value: 'malawi', label: 'Malawi' },
  { value: 'sierra_leone', label: 'Sierra Leone' },
  { value: 'chad', label: 'Chad' },
  { value: 'mali', label: 'Mali' },
  { value: 'burkina_faso', label: 'Burkina Faso' },
  { value: 'rwanda', label: 'Rwanda' },
  { value: 'burundi', label: 'Burundi' },
  { value: 'lesotho', label: 'Lesotho' },
  { value: 'eswatini', label: 'Eswatini' }

];

const budget = [
  { value: '10000', label: '< $10,000' },
  { value: '25000', label: '$10000 - $25000' },
  { value: '50000', label: '$25000 - $50000' },
  { value: '50000+', label: '> $50000' }
];

const frontEndSkills = [
  { value: 'React', label: 'React' },
  { value: 'Angular', label: 'Angular' },
  { value: 'Vue', label: 'Vue' },
  { value: 'HTML5', label: 'HTML5' },
  { value: 'Bootstrap', label: 'Bootstrap' },
  { value: 'CSS', label: 'CSS' }
];

const backEndSkills = [
  { value: 'Node', label: 'Node' },
  { value: 'Java', label: 'Java' },
  { value: 'Python', label: 'Python' },
  { value: 'C++', label: 'C++' },
  { value: 'C', label: 'C' },
  { value: 'AWS', label: 'AWS' }
];

const mobileSkills = [
  { value: 'Kotlin', label: 'Kotlin' },
  { value: 'Java', label: 'Java' },
  { value: 'Python', label: 'Python' },
  { value: 'C#', label: 'C#' }
];

const industryOptions = [
  { value: 'Healthcare', label: 'Healthcare' },
  { value: 'Banking', label: 'Banking and Financial Services' },
  { value: 'Retail', label: 'Retail' },
  { value: 'Travel', label: 'Travel' }
]


class Register extends Component {
  
  state = {
    projectObjective: "",
    frontendTechSkills: "",
    backendTechSkills: "",
    mobileTechSkills: "",
    remoteEngPref: "",
    budgetRange: "",
    industry: "",
    errors: {
      blankfield: false
    } 
  }

  //for select option
  handleChangeremotepref = event => {
    this.setState({ remoteEngPref : event.value });
  };

  handleChangebudget = event => {
    this.setState({ budgetRange : event.value });
  };

  handleChangefrontskill = event => {
    this.setState({ frontendTechSkills : event.value });
  };

  handleChangebkndskill = event => {
    this.setState({ backendTechSkills : event.value });
  };

  handleChangemobiletechskill = event => {
    this.setState({ mobileTechSkills :  event.value});
  };

  handleChangeindustry = event => {
    this.setState({ industryOptions :  event.value});
  };


  

  

  //for clear error
  clearErrorState = () => {
    this.setState({
      errors: {
        blankfield: false
      }
    });
  }

  handleSubmit = async event => {
    const token = useAuthToken();

    event.preventDefault();
    const headers = {
      'Authorization' : token, // Replace with your actual token
      'Content-Type': 'application/json', // You can add more headers as needed
    };
    // Form validation
    this.clearErrorState();
    const error = Validate(event, this.state);
    if (error) {
      this.setState({
        errors: { ...this.state.errors, ...error }
      });
    }

    const { projectObjective, frontendTechSkills, backendTechSkills, mobileTechSkills, remoteEngPref, budgetRange  } = this.state;

    // Database insert here
    if (projectObjective  && remoteEngPref) {
      // https://lt8n4qcae3.execute-api.us-east-1.amazonaws.com/dev/employerProjectRequirements
      axios.post(process.env.REACT_APP_CLIENT_EMPLOYERPROJECTREQUIREMENTS, 
        {ProjectObjective: projectObjective, BackendSkills: backendTechSkills, FrontendSkills:frontendTechSkills, MobileTechSkills: mobileTechSkills, RemoteEngPrefernce: remoteEngPref, ProjectBudgetRange: budgetRange },{headers:headers}).then(res => {
         
          window.alert("New Requirement added successfully");
          this.props.history.push("/employerhome");
      }, (error) => {
          console.log(error);
      });
  }
  };

  onInputChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
    document.getElementById(event.target.id).classList.remove("is-danger");
  }

  render() {

    const { remoteEngPref } = this.state;
    const { budgetRange } = this.state;
    const { frontendTechSkills } = this.state;
    const { backendTechSkills } = this.state;
    const { mobileTechSkills } = this.state;
    const { industry } = this.state;

    return (
      <section className="section">
        <div className="container centeritem">
          <h1>Add a Requirement</h1>
          <FormErrors formerrors={this.state.errors} />
          <form onSubmit={this.handleSubmit}>

              <div className="field">
                <p className="control">
                  <textarea
                  className="input" 
                  id="projectObjective"
                  placeholder="Project Objective"
                  value={this.state.projectObjective}
                  onChange={this.onInputChange}
                  />
                </p>
              </div>

              {/* Multiselect Dropdown Starts Here */}


              {/* For Front end Skills */}
              <div className="field">
                <p className="control">
                <Select
                  value={frontendTechSkills}
                  onChange={this.handleChangefrontskill}
                  options={frontEndSkills}
                  id="frontEndSkills"
                  placeholder="Front-end Skills"
                  isMulti="true"
                  />
                </p>
              </div>
              {/* For Back end Skills */}
              <div className="field">
                <p className="control">
                <Select
                  value={backendTechSkills}
                  onChange={this.handleChangebkndskill}
                  options={backEndSkills}
                  id="backEndSkills"
                  placeholder="Back-end Skills"
                  isMulti="true"
                  />
                </p>
              </div>
              {/* For Mobile tech end Skills */}
              <div className="field">
                <p className="control">
                <Select
                  value={mobileTechSkills}
                  onChange={this.handleChangemobiletechskill}
                  options={mobileSkills}
                  id="mobileSkills"
                  placeholder="Mobile Skills"
                  isMulti="true"
                  />
                </p>
              </div>

              {/* Select option starts here */}

              {/* For Remote Eng Pref */}
              <div className="field">
                <p className="control">
                <Select
                  value={remoteEngPref}
                  onChange={this.handleChangeremotepref}
                  options={remoteEngineerPreference}
                  id="remoteEngModel"
                  placeholder="Remote Eng Pref"
                  />
                </p>
              </div>

              {/* For Budget Range */}
              <div className="field">
                <p className="control">
                <Select
                  value={budgetRange}
                  onChange={this.handleChangebudget}
                  options={budget}
                  id="budgetRange"
                  placeholder="Budget Range"
                  />
                </p>
              </div>

              {/* For Industry */}
              <div className="field">
                <p className="control">
                <Select
                  value={industry}
                  onChange={this.handleChangeindustry}
                  options={industryOptions}
                  id="industry"
                  placeholder="Industry"
                  />
                </p>
              </div>

              <div className="field">
                <p className="control">
                  <Link to="/employerhome">Back to Employer Home</Link>
                </p>
              </div>
              
              <div className="field">
                <p className="control">
                  <button className="button is-success">
                    Post Requirement
                  </button>
                </p>
              </div>
            
          </form>
        </div>
      </section>
    );
  }
}

export default Register;