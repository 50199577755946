import React, { useState } from 'react';
import styled from '@emotion/styled';
import MailIcon from '@mui/icons-material/Mail';

const developerTypes = [
  { name: 'React Native Developer', image: 'reactnative' },
  { name: 'NodeJS Developer', image: 'nodejs' },
  { name: 'PHP Developer', image: 'php' },
  { name: 'Java Developer', image: 'java' },
  { name: 'Python Developer', image: 'python' },
  { name: 'IOS Developer', image: 'apple' },
  { name: 'Android Developer', image: 'android' },
  { name: 'AngularJS Developer', image: 'angular' },
  { name: 'ReactJS Developer', image: 'react' },
  { name: 'Cloud Engineer', image: 'cloud' }
];

function ProjectRequirementData() {
  const [toggleMenu, setToggleMenu] = useState(true);

  const renderDeveloperCard = ({ name, image }, index) => (
    <div className='card1' key={index}>
      <center>
        <img className='img' src={`./assets/${image}.png`} alt={name} />
        <p className='names'>{name}</p>
      </center>
    </div>
  );

  return (
    <div>
      <center>
        <div className="container" style={{ marginTop: '15rem', marginLeft: '100px' }}>
          <h1 className='containerheader'>
            Check out the options below for on-demand talent<br />
            here to gain access to our Developer Talent Database
          </h1>
          
          <div className='items' style={{ marginRight: '140px' }}>
            {developerTypes.slice(0, 5).map(renderDeveloperCard)}
            <div className='nextitems'>
              {developerTypes.slice(5).map(renderDeveloperCard)}
            </div>
          </div>
        </div>

        <a 
          href='/project-requirment' 
          style={{ textDecoration: 'none' }} 
          onClick={() => setToggleMenu(false)}
        >
          <button style={{
            backgroundColor: '#1DBF73',
            color: 'white',
            width: '15rem',
            height: '4rem',
            borderRadius: '10px',
            marginTop: '100px',
            border: 'none'
          }}>
            Post Requirements
          </button>
        </a>
      </center>

      <Footer />
    </div>
  );
}

const Footer = () => (
  <div style={{
    height: '21rem',
    width: '100vw',
    marginLeft: '-1rem',
    marginTop: '12rem',
    border: '2px solid #00B4D8',
    backgroundColor: '#00B4D8'
  }}>
    <div style={{ marginLeft: '5rem', marginTop: '2.5rem', color: 'white' }}>
      <InputContainer>
        <Input type="text" placeholder="Enter Your E-mail Address" />
        <span style={{
          marginLeft: '-19.8rem',
          width: '30px',
          backgroundColor: 'white',
          height: '40px',
          color: 'grey',
          paddingTop: '6px',
          borderTopLeftRadius: '10px',
          borderBottomLeftRadius: '10px'
        }}>
          <MailIcon />
        </span>
      </InputContainer>

      <p style={{
        marginLeft: '75rem',
        borderRadius: '10px',
        marginTop: '-2.4rem',
        cursor: 'pointer',
        height: '2.3rem',
        width: '10rem',
        backgroundColor: 'white',
        color: 'gray',
        textAlign: 'center',
        padding: '6px'
      }}>
        <a>Subscribe</a>
      </p>

      {['Search Terms', 'Privacy and Cookie Policy', 'Advanced Search', 'Orders and Returns', 'Contact Us']
        .map((text, index) => (
          <p key={index} style={{ paddingTop: index === 0 ? '0.5rem' : '0.1rem' }}>{text}</p>
        ))}
    </div>

    <div style={{ height: '3rem', backgroundColor: 'black' }}>
      <p style={{
        color: 'white',
        fontWeight: '200',
        paddingTop: '10px',
        textAlign: 'center'
      }}>
        Copyright © 2014-present Digitivy Technology Solutions, Inc. All rights reserved.
      </p>
    </div>
  </div>
);

const InputContainer = styled.div`
  display: flex;
  margin-left: 55rem;
`;

const Input = styled.input`
  border: 0;
  height: 2.5rem;
  width: 18rem;
  background-color: white;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  &:focus {
    border: none;
    outline: none;
  }
`;

export default ProjectRequirementData;