import React, { useEffect, useState } from 'react';
import { Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../components/css/candidatedash.css';
import Footer from './Footer';
import styled from '@emotion/styled';
import { NavLink } from "react-router-dom";
import MailIcon from '@mui/icons-material/Mail';
import reactnative from './assets/reactnative.png';
import angular from './assets/angular.png';
import apple from './assets/apple.png';
import cloud from './assets/cloud.png';
import java from './assets/java.png';
import php from './assets/php.png';
import python from './assets/python.png';
import android from './assets/android.png';
import react from './assets/react.png';
import nodejs from './assets/nodejs.png';
import mean1 from './assets/mean1.png';
import lamp from './assets/lamp.png';
import mern from './assets/mern.png';
import homeimage from './assets/Group94.png';
import project from './assets/Group422.png';
import axios from 'axios';
import createprofile from './assets/Group917.png';
import mevn from './assets/mevn.png';
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import CreateGigForm from './CreateGigForm';
import { useAuthToken } from '../TokenContext';
import { useIntl } from 'react-intl';
import { Typography } from '@mui/material';



export default function CandidateHomeContent() {
  const { isLoggedIn, user } = useSelector(state => state.auth);
  const intl = useIntl();

  const renderProfileButton = () => {
    if (!user) return null;

    const userType = user.data[0]?.type;
    const profileStatus = user.data[0].profile;
console.log('use',user)
    if (userType === "Candidate" && profileStatus === "0") {
      return (
        <a href="/create-profile">
          <button className="homebutton" style={{ boxShadow: '15px 15px 25px #00000029' }}>
            {intl.formatMessage({ id: 'createProfile' })}
          </button>
        </a>
      );
    } else if (userType === "Client" && profileStatus === "0") {
      return (
        <a href="/client-profile">
          <button className="homebutton" style={{ boxShadow: '15px 15px 25px #00000029' }}>
            {intl.formatMessage({ id: 'createProfile' })}
          </button>
        </a>
      );
    } else if (userType === "podprovider") {
      return (
        <a href="/provider-profile">
          <button className="homebutton" style={{ boxShadow: '15px 15px 25px #00000029' }}>
            {intl.formatMessage({ id: 'manageProfile' })}
          </button>
        </a>
      );
    } else {
      return null;
    }
  };

  const renderClientContent = () => {
    if (!user || user.data[0].type !== "Client") return null;

    if (user.data[0].profile === "0") {
      return (
        <div>
          <h1 style={{ backgroundColor: "#cee6f1", paddingBottom: "70px" }}></h1>
          <div className='row' style={{ backgroundColor: "#cee6f1", paddingBottom: "60px", marginTop: "-10px" }}>
            <div className="column is-6 secondimage"></div>
            <div className="column is-6 ">
              <p className="createprofile">{intl.formatMessage({ id: 'createProfile' })}</p>
              <br></br>
              <center>
                <a href="/client-profile">
                  <button className="homebutton" style={{ boxShadow: '15px 15px 25px #00000029' }}>
                    {intl.formatMessage({ id: 'createProfile' })}
                  </button>
                </a>
              </center>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="column is-12 ">
          <br></br>
          <p className='containerheader'>{intl.formatMessage({ id: 'getStarted' })}</p>
          <br></br>
          <center>

                <center>
                  <Link to="/CreateGigForm">
                    <div className="homebutton2" >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ backgroundColor: '#FFD700', borderRadius: '50%', width: '40px', height: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '10px' }}>
                          1
                        </div>



                        <button className="homebutton" style={{ boxShadow: '15px 15px 25px #00000029' }}>

                        {intl.formatMessage({ id: 'createGig' })}
                        </button>
                      </div>
                    </div>
                  </Link>
                </center>
                <center>
                  <Link to="/project-requirment">
                    <div className="homebutton2" >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ backgroundColor: 'orange', borderRadius: '50%', width: '40px', height: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '10px' }}>
                          2
                        </div>
                        <button className="homebutton" >

                        {intl.formatMessage({ id: 'createArt' })}
                        </button>
                      </div>
                    </div>
                  </Link>
                </center>
                <center>
                  <Link to="/client-createpod">
                    <div className="homebutton2" >

                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ backgroundColor: '#CC5500', borderRadius: '50%', width: '40px', height: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '10px' }}>
                          3
                        </div>
                        <button className="homebutton" style={{ boxShadow: '15px 15px 25px #00000029' }}>

                        {intl.formatMessage({ id: 'createPod' })}
                        </button>
                      </div>
                    </div>
                  </Link>
                </center>




              </center>
        </div>
      );
    }
  };

  const renderCandidateContent = () => {
    if (!user || user.data[0].type !== "Candidate") return null;

    if (user.data[0].profile === "0") {
      return (
        <div>
          <h1 style={{ paddingBottom: "70px" }}></h1>
          <div className='row' style={{ backgroundColor: "#cee6f1", paddingBottom: "60px", marginTop: "-10px" }}>
            <div className="column is-6 secondimage"></div>
            <div className="column is-6 ">
              <p className="createprofile">{intl.formatMessage({ id: 'createProfile' })}</p>
              <br></br>
              <center>
                <a href="/create-profile">
                  <button className="homebutton" style={{ boxShadow: '15px 15px 25px #00000029' }}>
                    {intl.formatMessage({ id: 'createProfile' })}
                  </button>
                </a>
              </center>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div >
          <p className="createprofile">{intl.formatMessage({ id: 'findYourBestTalentPod' })}</p>
          <br></br>
          <center>
            <a href="/FindPods">
              <button className="homebutton" style={{ boxShadow: '15px 15px 25px #00000029' }}>
                {intl.formatMessage({ id: 'findPods' })}
              </button>
            </a>
          </center>
        </div>
      );
    }
  };

  const renderSignupSection = () => {
    if (isLoggedIn === false) {
      return (
        <>
          <h1 className='thirdcontainerheader'></h1>
          <div className="columns features" style={{ marginTop: "150px" }}>
            <div className="column is-12 ">
              <center>
                <img src={project} style={{ maxWidth: "100%", height: "auto" }} alt="Project" />
              </center>
            </div>
          </div>
          <div className="column is-12 ">
            <br></br>
            <p className='containerheader' style={{ fontFamily: 'Poppins,Medium', color: "#4d4d4d", fontSize: "22px" }}>  "Looking to optimize your global business operations?"</p>
            <br></br>
            <div>
              <center>
                <span>
                  <p className='containerheader'>{intl.formatMessage({ id: 'signUpToGetStarted' })}</p>
                </span>
                <Link to="/register">
                  <button className="homebutton" style={{ boxShadow: '15px 15px 25px #00000029' }}>
                    {intl.formatMessage({ id: 'register' })}
                  </button>
                </Link>
              </center>
            </div>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
          </div>
        </>
      );
    } else {
      return null;
    }
  };

  return (
    <Container>
      <Content>
        {/* Render Profile Button */}
        <div className="columns features" style={{ marginTop: "150px" }}>
          <div className="column is-6">
            <br></br>
            <br></br>
            <br></br>
            <div className='columnfeatures'>

<Typography
  component="p"
  sx={{ 
    marginLeft: "22%", 
    color: theme => theme.palette.mode === 'dark' ? '#fff' : '#000',
    fontWeight: "bold", 
    fontSize: "40px"
  }}
  className="welcomeText"
>
  {intl.formatMessage({ id: 'welcome' })} <br />
  {intl.formatMessage({ id: 'globalWorkPlatform' })}
</Typography>
              <br></br>
              {/* Render Profile Button */}
              {renderProfileButton()}
            </div>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
          </div>
          <div className="column is-6">
            <img src={homeimage} style={{ maxWidth: "80%", height: "auto" }} alt="Home" />
          </div>
        </div>

        {/* Render Client Content */}
        {renderClientContent()}

        {/* Render Candidate Content */}
        {renderCandidateContent()}

        {/* Render Signup Section */}
        {renderSignupSection()}
      </Content>

      {/* Bottom Content */}
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <BottomContentContainer>
        <FooterLinks>
          <a href="/" style={{ color: "white" }}>{intl.formatMessage({ id: 'privacyPolicy' })}</a>
          <a href="/" style={{ color: "white" }}>{intl.formatMessage({ id: 'termsOfService' })}</a>
        </FooterLinks>
        <CopyrightContainer>
          <p style={{ color: 'white', fontWeight: '200', textAlign: 'center' }}>
            {intl.formatMessage({ id: 'copyright' })}
          </p>
        </CopyrightContainer>
      </BottomContentContainer>
    </Container>
  );
}


const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: ${({ theme }) => theme.palette.mode === 'dark' ? '#121212' : '#ffffff'};
  color: ${({ theme }) => theme.palette.mode === 'dark' ? '#ffffff' : '#000000'};
  transition: all 0.3s ease;
  
  @media screen and (min-width:320px) and (max-width:1080px){
    display: flex;
    flex-direction: column;
  }
`;

const Content = styled.div`
  flex: 1;
  padding: 20px;
  background-color: ${({ theme }) => theme.palette.mode === 'dark' ? '#121212' : '#ffffff'};
  
  @media screen and (min-width:320px) and (max-width:1080px){
    display: flex;
    flex-direction: column;
  }

  .containerheader {
    color: ${({ theme }) => theme.palette.mode === 'dark' ? '#ffffff' : '#4d4d4d'};
  }

  .welcomeText {
    color: ${({ theme }) => theme.palette.mode === 'dark' ? '#ffffff' : '#000000'};
  }

  .createprofile {
    color: ${({ theme }) => theme.palette.mode === 'dark' ? '#ffffff' : '#000000'};
  }
`;

const HomeButton = styled.button`
  background-color: ${({ theme }) => theme.palette.mode === 'dark' ? '#2196F3' : '#00B4D8'};
  color: white;
  padding: 10px 24px;
  border: none;
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.palette.mode === 'dark' 
    ? '0 4px 12px rgba(33, 150, 243, 0.3)'
    : '15px 15px 25px #00000029'};
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: ${({ theme }) => theme.palette.mode === 'dark'
      ? '0 6px 16px rgba(33, 150, 243, 0.4)'
      : '15px 15px 35px #00000040'};
  }
`;

const CreateProfileSection = styled.div`
  background-color: ${({ theme }) => theme.palette.mode === 'dark' ? '#1E1E1E' : '#cee6f1'};
  padding-bottom: 60px;
  margin-top: -10px;

  .createprofile {
    color: ${({ theme }) => theme.palette.mode === 'dark' ? '#ffffff' : '#000000'};
  }
`;

const BottomContentContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.mode === 'dark' ? '#1565C0' : '#00B4D8'};
  border-top: 2px solid ${({ theme }) => theme.palette.mode === 'dark' ? '#1565C0' : '#00B4D8'};
  text-align: center;
  width: 100vw;
`;

const FooterLinks = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  
  a {
    color: white !important;
    opacity: ${({ theme }) => theme.palette.mode === 'dark' ? 0.9 : 1};
    transition: opacity 0.3s ease;

    &:hover {
      opacity: 1;
    }
  }
`;

const CopyrightContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.mode === 'dark' ? '#1A1A1A' : '#000000'};
  height: 55px;
  display: flex;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  color: white;
`;

const ActionButton = styled(HomeButton)`
  display: flex;
  align-items: center;
  margin: 10px 0;
  width: auto;
  background-color: ${({ theme }) => theme.palette.mode === 'dark' ? '#1E1E1E' : '#ffffff'};
  color: ${({ theme }) => theme.palette.mode === 'dark' ? '#ffffff' : '#000000'};
  border: ${({ theme }) => theme.palette.mode === 'dark' ? '1px solid #333' : '1px solid #ddd'};

  &:hover {
    background-color: ${({ theme }) => theme.palette.mode === 'dark' ? '#2C2C2C' : '#f5f5f5'};
  }
`;

const NumberCircle = styled.div`
  background-color: ${props => props.color};
  border-radius: 50%;
  width: 40px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  color: ${({ theme }) => theme.palette.mode === 'dark' ? '#ffffff' : '#000000'};
`;

const Input = styled.input`
  border: ${({ theme }) => theme.palette.mode === 'dark' ? '1px solid #333' : '0px solid black'};
  justify-content: center;
  height: 2.5rem;
  width: 17rem;
  background-color: ${({ theme }) => theme.palette.mode === 'dark' ? '#1E1E1E' : 'white'};
  color: ${({ theme }) => theme.palette.mode === 'dark' ? '#ffffff' : '#000000'};
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  
  &:focus {
    border: ${({ theme }) => theme.palette.mode === 'dark' ? '1px solid #1565C0' : 'none'};
    outline: none;
  }

  &::placeholder {
    color: ${({ theme }) => theme.palette.mode === 'dark' ? '#666' : '#999'};
  }
`;

