import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button } from 'react-bootstrap';
import axios from 'axios';
import { Select, MenuItem } from '@mui/material';
import styled from 'styled-components'
import { MdOutlineDeleteOutline } from 'react-icons/md';
import { AiOutlineEdit } from 'react-icons/ai';
import { useAuthToken } from "../TokenContext";
import PropTypes from 'prop-types';

function Master({podid,podsize}) {
  const token = useAuthToken();


  const [candidates, setCandidates] = useState([]);
  const [editingCandidateId, setEditingCandidateId] = useState(null);
  const [editedCandidateName, setEditedCandidateName] = useState('');
  const [candidateNames, setCandidateNames] = useState([]);
  const [editedCandidateID, setEditedCandidateID] = useState(null); 
  const [editedCandidates, setEditedCandidates] = useState({});
  const [editedCandidateData, setEditedCandidateData] = useState({
    Pod_Roles: '',
    time_zone:'', 
    region:'',
    github_username:'',
     jira_username:'',
    Primary_Skill: '',
    Secondary_Skill: '',
    Availability: '',
    github_repo:'',
  });





useEffect(() => {
  if(podid){
  fetchPodCandidates();
  }
}, [podid,token]);


const fetchPodCandidates = async () => {
  try {
    const response = await fetch(
      process.env.REACT_APP_CLIENT_FETCHCANDIDATESBASEDONPODS,
      {
        method: 'POST',
        body: JSON.stringify({ pod_id: podid||null }),
        headers: {
          'Content-Type': 'application/json',
          'Authorization' : token,
        },
      }
    );

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    setCandidateNames(data.data);

  } catch (error) {
    console.error('Error fetching candidates:', error);
  }
};



const emptyRowCount = podsize - (candidates ? candidates.length : 0);

const emptyRows = Array.from({ length: emptyRowCount }, (_, index) => ({
  id: `empty-${index}`, // You can generate unique IDs for each empty row if needed
  Candidate_Name: '', // You can set other properties to default empty values
  // Set other properties like Pod_Roles, Primary_Skill, etc. to empty values if needed
}));

// Merge candidates and emptyRows to render both
const mergedRows = [...(candidates || []), ...emptyRows];

  useEffect(() => {
    if(podid ){
    fetchCandidates();
    }
  }, [podid,token]);

  const fetchCandidates = async () => {
    const headers = {
      'Authorization' : token, // Replace with your actual token
      'Content-Type': 'application/json', // You can add more headers as needed
    };
    try {
      //'https://nxa3k1jn37.execute-api.us-east-1.amazonaws.com/dev/Pod_Candidates_Fetching'
      const response = await axios.post(process.env.REACT_APP_CLIENT_PODCANDIDATEFETCHING, {
        pods_id: podid ||null, // Replace with actual pods_id
      }, {headers:headers});
      // Log the received data
      setCandidates(response.data.data);
    } catch (error) {
      console.error('Error fetching candidates:', error);
    }
  };

  const updateCandidate = async (candidateId, id) => {
    try {
      const candidateToUpdate = candidates.find(candidate => candidate.candidateID === candidateId);
  
      if (!candidateToUpdate) {
        // Candidate not found, indicating a new candidate is being created
        const newCandidatePayload = {
          podID: podid,
          candidateID: candidateId || null,
          Pod_Roles: editedCandidateData.Pod_Roles || '',
          time_zone: editedCandidateData.time_zone || '', 
          region: editedCandidateData.region || '',
          github_username: editedCandidateData.github_username || '',
          jira_username: editedCandidateData.jira_username || '',
          github_repo: editedCandidateData.github_repo || '',
          Primary_Skill: editedCandidateData.Primary_Skill || '',
          Secondary_Skill: editedCandidateData.Secondary_Skill || '',
          Availability: editedCandidateData.Availability || '',
          Candidate_Name: candidateToUpdate.Candidate_Name || null,
        };
  
        // Send a POST request to create a new candidate
        await axios.put(
          process.env.REACT_APP_CLIENT_PODROLES,
          newCandidatePayload,
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization' : token, 
            },
          }
        );
      } else {
        // Existing candidate is being updated
        const payload = {
          podID: candidateToUpdate.podID,
          candidateID: candidateToUpdate.candidateID,
          id: id,
          status: candidateToUpdate.status,
          pod_status: candidateToUpdate.pod_status,
          pod_approved: candidateToUpdate.pod_approved,
          Pod_Roles: editedCandidateData.Pod_Roles || candidateToUpdate.Pod_Roles,
          time_zone: editedCandidateData.time_zone || candidateToUpdate.time_zone, 
          region: editedCandidateData.region || candidateToUpdate.region,
          github_username: editedCandidateData.github_username || candidateToUpdate.github_username,
          jira_username: editedCandidateData.jira_username || candidateToUpdate.jira_username,
          github_repo: editedCandidateData.github_repo || candidateToUpdate.github_repo,
          Primary_Skill: editedCandidateData.Primary_Skill || candidateToUpdate.Primary_Skill,
          Secondary_Skill: editedCandidateData.Secondary_Skill || candidateToUpdate.Secondary_Skill,
          Availability: editedCandidateData.Availability || candidateToUpdate.Availability,
          Candidate_Name: candidateToUpdate.Candidate_Name || null,
        };
  
        // Send a PUT request to update the existing candidate
        await axios.put(
          process.env.REACT_APP_CLIENT_PODCANDIDATEUPDATE,
          JSON.stringify(payload),
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization' : token, 
            },
          }
        );
      }
  
      fetchCandidates();
      setEditingCandidateId('');
      setEditedCandidateData({
        Pod_Roles: '',
        region:'',
        time_zone:'',
        github_username:'',
        jira_username:'',
        Primary_Skill: '',
        Secondary_Skill: '',
        Availability: '',
        github_repo:'',
      });
      setEditedCandidateName(''); // Reset the candidate name
    } catch (error) {
      console.error('Error updating/creating candidate:', error);
    }
  };
  
  
  
  
  
  
  
  
  
  
  

  const deleteCandidate = async (podID, candidateID) => {
    const headers = {
      'Authorization': token,
      'Content-Type': 'application/json',
    };
    try {
      const apiUrl = process.env.REACT_APP_CLIENT_PODCANDIDATEDELETE;
      const payload = {
        podID: podID,
        candidateId: candidateID
      };
  
      await axios.delete(apiUrl, {
        headers: headers,
        data: payload // Payload should be included in the same object
      });
  alert('Deleting Candidate From pod')
      fetchCandidates();
    } catch (error) {
      console.error('Error deleting candidate:', error);
    }
  };
  
        


  const podRolesOptions = ['Scrum Master', "Front-end developer", "Backend developer", "Data engineer", "Data Scientist", "AI Engineer", "Data analyst", "ETL Engineer", "JavaScript Developer", "Python Developer",'Lead', 'Product Owner','Developer','Tester','DevOps'];
  const timezone = [
    'Indian Standard Time',
    'Eastern Standard Time',
    'Central Standard Time',
    'Mountain Standard Time',
    'Pacific Standard Time',
    'Greenwich Mean Time',
    'Central European Time',
    'Western European Time',
    'Australian Eastern Standard Time',
    'Australian Central Standard Time',
    'Australian Western Standard Time',
    'New York Time (Eastern Daylight Time)',
    'Los Angeles Time (Pacific Daylight Time)',
    'London Time (British Summer Time)',
    'Paris Time (Central European Summer Time)',
    'Tokyo Time (Japan Standard Time)',
    'Sydney Time (Australian Eastern Daylight Time)',
    'Dubai Time (Gulf Standard Time)',
    'Moscow Time (Moscow Standard Time)',
    'Brazil Time (Brasilia Time)',
    'Chicago Time (Central Daylight Time)',
    'Houston Time (Central Daylight Time)',
    'Cape Town Time (South Africa Standard Time)'
];

  const region=['Asia Pacific','Latin America','North America'];
  const primarySkillOptions = ['html', 'css', 'JavaScript','Adobe Illustrator','ReactJS','AngularJS','Java','Node.js','Vue','Next','Typescript','Gatsby','Three.js'];
  const secondarySkillOptions = ['Java', 'Python', 'PHP','SQL','Git','c++','Perl','AngularJS','Kotlin','AWS','NodeJs','Azure'];
  const availabilityOptions = ['full time', 'part time'];

  useEffect(() => {
    if (editingCandidateId !== null) {
      const selectedCandidate = candidates.find(candidate => candidate.candidateID === editingCandidateId);
      if (selectedCandidate) {
        
        setEditedCandidateData({
          Pod_Roles: selectedCandidate.Pod_Roles,
          region:selectedCandidate.region,
          time_zone:selectedCandidate.time_zone,
          github_username:selectedCandidate.github_username,
          jira_username:selectedCandidate.jira_username,
          Primary_Skill: selectedCandidate.Primary_Skill,
          Secondary_Skill: selectedCandidate.Secondary_Skill,
          Availability: selectedCandidate.Availability,
          github_repo:selectedCandidate.github_repo,
        });
        setEditedCandidateName(selectedCandidate.Candidate_Name || ''); // Set the candidate name
      }
    } else {
      setEditedCandidateData({
        Pod_Roles: '',
        region:'',
        time_zone:'',
        github_username:'',
         jira_username:'',
        Primary_Skill: '',
        Secondary_Skill: '',
        Availability: '',
        github_repo:'',
      });
      setEditedCandidateName(''); // Reset the candidate name
    }
  }, [editingCandidateId, candidates]);
  useEffect(() => {
   
  }, [editedCandidateName]);
  console.log('candidateNames:');
  

  return (
    <Div className="col-lg-11">
      <TableContainer component={Paper}>
        <h6 style={{color:"#00B4D8"}}>"Please visit the 'Find Talent' page and add talented individuals to your pod."</h6>
        <Table component={Paper} sx={{ width: 1200, fontFamily: 'Poppins,Medium', borderRadius: '10px' }} aria-label="simple table">
          <TableHead sx={{ backgroundColor: '#00B4D8', '& td, & th': { fontFamily: 'Poppins,Medium', fontSize: '15px', color: 'white', fontWeight: '600' } }}>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Pod Roles</TableCell>
              {/* <TableCell>GitHub Repo</TableCell>
              <TableCell>GitHub Username</TableCell>
              <TableCell>Jira Username</TableCell> */}
              <TableCell>Primary Skill</TableCell>
              <TableCell>Secondary Skill</TableCell>
              <TableCell>Region</TableCell>
              <TableCell>Timezone</TableCell>
              <TableCell>Availability</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {mergedRows.slice(0, podsize).map(candidate => (
              <TableRow
                sx={{
                  '&:last-child td, &:last-child th': { border: 0, fontFamily: 'Poppins,Medium', fontSize: '15px' },
                  '&:nth-child(even)': { backgroundColor: '#90E0EF' },
                  '&:nth-child(odd)': { backgroundColor: '#caf0f8' },
                  '& td, & th': { fontFamily: 'Poppins,Medium', fontSize: '15px', color: 'black' },
                }}
                key={candidate.id}
              >
 <TableCell>
                  {editingCandidateId === candidate.id ? (
                    // Inside the select element where you handle candidate selection
<Select

  value={editedCandidates[candidate.id]?.editedCandidateID || ''}
  onChange={(e) => {
    const selectedCandidateID = parseInt(e.target.value, 10) || null;
    const selectedCandidate = candidateNames.find(
      (item) => item.candidateID === selectedCandidateID
    );

    setEditedCandidates((prevEditedCandidates) => ({
      ...prevEditedCandidates,
      [candidate.id]: {
        editedCandidateID: selectedCandidateID,
        editedCandidateName: selectedCandidate
          ? selectedCandidate.Candidate_Name
          : '',
      },
    }));

    // Set both editedCandidateID and editedCandidateName
    setEditedCandidateID(selectedCandidateID);
    setEditedCandidateName(selectedCandidate ? selectedCandidate.Candidate_Name : '');
  }}
  displayEmpty
>

<MenuItem value="" disabled={!candidate.Candidate_Name || candidate.Candidate_Name === 'null'}>
  {candidate.Candidate_Name && candidate.Candidate_Name !== 'null' ? candidate.Candidate_Name : "Edit Teammember"}
</MenuItem>

{candidateNames &&candidateNames.map((candidate) => (
<MenuItem key={candidate.candidateID} value={candidate.candidateID}>
{candidate.Candidate_Name}
</MenuItem>
))}
</Select>


                  ) : (
                    <MenuItem value="" disabled={! candidate.Candidate_Name}>
                    { candidate.Candidate_Name && candidate.Candidate_Name !=='null'?  candidate.Candidate_Name : "Edit Teammember"}
                  </MenuItem>// Display the selected candidate's name here
                  )}
                </TableCell>
             
                <TableCell>
                  {editingCandidateId === candidate.id ? (
                    <Select value={editedCandidateData.Pod_Roles} onChange={e => setEditedCandidateData({ ...editedCandidateData, Pod_Roles: e.target.value })}  displayEmpty>
                      <MenuItem value="" disabled>
        Select Pod Roles
      </MenuItem>
                      {podRolesOptions.map(option => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    <MenuItem value="" disabled={! candidate.Pod_Roles}>
                    { candidate.Pod_Roles ?  candidate.Pod_Roles : "Edit Pod Roles"}
                  </MenuItem>
                   
                  )}
                </TableCell>





                {/* 

                
                <TableCell>
                  {editingCandidateId === candidate.id ? (
                      <TextField
                      label="GitHub Repository"
                      variant="outlined"
                      fullWidth
                      name="github_repo"
                      value={editedCandidateData.github_repo}
                      onChange={(e) =>
                        setEditedCandidateData({ ...editedCandidateData, github_repo: e.target.value })
                      }
                    />
                  ) : (
                    candidate.github_repo
                  )}
                </TableCell>
                <TableCell>
                  {editingCandidateId === candidate.id ? (
                      <TextField
                      label="GitHub Username"
                      variant="outlined"
                      fullWidth
                      name="github_username"
                      value={editedCandidateData.github_username}
                      onChange={(e) =>
                        setEditedCandidateData({ ...editedCandidateData, github_username: e.target.value })
                      }
                    />
                  ) : (
                    candidate.github_username
                  )}
                </TableCell> */}
                {/* <TableCell>
                  {editingCandidateId === candidate.id ? (
                      <TextField
                      label="Jira Username"
                      variant="outlined"
                      fullWidth
                      name="jira_username"
                      value={editedCandidateData.jira_username}
                      onChange={(e) =>
                        setEditedCandidateData({ ...editedCandidateData, jira_username: e.target.value })
                      }
                    />
                  ) : (
                    candidate.jira_username
                  )}
                </TableCell> */}
                
                <TableCell>
  {editingCandidateId === candidate.id ? (
    <Select value={editedCandidateData.Primary_Skill}    onChange={e => setEditedCandidateData({ ...editedCandidateData, Primary_Skill: e.target.value })}  displayEmpty >
      <MenuItem value="" disabled>
        Select Primary Skill
      </MenuItem>
      {primarySkillOptions.map(option => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </Select>
  ) : (
    <MenuItem value="" disabled={! candidate.Primary_Skill}>
    { candidate.Primary_Skill ?  candidate.Primary_Skill : "Edit  Primary Skill"}
  </MenuItem>
  
  )}
</TableCell>

                <TableCell>
                  {editingCandidateId === candidate.id ? (
                    <Select value={editedCandidateData.Secondary_Skill} onChange={e => setEditedCandidateData({ ...editedCandidateData, Secondary_Skill: e.target.value })} displayEmpty>
                      <MenuItem value="" disabled>
        Select Secondary Skill
      </MenuItem>
                      {secondarySkillOptions.map(option => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (

                    <MenuItem value="" disabled={! candidate.Secondary_Skill}>
                    { candidate.Secondary_Skill ?  candidate.Secondary_Skill : "Edit  Secondary Skill"}
                  </MenuItem>
                    
                  )}
                </TableCell>

                <TableCell>
  {editingCandidateId === candidate.id ? (
    <Select
      value={editedCandidateData.region}
      onChange={e =>
        setEditedCandidateData({ ...editedCandidateData, region: e.target.value })
      }
      displayEmpty
  // This is important for displaying the placeholder
    >
      <MenuItem value="" disabled>
        Select Region
      </MenuItem>
      {region.map(option => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </Select>
  ) : (
    <MenuItem value="" disabled={! candidate.region}>
                    { candidate.region ?  candidate.region : "Edit  region"}
                  </MenuItem>
  
  )}
</TableCell>

                <TableCell>
                  {editingCandidateId === candidate.id ? (
                    <Select value={editedCandidateData.time_zone} onChange={e => setEditedCandidateData({ ...editedCandidateData, time_zone: e.target.value })} displayEmpty>
                       <MenuItem value="" disabled={!candidate.time_zone}>
    Select Timezone
      </MenuItem>
                      {timezone.map(option => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    <MenuItem value="" disabled={!candidate.time_zone}>
                    {candidate.time_zone ? candidate.time_zone : "Edit Timezone"}
                  </MenuItem>
                  )}
                </TableCell>
                
                
                <TableCell>
                  {editingCandidateId === candidate.id ? (
                    <Select value={editedCandidateData.Availability} onChange={e => setEditedCandidateData({ ...editedCandidateData, Availability: e.target.value })} displayEmpty>
                     <MenuItem value="" disabled>
        Select Availability
      </MenuItem>
                      {availabilityOptions.map(option => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (

                    <MenuItem value="" disabled={! candidate.Availability}>
                    { candidate.Availability ?  candidate.Availability : "Edit  Availability"}
                  </MenuItem>
              
                  )}
                </TableCell>
                <TableCell>
                  {editingCandidateId === candidate.id ? (
                    <Button variant="contained" color="primary" onClick={() => updateCandidate(candidate.candidateID,candidate.id)}>
                      Save
                    </Button>
                  ) : (
                    <>
                      <Button
  variant="contained"
  color="primary"
  onClick={() => {
    
    setEditingCandidateId(candidate.id);
    setEditedCandidateData({
      Pod_Roles: candidate.Pod_Roles,
      Primary_Skill: candidate.Primary_Skill,
      time_zone:candidate.time_zone, 
      region:candidate.region,
      Secondary_Skill: candidate.Secondary_Skill,
      Availability: candidate.Availability,
      github_username:candidate.github_username,
      jira_username:candidate.jira_username,
      github_repo:candidate.github_repo,

    });
  }}
>
  <AiOutlineEdit />
</Button>


                      <Button variant="contained" color="secondary" onClick={() => deleteCandidate(candidate.podID,candidate.candidateID,candidate.Candidate_Name)}>
                        <MdOutlineDeleteOutline />
                      </Button>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Div>
  );
}
Master.propTypes = {
  podid: PropTypes.array.isRequired,
  podsize:PropTypes.array.isRequired,
};
export default Master;


const Div = styled.div`
display: flex;
 justifyContent: center;
 align-items: center;
  margin: auto;
   margin-top:10rem;
  

@media screen and (min-width:320px) and (max-width:1080px){


}
`;