import React, { useState } from 'react';
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { useAuthToken } from "../TokenContext";
import {
  Box,
  Container,
  TextField,
  Button,
  Typography,
  Paper,
  IconButton,
  Tabs,
  Tab,
  Alert
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material';

// Constants
const MAX_INVITES = 3;
const MIN_INVITES = 1;

// Styled components using MUI
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2)
  }
}));

const StyledNavLink = styled(NavLink)(({ theme, isActive }) => ({
  textDecoration: 'none',
  borderRadius: theme.spacing(4),
  padding: theme.spacing(1, 4),
  color: isActive ? theme.palette.common.white : theme.palette.text.primary,
  backgroundColor: isActive ? theme.palette.primary.main : theme.palette.grey[200],
  '&:hover': {
    backgroundColor: isActive ? theme.palette.primary.dark : theme.palette.grey[300]
  },
  pointerEvents: isActive ? 'none' : 'auto',
  boxShadow: isActive ? theme.shadows[2] : 'none',
  marginLeft: isActive ? theme.spacing(-4) : 0,
  paddingLeft: isActive ? theme.spacing(4) : theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    marginLeft: isActive ? theme.spacing(3) : 0,
    padding: theme.spacing(1, 2)
  }
}));

const EmailFieldContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(2),
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'stretch'
  }
}));

const Stakeholderinvite = () => {
  const token = useAuthToken();
  const { user } = useSelector(state => state.auth);
  const [invites, setInvites] = useState([{ emails: '' }]);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);

  const validateEmails = (emails) => {
    const uniqueEmails = new Set(emails.map(invite => invite.emails.toLowerCase()));
    if (uniqueEmails.size !== emails.length) {
      return 'Duplicate email addresses are not allowed';
    }
    
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const invalidEmails = emails.filter(invite => !emailRegex.test(invite.emails));
    if (invalidEmails.length > 0) {
      return 'Please enter valid email addresses';
    }
    
    return null;
  };

  const handleInputChange = (index, value) => {
    const newInvites = invites.map((invite, i) => 
      i === index ? { ...invite, emails: value } : invite
    );
    setInvites(newInvites);
    setError('');
  };

  const handleAddEmail = () => {
    if (invites.length >= MAX_INVITES) {
      setError(`Maximum ${MAX_INVITES} invites allowed`);
      return;
    }
    setInvites([...invites, { emails: '' }]);
    setError('');
  };

  const handleRemoveEmail = (index) => {
    if (invites.length <= MIN_INVITES) {
      setError('At least one email is required');
      return;
    }
    const newInvites = invites.filter((_, i) => i !== index);
    setInvites(newInvites);
    setError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    setLoading(true);

    const validationError = validateEmails(invites);
    if (validationError) {
      setError(validationError);
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(process.env.REACT_APP_CUSTOMERADMIN_EMAILINVITE, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({
          invite: invites,
          customer_id: user.data[0].customer_id
        })
      });

      const data = await response.json();

      if (data.status === 200) {
        setSuccess('Invite emails sent successfully');
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        setError(data.data || 'Failed to send invites');
      }
    } catch (error) {
      setError('Failed to send invites. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="md">
      <StyledPaper elevation={3}>
        <Box mb={4}>
          <Box display="flex" gap={2} justifyContent="center">
            <StyledNavLink to="/Clientinvite" isActive={false}>
              Manager
            </StyledNavLink>
            <StyledNavLink to="/Stakeholderinvite" isActive={true}>
              Stakeholder
            </StyledNavLink>
          </Box>
        </Box>

        <Typography variant="h5" gutterBottom align="center" fontWeight={600}>
          Enter Email to Invite Stakeholder
        </Typography>

        {error && (
          <Alert severity="error" sx={{ width: '100%', mb: 2 }}>
            {error}
          </Alert>
        )}

        {success && (
          <Alert severity="success" sx={{ width: '100%', mb: 2 }}>
            {success}
          </Alert>
        )}

        <Box component="form" onSubmit={handleSubmit} width="100%">
          {invites.map((invite, index) => (
            <EmailFieldContainer key={index}>
              <TextField
                fullWidth
                type="email"
                placeholder="Enter an Email"
                value={invite.emails}
                onChange={(e) => handleInputChange(index, e.target.value)}
                required
                variant="outlined"
                error={Boolean(error && invite.emails)}
              />
              <Box>
                {index === invites.length - 1 && (
                  <IconButton
                    onClick={handleAddEmail}
                    color="primary"
                    disabled={invites.length >= MAX_INVITES}
                  >
                    <AddIcon />
                  </IconButton>
                )}
                {invites.length > MIN_INVITES && (
                  <IconButton
                    onClick={() => handleRemoveEmail(index)}
                    color="primary"
                  >
                    <RemoveIcon />
                  </IconButton>
                )}
              </Box>
            </EmailFieldContainer>
          ))}

          <Button
            type="submit"
            variant="contained"
            fullWidth
            size="large"
            disabled={loading}
            sx={{
              mt: 3,
              mb: 2,
              bgcolor: '#1DBF73',
              borderRadius: '10px',
              height: '54px',
              '&:hover': {
                bgcolor: '#19a864'
              }
            }}
          >
            {loading ? 'Sending...' : 'Invite'}
          </Button>
        </Box>
      </StyledPaper>
    </Container>
  );
};

export default Stakeholderinvite;