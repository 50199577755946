import React, { useEffect, useState, useRef } from 'react';
import { Chart, registerables } from 'chart.js';
import 'chartjs-plugin-annotation';
import './BarGraph.css';
import { v4 as uuidv4 } from 'uuid';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {Paper,useTheme} from '@mui/material';
import { useAuthToken } from "../TokenContext";
import { useIntl } from 'react-intl';

const DetailBitbucketcodeTalent = ({ initialPodIds, selectedSprint, sprintStartDate, sprintEndDate }) => {
  const [metrics, setMetrics] = useState(null);
  const { user } = useSelector((state) => state.auth);
  const canvasId = useRef(`chart-${uuidv4()}`);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const chartInstanceRef = useRef(null);
  const [selectedDropdownValue, setSelectedDropdownValue] = useState("");
  const token = useAuthToken();
  const intl = useIntl();
const theme=useTheme();
  const openModal = () => {
    setModalIsOpen(true);
  };
  
  const closeModal = () => {
    setModalIsOpen(false);
  };

  useEffect(() => {
    Chart.register(...registerables);
    fetchMetrics();
    
    // Cleanup function
    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
        chartInstanceRef.current = null;
      }
    };
  }, [initialPodIds, token, selectedSprint, sprintStartDate, sprintEndDate]);

  const fetchMetrics = async () => {
    try {
      if (!token) return;
      
      const apiEndpoint = process.env.REACT_APP_TALENT_CODEMETRICSTALENT;
      const response = await fetch(apiEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify({
          talent_id: user.data[0].c_id,
          pod_id: initialPodIds || [],
          sprint_name: selectedSprint || '',
          sprint_start_date: sprintStartDate || '',
          sprint_end_date: sprintEndDate || ''
        }),
      });
      
      const data = await response.json();
      if (response.ok) {
        setMetrics(data?.body || {});
      } else {
        console.error('Failed to fetch metrics:', data);
      }
    } catch (error) {
      console.error('Error fetching metrics:', error);
    }
  };

  const getChartData = () => {
    if (!metrics) return [];

    if (selectedDropdownValue) {
      const talentUser = metrics?.talent_users?.find(user => user.talent_user === selectedDropdownValue);
      if (!talentUser) return [];
      
      const { total_lines_changed_last_week, total_lines_changed_this_week } = talentUser;
      return getTotalLinesChartData(total_lines_changed_last_week, total_lines_changed_this_week);
    } else {
      const { current_week, previous_week } = metrics?.all_repo_checkins || {};
      return getTotalLinesChartData(previous_week, current_week);
    }
  };

  const getTotalLinesChartData = (lastWeek, thisWeek) => {
    const chartData = [lastWeek || 0, thisWeek || 0];
    if (chartData.every(value => value === 0)) {
      return [];
    }
    return chartData;
  };

  useEffect(() => {
    if (!metrics) return;

    const canvas = document.getElementById(canvasId.current);
    if (!canvas) return;

    // Destroy existing chart if it exists
    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
      chartInstanceRef.current = null;
    }

    const chartData = getChartData();
    if (chartData.length === 0) return;

    chartInstanceRef.current = new Chart(canvas, {
      type: 'pie',
      data: {
        labels: ['Lines Changed Last Week', 'Lines Changed This Week'],
        datasets: [
          {
            label: 'Code',
            data: chartData,
            backgroundColor: ['seagreen', 'red'],
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          annotation: {},
          legend: {
            display: false,
          },
        },
        onClick: (event, elements) => {
          openModal();
        },
        hover: {
          onHover: function (event, elements) {
            const chart = event.target;
            if (chart) {
              chart.options.plugins.annotation.labels = {
                render: 'label',
                position: 'outside',
                fontSize: 12,
                fontStyle: 'bold',
              };
              chart.update();
            }
          },
        },
      },
    });
  }, [metrics, selectedDropdownValue]);

  const shouldShowChart = metrics && 
    (!metrics.all_repo_checkins || 
     metrics.all_repo_checkins.current_week !== 0 || 
     metrics.all_repo_checkins.previous_week !== 0);

  return (
    <div className="chart-container">
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <h2 className="chart-heading">{intl.formatMessage({ id: 'code' })}</h2>
        <br/>
        <div>
          <select
            style={{ width: '12rem', color: theme.palette.mode === 'dark' ? 'white' : 'black',      backgroundColor:  theme.palette.mode === 'dark' ? theme.palette.background.paper : 'white',
              // Lighter shade in dark mode
            }}
            value={selectedDropdownValue}
            onChange={(e) => setSelectedDropdownValue(e.target.value)}
          >
            <option value="">{intl.formatMessage({id:'allReporters'})}</option>
            {metrics?.talent_users?.map((user) => (
              <option key={user.talent_user} value={user.talent_user}>
                {user.talent_user}
              </option>
            ))}
          </select>
        </div>
        <br />
        <div className="chart-wrapper" style={{ width: "130px", height: "140px", margin: "auto", textAlign: "center", marginLeft: "2rem", marginTop:"10px"}}>
          {shouldShowChart ? (
            <canvas id={canvasId.current}></canvas>
          ) : (
            <p>{intl.formatMessage({ id: 'noDataAvailable' })}</p>
          )}
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="GitHub Summary Modal"
        style={{
          content: {
            width: '50%',
            maxWidth: '800px',
            margin: 'auto',
            marginTop: '5rem',
          },
        }}
      >
        <h6>{intl.formatMessage({ id: 'githubSummary' })}</h6>
        <TableContainer component={Paper}>
          <Table 
            sx={{ maxWidth: 900, fontFamily: 'Poppins,Medium', borderRadius: '10px' }}
            aria-label="simple table"
          >
            <TableHead
              sx={{
                backgroundColor: '#00B4D8',
                '& td, & th': {
                  fontFamily: 'Poppins,Medium',
                  fontSize: '15px',
                  color: 'white',
                  fontWeight: '600'
                },
              }}
            >
              <TableRow>
                <TableCell>{intl.formatMessage({ id: 'githubUser' })}</TableCell>
                <TableCell align="right">{intl.formatMessage({ id: 'totalLinesChangedLastWeek' })}</TableCell>
                <TableCell align="right">{intl.formatMessage({ id: 'totalLinesChangedThisWeek' })}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {metrics?.talent_users?.map((user, index) => (
                <TableRow
                  key={index}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0, fontFamily: 'Poppins,Medium', fontSize: '15px' },
                    '&:nth-child(even)': {
                      backgroundColor: '#90E0EF'
                    },
                    '&:nth-child(odd)': {
                      backgroundColor: '#caf0f8'
                    },
                    '& td, & th': {
                      fontFamily: 'Poppins,Medium',
                      fontSize: '15px',
                      color: 'black',
                    },
                  }}
                >
                  <TableCell>{user.talent_user}</TableCell>
                  <TableCell align="right">{user.total_lines_changed_last_week}</TableCell>
                  <TableCell align="right">{user.total_lines_changed_this_week}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div>
          {metrics?.all_repo_checkins && (
            <>
              <h6>{intl.formatMessage({ id: 'allRepoCheckins' })}</h6>
              <p>{intl.formatMessage({ id: 'currentWeek' })}: {metrics.all_repo_checkins.current_week}</p>
              <p>{intl.formatMessage({ id: 'previousWeek' })}: {metrics.all_repo_checkins.previous_week}</p>
            </>
          )}
        </div>
        <button onClick={closeModal}>{intl.formatMessage({ id: 'done' })}</button>
      </Modal>
    </div>
  );
};

export default DetailBitbucketcodeTalent;