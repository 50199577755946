import React, { useEffect, useState } from 'react';
import { styled as muiStyled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { IoStatsChart } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { useIntl } from 'react-intl';
import { useAuthToken } from "../TokenContext";

// Import your components
import DetailGithublinesofcodeTalent from './DetailGithublinesofcodeTalent';
import DetailPRCountTalent from './DetailPRCountTalent';
import DetailbitbucketPRcountTalent from './DetailbitbucketPRcountTalent';
import Detailstoriesmetricstalent from './Detailstoriesmetricstalent';
import Detaildefectsmetricstalent from './Detaildefectsmetricstalent';
import ProjectPieCharttalent from './ProjectPieCharttalent';
import CloudCosttalent from './CloudCosttalent';
import Bitbucketcodebydaywisetalent from './Bitbucketcodebydaywisetalent';
import SprintVelocitiesCharttalent from './SprintVelocitiesCharttalent';
import Dorametircslinegraphtalent from './Dorametircslinegraphtalent';
import CicdPiechartmanagerdetailvivewtalent from './CicdPiechartmanagerdetailvivewtalent';
import TimeDetailViewtalent from './TimeDetailViewtalent';
import DetailsTaskmetricsTalent from './DetailsTaskmetricsTalent';
import DetailBitbucketcodeTalent from './DetailBitbucketcodeTalent';
import TicketsDetailviewmetricsTalent from './TicketsDetailviewmetricsTalent';

// Styled components using MUI's styled
const Container = muiStyled(Box)(({ theme,collapsed }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: 'auto',
  width: `calc(100vw - ${collapsed ? '60px' : '240px'} - 48px)`,
  marginLeft: collapsed ? '60px' : '240px',
  flexDirection: 'column',
  backgroundColor: theme.palette.background.default,
  // minHeight: '100vh',
  padding: theme.spacing(2)
}));

const Title = muiStyled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 600,
  marginTop: theme.spacing(10),
  marginBottom: theme.spacing(2),
  fontFamily: 'Poppins, Medium',
  fontSize: '1.5rem'
}));

const MetricsContainer = muiStyled(Paper)(({ theme }) => ({
  width: '80vw',
  marginTop: theme.spacing(2),
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#0077b6',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1),
  boxShadow: theme.shadows[4],
  transition: '0.45s ease-in-out',
  [theme.breakpoints.down('md')]: {
    width: '80vw',
    marginLeft: theme.spacing(4)
  }
}));

const ContentWrapper = muiStyled(Paper)(({ theme }) => ({
  display:"flex",
  justifyContent:"center",
  alignItems:'center',
  margin:"auto",
  width: '100%',
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2.5),
  height: '20rem',
  [theme.breakpoints.down('md')]: {
    overflowY: 'scroll'
  }
}));
const ContentWrapper1 = muiStyled(Paper)(({ theme }) => ({
  display:"flex",
  justifyContent:"center",
  alignItems:'center',
  margin:"auto",
  width: '100%',
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2.5),
  height: 'auto',

  [theme.breakpoints.down('md')]: {
    overflowY: 'scroll'
  }
}));

const selectStyles = (theme) => ({
 
  control: (base) => ({
    ...base,
    width: '40vw',
    margin: '0.5rem',
    backgroundColor: theme.palette.mode === 'dark' ? '#2a2a2a' : theme.palette.background.paper,
    borderColor: theme.palette.mode === 'dark' ? '#404040' : theme.palette.divider,
    borderRadius: theme.shape.borderRadius,
    boxShadow: 'none',
    '&:hover': {
      borderColor: theme.palette.mode === 'dark' ? '#505050' : theme.palette.primary.main
    },
    minHeight: '56px'
  }),
  menu: (base) => ({
    ...base,
    backgroundColor: theme.palette.mode === 'dark' ? '#2a2a2a' : theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    padding: 4
  }),
  menuList: (base) => ({
    ...base,
    padding: 0
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected 
      ? theme.palette.mode === 'dark' ? '#3b82f6' : theme.palette.primary.main
      : state.isFocused 
        ? theme.palette.mode === 'dark' ? '#404040' : theme.palette.action.hover
        : 'transparent',
    color: state.isSelected
      ? '#ffffff'
      : theme.palette.mode === 'dark' ? '#ffffff' : theme.palette.text.primary,
    '&:hover': {
      backgroundColor: theme.palette.mode === 'dark' ? '#404040' : theme.palette.action.hover
    },
    cursor: 'pointer',
    padding: '8px 12px'
  }),
  input: (base) => ({
    ...base,
    color: theme.palette.mode === 'dark' ? '#ffffff' : theme.palette.text.primary
  }),
  placeholder: (base) => ({
    ...base,
    color: theme.palette.mode === 'dark' ? '#808080' : theme.palette.text.secondary
  }),
  singleValue: (base) => ({
    ...base,
    color: theme.palette.mode === 'dark' ? '#ffffff' : theme.palette.text.primary
  }),
  multiValue: (base) => ({
    ...base,
    backgroundColor: theme.palette.mode === 'dark' ? '#404040' : theme.palette.grey[200]
  }),
  multiValueLabel: (base) => ({
    ...base,
    color: theme.palette.mode === 'dark' ? '#ffffff' : theme.palette.text.primary
  }),
  multiValueRemove: (base) => ({
    ...base,
    color: theme.palette.mode === 'dark' ? '#808080' : theme.palette.grey[600],
    '&:hover': {
      backgroundColor: theme.palette.mode === 'dark' ? '#505050' : theme.palette.grey[300],
      color: theme.palette.mode === 'dark' ? '#ffffff' : theme.palette.error.main
    }
  })
});
const ProjectGrid = muiStyled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
 margin:theme.spacing(2),
  marginBottom: theme.spacing(0.3),
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

const DropdownContainer = muiStyled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    margin: 'auto',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

const StyledSelect = muiStyled(Select)(({ theme }) => ({
  width: '40vw',
  margin: theme.spacing(0.5),
  '& .Select__control': {
    backgroundColor: theme.palette.background.paper,
    borderColor: theme.palette.divider,
  },
  '& .Select__menu': {
    backgroundColor: theme.palette.background.paper,
  },
  '& .Select__option': {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  [theme.breakpoints.down('md')]: {
    width: '40vw'
  }
}));

const SprintSelect = muiStyled('select')(({ theme }) => ({
  textDecoration: 'none',
  border: 'none',
  borderRadius: theme.shape.borderRadius,
  width: '15em',
  padding: theme.spacing(1),
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  [theme.breakpoints.down('md')]: {
    width: '10em'
  }
}));

const DoraTitle = muiStyled(Typography)(({ theme }) => ({
  textAlign: 'center',
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(2)
}));

function PodMetricsTalentDetailview({collapsed}) {
  const theme = useTheme();
  const [podData, setPodData] = useState([]);
  const [selectedPod, setSelectedPod] = useState('');
  const { user } = useSelector((state) => state.auth);
  const token = useAuthToken();
  const intl = useIntl();
  const [sprintData, setSprintData] = useState([]);
  const [selectedSprint, setSelectedSprint] = useState({
    name: '',
    startDate: '',
    endDate: ''
  });

  useEffect(() => {
    if (!token) return;
    fetchSprintfilter();
    
    const abortController = new AbortController();
    fetch(process.env.REACT_APP_TALENT_GETPODSFORMETRICSTALENT, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({ c_id: user.data[0].c_id || null }),
      signal: abortController.signal
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.statusCode === 200 && Array.isArray(data.body)) {
          setPodData(data.body);
        }
      })
      .catch((error) => {
        if (error.name !== 'AbortError') {
          console.error('Error fetching data:', error);
        }
      });

    return () => abortController.abort();
  }, [token, user.data]);

  const fetchSprintfilter = async () => {
    if (!token) return;
    
    try {
      const response = await fetch(process.env.REACT_APP_TALENT_SPRINTFILTERTAENT, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({ talent_id: user.data[0].c_id }),
      });
      const data = await response.json();
      if (data.statusCode === 200 && data.body?.sprint_filter) {
        setSprintData(data.body.sprint_filter);
      }
    } catch (error) {
      console.error('Error fetching sprint data:', error);
    }
  };

  const podOptions = podData.map((pod) => ({
    value: pod.pod_id,
    label: pod.pod_name,
  }));

  const handlePodSelect = (selectedOptions) => {
    setSelectedPod(selectedOptions.map((option) => option.value));
  };

  const handleSprintSelect = (e) => {
    const selectedSprintName = e.target.value;
    const selectedSprintData = sprintData.find(sprint => sprint.sprint_name === selectedSprintName);
    setSelectedSprint({
      name: selectedSprintName,
      startDate: selectedSprintData?.sprint_start_date || '',
      endDate: selectedSprintData?.sprint_end_date || ''
    });
  };

  return (
    <Container collapsed={collapsed} >
      <Title variant="h4">
        {intl.formatMessage({id: 'podAnalytics'})}
      </Title>
      
      <MetricsContainer  elevation={3}>
        <DropdownContainer>
          <Select
            options={podOptions}
            isMulti
            value={podOptions.filter((option) => selectedPod.includes(option.value))}
            onChange={handlePodSelect}
            placeholder={intl.formatMessage({id: 'selectPods'})}
            styles={selectStyles(theme)}
          />
          <SprintSelect value={selectedSprint.name} onChange={handleSprintSelect}>
            <option value="">{intl.formatMessage({id: 'selectSprint'})}</option>
            {sprintData.map((sprint, index) => (
              <option key={index} value={sprint.sprint_name}>
                {sprint.sprint_name}
              </option>
            ))}
          </SprintSelect>
        </DropdownContainer>

        <ContentWrapper>
          <ProjectGrid>
            <div><Detailstoriesmetricstalent initialPodIds={selectedPod} selectedSprint={selectedSprint.name}/></div>
            <div><Detaildefectsmetricstalent initialPodIds={selectedPod} selectedSprint={selectedSprint.name}/></div>
            <div><DetailBitbucketcodeTalent initialPodIds={selectedPod} selectedSprint={selectedSprint.name}
              sprintStartDate={selectedSprint.startDate} sprintEndDate={selectedSprint.endDate}/></div>
            <div><TimeDetailViewtalent initialPodIds={selectedPod} selectedSprint={selectedSprint.name}
              sprintStartDate={selectedSprint.startDate} sprintEndDate={selectedSprint.endDate}/></div>
            <div><DetailsTaskmetricsTalent initialPodIds={selectedPod} selectedSprint={selectedSprint.name}
              sprintStartDate={selectedSprint.startDate} sprintEndDate={selectedSprint.endDate}/></div>
          </ProjectGrid>
        </ContentWrapper>
      </MetricsContainer>

      <MetricsContainer elevation={3}>
        <ContentWrapper>
          <ProjectGrid>
            <div><CicdPiechartmanagerdetailvivewtalent initialPodIds={selectedPod}/></div>
            <div><ProjectPieCharttalent initialPodIds={selectedPod}/></div>
            <div><CloudCosttalent initialPodIds={selectedPod}/></div>
            <div><DetailbitbucketPRcountTalent initialPodIds={selectedPod}/></div>
            <div><TicketsDetailviewmetricsTalent initialPodIds={selectedPod}/></div>
          </ProjectGrid>
        </ContentWrapper>
      </MetricsContainer>

      <MetricsContainer elevation={3}>
        <ContentWrapper1>
          <ProjectGrid>
            <div ><Bitbucketcodebydaywisetalent initialPodIds={selectedPod}/></div>
            <div style={{ marginLeft:"2rem",}}><SprintVelocitiesCharttalent initialPodIds={selectedPod}/></div>
          </ProjectGrid>
        </ContentWrapper1>
      </MetricsContainer>

      <MetricsContainer elevation={3}>
      <DoraTitle variant="h6">
            {intl.formatMessage({id: 'doraMetrics'})}
          </DoraTitle>
        <ContentWrapper1>
         
          <ProjectGrid>
            <div><Dorametircslinegraphtalent/></div>
          </ProjectGrid>
        </ContentWrapper1>
      </MetricsContainer>
    </Container>
  );
}

export default PodMetricsTalentDetailview;