import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  Tooltip,
  CircularProgress,
  Alert,
  TablePagination,
  Stack,
  useTheme
} from '@mui/material';
import {
  Event,
  Refresh,
  Search,
  Visibility,
  CalendarMonth
} from '@mui/icons-material';
import { useAuthToken } from '../TokenContext';

const MeetingSummariesTable = () => {
  const theme = useTheme();
  const [meetings, setMeetings] = useState([]);
  const [filteredMeetings, setFilteredMeetings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [timeRange, setTimeRange] = useState('all');
  const [selectedMeeting, setSelectedMeeting] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const token = useAuthToken();

  const fetchMeetings = async () => {
    try {
      setLoading(true);
      const response = await fetch('https://zhdz4gnafl.execute-api.us-east-1.amazonaws.com/prod/meetingSummary', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error(response.status === 401 ? 'Unauthorized: Please login again' : 'Failed to fetch meetings');
      }

      const data = await response.json();
      const validMeetings = data.body.data.transcripts
        .filter(meeting => meeting.summary?.short_summary)
        .sort((a, b) => new Date(b.dateString) - new Date(a.dateString));

      setMeetings(validMeetings);
      setFilteredMeetings(validMeetings);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMeetings();
  }, []);

  useEffect(() => {
    filterMeetings();
  }, [searchQuery, startDate, endDate, timeRange, meetings]);

  const filterMeetings = () => {
    let filtered = [...meetings];

    if (searchQuery) {
      filtered = filtered.filter(meeting =>
        meeting.summary.short_summary.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    if (startDate) {
      filtered = filtered.filter(meeting =>
        new Date(meeting.dateString) >= new Date(startDate)
      );
    }
    
    if (endDate) {
      filtered = filtered.filter(meeting =>
        new Date(meeting.dateString) <= new Date(endDate)
      );
    }

    if (timeRange !== 'all') {
      const now = new Date();
      let startDate = new Date();
      let endDate = new Date();
      
      switch (timeRange) {
        case 'week':
          startDate.setDate(now.getDate() - 7);
          break;
        case 'month':
          startDate = new Date(now.getFullYear(), now.getMonth() - 1, 1);
          endDate = new Date(now.getFullYear(), now.getMonth(), 0);
          break;
        case '3months':
          startDate = new Date(now.getFullYear(), now.getMonth() - 3, 1);
          endDate = new Date(now.getFullYear(), now.getMonth(), 0);
          break;
        default:
          break;
      }
      
      filtered = filtered.filter(meeting => {
        const meetingDate = new Date(meeting.dateString);
        meetingDate.setHours(0, 0, 0, 0);
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(23, 59, 59, 999);
        return meetingDate >= startDate && meetingDate <= endDate;
      });
    }

    setFilteredMeetings(filtered);
    setPage(0);
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString('en-US', {
      weekday: 'short',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
        <CircularProgress sx={{ color: theme.palette.primary.main }} />
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error" sx={{ m: 2 }}>
        {error}
      </Alert>
    );
  }

  
    return (
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Paper 
          elevation={3} 
          sx={{ 
            p: 3,
            background: theme.palette.mode === 'dark' 
              ? 'linear-gradient(to right bottom, #1a1a1a, #2d2d2d)'
              : 'linear-gradient(to right bottom, #ffffff, #f8f9ff)',
            borderRadius: 2
          }}
        >
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
            <Typography 
              variant="h4" 
              component="h1"
              sx={{ 
                color: theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.dark,
                fontWeight: 600,
                letterSpacing: '-0.5px'
              }}
            >
              POD Sync Summaries
            </Typography>
            <Box>
              
              <Tooltip title="Refresh">
                <IconButton 
                  onClick={fetchMeetings} 
                  sx={{
                    backgroundColor: theme.palette.primary.light,
                    color: theme.palette.primary.dark,
                    '&:hover': {
                      backgroundColor: theme.palette.primary.main,
                      color: '#fff'
                    }
                  }}
                >
                  <Refresh />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
  
          {/* Filters Section */}
          <Paper 
            elevation={1} 
            sx={{ 
              p: 2, 
              mb: 3,
              backgroundColor: theme.palette.mode === 'dark' 
                ? 'rgba(255, 255, 255, 0.05)'
                : 'rgba(255, 255, 255, 0.8)',
              backdropFilter: 'blur(10px)',
              borderRadius: 1
            }}
          >
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Search in summaries..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search color="action" />
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      backgroundColor: theme.palette.background.paper,
                      '&:hover fieldset': {
                        borderColor: theme.palette.primary.main,
                      }
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <InputLabel>Quick Time Range</InputLabel>
                  <Select
                    value={timeRange}
                    onChange={(e) => {
                      setTimeRange(e.target.value);
                      if (e.target.value !== 'all') {
                        setStartDate('');
                        setEndDate('');
                      }
                    }}
                    label="Quick Time Range"
                    sx={{
                      backgroundColor: theme.palette.background.paper,
                      '&:hover': {
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: theme.palette.primary.main,
                        }
                      }
                    }}
                  >
                    <MenuItem value="all">All Time</MenuItem>
                    <MenuItem value="week">Last Week</MenuItem>
                    <MenuItem value="month">Last Month</MenuItem>
                    <MenuItem value="3months">Last 3 Months</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <Stack direction="row" spacing={2}>
                  {['start', 'end'].map((type) => (
                    <TextField
                      key={type}
                      type="date"
                      label={`${type === 'start' ? 'Start' : 'End'} Date`}
                      value={type === 'start' ? startDate : endDate}
                      onChange={(e) => {
                        if (type === 'start') setStartDate(e.target.value);
                        else setEndDate(e.target.value);
                        setTimeRange('all');
                      }}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      disabled={timeRange !== 'all'}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          backgroundColor: theme.palette.background.paper,
                          '&:hover fieldset': {
                            borderColor: theme.palette.primary.main,
                          }
                        }
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <CalendarMonth color="action" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  ))}
                </Stack>
              </Grid>
            </Grid>
          </Paper>
  
          {/* Table Section */}
          <Paper 
            sx={{ 
              width: '100%', 
              overflow: 'hidden',
              backgroundColor: theme.palette.mode === 'dark' 
                ? 'rgba(255, 255, 255, 0.05)'
                : 'rgba(255, 255, 255, 0.9)',
              borderRadius: 1
            }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      backgroundColor: theme.palette.mode === 'dark' 
                        ? theme.palette.grey[900]
                        : theme.palette.primary.dark,
                      color: '#fff',
                      fontWeight: 600
                    }}
                  >
                    <Box display="flex" alignItems="center">
                      <Event sx={{ mr: 1 }} />
                      Date & Time
                    </Box>
                  </TableCell>
                  <TableCell 
                    align="right"
                    sx={{
                      backgroundColor: theme.palette.mode === 'dark' 
                        ? theme.palette.grey[900]
                        : theme.palette.primary.dark,
                      color: '#fff',
                      fontWeight: 600
                    }}
                  >
                    Summary
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredMeetings
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((meeting, index) => (
                    <TableRow
                      key={meeting.dateString}
                      sx={{
                        backgroundColor: theme.palette.mode === 'dark'
                          ? index % 2 === 0 ? 'rgba(255, 255, 255, 0.02)' : 'transparent'
                          : index % 2 === 0 ? 'rgba(0, 0, 0, 0.02)' : 'transparent',
                        '&:hover': {
                          backgroundColor: theme.palette.mode === 'dark'
                            ? `${theme.palette.primary.dark}30`
                            : `${theme.palette.primary.light}30`,
                          transition: 'background-color 0.2s ease'
                        }
                      }}
                    >
                      <TableCell sx={{ color: theme.palette.text.primary }}>
                        {formatDate(meeting.dateString)}
                      </TableCell>
                      <TableCell align="right">
                        <Tooltip title="View Summary">
                          <IconButton
                            onClick={() => setSelectedMeeting(meeting)}
                            sx={{
                              color: theme.palette.primary.main,
                              '&:hover': {
                                backgroundColor: `${theme.palette.primary.main}20`
                              }
                            }}
                          >
                            <Visibility />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50]}
              component="div"
              count={filteredMeetings.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{
                borderTop: `1px solid ${theme.palette.divider}`,
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.text.primary
              }}
            />
          </Paper>
  
          {/* Summary Dialog */}
          <Dialog
            open={Boolean(selectedMeeting)}
            onClose={() => setSelectedMeeting(null)}
            maxWidth="md"
            fullWidth
            PaperProps={{
              sx: {
                borderRadius: 2,
                background: theme.palette.mode === 'dark'
                  ? 'linear-gradient(to right bottom, #1a1a1a, #2d2d2d)'
                  : 'linear-gradient(to right bottom, #ffffff, #f8f9ff)',
              }
            }}
          >
            <DialogTitle sx={{ 
              borderBottom: `1px solid ${theme.palette.divider}`,
              backgroundColor: theme.palette.mode === 'dark'
                ? theme.palette.grey[900]
                : theme.palette.primary.dark,
              color: '#fff'
            }}>
              <Box display="flex" alignItems="center">
                <Event sx={{ mr: 1 }} />
                {selectedMeeting && formatDate(selectedMeeting.dateString)}
              </Box>
            </DialogTitle>
            <DialogContent sx={{ mt: 2 }}>
              <Typography 
                variant="body1" 
                sx={{ 
                  color: theme.palette.text.primary,
                  lineHeight: 1.6
                }}
              >
                {selectedMeeting?.summary.short_summary}
              </Typography>
            </DialogContent>
          </Dialog>
        </Paper>
      </Container>
    );
  };
  

export default MeetingSummariesTable;