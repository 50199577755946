import React, { useEffect, useState, useRef } from "react";
import { Chart, registerables } from "chart.js";
import "chartjs-plugin-annotation";
import { useSelector } from "react-redux";
import { useAuthToken } from "../TokenContext";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper,
  Modal,
  Box,
  FormControl,
  Select,
  MenuItem
} from "@mui/material";

Chart.register(...registerables);

// Constants
const CHART_CONFIG = {
  dimensions: { width: 170, height: 170 },
  colors: {
    submitted: "#D3D3D3",
    approved: "#32CD32"
  }
};

const MODAL_STYLE = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  maxWidth: '800px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

// Reusable Components
const StyledTableHead = ({ children }) => (
  <TableHead
    sx={{
      backgroundColor: "#00B4D8",
      "& td, & th": {
        fontFamily: "Poppins,Medium",
        fontSize: "15px",
        color: "white",
        fontWeight: "600",
      },
    }}
  >
    {children}
  </TableHead>
);

const StyledTableRow = ({ children, index }) => (
  <TableRow
    sx={{
      "&:last-child td, &:last-child th": {
        border: 0,
        fontFamily: "Poppins,Medium",
        fontSize: "15px",
      },
      backgroundColor: index % 2 === 0 ? "#90E0EF" : "#caf0f8",
      "& td, & th": {
        fontFamily: "Poppins,Medium",
        fontSize: "15px",
        color: "black",
      },
    }}
  >
    {children}
  </TableRow>
);

const PRSummaryTable = ({ talentUsers, intl }) => (
  <TableContainer component={Paper}>
    <Table sx={{ maxWidth: 900, fontFamily: "Poppins,Medium", borderRadius: "10px" }}>
      <StyledTableHead>
        <TableRow>
          {['githubUser', 'submittedPRCount', 'approvedPRCount', 'totalPRCount'].map(key => (
            <TableCell key={key} align="right">
              {intl.formatMessage({ id: key })}
            </TableCell>
          ))}
        </TableRow>
      </StyledTableHead>
      <TableBody>
        {talentUsers?.map((project, index) => (
          <StyledTableRow key={index} index={index}>
            <TableCell align="right">{project.bitbucket_user}</TableCell>
            <TableCell align="right">{project.submitted_pr_count}</TableCell>
            <TableCell align="right">{project.approved_pr_count}</TableCell>
            <TableCell align="right">{project.total_pr_count}</TableCell>
          </StyledTableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

const DoughnutChart = ({ chartId, data, onClick }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const ctx = document.getElementById(chartId);
    if (!ctx) return;

    if (chartRef.current) {
      chartRef.current.destroy();
    }

    chartRef.current = new Chart(ctx, {
      type: "doughnut",
      data: {
        labels: data.labels,
        datasets: [{
          data: data.values,
          backgroundColor: [CHART_CONFIG.colors.submitted, CHART_CONFIG.colors.approved],
        }],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          annotation: {},
          legend: { display: false },
        },
        layout: {
          padding: 10
        },
        tooltips: {
          enabled: true,
          mode: "single",
          callbacks: {
            label: (tooltipItem, chartData) => {
              const value = chartData.datasets[0].data[tooltipItem.dataIndex];
              const label = chartData.labels[tooltipItem.dataIndex];
              return `${label}: ${value}`;
            },
          },
        },
        onClick,
      },
    });

    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, [data, chartId, onClick]);

  return <canvas id={chartId} {...CHART_CONFIG.dimensions} />;
};

const DetailbitbucketPRcountmanager = ({ initialPodIds }) => {
  const [metrics, setMetrics] = useState(null);
  const [talentUsers, setTalentUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  
  const chartId = useRef(`pr-chart-${crypto.randomUUID()}`).current;  const intl = useIntl();
  const { user } = useSelector((state) => state.auth);
  const token = useAuthToken();

  const fetchData = async (url, payload) => {
    if (!token) {
      console.log("Token is missing. API requests will not be made.");
      return;
    }

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();
      
      if (response.status === 500) {
        setErrorMessage(intl.formatMessage({ id: "noDataAvailable" }));
      } else if (data?.body) {
        const responseData = typeof data.body === 'string' ? JSON.parse(data.body) : data.body;
        setMetrics(responseData);
        setTalentUsers(responseData.talent_users);
      }
    } catch (error) {
      console.error("Error fetching metrics:", error);
      setErrorMessage(intl.formatMessage({ id: "errorFetchingData" }));
    }
  };

  useEffect(() => {
    const fetchMetrics = async () => {
      if (user.data[0].type === "stakeholder") {
        const payload = initialPodIds?.length > 0 
          ? { pod_id: initialPodIds }
          : { org_code: user.data[0].client_code };
        await fetchData(process.env.REACT_APP_STAKEHOLDER_PULLREQUESTMETRICSSTAKEHOLDER, payload);
      } else {
        await fetchData(process.env.REACT_APP_CLIENT_PULLREQUESTMETRICSMANAGER, {
          manager_id: user.data[0].emp_id,
          pod_id: initialPodIds || null,
        });
      }
    };

    fetchMetrics();
  }, [token, user.data[0].type, initialPodIds]);

  const getChartData = () => {
    if (!metrics) return null;

    if (selectedUser) {
      const userData = talentUsers.find(u => u.talent_user === selectedUser);
      return userData ? {
        labels: ["Submitted PRs", "Approved PRs"],
        values: [userData.submitted_pr_count, userData.approved_pr_count]
      } : null;
    }

    return {
      labels: ["Total Submitted PRs", "Total Approved PRs"],
      values: [metrics.total_prs?.total_submitted_prs, metrics.total_prs?.total_Approved_prs]
    };
  };

  const hasData = metrics && (
    metrics.total_prs?.total_submitted_prs !== 0 || 
    metrics.total_prs?.total_Approved_prs !== 0
  );

  return (
    <div className="chart-container">
      <div style={{ display: "flex", flexDirection: "column" }}>
        <h2 className="chart-heading">
          {intl.formatMessage({ id: "prCount" })}
        </h2>
        <br/>
        
          <select
            value={selectedUser}
            onChange={(e) => setSelectedUser(e.target.value)}
          style  ={{ width: "12rem"}}
          >
            <option value="">
              {intl.formatMessage({ id: "allReporters" })}
            </option>
            {talentUsers?.map((user) => (
              <option  key={user.talent_user} value={user.talent_user}>
                {user.talent_user}
              </option>
            ))}
          </select>
      

        <div className="chart-wrapper" style={{ ...CHART_CONFIG.dimensions, margin: "auto", textAlign: "center", marginTop: "20px" }}>
          {errorMessage ? (
            <p>{errorMessage}</p>
          ) : (
            hasData && metrics && (
              <DoughnutChart
                chartId={chartId}
                data={getChartData()}
                onClick={() => setModalIsOpen(true)}
              />
            )
          )}
        </div>
      </div>

      <Modal
        open={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
      >
        <Box sx={MODAL_STYLE}>
          <h6>{intl.formatMessage({ id: "prSummary" })}</h6>
          <PRSummaryTable talentUsers={talentUsers} intl={intl} />
        </Box>
      </Modal>
    </div>
  );
};

DetailbitbucketPRcountmanager.propTypes = {
  initialPodIds: PropTypes.array.isRequired,
};

export default DetailbitbucketPRcountmanager;