const messagesEn = {
  about: 'About',
  acceptanceCriteria: 'Acceptance Criteria',
  accomplishments: 'Accomplishments',
  actions: 'Actions',
  activity: 'Activity',
  activatePod: 'Activate Pod',
  activePods: 'Active Pods',
  add: 'Add',
  addBlocker: 'Add Blocker Information',
  addingCandidateWait: 'Adding Candidate Wait...',
  addPortfolio: 'Add Portfolio',
  addStakeholder: 'Add Stakeholder',
  addVideoUpdate: 'Add Video Update',
  findAgents:"Find Agent",
  all: 'All',
  allReporters: 'All Reporters',
  allRepoCheckins: 'All Repo Checkins',
  approve: 'Approve',
  approved: 'Approved',
  approvalRequests: 'Approval Requests',
  approvedPRCount: 'Approved PR Count',
  assignedItems: 'Assigned Items',
  availability: 'Availability',
  availableDate: 'Available date',
  backToPods: 'Back to My Pods',
  basicInformation: 'Basic Information',
  blocker: 'Blocker',
  blockerDetails: 'Blocker Details',
  blockers: 'Blockers',
  board: 'Board',
  browseTalent: 'Browse Talent',
  bugs: 'Bugs',
  calendlyUrl: 'Calendly URL',
  cancel: 'Cancel',
  candidates: 'Candidates',
  categories: 'Categories',
  certifications: 'Certifications',
  certificationUrl: 'Certification Url',
  checkBoxBlocker: 'Check this box if it is a blocker',
  ciCdPipeline: 'CICD Pipeline',
  clear: 'Clear',
  client: 'Client',
  clickHereUploadVideoClip: 'Click here to Upload a Video Clip !',
  cloudCost: 'Cloud Cost',
  code: 'Lines of Code',
  codeSmells: 'Code Smells',
  commentHere: 'comment here...',
  companyName: 'Company Name',
  copied: 'Copied!',
  copyright: 'Copyright © 2023-present Brilli Global, Inc. All rights reserved.',
  copyToClipboard: 'Copy to clipboard',
  cost: 'Cost',
  costDetails: 'Cost Details',
  country: 'Country',
  create: 'Create',
  createArt: 'Create ART',
  createGig: 'Create Gig',
  createPod: 'Create POD',
  createProfile: 'Create Profile',
  createProfileOpportunity: 'Create your profile in our talent database to be included for various remote work opportunities.',
  createTask: 'Create Task',
  createYourOwnPod: 'Create your own pod and browse for projects',
  creatingProfile: 'Creating profile',
  currency: 'Currency',
  currentlyAvailable: 'Currently Available',
  currentWeek: 'Current Week',
  dashboard: 'Dashboard',
  date: 'Date',
  dateRangeFilter: 'Date Range Filter',
  deactivatePod: 'Deactivate Pod',
  deactivateToUpdate: 'De-activate The Pod to Update the Pod!',
  defects: 'Defects',
  delete: 'Delete',
  deletePod: 'Delete Pod',
  description: 'Description',
  detailsForGitHubRepo: 'Details for GitHub Repository',
  done: 'close',
  Done: 'DONE',
  doraMetrics: 'Dora Metrics',
  dueDate: 'Due Date',
  duplicatedLinesDensity: 'Duplicated Lines Density',
  edit: 'Edit',
  education: 'Education',
  educationAndCertifications: 'Education & Certifications',
  email: 'E-Mail',
  podTime: "POD Time",  
  podVelocity: "POD Velocity",
    podBlockers: "POD Blockers",
    podsync:"POD Sync",
  endDate: 'End date',
  enterEmailToInvite: 'Enter an E-Mail to Invite',
  enterPodMission: 'Enter POD Mission',
  enterPodName: 'Enter Pod Name',
  enterStoryDescription: 'Enter a Story Description',
  executionDate: 'Execution Date',
  executionID: 'Execution ID',
  externalPod: 'External Pod',
  fileNameNoSpaces: 'The file name should not include spaces between all elements or characters',
  fileUpload: 'File Upload',
  findBestFreelancers: 'Find the best freelancers or talent pods to get your projects delivered at a faster pace!',
  findBestPod: 'Find PODs that match your experience/skillset',
  findPods: 'Find Pods',
  findTalent: 'Find Talent',
  findYourBestTalentPod: 'Find your best talent pod',
  firstName: 'First Name',
  focus: 'Focus',
  gitHubRepo: 'GitHub Repo',
  gitHubUsername: 'GitHub Username',
  github: 'Github',
  githubSummary: 'GitHub Summary',
  githubUrl: 'Github URL',
  githubUser: 'GitHub User',
  giveRating: 'Give Rating',
  globalWorkPlatform: 'Global Work platform',
  home: 'Home',
  hoursPerWeek: 'Hours per Week',
  inactivePod: 'Inactive Pod',
  individual: 'Individual',
  industry: 'Industry',
  inProgress: 'IN PROGRESS',
  institute: 'Institute',
  internalPod: 'Internal Pod',
  invite: 'Invite',
  invoices: 'Invoices',
  issueKey: 'Issue Key',
  issueStatus: 'Issue Status',
  issueSummary: 'Issue Summary',
  jiraIssue: 'Jira Issue',
  jiraIssues: 'Jira Issues',
  jiraItem: 'Jira Item',
  jiraTimeDetails: 'Time Log',
  jiraUsername: 'Jira Username',
  keepDoing: 'Keep Doing',
  keyword: 'Keyword',
  languageSpoken: 'Language Spoken',
  lastName: 'Last Name',
  latestSprintVelocity: 'Latest Sprint Velocity',
  linkedIn: 'LinkedIn',
  linkedInUrl: 'LinkedIn URL',
  linesOfCodeByDay: 'Lines of Code By day',
  listOfTalents: 'List of Talents',
  loading: 'Loading',
  loadingAcceptanceCriteria: 'Loading Acceptance Criteria',
  loadingStoryDescription: 'Loading Story Description',
  loadingTestCases: 'Loading Test Cases',
  logOut: 'Log Out',
  login: 'Login',
  manage: 'Manage',
  manageActivities: 'Manage your Daily Pod activities and Updates',
  manageProfile: 'Manage profile',
  manageProfileHere: 'Manage your profile here',
  managePod: 'Manage Pod',
  managePods: 'Manage all your pods and their activities',
  markComplete: 'Mark Complete',
  middleName: 'Middle Name',
  myPods: 'My Pods',
  myTasks: 'My Tasks',
  name: 'Name',
  ncloc: 'Ncloc',
  newActivityUpdate: 'New Activity Update',
  next: 'Next',
  noArtOptionsAvailable: 'No ART Options Available',
  noBlockers: 'There Are No blockers',
  noCandidates: 'No Candidates are Added',
  noCandidatesAddedToPod: 'No Candidates Added to Pod!',
  noCandidateReviews: 'There are no candidate reviews.',
  noData: 'No Data',
  noDataAvailable: 'No data available',
  noExperience: 'No Experience',
  noMatchingPods: 'There are no matching pods available',
  noNotifications: 'There are no notifications',
  noPodActivity: 'You Don\'t Have Any Pod Activity',
  noPods: 'You Don\'t have any Pod',
  noPodTasks: 'You don\'t have any Pod Tasks',
  noReviews: 'There are no reviews.',
  noStakeholders: 'There Are No stakeholders for this pod',
  noSubscription: 'You don\'t have any subscription plan',
  noVideoAvailable: 'No video available',
  notifications: 'Notification Recipient',
  otherInformation: 'Other Information',
  pending: 'Pending',
  pendingPods: 'Pending Pods',
  phoneNumber: 'Phone Number',
  pipelineExecutionDetails: 'Pipeline Execution Details',
  pipelineName: 'Pipeline Name',
  pipelineVersion: 'Pipeline Version',
  pleaseEnterStoryDescription: 'Please enter a story description and click on submit',
  pod: 'Pod',
  podActivities: 'Pod Activities',
  podActivity: 'Pod Activity',
  podAnalytics: 'POD Analytics',
  podAssist: 'POD Assist',
  podAutomation: 'POD Automation',
  podBoard: 'POD Board',
  podCategories: 'POD Categories',
  podDescription: 'Pod Description',
  podExperience: 'Pod Experience',
  podFeedback: 'POD Feedback',
  podMetrics: 'POD Metrics',
  podName: 'Pod Name',
  podQuery: 'POD Query',
  podRequests: 'POD REQUESTS',
  podRetro: 'Pod Retro',
  podSize: 'Pod Size',
  podTasks: 'POD Tasks',
  podTaskDescription: 'POD Task Description',
  podTaskDueDate: 'POD Task Due Date',
  podTaskName: 'POD Task Name',
  podTaskOwner: 'POD Task Owner',
  podTaskStatus: 'POD Task Status',
  podTechStack: 'Pod Tech Stack',
  podTeamMember: 'Pod Team Member',
  podType: 'Pod Type',
  portfolio: 'Portfolio',
  portfolioUrl: 'Portfolio URL',
  post: 'Post',
  postActivity: 'Post Activity',
  postPodActivity: 'Post Pod Activity',
  postRequirement: 'Post Requirement',
  posting: 'Posting...',
  postedDate: 'Posted Date',
  postedRequirements: 'Your Posted Pod Requirements',
  positionName: 'Position Name',
  prCount: 'PR Count',
  preferredWorkHours: 'Preferred Work Hours',
  prev: 'Prev',
  previousPods: 'Previous Pods',
  previousWeek: 'Previous Week',
  primarySkill: 'Primary Skill',
  privacyPolicy: 'Privacy Policy',
  privatePods: 'Private Pods',
  prSummary: 'PR Summary',
  projectKey: 'Project Key',
  profileLink: 'Profile Link',
  profileVisibility: 'Profile Visibility',
  public: 'Public',
  qualification: 'Qualification',
  ratePerHour: 'Rate /hr',
  ratePerMonth: 'Rate /month',
  recentSearches: 'Recent Searches',
  region: 'Region',
  register: 'Register',
  resetPassword: 'Reset Password',
  restricted: 'Restricted',
  retro: 'Retro',
  review: 'Review & Rating',
  revisionSummary: 'Revision Summary',
  revisionURL: 'Revision URL',
  resume: 'Resume',
  resumeLink: 'View Resume',
  rowsPerPage: 'Rows per page',
  save: 'Save',
  search: 'Search',
  searchProjects: 'Search for projects',
  secondarySkill: 'Secondary Skill',
  select: 'Select',
  selectArtName: 'Select ART Name',
  selectAUser: 'Select a User',
  selectFilter: 'Select Filter',
  selectItemsPerPage: 'Select items per page',
  selectJiraIssues: 'Select Jira Issues',
  selectPod: 'Select a Pod',
  selectPodTeamMember: 'Select Pod Team Member',
  selectPods: 'Select Pods',
  services: 'Services',
  setting: 'Setting',
  shareableLink: 'Shareable Link',
  signUpToGetStarted: '"Sign up" below to Get Started!',
  skills: 'Skills',
  skillsAndExpertise: 'Skills & Expertise',
  sonarqubeprojectkey: 'Sonarqube Project Key',
  sonarqubeStats: 'Sonarqube Stats',
  specialization: 'Specialization',
  sprintVelocity: 'Sprint Velocity',
  sprint: 'Sprint',
  spotsLeft: 'Spots Left',
  startDate: 'Start date',
  startDoing: 'Start Doing',
  status: 'Status',
  stopDoing: 'Stop Doing',
  storyDescription: 'Story Description',
  stories: 'Stories',
  submit: 'Submit',
  submittedDate: 'Submitted Date',
  submittedPRCount: 'Submitted PR Count',
  subscription: 'Subscription',
  talentApprovalRequests: 'Talent Approval Requests',
  talentCode: 'Talent Code',
  taskForm: 'Task Form',
  taskManager: 'Task Manager',
  taskName: 'Task Name',
  tasks: 'Tasks',
  teamMember: 'Team Member',
  techStack: 'TechStack',
  termsOfService: 'Terms of Service',
  testCases: 'Test Cases',
  time: 'Time',
  timeLog: 'Time Log',
  timeSubmit: 'Time Submit',
  timeZone: 'Time Zone',
  toDo: 'TO DO',
  todaysFocus: "Today's Focus",
  totalLinesChangedLastWeek: 'Total Lines Changed Last Week',
  totalLinesChangedThisWeek: 'Total Lines Changed This Week',
  totalPRCount: 'Total PR Count',
  university: 'University',
  update: 'Update',
  updateProfileHere: 'Update your profile here',
  updatingVisibility: 'Updating visibility...',
  upload: 'Upload',
  userStory: 'User Story',
  validFrom: 'Valid from',
  validTill: 'Valid till',
  videoUrl: 'Video Url',
  view: 'View',
  viewActivities: 'View Activities',
  viewDetails: 'View Details',
  viewPodActivities: 'View Pod Activities',
  viewPodDetails: 'View Pod Details',
  viewProfile: 'View Profile',
  viewTasks: 'View Tasks',
  viewTimeEntries: 'View Time Entries',
  viewVideo: 'View Video',
  visitFindTalent: "Please visit the 'Find Talent' page and add talented individuals to your pod.",
  vulnerabilities: 'Vulnerabilities',
  welcome: 'Welcome to Globl.ai!',
  workExperience: 'Work Experience',
  yourPod: 'Your Pod',
  yourPods: 'Your Pods',
  yourPostedPodActivity: 'Your Posted Pod Activity'
};
export default messagesEn;