import React, { useState, useEffect } from 'react';
import { NavHashLink } from 'react-router-hash-link';
import { useSelector } from 'react-redux';
import { useAuthToken } from '../../TokenContext';
import { useIntl } from 'react-intl';
import axios from 'axios';
import moment from 'moment';
import { FaSortUp, FaSortDown } from 'react-icons/fa';
import {
  Table, TableBody, TableCell,Box,styled, TableContainer, TableHead, 
  TableRow, Paper, Chip, Button, Menu, MenuItem
} from '@mui/material';

const Container = styled(Box)(({ theme }) => ({
  overflow: 'hidden',
  backgroundColor: theme.palette.mode === 'dark' ? '#1E1E1E' : 'white',
  borderRadius: '1rem',
  height: '35rem',
  overflowY: 'scroll',
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  padding: '20px',
  boxShadow: theme.palette.mode === 'dark'
    ? '0 4px 20px rgba(0, 0, 0, 0.4)'
    : '0 4px 20px rgba(0, 0, 0, 0.1)',
  '&::-webkit-scrollbar': {
    display: 'none'
  },
  '@media screen and (min-width: 320px) and (max-width: 1080px)': {
    width: '20rem'
  }
}));

const Title = styled(Box)(({ theme }) => ({
  height: '8%',
  color: theme.palette.mode === 'dark' ? '#ffffff' : 'black',
  fontWeight: 700,
  fontSize: '18px',
  fontFamily: '\'Poppins\', sans-serif',
  transition: 'color 0.3s ease',
  '&:hover': {
    color: theme.palette.mode === 'dark' ? '#4FC3F7' : '#00B4D8'
  }
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#2D2D2D' : 'white',
  borderRadius: '10px !important',
  boxShadow: theme.palette.mode === 'dark'
    ? '0 4px 12px rgba(0, 0, 0, 0.3)'
    : '0 4px 12px rgba(0, 0, 0, 0.1)'
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? '#E0E0E0' : 'black',
  borderBottom: `1px solid ${theme.palette.mode === 'dark' ? '#404040' : '#ccc'}`,
  fontFamily: 'Poppins, Medium',
  fontSize: '15px',
  '&.header': {
    color: theme.palette.mode === 'dark' ? '#4FC3F7' : '#17a2b8',
    fontWeight: 'bold'
  }
}));

const StyledFilterButton = styled(Button)(({ theme }) => ({
  marginBottom: '1rem',
  backgroundColor: theme.palette.mode === 'dark' ? '#2D2D2D' : 'white',
  color: theme.palette.mode === 'dark' ? '#E0E0E0' : 'rgba(0, 0, 0, 0.87)',
  borderColor: theme.palette.mode === 'dark' ? '#404040' : 'rgba(0, 0, 0, 0.23)',
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark' ? '#404040' : '#f5f5f5'
  }
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#0277BD' : '#2196F3',
  color: 'white',
  marginRight: '5px'
}));

const StyledMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundColor: theme.palette.mode === 'dark' ? '#2D2D2D' : 'white',
    color: theme.palette.mode === 'dark' ? '#E0E0E0' : 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.palette.mode === 'dark'
      ? '0 4px 12px rgba(0, 0, 0, 0.3)'
      : '0 4px 12px rgba(0, 0, 0, 0.1)'
  },
  '& .MuiMenuItem-root': {
    '&:hover': {
      backgroundColor: theme.palette.mode === 'dark' ? '#404040' : '#f5f5f5'
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.mode === 'dark' ? '#0277BD' : '#2196F3',
      color: 'white'
    }
  }
}));

const ViewButton = styled(Button)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? '#4FC3F7' : '#00B4D8',
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark' 
      ? 'rgba(79, 195, 247, 0.08)' 
      : 'rgba(0, 180, 216, 0.08)'
  }
}));

// Update tableStyles to use theme
const tableStyles = (theme) => ({
  container: {
    backgroundColor: theme.palette.mode === 'dark' ? '#2D2D2D' : 'white',
    borderRadius: '10px',
    fontFamily: 'Poppins, Medium',
    borderCollapse: 'separate'
  },
  header: {
    '& td, & th': {
      fontFamily: 'Poppins, Medium',
      fontSize: '15px',
      color: theme.palette.mode === 'dark' ? '#4FC3F7' : '#17a2b8',
      fontWeight: 'bold'
    }
  },
  row: {
    '&:last-child td, &:last-child th': {
      border: 0,
      fontFamily: 'Poppins, Medium',
      fontSize: '15px'
    },
    '& td, & th': {
      fontFamily: 'Poppins, Medium',
      fontSize: '15px',
      color: theme.palette.mode === 'dark' ? '#E0E0E0' : 'black',
      fontWeight: '600',
      borderBottom: `1px solid ${theme.palette.mode === 'dark' ? '#404040' : '#ccc'}`,
      borderRight: 'none',
      borderLeft: 'none'
    },
    '&:hover': {
      backgroundColor: theme.palette.mode === 'dark' ? '#353535' : '#f5f5f5'
    }
  }
});

const TeamMemberFilter = ({ selectedMembers, onMemberSelect, activities, intl }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const uniqueMembers = [...new Set(activities.map(data => data.pod_candidates))];

  return (
    <>
      <Button 
        onClick={e => setAnchorEl(e.currentTarget)} 
        variant="outlined" 
        style={{ marginBottom: '1rem' }}
      >
        {selectedMembers.length === 0 
          ? intl.formatMessage({ id: 'selectPodTeamMember' })
          : selectedMembers.map(item => (
              <Chip
                key={item}
                label={item}
                color="primary"
                style={{ marginRight: 5 }}
              />
            ))
        }
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {uniqueMembers.map(name => (
          <MenuItem
            key={name}
            onClick={() => {
              onMemberSelect(name);
              setAnchorEl(null);
            }}
            selected={selectedMembers.includes(name)}
          >
            {selectedMembers.includes(name) && (
              <Chip label={name} color="primary" style={{ marginRight: 5 }} />
            )}
            {name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

const ActivityTable = ({ activities, sortOrder, onSort, onVideoView, intl }) => (
  <TableContainer component={Paper}>
    <Table sx={tableStyles.container}>
      <TableHead sx={tableStyles.header}>
        <TableRow>
          <TableCell onClick={onSort}>
            {intl.formatMessage({ id: 'submittedDate' })}
            {sortOrder === 'asc' ? <FaSortUp /> : <FaSortDown />}
          </TableCell>
          <TableCell>{intl.formatMessage({ id: 'teamMember' })}</TableCell>
          <TableCell>{intl.formatMessage({ id: 'jiraIssue' })}</TableCell>
          <TableCell>{intl.formatMessage({ id: 'todaysFocus' })}</TableCell>
          <TableCell>{intl.formatMessage({ id: 'accomplishments' })}</TableCell>
          <TableCell>{intl.formatMessage({ id: 'blockers' })}</TableCell>
          <TableCell>{intl.formatMessage({ id: 'videoUrl' })}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {activities.length > 0 ? (
          activities.map((item, index) => (
            <TableRow key={index} sx={tableStyles.row}>
              <TableCell>{moment(item.createdAt).format("MM-DD-YYYY")}</TableCell>
              <TableCell>{item.pod_candidates}</TableCell>
              <TableCell>{item.jira_issue}</TableCell>
              <TableCell>{item.today_focus}</TableCell>
              <TableCell>{item.accomplishments}</TableCell>
              <TableCell>{item.blockers}</TableCell>
              <TableCell>
                {item.loom_url ? (
                  <Button onClick={() => onVideoView(item.loom_url, item.id)}>
                    {intl.formatMessage({ id: "viewVideo" })}
                  </Button>
                ) : (
                  <p>{intl.formatMessage({ id: 'noDataAvailable' })}</p>
                )}
              </TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={7}>
              {intl.formatMessage({ id: 'noDataAvailable' })}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  </TableContainer>
);

const Invoices = ({ expanded }) => {
  const [activities, setActivities] = useState([]);
  const [sortOrder, setSortOrder] = useState('desc');
  const [selectedMembers, setSelectedMembers] = useState([]);
  const { user } = useSelector(state => state.auth);
  const token = useAuthToken();
  const intl = useIntl();

  const fetchActivities = async () => {
    if (!token) return;

    try {
      const response = await axios.post(
        process.env.REACT_APP_CLIENT_GETLATESTPODACTIVITIESDASHBOARD,
        { clientID: user.data[0].emp_id },
        { headers: { Authorization: token, 'Content-Type': 'application/json' }}
      );

      const sortedData = response.data.data?.activities?.sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
      }) || [];

      setActivities(sortedData);
    } catch (error) {
      console.error("Error fetching activities:", error);
      setActivities([]);
    }
  };

  const handleVideoView = async (loomUrl, id) => {
    try {
      const [filename] = loomUrl.split('?')[0].split('/').slice(-1);
      const fullUrl = `${process.env.REACT_APP_CLOUDFRONTs3}${filename}`;
      
      const response = await axios.post(
        process.env.REACT_APP_TALENT_CLOUDFRONTAPI,
        { url: fullUrl }
      );

      const { signedUrl } = JSON.parse(response.data.body);
      if (!signedUrl) {
        throw new Error('Signed URL not found');
      }

      window.open(signedUrl, '_blank');
    } catch (error) {
      console.error('Error processing video:', error);
      alert(intl.formatMessage({ id: 'videoError' }));
    }
  };

  useEffect(() => {
    fetchActivities();
  }, [token]);

  const filteredActivities = selectedMembers.length === 0
    ? activities
    : activities.filter(data => selectedMembers.includes(data.pod_candidates));

  return (
    <div expanded={expanded}>
      {activities.length === 0 ? (
        <p>{intl.formatMessage({ id: 'noPodActivity' })}</p>
      ) : (
        <Container>
          <NavHashLink to="/PodActivity" smooth>
            <Title>{intl.formatMessage({ id: 'podActivity' })}</Title>
          </NavHashLink>

          <TeamMemberFilter
            selectedMembers={selectedMembers}
            onMemberSelect={member => {
              setSelectedMembers(prev => 
                prev.includes(member)
                  ? prev.filter(item => item !== member)
                  : [...prev, member]
              );
            }}
            activities={activities}
            intl={intl}
          />

          <ActivityTable
            activities={filteredActivities}
            sortOrder={sortOrder}
            onSort={() => {
              setSortOrder(prev => prev === 'asc' ? 'desc' : 'asc');
              setActivities(prev => [...prev].reverse());
            }}
            onVideoView={handleVideoView}
            intl={intl}
          />
        </Container>
      )}
    </div>
  );
};

export default Invoices;