import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import { Box, Typography, TextField, Button, IconButton, Paper } from '@mui/material';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import axios from 'axios';
import { useIntl } from 'react-intl';
import { useAuthToken } from "../TokenContext";
import { useSelector } from "react-redux";

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  fontFamily: 'Poppins, sans-serif',
  width: '100%',
  height: '100vh',
  overflow: 'hidden',
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : '#e0f7fa',
  '@media screen and (min-width:320px) and (max-width:1080px)': {
    width: '90vw',
    marginLeft: '4rem',
    height: '100vh',
  }
}));

const MainContent = styled(Box)(({ theme, showRecentSearches, sidebarWidth ,collapsed }) => ({
  marginLeft: collapsed ? "60px" : "240px",
  display:"flex",
  justifyContent: 'center',
  alignItems: 'center',
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'space-between',
  marginTop: '5rem',
  width: `calc(100% - ${showRecentSearches ? sidebarWidth + 'px' : '0'})`,
  overflow: 'hidden',
}));

const MessagesContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  overflowY: 'auto',
  padding: '20px',
  marginBottom: '80px',
  scrollbarWidth: 'none',
  msOverflowStyle: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  }
}));

const Message = styled(Paper)(({ theme, type }) => ({
  backgroundColor: type === 'user' 
    ? theme.palette.mode === 'dark' ? theme.palette.primary.dark : '#caf0f8'
    : type === 'response' 
    ? theme.palette.mode === 'dark' ? theme.palette.primary.main : '#0077b6'
    : type === 'error' 
    ? theme.palette.error.main 
    : 'transparent',
  color: type === 'user'
    ? theme.palette.mode === 'dark' ? theme.palette.primary.contrastText : 'black'
    : type === 'response'
    ? '#fff'
    : type === 'error'
    ? '#fff'
    : theme.palette.text.primary,
  padding: '10px',
  borderRadius: '10px',
  marginBottom: '10px',
  maxWidth: '80%',
  wordWrap: 'break-word',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.main : '#00b4d8',
  color: 'white',
  height: '50px',
  borderRadius: '6px',
  fontSize: '1rem',
  margin: '10px',
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.dark : '#0090ad',
  }
}));

const InsightCard = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#caf0f8',
  color: theme.palette.mode === 'dark' ? theme.palette.text.primary : 'black',
  padding: '10px',
  borderRadius: '10px',
  marginBottom: '10px',
  maxWidth: '80%',
}));

const InputContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  maxWidth: '800px',
  margin: '20px 0',
});

const PodInsights = ({ collapsed }) => {
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [question, setQuestion] = useState('');
  const intl = useIntl();
  const [showRecentSearches, setShowRecentSearches] = useState(false);
  const [sidebarWidth, setSidebarWidth] = useState(350);
  const theme = useTheme();

  const { user } = useSelector(state => state.auth);
  const token = useAuthToken();

  const handleSendClick = async () => {
    setIsLoading(true);
    setErrorMessage(null);

    const headers = {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    };

    const payload = {
      "manager_id": user?.data[0]?.emp_id,
      "question": question
    };

    try {
      const response = await axios.post(
        process.env.REACT_APP_CLIENT_PODINSIGHTS,
        payload,
        { headers: headers }
      );

      const responseData = response?.data?.pod_insights;

      if (responseData) {
        const insights = responseData.split('\n • ').slice(1);
        const formattedResponse = insights.map((insightData, index) => {
          if (insightData.includes("blocker:")) {
            const [blocker, reportedDate, talentName] = insightData.split(/reported_date:|talent_name:/).map(part => part?.trim());
            
            return (
              <InsightCard key={index}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>{blocker}</Typography>
                <Typography variant="body2" sx={{ marginLeft: '10px', fontStyle: 'italic' }}>
                  <strong>Reported Date:</strong> {reportedDate}
                </Typography>
                <Typography variant="body2" sx={{ marginLeft: '10px', fontStyle: 'italic' }}>
                  <strong>Talent Name:</strong> {talentName}
                </Typography>
              </InsightCard>
            );
          } else {
            return (
              <InsightCard key={index}>
                <Typography variant="body1">{insightData}</Typography>
              </InsightCard>
            );
          }
        });

        setMessages([...messages, { type: 'user', text: question }, { type: 'response', text: formattedResponse }]);
      } else {
        throw new Error('No pod insights found in the response');
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
      setErrorMessage('Error fetching data: ' + error.message);
    }
  };

  return (
    <Container>
      <MainContent showRecentSearches={showRecentSearches} sidebarWidth={sidebarWidth}  collapsed={collapsed}>      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between', padding: '0 20px' }}>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>Pod Insights</Typography>
        
        </Box>
        <MessagesContainer>
          {messages.map((message, index) => (
            <Message key={index} type={message.type}>
              {message.type === 'response' ? message.text : <Typography>{message.text}</Typography>}
            </Message>
          ))}
          {isLoading && <Message type="loading"><Typography>Loading PodInsights ...</Typography></Message>}
          {errorMessage && <Message type="error"><Typography>{errorMessage}</Typography></Message>}
        </MessagesContainer>
        <InputContainer>
          <TextField
            fullWidth
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            placeholder="Enter your question here"
            variant="outlined"
            sx={{
              '& .MuiOutlinedInput-root': {
                height: '4rem',
                backgroundColor: theme.palette.background.paper,
              }
            }}
          />
          <StyledButton onClick={handleSendClick}>Send</StyledButton>
        </InputContainer>
      </MainContent>
    </Container>
  );
};

export default PodInsights;