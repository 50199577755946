import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { 
  Modal, 
  Box, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper,
  Typography,
  Select,
  MenuItem,
  Card,
  CardContent,
  CircularProgress,
  Container,
  styled
} from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';   
import { useAuthToken } from "../TokenContext";
import { useIntl } from 'react-intl';
import PropTypes from "prop-types";

const StyledCard = styled(Card)(({ theme }) => ({
  cursor: 'pointer',
  transition: 'box-shadow 0.3s',
  width: '300px',
  height: '250px',
  '&:hover': {
    boxShadow: theme.shadows[4]
  }
}));

const LoadingContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '140px'
});

const StyledModalBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(3),
  width: '75%',
  maxHeight: '80vh',
  overflowY: 'auto'
}));



const CardStories = ({ initialPodIds, selectedSprint }) => {
  const [metrics, setMetrics] = useState([]);
  const [openStories, setOpenStories] = useState([]);
  const [closedStories, setClosedStories] = useState([]);
  const [selectedReporter, setSelectedReporter] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalUserStoryCount, setTotalUserStoryCount] = useState({ 
    open_user_stories: 0, 
    completed_user_stories: 0 
  });

  const token = useAuthToken();
  const intl = useIntl();
  const { user } = useSelector(state => state.auth);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        if (!token) {
          console.log('Token is missing. API requests will not be made.');
          return;
        }

        if (user.data[0].type === "stakeholder") {
          const payload = {
            pod_id: initialPodIds || [],
            org_code: user.data[0].client_code,
            sprint_name: selectedSprint || ''
          };

          const response = await fetch(process.env.REACT_APP_STAKEHOLDER_JIRASTORIESMETRICSSTAKEHOLDER, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token,
            },
            body: JSON.stringify(payload),
          });

          const data = await response.json();
          const parsedData = JSON.parse(data.body);
          handleResponseData(parsedData);
        } else {
          if (initialPodIds?.length > 0) {
            await fetchMetricsForPodId();
          } else {
            await fetchMetrics();
          }
        }
      } catch (error) {
        console.log('Error fetching metrics:', error);
        resetState();
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [token, user.data[0].type, initialPodIds, selectedSprint]);

  const handleResponseData = (parsedData) => {
    setTableData([...parsedData.open_stories, ...parsedData.closed_stories]);
    if (Array.isArray(parsedData.reporters)) {
      setMetrics(parsedData.reporters);
      setOpenStories(parsedData.open_stories);
      setClosedStories(parsedData.closed_stories);
      setTotalUserStoryCount(parsedData.total_user_story_count);
    } else {
      resetState();
    }
  };

  const resetState = () => {
    setMetrics([]);
    setOpenStories([]);
    setClosedStories([]);
    setTableData([]);
    setTotalUserStoryCount({ open_user_stories: 0, completed_user_stories: 0 });
  };

  const fetchMetrics = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_CLIENT_GITJIRAUSERSTORIES, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify({
          manager_id: user.data[0].emp_id,
          sprint_name: selectedSprint,
        }),
      });

      const data = await response.json();
      const parsedData = JSON.parse(data.body);
      handleResponseData(parsedData);
    } catch (error) {
      console.log('Error fetching metrics:', error);
      resetState();
    }
  };

  const fetchMetricsForPodId = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_CLIENT_JIRASTORIESMETRICSMANAGERPODID, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify({
          pod_id: initialPodIds,
          sprint_name: selectedSprint,
        }),
      });

      const data = await response.json();
      const parsedData = JSON.parse(data.body);
      handleResponseData(parsedData);
    } catch (error) {
      console.log('Error fetching metrics:', error);
      resetState();
    }
  };

  const getDisplayData = () => {
    if (!selectedReporter) {
      return totalUserStoryCount;
    }
    const reporter = metrics.find((item) => item.Reporter === selectedReporter);
    return reporter ? {
      open_user_stories: reporter.open_user_stories,
      completed_user_stories: reporter.completed_user_stories
    } : { open_user_stories: 0, completed_user_stories: 0 };
  };

  return (
    <Box sx={{ height: '100%' }}>
      <Box 
        display="flex"
        flexDirection="column" 
        alignItems="center"
        sx={{ py: 3 }}
      >
        <Typography 
          variant="h5" 
          gutterBottom 
          sx={{ fontWeight: 'medium', mb: 3 }}
        >
          {intl.formatMessage({ id: 'stories' })}
        </Typography>
        
        <Select
          value={selectedReporter}
          onChange={(e) => setSelectedReporter(e.target.value)}
          sx={{ width: 240, mb: 2 }}
          displayEmpty
        >
          <MenuItem value="">
            {intl.formatMessage({ id: 'allReporters' })}
          </MenuItem>
          {metrics.map(item => (
            <MenuItem key={item.Reporter} value={item.Reporter}>
              {item.Reporter}
            </MenuItem>
          ))}
        </Select>

        <StyledCard onClick={() => !isLoading && setModalIsOpen(true)}>
          <CardContent sx={{ p: 3, minHeight: '160px' }}>
           
                <Box display="flex" alignItems="center" mb={5.5}>
                  <AssignmentIcon style={{ width: 32, height: 32, marginRight: 12, color: '#2196f3' }} />
                  <Typography variant="h6"   sx={{ 
            fontWeight: 600,
            background: 'linear-gradient(135deg, #2196f3 0%, #1976d2 100%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            letterSpacing: '0.5px'
          }}
        >Stories</Typography>
                </Box>
                {isLoading ? (
              <LoadingContainer>
                <CircularProgress />
              </LoadingContainer>
            ) : (
              <>
                <Box>
                  <Box display="flex" justifyContent="space-between" mb={1}>
                    <Typography color="text.secondary">TO DO:</Typography>
                    <Typography fontWeight="bold">{getDisplayData().open_user_stories}</Typography>
                  </Box>
                  <Box display="flex" justifyContent="space-between">
                    <Typography color="text.secondary">DONE:</Typography>
                    <Typography fontWeight="bold">{getDisplayData().completed_user_stories}</Typography>
                  </Box>
                </Box>
              </>
            )}
          </CardContent>
        </StyledCard>
      </Box>

      <Modal
        open={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        aria-labelledby="stories-modal-title"
      >
        <StyledModalBox>
          <Typography id="stories-modal-title" variant="h6" gutterBottom>
            Stories Details
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow sx={{ bgcolor: 'primary.light' }}>
                  <TableCell>{intl.formatMessage({ id: 'reporter' })}</TableCell>
                  <TableCell>{intl.formatMessage({ id: 'issueKey' })}</TableCell>
                  <TableCell>{intl.formatMessage({ id: 'issueSummary' })}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map((item, index) => (
                  <TableRow 
                    key={index}
                    sx={{ '&:nth-of-type(odd)': { bgcolor: 'action.hover' } }}
                  >
                    <TableCell>{item.Reporter}</TableCell>
                    <TableCell>{item.issue_key}</TableCell>
                    <TableCell>{item.issue_summary}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </StyledModalBox>
      </Modal>
    </Box>
  );
};

CardStories.propTypes = {
  initialPodIds: PropTypes.array.isRequired,
  selectedSprint: PropTypes.string
};

export default CardStories;