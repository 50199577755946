import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { useSelector } from "react-redux";
import { useAuthToken } from "../TokenContext";
import { cardShadow, hoverEffect } from "../utils";
import Avatar from "./assets/MaskGroup.png";

const Pods = () => {
  const token = useAuthToken();
  const { user } = useSelector((state) => state.auth);

  const [podData, setPodData] = useState({
    podDetails: [],
    candidates: [],
    activities: [],
  });

  const fetchPodData = async () => {
    try {
      const { data } = await axios.post(
        process.env.REACT_APP_PARTNER_PPODPARTNERLISTPODS,
        { partner_id: user.data[0].id },
        {
          headers: { Authorization: token, "Content-Type": "application/json" },
        }
      );

      setPodData({
        podDetails: data.data?.podDetails || [],
        candidates: data.data?.candidates || [],
        activities: data.data?.activities || [],
      });
    } catch (error) {
      console.error("Failed to fetch pod data:", error);
    }
  };

  useEffect(() => {
    fetchPodData();
  }, [token, user.data]);

  const getTimeSince = (date) => {
    const intervals = [
      { label: "year", seconds: 31536000 },
      { label: "month", seconds: 2592000 },
      { label: "day", seconds: 86400 },
      { label: "hour", seconds: 3600 },
      { label: "minute", seconds: 60 },
      { label: "second", seconds: 1 },
    ];

    const seconds = Math.floor((Date.now() - new Date(date).getTime()) / 1000);
    const interval = intervals.find((i) => i.seconds < seconds);
    const count = Math.floor(seconds / interval.seconds);
    return `${count} ${interval.label}${count !== 1 ? "s" : ""} ago`;
  };

  const PodSection = () => (
    <Section>
      <Title>Your Pod</Title>
      <ContentCard>
        {!podData.podDetails.length ? (
          <EmptyState>
            <p>You Don't have any Pod</p>
            <a href="/PartnerCreatepod">
              <Button className="btn btn-primary">Create Pod</Button>
            </a>
          </EmptyState>
        ) : (
          podData.podDetails.map((pod) => (
            <PodDetails key={pod.id}>
              <Header>
                <Avatars src={Avatar} alt="Pod Avatar" />
                <PodName>{pod.podName}</PodName>
              </Header>

              <InfoGrid>
                <InfoItem>
                  <Label>Pod Size:</Label>
                  <Value>{pod.podsize}</Value>
                </InfoItem>
                <InfoItem>
                  <Label>Skills:</Label>
                  <SkillsList>
                    {JSON.parse(pod.skills).map((skill, index) => (
                      <Skill key={index}>{skill.skill}</Skill>
                    ))}
                  </SkillsList>
                </InfoItem>
              </InfoGrid>

              <CandidatesSection>
                <SubTitle>Candidates</SubTitle>
                {podData.candidates.length ? (
                  podData.candidates.map((candidate) => (
                    <CandidateRow key={candidate.id}>
                      <CandidateAvatar src={candidate.profilePicture} alt="" />
                      <CandidateName>
                        {candidate.firstName} {candidate.lastName}
                      </CandidateName>
                    </CandidateRow>
                  ))
                ) : (
                  <EmptyMessage>No Candidates Added to Pod!</EmptyMessage>
                )}
              </CandidatesSection>
            </PodDetails>
          ))
        )}
      </ContentCard>
    </Section>
  );

  const ActivitySection = () => (
    <Section>
      <Title>Pod Activity</Title>
      <ContentCard>
        {podData.activities.length ? (
          podData.activities.map((activity) => (
            <ActivityCard key={activity.id}>
              <Header>
                <Avatars src={activity.profilePicture || ""} alt="" />
                <ActivityInfo>
                  <ActivityTitle>
                    {activity.categories}
                    <MemberName>
                      {activity.firstName} {activity.lastName}
                    </MemberName>
                  </ActivityTitle>
                  <TimeStamp>{getTimeSince(activity.createdAt)}</TimeStamp>
                </ActivityInfo>
              </Header>
              <ActivityContent>{activity.today_update}</ActivityContent>
            </ActivityCard>
          ))
        ) : (
          <EmptyMessage>There are no reviews.</EmptyMessage>
        )}
      </ContentCard>
    </Section>
  );

  return (
    <Container>
      <PodSection />
      <ActivitySection />
    </Container>
  );
};

const Container = styled.div`
  padding: 6rem 6.5rem;
  @media (max-width: 1080px) {
    padding: 2rem 1rem;
  }
`;

const Section = styled.div`
  margin-bottom: 2rem;
`;

const Title = styled.h4`
  font-weight: 600;
  margin-bottom: 1rem;
`;

const PodDetails = styled.div`
  padding: 1rem;
  border-bottom: 1px solid #cecece;
`;

const PodName = styled.h3`
  font-weight: 600;
  margin: 0;
  font-size: 1.1rem;
`;

const ContentCard = styled.div`
  background-color: #f2f4f5;
  border-radius: 15px;
  padding: 1rem;
  box-shadow: ${cardShadow};

  &:hover {
    box-shadow: ${hoverEffect};
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
`;

const Avatars = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
`;

const InfoGrid = styled.div`
  display: grid;
  gap: 1rem;
  margin: 1rem 0;
`;

const InfoItem = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const Label = styled.span`
  font-weight: 600;
  min-width: 100px;
`;

const Value = styled.span`
  color: ${(props) => props.theme.textColor};
`;

const SkillsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

const Skill = styled.span`
  background: #e0e0e0;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
`;

const CandidatesSection = styled.div`
  background: rgba(166, 194, 206, 0.3);
  padding: 1rem;
  border-radius: 15px;
  margin-top: 1rem;
`;

const SubTitle = styled.h5`
  font-weight: 600;
  margin-bottom: 1rem;
`;

const CandidateRow = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
`;

const CandidateAvatar = styled.img`
  width: 35px;
  height: 35px;
  border-radius: 50%;
`;

const CandidateName = styled.span`
  font-weight: 500;
`;

const ActivityCard = styled.div`
  padding: 1rem;
  border-bottom: 1px solid #cecece;
`;

const ActivityInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const ActivityTitle = styled.div`
  font-weight: 600;
`;

const MemberName = styled.span`
  margin-left: 1rem;
  font-size: 13px;
`;

const TimeStamp = styled.span`
  font-size: 13px;
  color: #666;
`;

const ActivityContent = styled.p`
  margin-top: 1rem;
`;

const EmptyState = styled.div`
  text-align: center;
  padding: 2rem;
`;

const EmptyMessage = styled.p`
  color: #666;
  text-align: center;
`;

const Button = styled.button`
  background: #1dbf73;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;

  &:hover {
    opacity: 0.9;
  }
`;

export default Pods;
