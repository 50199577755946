import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  TextField,
  MenuItem,
  Button,
  Box,
  Chip,
  IconButton,
  styled
} from '@mui/material';
import { Avatar } from '@mui/material';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  '& .MuiCardContent-root': {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  }
}));

const FilterContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4)
}));

const AgentHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(2)
}));
const ContentWrapper = styled(Box)(({ theme, collapsed }) => ({
  marginLeft: collapsed ? "60px" : "240px",
  padding: theme.spacing(3),
  [theme.breakpoints.down("sm")]: {
    marginLeft: "1rem",
    padding: theme.spacing(1),
  },
}));
const SkillsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  flexWrap: 'wrap',
  marginBottom: theme.spacing(2)
}));

const AgentFooter = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: 'auto'
}));

const AIAgentSearch = ({collapsed}) => {
  const [filters, setFilters] = useState({
    primarySkill: '',
    type: '',
    budget: ''
  });

  // Sample data for AI agents
  const agents = [
    {
      id: 1,
      name: 'Scrum Master',
      type: 'global',
      icon: 'G',
      primarySkill: 'Agile Management',
      secondarySkill: 'Team Coordination',
      budget: 1000,
      description: 'Expert in Agile methodologies and team management'
    },
    {
      id: 2,
      name: 'Product Owner',
      type: 'internal',
      icon: 'I',
      primarySkill: 'Product Management',
      secondarySkill: 'Stakeholder Communication',
      budget: 800,
      description: 'Specialized in product backlog management and stakeholder alignment'
    },
    {
      id: 3,
      name: 'QA Agent',
      type: 'partner',
      icon: 'P',
      primarySkill: 'Test Automation',
      secondarySkill: 'Quality Assurance',
      budget: 600,
      description: 'Advanced testing and quality assurance capabilities'
    }
  ];

  // Extract unique values from actual data
  const primarySkills = [...new Set(agents.map(agent => agent.primarySkill))];
  const agentTypes = [
    { value: 'global', label: 'Global (G)' },
    { value: 'internal', label: 'Internal (I)' },
    { value: 'partner', label: 'Partner (P)' }
  ];

  const budgetRanges = [
    { value: '0-500', label: 'Under $500' },
    { value: '500-750', label: '$500 - $750' },
    { value: '750-1000', label: '$750 - $1000' },
    { value: '1000+', label: 'Over $1000' }
  ];

  const handleFilterChange = (event) => {
    setFilters({
      ...filters,
      [event.target.name]: event.target.value
    });
  };

  const filteredAgents = agents.filter(agent => {
    if (filters.primarySkill && agent.primarySkill !== filters.primarySkill) return false;
    if (filters.type && agent.type !== filters.type) return false;
    if (filters.budget) {
      const [min, max] = filters.budget.split('-').map(num => 
        num.endsWith('+') ? [parseInt(num), Infinity] : parseInt(num)
      );
      if (Array.isArray(min)) {
        if (agent.budget < min[0]) return false;
      } else if (max) {
        if (agent.budget < min || agent.budget > max) return false;
      }
    }
    return true;
  });

  const getTypeColor = (type) => {
    switch(type) {
      case 'global': return 'primary';
      case 'internal': return 'success';
      case 'partner': return 'secondary';
      default: return 'default';
    }
  };

  return (
    <ContentWrapper collapsed={collapsed}>
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" sx={{ mb: 4 }}>
        AI Agent Search
      </Typography>
      
      {/* Filters */}
      <FilterContainer>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <TextField
              select
              fullWidth
              label="Primary Skill"
              name="primarySkill"
              value={filters.primarySkill}
              onChange={handleFilterChange}
            >
              <MenuItem value="">All Skills</MenuItem>
              {primarySkills.map(skill => (
                <MenuItem key={skill} value={skill}>{skill}</MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              select
              fullWidth
              label="Agent Type"
              name="type"
              value={filters.type}
              onChange={handleFilterChange}
            >
              <MenuItem value="">All Types</MenuItem>
              {agentTypes.map(type => (
                <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              select
              fullWidth
              label="Budget Range"
              name="budget"
              value={filters.budget}
              onChange={handleFilterChange}
            >
              <MenuItem value="">All Budgets</MenuItem>
              {budgetRanges.map(range => (
                <MenuItem key={range.value} value={range.value}>{range.label}</MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </FilterContainer>

      {/* Results */}
      <Grid container spacing={3}>
        {filteredAgents.map(agent => (
          <Grid item xs={12} sm={6} md={4} key={agent.id}>
            <StyledCard>
              <CardContent>
                <AgentHeader>
                  <Typography variant="h6" component="div">
                    {agent.name}
                  </Typography>
                  <Avatar 
                    sx={{ 
                      bgcolor: theme => theme.palette[getTypeColor(agent.type)].main,
                      width: 35,
                      height: 35,
                      fontSize: '1rem'
                    }}
                  >
                    {agent.icon}
                  </Avatar>
                </AgentHeader>
                
                <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                  {agent.description}
                </Typography>
                
                <SkillsContainer>
                  <Chip 
                    label={`Primary: ${agent.primarySkill}`}
                    color="primary"
                    size="small"
                  />
                  <Chip 
                    label={`Secondary: ${agent.secondarySkill}`}
                    color="secondary"
                    size="small"
                  />
                </SkillsContainer>
                
                <AgentFooter>
                  <Typography variant="body2" color="text.secondary">
                    Budget: ${agent.budget}/month
                  </Typography>
                  <Button variant="contained" color="primary" size="small">
                    Subscribe
                  </Button>
                </AgentFooter>
              </CardContent>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
    </Box>
     </ContentWrapper>
  );
};

export default AIAgentSearch;