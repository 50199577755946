import { green } from "@mui/material/colors";
import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { postpodproject, postproject } from "./action/auth";
import { useDispatch, useSelector } from "react-redux";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import Select from "react-select";
import profile from "./assets/profile_img.png";
import { event } from "jquery";
import styled from "styled-components";
import { style } from "@mui/system";
import star from "./assets/star.png";
import { Podcasts } from "@mui/icons-material";
import defaultimg from "./assets/defaultimge.png";
import "./BrowseTalent.css";
import CloseIcon from "@mui/icons-material/Close";
import allergens from "./allergens";
import OptionsOutsideSelect from "../OptionsOutsideSelect";
import CreatePodSkillspage from "./CreatePodSkillspage";
import PodCreatedSuccessFullyCan from "./PodCreatedSuccessFullyCan";
import PersonIcon from "@mui/icons-material/Person";
import AWS from "aws-sdk";
import S3FileUpload from "react-s3";
import EditIcon from "@mui/icons-material/Edit";
import { useAuthToken } from "../TokenContext";

const config = {
  bucketName: process.env.REACT_APP_BUCKETNAME,
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESSKEYID,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY,
};

const S3Config = {
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESSKEYID,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY,
};

const PartnerManagePod = (props) => {
  const token = useAuthToken();

  const [userData, setUserData] = useState([]);
  const [userSearchData, setUserSearchData] = useState([]);
  const [name, setName] = useState("");
  const [profession, setProfession] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [job, setJob] = useState("");
  const [data, SetData] = useState([]);
  const [Team, SetTeam] = useState([]);
  const [Candidatetoadd, SetCandidatetoadd] = useState("");
  const [Addcandidatescreen, setAddcandidatescreen] = useState(false);
  const [getid, setGetid] = useState("");
  const [PodStatus, setPodStatus] = useState("");
  const [AuthImage, setAuthImage] = useState("");
  const [Picturepod, setPicturepod] = useState("");
  const [Prevskill, setPrevskill] = useState([]);

  const [s3img, setS3img] = useState("");
  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(4);
  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
  const [value, setValue] = useState("default");
  const [mis, setMis] = useState("");
  const [bdg, setBdg] = useState("");
  const [mr, setMr] = useState("");
  const [pri, setPri] = useState("");
  const [sec, setSec] = useState("");
  const [tz, setTz] = useState("");
  const [Title, setTitle] = useState("");
  const [avail, setAvail] = useState("");
  const [pref, setPref] = useState("");
  const [rep, setRep] = useState([]);
  const [Candidatedata, setCandidatedata] = useState([]);
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const [len, setLen] = useState(0);
  const handleClick = (event) => {
    setcurrentPage(Number(event.target.id));
  };
  const available = (val) => {
    setAvail(val.target.value);
  };

  const jobtitle = (val) => {
    setTitle(val.target.value);
  };
  const resetAvailableField = () => {
    setAvail("");
  };
  const resetTitleField = () => {
    setAvail("");
  };
  const preferfield = (val) => {
    setPref(val.target.value);
  };
  const resetPreferField = () => {
    setPref("");
  };
  const handleUserInput = (val) => {
    setMr(val.target.value);
  };
  const resetInputField = () => {
    setMr("");
  };
  const handleJob = (val) => {
    setMis(val.target.value);
  };
  const resetJobField = () => {
    setMis("");
  };
  const budgetfield = (val) => {
    setBdg(val.target.value);
  };
  const resetBudgetField = () => {
    setBdg("");
  };
  const primaryfield = (val) => {
    // alert(val.target.value);
    setPri(val.target.value);
  };
  const resetPrimaryField = () => {
    setPri("");
  };
  const secondaryfield = (val) => {
    setSec(val.target.value);
  };
  const resetSecondaryField = () => {
    setSec("");
  };
  const timezonefield = (val) => {
    setTz(val.target.value);
  };
  const resetTimezoneField = () => {
    setTz("");
  };
  const handleChange = (e) => {
    setValue(e.target.value);
  };
  const pages = [];
  for (let i = 1; i <= Math.ceil(rep.length / itemsPerPage); i++) {
    pages.push(i);
  }
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = rep.slice(indexOfFirstItem, indexOfLastItem);
  const renderPageNumbers = pages.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li
          key={number}
          id={number}
          onClick={handleClick}
          className={currentPage == number ? "active" : null}
        >
          {number}
        </li>
      );
    } else {
      return null;
    }
  });

  // const { isLoggedIn, user } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const [Loading, setLoading] = useState(false);

  var scrollTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(
    (token) => {
      try {
        const data = {
          clientID: user.data[0].emp_id,
        };

        const headers = {
          Authorization: token, // Replace with your actual token
          "Content-Type": "application/json", // You can add more headers as needed
        };
        // https://sfhr6xe082.execute-api.us-east-1.amazonaws.com/dev/getteammembers
        axios
          .post(process.env.REACT_APP_CLIENT_GETTEAMMEMBERS, data, {
            headers: headers,
          })
          .then((res) => {
            SetTeam(res.data.data);
          })
          .catch((err) => {
            console.log("catch", err);
          });
      } catch (error) {
        console.log(error);
      }
    },
    [token]
  );

  function filter(c_id) {
    return getcandidates.map((data) => {
      return data.c_id == c_id;
    });
  }

  function filter32(c_id) {
    return Poddata.candidatesid.map((data) => {
      return data.id == c_id;
    });
  }

  const [podname, setPodName] = useState("");
  const [techStack, setTechStack] = useState("");
  const [skills, setSkills] = useState([]);
  const [podtype, setpodtype] = useState("");

  const [podSize, setPodsize] = useState("");

  const [podDescription, setPodDescription] = useState("");
  const [budget1, setBudget1] = useState("");
  const [countrypod, setCountrypod] = useState("");
  const [timezonepod, setTimezonepod] = useState("");
  const [multiskill, setMultiskill] = useState([]);
  const [Image, setImage] = useState("");

  const [selected, setSelected] = useState([]);
  const handleSelectChange = (values) => {
    setSelected(values);
  };
  const [Poddata, setPoddata] = useState({
    podname: "",
    podDescription: "",
    podsize: "",
    podtype: "",
    techStack: "",
    skills: [{}],
    budget: "",
    country: "",
    timezone: "",

    canID: user.data[0].c_id,
    candidatesid: [],
    podID: [],
  });

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "white",
      height: "50px",
      border: "2px solid #B7B7B7",
      borderRadius: "10px",
      color: "grey",
      fontWeight: "600",
      paddingLeft: "12px",
    }),
  };

  //  const onInputChangepodName =(e)=>{
  //   setPodName(e.target.value);
  // }
  // const onInputChangetimezonepod =(e)=>{
  //   setTimezonepod(e.target.value);
  // }
  // const onInputChangecountrypod =(e)=>{
  //   setCountrypod(e.target.value);
  // }

  const handleSelectChange3 = (values) => {
    setSelected(values);
    var testskill = [];

    values.map(function (ext) {
      var c = {
        label: ext.label,
      };
      testskill.push(c);
    });

    setSkills(testskill);
  };

  const onInputChangetechstack = (e) => {
    setTechStack(e.target.value);
  };
  const onInputChangebudget1 = (e) => {
    setBudget1(e.target.value);
  };

  const addCandidate = async (c_id, name) => {
    if (filter32(c_id).includes(true)) {
      alert("Already Added");
    } else {
      Poddata.candidatesid.push({ id: c_id, name: name });
      try {
        const data = {
          provider_id: user.data[0].id,
          candidateID: c_id,
        };
        const headers = {
          Authorization: token, // Replace with your actual token
          "Content-Type": "application/json", // You can add more headers as needed
        };

        // https://a8da94tu54.execute-api.us-east-1.amazonaws.com/dev/ppod-partner-addteammember-into-pod
        const response = await axios.post(
          process.env.REACT_APP_PARTNER_PPODPARTNERADDTEAMMEMBERINTOPOD,
          data,
          { headers: headers }
        );

        if (response.data.data == "Create the pod to add the candidate!") {
          alert("Create the pod to add the candidate!");
          setLoading(false);
        } else {
          // dispatch(login(user.data[0].email, "employer"));

          setTimeout(() => {
            alert("Candidate Added to your team!");
            setLoading(false);
            // window.location.reload();
          }, 5000);
        }
      } catch (error) {
        console.log(error);
        alert(error.data);
        setLoading(false);
      }

      setAddcandidatescreen(false);
    }
  };

  const [getpoddetails, setGetpoddetails] = useState({});
  const [candcount, setCandcount] = useState(0);
  const [getcandidates, setGetcandidates] = useState([]);
  const [podskill, setPodskill] = useState([]);
  const email = user.data[0].email;

  const portfolioUrl = user.data[0].portfolioUrl;

  const firstName = user.data[0].firstName;

  const lastName = user.data[0].lastName;

  const handleSubmitOptions = (e) => {
    e.preventDefault();
    let selectedvalue = [];
    selected.map((item) => {
      selectedvalue.push({ skill: item.label });
    });

    const candidates_idss = getcandidates.map((n) => n.c_id);

    var options = {
      podName: podname,
      podtype: podtype,
      techStack: techStack,
      skills: selected,
      podDescription: podDescription,
      budget: budget1,
      timezone: timezonepod,
      country: countrypod,
      canID: user.data[0].id,
      location: "",
      candidatesid: candidates_idss,
      id: getid,
      picture: s3img,
      podsize: podSize,
    };

    // setPoddata({
    //   podname: res.data.data.podDetails[0].podName,
    //   podDescription: res.data.data.podDetails[0].podDescription,
    //   podsize: res.data.data.podDetails[0].podsize,
    //   podtype: res.data.data.podDetails[0].podtype,
    //   techStack: res.data.data.podDetails[0].techStack,
    //   skills: res.data.data.podDetails[0].skills,
    //   budget: res.data.data.podDetails[0].budget,
    //   country: res.data.data.podDetails[0].country,
    //   timezone: res.data.data.podDetails[0].timezone,

    //   canID: user.data[0].id,
    //   candidatesid: candidates_idss,
    //   podID: res.data.data.podDetails[0].id,
    // })

    // https://a8da94tu54.execute-api.us-east-1.amazonaws.com/dev/ppod-partner-editpod
    fetch(process.env.REACT_APP_PARTNER_PPODPARTNEREDITPOD, {
      method: "PUT",
      headers: { Authorization: token, "Content-Type": "application/json" },
      body: JSON.stringify(options),
    }).then(() => {
      alert("Pod is Created Successfully.");
      window.location.href = "/Pods";
    });
  };

  useEffect(() => {
    const body = {
      partner_id: user.data[0].id,
    };
    const headers = {
      Authorization: token, // Replace with your actual token
      "Content-Type": "application/json", // You can add more headers as needed
    };
    console.log();

    // https://a8da94tu54.execute-api.us-east-1.amazonaws.com/dev/ppod-partner-list_pods
    axios
      .post(process.env.REACT_APP_PARTNER_PPODPARTNERLISTPODS, body, {
        headers: headers,
      })

      .then((res) => {
        // console.log("this is get data", res.data.data.podDetails.picture);

        // console.log("this is get data2", res.data.data.podDetails.podName);
        const candidates_idss = res.data.data.candidates.map((n) => n.c_id);

        setPoddata({
          podname: res.data.data.podDetails[0].podName,
          podDescription: res.data.data.podDetails[0].podDescription,
          podsize: res.data.data.podDetails[0].podsize,
          podtype: res.data.data.podDetails[0].podtype,
          techStack: res.data.data.podDetails[0].techStack,
          skills: res.data.data.podDetails[0].skills,
          budget: res.data.data.podDetails[0].budget,
          country: res.data.data.podDetails[0].country,
          timezone: res.data.data.podDetails[0].timezone,
          canID: user.data[0].id,
          candidatesid: candidates_idss,
          podID: res.data.data.podDetails[0].id,
        });
        setGetcandidates(res.data.data.candidates);
        setCandcount(res.data.data.candidates.length);
        setGetid(res.data.data.podDetails[0].id);
        // setGetpoddetails(res.data.data.podDetails)
        setPodStatus(res.data.data.podDetails[0].status);
        setPodsize(res.data.data.podDetails[0].podsize);
        setpodtype(res.data.data.podDetails[0].podtype);
        setTechStack(res.data.data.podDetails[0].techStack);
        setPodDescription(res.data.data.podDetails[0].podDescription);
        setPodName(res.data.data.podDetails[0].podName);
        setCountrypod(res.data.data.podDetails[0].location);
        setPicturepod(res.data.data.podDetails[0].picture);

        setTimezonepod(res.data.data.podDetails[0].pod_timezone);

        var kks = res.data.data.podDetails[0].skills;
        var ab = kks;

        var collas = [];
        ab.map(function (item) {
          // console.log("-------", item.label);
          var selectvaluefromallergens = allergens.filter(
            (element) => element.label == item.label
          );
          //console.log(selectvaluefromallergens, "--->selectvaluefromallergens")
          if (selectvaluefromallergens.length > 0)
            collas.push(selectvaluefromallergens[0]);
        });
        setSelected(collas);

        const S3Config = {
          region: "us-east-1",
          accessKeyId: "AKIA6CNQFW6ISZIFRSNJ",
          secretAccessKey: "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",
        };

        setS3img(res.data.data.podDetails.picture);

        const myBucket = new AWS.S3(S3Config);

        const URL_EXPIRATION_TIME = 900;

        const params = {
          Bucket: "globldataprod",
          Key: res.data.data.podDetails.picture,
          Expires: URL_EXPIRATION_TIME,
        };

        let s3url = params.Key;

        myBucket.getSignedUrl("getObject", params, (err, url) => {
          // console.log("cjijcijfijfjijfijfijijidji", err, url)
          setAuthImage(url);
        });
      })
      .catch((err) => {
        console.log("catch", err);
      });
  }, [token]);

  const onInputChange = (e) => {
    console.log(e.target);
    setPoddata((prevState) => {
      return { ...prevState, [e.target.id]: e.target.value };
    });
  };

  const onInputChangebudget = (e) => {
    console.log(e.target);
    setPoddata((prevState) => {
      return { ...prevState, budget: e.target.value };
    });
  };

  const onImageUpload = (e) => {
    const file = e.target.files[0];

    if (file) {
      setImage(URL.createObjectURL(file));
    }
    S3FileUpload.uploadFile(file, config)
      .then((data) => {
        setS3img(data.key);

        setPoddata((prevState) => {
          return { ...prevState, picture: data.key };
        });
      })
      .catch((err) => {
        console.log(err);
      });

    // setState((prevState) => {
    //   return { ...prevState, [e.target.id]: e.target.value };
    // });
  };

  const renderData = (rep) => {
    return (
      <Pega>
        <Pagination2>
          <div className="paginationcontent">
            <Num>
              <ol className="pageNumbers">
                <Num2>
                  <li>
                    <button
                      onClick={handlePrevbtn}
                      disabled={currentPage == pages[0] ? true : false}
                    >
                      Prev
                    </button>
                  </li>
                  <Numbers>{renderPageNumbers} </Numbers>
                  <li>
                    <button
                      onClick={handleNextbtn}
                      disabled={
                        currentPage == pages[pages.length - 1] ? true : false
                      }
                    >
                      {" "}
                      Next
                    </button>
                  </li>
                </Num2>
              </ol>
              <Num1>
                <p className="length"> {Candidatedata.length} Candidates</p>{" "}
                <br></br>
              </Num1>
            </Num>
            <ol className="ol">
              {rep && rep.length > 0
                ? rep.map((dev, index) => {
                    return (
                      <li className="smg" key={index}>
                        <div className="name">
                          {dev.profilePicture == "" ? (
                            <img
                              src={defaultimg}
                              alt=""
                              style={{
                                width: 50,
                                height: 50,
                                borderRadius: "50px",
                              }}
                            ></img>
                          ) : (
                            <img
                              style={{
                                width: "50px",
                                height: "50px",
                                borderRadius: "50px",
                              }}
                              src={
                                "https://prismpoddata.s3.amazonaws.com/" +
                                dev.profilePicture
                              }
                            ></img>
                          )}
                          <p className="firstname">{dev.firstName}</p>{" "}
                        </div>{" "}
                        <div className="name2">
                          <img
                            style={{ width: "13px", height: "13px" }}
                            src={star}
                          ></img>
                          4.5<p className="place">{dev.country}</p>{" "}
                        </div>{" "}
                        <div>
                          <p className="name1">{dev.work_position}</p>
                        </div>
                        <div className="skill">
                          {" "}
                          <p className="about">{dev.about}</p>
                        </div>
                        <div className="time">
                          <p className="role">{dev.primaryskill}</p>{" "}
                          <p className="role">{dev.secondaryskill}</p>{" "}
                        </div>
                        <Col>
                          <p className="time1">{dev.timezone}</p>
                          <p className="role">{dev.availability}</p>{" "}
                          <p className="role">{dev.workhours}</p>{" "}
                          {dev.currentStatus == "Yes" ? (
                            <p className="role" style={{ color: "green" }}>
                              Currently Available
                            </p>
                          ) : (
                            <>
                              <p className="role" style={{ color: "red" }}>
                                Currently Unavailable
                              </p>{" "}
                              <p className="role" style={{ color: "red" }}>
                                Available Date : {dev.availabledate}
                              </p>
                            </>
                          )}
                          <p className="budget1">
                            {filter(dev.c_id).includes(true) ? (
                              <>
                                <p className="budget1">
                                  Already added to your pod.
                                </p>
                              </>
                            ) : (
                              <>
                                <button
                                  className="sub"
                                  onClick={() => {
                                    // if (countrypod == dev.country.toLowerCase()) {

                                    var then = new Date(dev.availabledate);
                                    var now = new Date();

                                    const msBetweenDates = Math.abs(
                                      then.getTime() - now.getTime()
                                    );

                                    const daysBetweenDates =
                                      msBetweenDates / (24 * 60 * 60 * 1000);

                                    if (dev.currentStatus == "No") {
                                      if (daysBetweenDates < 30) {
                                        var alredy_candidate = getcandidates;
                                        alredy_candidate.push({
                                          c_id: dev.c_id,
                                        });

                                        setGetcandidates(alredy_candidate);
                                        addCandidate(dev.c_id);
                                      } else {
                                        alert(
                                          "Sorry, candidate is not available to add to pod!"
                                        );
                                      }
                                    } else {
                                      var alredy_candidate = getcandidates;
                                      alredy_candidate.push({ c_id: dev.c_id });
                                      console.log(alredy_candidate);
                                      setGetcandidates(alredy_candidate);
                                      addCandidate(dev.c_id, dev.firstName);
                                    }
                                    // } else {
                                    //   alert("The candidate you are adding is not in your region!");
                                    // }
                                  }}
                                >
                                  Add to Pod{" "}
                                </button>
                              </>
                            )}
                          </p>
                        </Col>
                      </li>
                    );
                  })
                : "No data"}
            </ol>
          </div>
        </Pagination2>
      </Pega>
    );
  };
  useEffect(() => {
    per();
    setLen(rep.length);
  }, [token]);
  const handleNextbtn = () => {
    setcurrentPage(currentPage + 1);
    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };
  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1);
    if ((currentPage - 1) % pageNumberLimit == 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };
  const per = () => {
    const kk = mis != "default" ? mis : "";
    const tk = mr != "default" ? mr : "";
    const bd = bdg != "default" ? bdg : "";
    const ps = pri != "default" ? pri : "";
    const ss = sec != "default" ? sec : "";
    const tmz = tz != "default" ? tz : "";
    const avb = avail != "default" ? avail : "";
    const Prefered = pref != "default" ? pref : "";

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json", Authorization: token },
      body: JSON.stringify({
        project_location: kk,
        project_name: Title,
        // "skills" : "",
        budget: bd,
        primaryskill: ps,
        secondaryskill: ss,
        timezone: tmz,
        availability: avb,
        workhours: Prefered,
        about: mr,
        partner_id: user.data[0].id,
      }),
    };
    // https://a8da94tu54.execute-api.us-east-1.amazonaws.com/dev/ppod-partner-talent-list
    // https://pp0mvip9j1.execute-api.us-east-1.amazonaws.com/dev/browseTalent
    // https://a8da94tu54.execute-api.us-east-1.amazonaws.com/dev/ppod-partner-talent-list
    fetch(process.env.REACT_APP_PARTNER_PPODPARTNERTALENTLIST, requestOptions)
      .then((response) => response.json())
      .then((json) => {
        setRep(json.data);
        setCandidatedata(json.data);
      });
    // console.log(rep);
  };
  function getData(val) {
    setMis(val.target.value);
  }
  function hey(val) {
    setMr(val.target.value);
  }
  function budget(val) {
    setBdg(val.target.value);
  }
  function priskill(val) {
    setPri(val.target.value);
  }
  function secskill(val) {
    setSec(val.target.value);
  }
  function tmzone(val) {
    setTz(val.target.value);
  }
  function Availability(val) {
    setAvail(val.target.value);
  }
  function preferworkhours(val) {
    setPref(val.target.value);
  }

  function onInputChangesize(val) {
    setPodsize(val.target.value);
  }

  function onInputChangetype(val) {
    setpodtype(val.target.value);
  }
  function onInputChangestack(val) {
    setTechStack(val.target.value);
  }

  function onInputChangedescription(val) {
    setPodDescription(val.target.value);
  }

  const [selectedOption, setSelectedOption] = useState(null);
  const handlechange = (e) => {
    setSelectedOption(e);
  };
  const handlePageClick = (pdata) => {
    console.log(pdata.selected);
  };
  const refreshPage = () => {
    window.location.reload();
  };
  const deleteitem = (id, pid) => {
    let confirm = window.confirm(
      "Are you sure you want to remove the candidate!"
    );

    if (confirm == true) {
      const candidates_idss = getcandidates.filter((n) => n.c_id != id);
      setGetcandidates(candidates_idss);

      // console.log(id)
      // console.log(getid)
      // console.log("@$@$@$@$@$@$@$@$@$@$@  : : ", id)
      let kk = JSON.stringify(id);
      let kk1 = JSON.stringify(getid);
      const requestOptions = {
        method: "POST",
        headers: {
          Authorization: token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          candidateID: kk,
          podID: kk1,
        }),
      };

      // https://7fb7dwo1li.execute-api.us-east-1.amazonaws.com/dev/deleteCandiatePod
      fetch(process.env.REACT_APP_TALENT_DELETECANDIDATEPOD, requestOptions)
        .then((response) => {
          response.json();
          window.location.reload();
        })
        .then((data) => {
          // console.log("!!!!!!!!!!!!!!!!!!!!!!!!type", data);
        });
    }
  };

  console.log(podSize);

  const [selectedone, setSelectedone] = useState([]);
  const handleSelectChangeone = (values) => {
    setSelectedone(values);
  };
  const countryoptions = [
    { value: "usa", label: "USA" },
    { value: "canada", label: "Canada" },
    { value: "india", label: "India" },
    { value: "australia", label: "Australia" },
    { value: "united_kingdom", label: "United Kingdom" },
    { value: "germany", label: "Germany" },
    { value: "france", label: "France" },
    { value: "china", label: "China" },
    { value: "japan", label: "Japan" },
    { value: "brazil", label: "Brazil" },
    { value: "south_africa", label: "South Africa" },
    { value: "mexico", label: "Mexico" },
    { value: "italy", label: "Italy" },
    { value: "spain", label: "Spain" },
    { value: "russia", label: "Russia" },
    { value: "south_korea", label: "South Korea" },
    { value: "new_zealand", label: "New Zealand" },
    { value: "singapore", label: "Singapore" },
    { value: "united_arab_emirates", label: "United Arab Emirates" },
    { value: "saudi_arabia", label: "Saudi Arabia" },
    { value: "argentina", label: "Argentina" },
    { value: "netherlands", label: "Netherlands" },
    { value: "sweden", label: "Sweden" },
    { value: "switzerland", label: "Switzerland" },
    { value: "norway", label: "Norway" },
    { value: "denmark", label: "Denmark" },
    { value: "finland", label: "Finland" },
    { value: "portugal", label: "Portugal" },
    { value: "poland", label: "Poland" },
    { value: "turkey", label: "Turkey" },
    { value: "indonesia", label: "Indonesia" },
    { value: "malaysia", label: "Malaysia" },
    { value: "thailand", label: "Thailand" },
    { value: "vietnam", label: "Vietnam" },
    { value: "philippines", label: "Philippines" },
    { value: "egypt", label: "Egypt" },
    { value: "nigeria", label: "Nigeria" },
    { value: "kenya", label: "Kenya" },
    { value: "ghana", label: "Ghana" },
    { value: "colombia", label: "Colombia" },
    { value: "chile", label: "Chile" },
    { value: "peru", label: "Peru" },
    { value: "venezuela", label: "Venezuela" },
    { value: "pakistan", label: "Pakistan" },
    { value: "bangladesh", label: "Bangladesh" },
    { value: "sri_lanka", label: "Sri Lanka" },
    { value: "nepal", label: "Nepal" },
    { value: "bhutan", label: "Bhutan" },
    { value: "maldives", label: "Maldives" },
    { value: "iceland", label: "Iceland" },
    { value: "luxembourg", label: "Luxembourg" },
    { value: "qatar", label: "Qatar" },
    { value: "oman", label: "Oman" },
    { value: "kuwait", label: "Kuwait" },
    { value: "bahrain", label: "Bahrain" },
    { value: "iraq", label: "Iraq" },
    { value: "iran", label: "Iran" },
    { value: "afghanistan", label: "Afghanistan" },
    { value: "israel", label: "Israel" },
    { value: "jordan", label: "Jordan" },
    { value: "lebanon", label: "Lebanon" },
    { value: "morocco", label: "Morocco" },
    { value: "algeria", label: "Algeria" },
    { value: "tunisia", label: "Tunisia" },
    { value: "libya", label: "Libya" },
    { value: "ethiopia", label: "Ethiopia" },
    { value: "tanzania", label: "Tanzania" },
    { value: "uganda", label: "Uganda" },
    { value: "zimbabwe", label: "Zimbabwe" },
    { value: "zambia", label: "Zambia" },
    { value: "mozambique", label: "Mozambique" },
    { value: "botswana", label: "Botswana" },
    { value: "namibia", label: "Namibia" },
    { value: "angola", label: "Angola" },
    { value: "cuba", label: "Cuba" },
    { value: "jamaica", label: "Jamaica" },
    { value: "haiti", label: "Haiti" },
    { value: "dominican_republic", label: "Dominican Republic" },
    { value: "trinidad_and_tobago", label: "Trinidad and Tobago" },
    { value: "bahamas", label: "Bahamas" },
    { value: "barbados", label: "Barbados" },
    { value: "panama", label: "Panama" },
    { value: "costa_rica", label: "Costa Rica" },
    { value: "honduras", label: "Honduras" },
    { value: "guatemala", label: "Guatemala" },
    { value: "el_salvador", label: "El Salvador" },
    { value: "nicaragua", label: "Nicaragua" },
    { value: "belize", label: "Belize" },
    { value: "paraguay", label: "Paraguay" },
    { value: "uruguay", label: "Uruguay" },
    { value: "bolivia", label: "Bolivia" },
    { value: "ecuador", label: "Ecuador" },
    { value: "guinea", label: "Guinea" },
    { value: "ivory_coast", label: "Ivory Coast" },
    { value: "cameroon", label: "Cameroon" },
    { value: "senegal", label: "Senegal" },
    { value: "sudan", label: "Sudan" },
    { value: "south_sudan", label: "South Sudan" },
    { value: "congo", label: "Congo" },
    { value: "madagascar", label: "Madagascar" },
    { value: "malawi", label: "Malawi" },
    { value: "sierra_leone", label: "Sierra Leone" },
    { value: "chad", label: "Chad" },
    { value: "mali", label: "Mali" },
    { value: "burkina_faso", label: "Burkina Faso" },
    { value: "rwanda", label: "Rwanda" },
    { value: "burundi", label: "Burundi" },
    { value: "lesotho", label: "Lesotho" },
    { value: "eswatini", label: "Eswatini" },
  ];
  const searchskillsoptions = [
    { value: "0", label: "Software Developer" },
    { value: "14", label: "Software Engineer" },
    { value: "15", label: "Computer Software" },
  ];

  return (
    <div className="container" style={{ "margin-top": "140px" }}>
      {Addcandidatescreen == false ? (
        <>
          <div className="row">
            <br></br>
            <div className="col-lg-2"></div>

            <div
              className="col-lg-8"
              style={{
                boxShadow: "1px 3px 6px #00000029",
                border: "1px solid #D8D8D8",
                borderRadius: "16px",
                opacity: 1,
              }}
            >
              <Headingtext1>
                Edit your pod and browse for projects...
                {JSON.stringify(Poddata)}
              </Headingtext1>

              <p
                style={{
                  color: "#8EAEBC",
                  marginTop: "2rem",
                  marginBottom: "5rem",
                  textAlign: "center",
                  fontWeight: "600",
                  fontSize: "40px",
                }}
              >
                Manage Pod1
              </p>

              {PodStatus == "active" ? (
                <>
                  <center>
                    <p>De-activate The Pod to Update the Pod!</p>
                  </center>
                </>
              ) : (
                <>
                  <form onSubmit={handleSubmitOptions}>
                    <center>
                      {Image == "" ? (
                        <div
                          className="email"
                          style={{
                            padding: "0px",
                            border: "1px solid #8EAEBC",
                            width: "100px",
                            height: "100px",
                            borderRadius: "50px",
                            textAlign: "center",
                          }}
                        >
                          <span>
                            {AuthImage == null ? (
                              <img
                                src={defaultimg}
                                alt=""
                                style={{
                                  width: 50,
                                  height: 50,
                                  borderRadius: "50px",
                                }}
                              ></img>
                            ) : (
                              <img
                                src={AuthImage}
                                alt=""
                                style={{
                                  width: "100px",
                                  height: "100px",
                                  borderRadius: "50px",
                                }}
                                id="profilePicture"
                              ></img>
                            )}

                            <input
                              type="file"
                              style={{
                                marginLeft: "40px",
                                width: "5rem",
                                height: "3rem",
                              }}
                              className="profileclass"
                              onChange={onImageUpload}
                              id="profilePicture"
                            />
                            <EditIcon
                              style={{
                                color: "white",
                                width: "25px",
                                height: "25px",
                                marginLeft: "60px",
                                marginTop: "-110px",
                                backgroundColor: "#03045e",
                                boxShadow:
                                  "rgb(255, 255, 255) -15px -15px 25px",
                                borderRadius: "50px",
                              }}
                              onChange={onImageUpload}
                            />
                          </span>
                          {/* <span style={{marginTop:"-10px",marginLeft:"20px"}}> hhgjh</span> */}
                        </div>
                      ) : (
                        <img
                          src={Image}
                          alt=""
                          style={{
                            width: "100px",
                            height: "100px",
                            marginTop: "30px",
                            borderRadius: "50px",
                          }}
                          id="profilePicture"
                        ></img>
                      )}
                    </center>
                    <br></br>
                    <Postfield>
                      <Input
                        type="text"
                        value={podname}
                        placeholder="Enter Pod Name"
                        onChange={(e) => setPodName(e.target.value)}
                        required
                      />
                    </Postfield>

                    <Postfield>
                      <Input
                        type="text"
                        value={podDescription}
                        placeholder="Enter Pod Description"
                        onChange={(e) => setPodDescription(e.target.value)}
                        required
                      />
                    </Postfield>

                    <Postfield>
                      <Select2
                        onChange={onInputChangetype}
                        id="podtype"
                        placeholder="Pod Type"
                        value={podtype}
                        required
                      >
                        <option
                          style={{ color: "black" }}
                          value=""
                          defaultValue
                        >
                          Pod Type
                        </option>
                        <option value="Saas">Saas</option>
                        <option value="Web app">Web app</option>
                        <option value="Mobile app">Mobile app</option>
                      </Select2>
                    </Postfield>
                    <Postfield>
                      <Select2
                        onChange={onInputChangestack}
                        id="techStack"
                        placeholder="Pod Tech Stack"
                        value={techStack}
                        required
                      >
                        <option
                          style={{ color: "black" }}
                          value=""
                          defaultValue
                        >
                          Pod Tech Stack
                        </option>
                        <option value="MEAN">MEAN</option>
                        <option value="MERN">MERN</option>
                        <option value="LAMP">LAMP</option>
                        <option value="MEVN">MEVN</option>
                      </Select2>
                    </Postfield>
                    <Postfield>
                      <Select2
                        onChange={onInputChangesize}
                        id="podsize"
                        placeholder="Pod Size"
                        value={podSize}
                        required
                      >
                        <option
                          style={{ color: "black" }}
                          value=""
                          defaultValue
                        >
                          Pod Size
                        </option>
                        <option value="2">2</option>
                        <option value="5">5</option>
                        <option value="7">7</option>
                      </Select2>
                    </Postfield>

                    <Postfield>
                      <Select2
                        value={countrypod}
                        placeholder="Country"
                        onChange={(e) => setCountrypod(e.target.value)}
                      >
                        <option
                          style={{ color: "black" }}
                          value=""
                          defaultValue
                        >
                          Country
                        </option>
                        <option value="usa">USA</option>
                        <option value="india">India</option>
                        <option value="canada">Canada</option>
                        <option value="australia">Australia</option>
                        <option value="uk">United Kingdom</option>
                        <option value="germany">Germany</option>
                        <option value="france">France</option>
                        <option value="china">China</option>
                        <option value="japan">Japan</option>
                        <option value="brazil">Brazil</option>
                        <option value="south_africa">South Africa</option>
                        <option value="mexico">Mexico</option>
                        <option value="italy">Italy</option>
                        <option value="spain">Spain</option>
                        <option value="russia">Russia</option>
                        <option value="south_korea">South Korea</option>
                        <option value="new_zealand">New Zealand</option>
                        <option value="singapore">Singapore</option>
                        <option value="uae">United Arab Emirates</option>
                        <option value="saudi_arabia">Saudi Arabia</option>
                        <option value="argentina">Argentina</option>
                        <option value="netherlands">Netherlands</option>
                        <option value="sweden">Sweden</option>
                        <option value="switzerland">Switzerland</option>
                        <option value="norway">Norway</option>
                        <option value="denmark">Denmark</option>
                        <option value="finland">Finland</option>
                        <option value="portugal">Portugal</option>
                        <option value="poland">Poland</option>
                        <option value="turkey">Turkey</option>
                        <option value="indonesia">Indonesia</option>
                        <option value="malaysia">Malaysia</option>
                        <option value="thailand">Thailand</option>
                        <option value="vietnam">Vietnam</option>
                        <option value="philippines">Philippines</option>
                        <option value="egypt">Egypt</option>
                        <option value="nigeria">Nigeria</option>
                        <option value="kenya">Kenya</option>
                        <option value="ghana">Ghana</option>
                        <option value="colombia">Colombia</option>
                        <option value="chile">Chile</option>
                        <option value="peru">Peru</option>
                        <option value="venezuela">Venezuela</option>
                        <option value="pakistan">Pakistan</option>
                        <option value="bangladesh">Bangladesh</option>
                        <option value="sri_lanka">Sri Lanka</option>
                        <option value="nepal">Nepal</option>
                        <option value="bhutan">Bhutan</option>
                        <option value="maldives">Maldives</option>
                        <option value="iceland">Iceland</option>
                        <option value="luxembourg">Luxembourg</option>
                        <option value="qatar">Qatar</option>
                        <option value="oman">Oman</option>
                        <option value="kuwait">Kuwait</option>
                        <option value="bahrain">Bahrain</option>
                        <option value="iraq">Iraq</option>
                        <option value="iran">Iran</option>
                        <option value="afghanistan">Afghanistan</option>
                        <option value="israel">Israel</option>
                        <option value="jordan">Jordan</option>
                        <option value="lebanon">Lebanon</option>
                        <option value="morocco">Morocco</option>
                        <option value="algeria">Algeria</option>
                        <option value="tunisia">Tunisia</option>
                        <option value="libya">Libya</option>
                        <option value="ethiopia">Ethiopia</option>
                        <option value="tanzania">Tanzania</option>
                        <option value="uganda">Uganda</option>
                        <option value="zimbabwe">Zimbabwe</option>
                        <option value="zambia">Zambia</option>
                        <option value="mozambique">Mozambique</option>
                        <option value="botswana">Botswana</option>
                        <option value="namibia">Namibia</option>
                        <option value="angola">Angola</option>
                        <option value="cuba">Cuba</option>
                        <option value="jamaica">Jamaica</option>
                        <option value="haiti">Haiti</option>
                        <option value="dominican_republic">
                          Dominican Republic
                        </option>
                        <option value="trinidad_tobago">
                          Trinidad and Tobago
                        </option>
                        <option value="bahamas">Bahamas</option>
                        <option value="barbados">Barbados</option>
                        <option value="panama">Panama</option>
                        <option value="costa_rica">Costa Rica</option>
                        <option value="honduras">Honduras</option>
                        <option value="guatemala">Guatemala</option>
                        <option value="el_salvador">El Salvador</option>
                        <option value="nicaragua">Nicaragua</option>
                        <option value="belize">Belize</option>
                        <option value="paraguay">Paraguay</option>
                        <option value="uruguay">Uruguay</option>
                        <option value="bolivia">Bolivia</option>
                        <option value="ecuador">Ecuador</option>
                        <option value="guinea">Guinea</option>
                        <option value="ivory_coast">Ivory Coast</option>
                        <option value="cameroon">Cameroon</option>
                        <option value="senegal">Senegal</option>
                        <option value="sudan">Sudan</option>
                        <option value="south_sudan">South Sudan</option>
                        <option value="congo">Congo</option>
                        <option value="madagascar">Madagascar</option>
                        <option value="malawi">Malawi</option>
                        <option value="sierra_leone">Sierra Leone</option>
                        <option value="chad">Chad</option>
                        <option value="mali">Mali</option>
                        <option value="burkina_faso">Burkina Faso</option>
                        <option value="rwanda">Rwanda</option>
                        <option value="burundi">Burundi</option>
                        <option value="lesotho">Lesotho</option>
                        <option value="eswatini">Eswatini</option>
                      </Select2>
                    </Postfield>
                    <Postfield>
                      <Select2
                        value={timezonepod}
                        placeholder=" Time Zone"
                        onChange={(e) => setTimezonepod(e.target.value)}
                      >
                        <option
                          style={{ color: "black" }}
                          value=""
                          defaultValue
                        >
                          Time Zone
                        </option>
                        <option value="indian_standard_time">
                          Indian Standard Time
                        </option>
                        <option value="eastern_standard_time">
                          Eastern Standard Time
                        </option>
                        <option value="central_standard_time">
                          Central Standard Time
                        </option>
                        <option value="mountain_standard_time">
                          Mountain Standard Time
                        </option>
                        <option value="pacific_standard_time">
                          Pacific Standard Time
                        </option>
                        <option value="greenwich_mean_time">
                          Greenwich Mean Time
                        </option>
                        <option value="central_european_time">
                          Central European Time
                        </option>
                        <option value="western_european_time">
                          Western European Time
                        </option>
                        <option value="australian_eastern_standard_time">
                          Australian Eastern Standard Time
                        </option>
                        <option value="australian_central_standard_time">
                          Australian Central Standard Time
                        </option>
                        <option value="australian_western_standard_time">
                          Australian Western Standard Time
                        </option>
                        <option value="new_york_time">
                          New York Time (Eastern Daylight Time)
                        </option>
                        <option value="los_angeles_time">
                          Los Angeles Time (Pacific Daylight Time)
                        </option>
                        <option value="london_time">
                          London Time (British Summer Time)
                        </option>
                        <option value="paris_time">
                          Paris Time (Central European Summer Time)
                        </option>
                        <option value="tokyo_time">
                          Tokyo Time (Japan Standard Time)
                        </option>
                        <option value="sydney_time">
                          Sydney Time (Australian Eastern Daylight Time)
                        </option>
                        <option value="dubai_time">
                          Dubai Time (Gulf Standard Time)
                        </option>
                        <option value="moscow_time">
                          Moscow Time (Moscow Standard Time)
                        </option>
                        <option value="brazil_time">
                          Brazil Time (Brasilia Time)
                        </option>
                        <option value="chicago_time">
                          Chicago Time (Central Daylight Time)
                        </option>
                        <option value="houston_time">
                          Houston Time (Central Daylight Time)
                        </option>
                        <option value="cape_town_time">
                          Cape Town Time (South Africa Standard Time)
                        </option>
                      </Select2>
                    </Postfield>

                    <Postfield>
                      <CreatePodSkillspage
                        onChange={handleSelectChange3}
                        isMulti
                        options={allergens}
                        placeholder="Skills"
                        value={selected}
                        styles={colourStyles}
                      />
                    </Postfield>

                    {/* <Postfield>
          <Select2
            onChange={onInputChangebudget1}
            // value={Poddata.budget}
         
            placeholder="Budget Range"
          >
            <option style={{ color: "black" }} value="" defaultValue>
              Budget Range
            </option>
            <option  value= '100k - 115k' style={{ color: "black" }}>100k - 115k</option>
  <option value='115k - 150k' style={{ color: "black" }}>115k - 150k</option>
          </Select2>
        </Postfield> */}

                    <br></br>

                    <Postfield1>
                      <Canbudtton>Candidates</Canbudtton>
                      {/* <Postfield1> */}

                      {candcount >= podSize ? (
                        <Button2
                          type="submit"
                          onClick={() => {
                            setAddcandidatescreen(true);
                          }}
                          disabled
                        >
                          Add +
                        </Button2>
                      ) : (
                        <Button2
                          type="submit"
                          onClick={() => {
                            setAddcandidatescreen(true);
                            var candidatelength = candcount + 1;
                            setCandcount(candidatelength);
                          }}
                        >
                          Add +
                        </Button2>
                      )}
                    </Postfield1>

                    {getcandidates.map((data) => {
                      if (podSize >= getcandidates.length) {
                        return (
                          <Postfield>
                            <Select3>
                              <p style={{ margin: "20px" }}>
                                {data.firstName}{" "}
                              </p>

                              <p
                                style={{
                                  marginLeft: "auto",
                                  marginTop: "1rem",
                                  backgroundColor: "red",
                                  borderRadius: "50%",
                                  color: "white",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  deleteitem(data.c_id, data.podID);
                                }}
                              >
                                <CloseIcon />
                              </p>
                            </Select3>
                          </Postfield>
                        );
                      }
                    })}

                    {Poddata.candidatesid.map((data, i) => {
                      return (
                        <Postfield>
                          <Select3>
                            <p style={{ margin: "20px" }}>{data.name}</p>

                            <p
                              style={{
                                marginLeft: "auto",
                                marginTop: "1rem",
                                backgroundColor: "red",
                                borderRadius: "50%",
                                color: "white",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                Poddata.candidatesid.splice(i, i + 1);
                                setPoddata({
                                  candidatesid: Poddata.candidatesid,
                                });
                              }}
                            >
                              <CloseIcon />
                            </p>
                          </Select3>
                        </Postfield>
                      );
                    })}

                    <center>
                      {/* <NavLink to='/PodCreatedSuccessFullyCan'> */}

                      <Button type="submit">Update</Button>
                      {/* </NavLink> */}
                    </center>
                  </form>
                </>
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <Browsecontainer>
            <div>
              <Heading>
                <h3 style={{ fontSize: "1.625rem", marginLeft: "5rem" }}>
                  Candidate-Pod Lead-Talent Search
                </h3>
              </Heading>
              <br></br>
              <div>
                {" "}
                <Para>
                  {" "}
                  <p style={{ color: "#A2A2A2", fontSize: " 1.125rem" }}>
                    Find the best freelancers or talent pods to get your
                    projects delivered at a faster pace!
                  </p>
                </Para>
              </div>
              <br></br>
              <br></br>

              <Main>
                {/* <div className='center'> */}
                <Table>
                  <div className="singlecontainer">
                    <Singlecontainer>
                      <td className="mis">
                        <SearchTag>
                          <SearchOutlinedIcon
                            style={{
                              marginTop: "15px",
                              marginLeft: "20px",
                              margin: "auto",
                              color: "#7E7E7E",
                            }}
                          />
                          <input
                            className="jobsearch"
                            type="text"
                            placeholder="Keyword"
                            value={mr}
                            onChange={
                              ((val) => setMr(val.target.value),
                              handleUserInput)
                            }
                          />
                        </SearchTag>
                      </td>
                      {/* <td className='mis1' >
                  <Selecttag>
                    <select className='select'  value={mis} onChange={((val)=>setMis(val.target.value),handleJob)} >
                      <option value='default'>Location</option>
                      <option value='usa'>USA</option>
                      <option value='india'>India</option>
                      <option value='canada'>Canada</option>
                    </select>
                    </Selecttag>
                  </td> */}
                      <td>
                        {" "}
                        <Clear>
                          <p
                            style={{
                              marginTop: "20px",
                              width: "6rem",
                              marginLeft: "31rem",
                              cursor: "pointer",
                              fontWeight: "600",
                              fontSize: "18px",
                              fontFamily: "Poppins,Regular",
                            }}
                            onClick={() => {
                              resetInputField();
                              resetJobField();
                              resetBudgetField();
                              resetPrimaryField();
                              resetSecondaryField();
                              resetTimezoneField();
                              resetPreferField();
                              resetAvailableField();
                              resetTitleField();
                            }}
                          >
                            Clear
                          </p>
                        </Clear>{" "}
                      </td>
                      <Search>
                        <td>
                          <button
                            style={{
                              marginTop: "10px",
                              width: "10rem",
                              height: "2.6rem",
                              color: "white",
                              border: "1px solid  #1DBF73",
                              borderRadius: "5px",
                              cursor: "pointer",
                              backgroundColor: "#1DBF73",
                              boxShadow: "1px 6px 9px #00000029",
                              fontFamily: "Poppins,Regular",
                              fontWeight: "500",
                            }}
                            onClick={() => per()}
                          >
                            Search
                          </button>
                        </td>
                      </Search>
                    </Singlecontainer>
                  </div>
                  {/* <div className='main'> */}
                  <Row>
                    {/* <tr> */}
                    <Row1>
                      <td>
                        <select
                          className="options"
                          value={pri}
                          onChange={
                            ((val) => setPri(val.target.value), primaryfield)
                          }
                        >
                          <option value="default">Primary Skill</option>
                          <option value="html"> HTML/CSS</option>
                          <option value="JavaScript">JavaScript/jQuery</option>
                          <option value="Adobe">Adobe Illustrator</option>
                          <option value="React">ReactJS</option>
                          <option value="AngularJS">AngularJS</option>
                          <option value="Vue">Vue</option>
                          <option value="Next">Next</option>
                          <option value="Typescript"> Typescript</option>
                          <option value="Gatsby">Gatsby</option>

                          <option value="ThreeJs">Three.js</option>
                          <option value="NodeJs">Node.js</option>
                          <option value="Java">Java</option>
                        </select>
                      </td>
                    </Row1>
                    <Row2>
                      <td>
                        <select
                          className="options"
                          value={avail}
                          onChange={
                            ((val) => setAvail(val.target.value), available)
                          }
                        >
                          <option value="default">Availability</option>
                          <option value="parttime">Part Time</option>
                          <option value="fulltime">Full Time</option>
                        </select>
                      </td>
                      <td>
                        <select
                          className="options"
                          value={tz}
                          onChange={
                            ((val) => setTz(val.target.value), timezonefield)
                          }
                        >
                          <option value="default">Time Zone</option>
                          <option value="indian_standard_time">Indian Standard Time</option>
<option value="eastern_standard_time">Eastern Standard Time</option>
<option value="central_standard_time">Central Standard Time</option>
<option value="mountain_standard_time">Mountain Standard Time</option>
<option value="pacific_standard_time">Pacific Standard Time</option>
<option value="greenwich_mean_time">Greenwich Mean Time</option>
<option value="central_european_time">Central European Time</option>
<option value="western_european_time">Western European Time</option>
<option value="australian_eastern_standard_time">Australian Eastern Standard Time</option>
<option value="australian_central_standard_time">Australian Central Standard Time</option>
<option value="australian_western_standard_time">Australian Western Standard Time</option>
<option value="new_york_time">New York Time (Eastern Daylight Time)</option>
<option value="los_angeles_time">Los Angeles Time (Pacific Daylight Time)</option>
<option value="london_time">London Time (British Summer Time)</option>
<option value="paris_time">Paris Time (Central European Summer Time)</option>
<option value="tokyo_time">Tokyo Time (Japan Standard Time)</option>
<option value="sydney_time">Sydney Time (Australian Eastern Daylight Time)</option>
<option value="dubai_time">Dubai Time (Gulf Standard Time)</option>
<option value="moscow_time">Moscow Time (Moscow Standard Time)</option>
<option value="brazil_time">Brazil Time (Brasilia Time)</option>
<option value="chicago_time">Chicago Time (Central Daylight Time)</option>
<option value="houston_time">Houston Time (Central Daylight Time)</option>
<option value="cape_town_time">Cape Town Time (South Africa Standard Time)</option>

                        </select>
                      </td>
                    </Row2>
                    <Row2>
                      <td>
                        <select
                          className="options"
                          value={pref}
                          onChange={
                            ((val) => setPref(val.target.value), preferfield)
                          }
                        >
                          <option value="default">Prefered Work Hours</option>
                          <option value="Morning Shift 9am-6pm">
                            Morning Shift 9am-6pm
                          </option>
                          <option value="Night Shift 10pm - 7am">
                            Night Shift 10pm - 7am
                          </option>
                        </select>
                      </td>
                      <td>
                        <select
                          className="options"
                          value={Title}
                          onChange={
                            ((val) => setTitle(val.target.value), jobtitle)
                          }
                        >
                          <option value="default">Job title</option>
                          <option value="Software Engineer">
                            Software Engineer
                          </option>
                          <option value="Full Stack Developer">
                            Full Stack Developer
                          </option>
                          <option value="Java Developer">Java Developer</option>
                        </select>
                      </td>
                    </Row2>
                    <tr>
                      <td>
                        <select
                          className="options"
                          value={mis}
                          onChange={
                            ((val) => setMis(val.target.value), handleJob)
                          }
                        >
                          <option value="default">Location</option>
                          <option value="usa">USA</option>
                          <option value="india">India</option>
                          <option value="canada">Canada</option>
                          <option value="australia">Australia</option>
                          <option value="uk">United Kingdom</option>
                          <option value="germany">Germany</option>
                          <option value="france">France</option>
                          <option value="china">China</option>
                          <option value="japan">Japan</option>
                          <option value="brazil">Brazil</option>
                          <option value="south_africa">South Africa</option>
                          <option value="mexico">Mexico</option>
                          <option value="italy">Italy</option>
                          <option value="spain">Spain</option>
                          <option value="russia">Russia</option>
                          <option value="south_korea">South Korea</option>
                          <option value="new_zealand">New Zealand</option>
                          <option value="singapore">Singapore</option>
                          <option value="uae">United Arab Emirates</option>
                          <option value="saudi_arabia">Saudi Arabia</option>
                          <option value="argentina">Argentina</option>
                          <option value="netherlands">Netherlands</option>
                          <option value="sweden">Sweden</option>
                          <option value="switzerland">Switzerland</option>
                          <option value="norway">Norway</option>
                          <option value="denmark">Denmark</option>
                          <option value="finland">Finland</option>
                          <option value="portugal">Portugal</option>
                          <option value="poland">Poland</option>
                          <option value="turkey">Turkey</option>
                          <option value="indonesia">Indonesia</option>
                          <option value="malaysia">Malaysia</option>
                          <option value="thailand">Thailand</option>
                          <option value="vietnam">Vietnam</option>
                          <option value="philippines">Philippines</option>
                          <option value="egypt">Egypt</option>
                          <option value="nigeria">Nigeria</option>
                          <option value="kenya">Kenya</option>
                          <option value="ghana">Ghana</option>
                          <option value="colombia">Colombia</option>
                          <option value="chile">Chile</option>
                          <option value="peru">Peru</option>
                          <option value="venezuela">Venezuela</option>
                          <option value="pakistan">Pakistan</option>
                          <option value="bangladesh">Bangladesh</option>
                          <option value="sri_lanka">Sri Lanka</option>
                          <option value="nepal">Nepal</option>
                          <option value="bhutan">Bhutan</option>
                          <option value="maldives">Maldives</option>
                          <option value="iceland">Iceland</option>
                          <option value="luxembourg">Luxembourg</option>
                          <option value="qatar">Qatar</option>
                          <option value="oman">Oman</option>
                          <option value="kuwait">Kuwait</option>
                          <option value="bahrain">Bahrain</option>
                          <option value="iraq">Iraq</option>
                          <option value="iran">Iran</option>
                          <option value="afghanistan">Afghanistan</option>
                          <option value="israel">Israel</option>
                          <option value="jordan">Jordan</option>
                          <option value="lebanon">Lebanon</option>
                          <option value="morocco">Morocco</option>
                          <option value="algeria">Algeria</option>
                          <option value="tunisia">Tunisia</option>
                          <option value="libya">Libya</option>
                          <option value="ethiopia">Ethiopia</option>
                          <option value="tanzania">Tanzania</option>
                          <option value="uganda">Uganda</option>
                          <option value="zimbabwe">Zimbabwe</option>
                          <option value="zambia">Zambia</option>
                          <option value="mozambique">Mozambique</option>
                          <option value="botswana">Botswana</option>
                          <option value="namibia">Namibia</option>
                          <option value="angola">Angola</option>
                          <option value="cuba">Cuba</option>
                          <option value="jamaica">Jamaica</option>
                          <option value="haiti">Haiti</option>
                          <option value="dominican_republic">
                            Dominican Republic
                          </option>
                          <option value="trinidad_tobago">
                            Trinidad and Tobago
                          </option>
                          <option value="bahamas">Bahamas</option>
                          <option value="barbados">Barbados</option>
                          <option value="panama">Panama</option>
                          <option value="costa_rica">Costa Rica</option>
                          <option value="honduras">Honduras</option>
                          <option value="guatemala">Guatemala</option>
                          <option value="el_salvador">El Salvador</option>
                          <option value="nicaragua">Nicaragua</option>
                          <option value="belize">Belize</option>
                          <option value="paraguay">Paraguay</option>
                          <option value="uruguay">Uruguay</option>
                          <option value="bolivia">Bolivia</option>
                          <option value="ecuador">Ecuador</option>
                          <option value="guinea">Guinea</option>
                          <option value="ivory_coast">Ivory Coast</option>
                          <option value="cameroon">Cameroon</option>
                          <option value="senegal">Senegal</option>
                          <option value="sudan">Sudan</option>
                          <option value="south_sudan">South Sudan</option>
                          <option value="congo">Congo</option>
                          <option value="madagascar">Madagascar</option>
                          <option value="malawi">Malawi</option>
                          <option value="sierra_leone">Sierra Leone</option>
                          <option value="chad">Chad</option>
                          <option value="mali">Mali</option>
                          <option value="burkina_faso">Burkina Faso</option>
                          <option value="rwanda">Rwanda</option>
                          <option value="burundi">Burundi</option>
                          <option value="lesotho">Lesotho</option>
                          <option value="eswatini">Eswatini</option>
                        </select>
                      </td>
                    </tr>
                    <tr></tr>
                    {/* </tr> */}
                    <tr>
                      {" "}
                      <td>
                        <button
                          className="optionsaplly"
                          style={{
                            width: "10rem",
                            height: "2rem",
                            color: "white",
                            border: "none",
                            borderRadius: "5px",
                            cursor: "pointer",
                            backgroundColor: "#1DBF73",
                            boxShadow: "1px 6px 9px #00000029",
                            fontFamily: "Poppins,Regular",
                            fontWeight: "500",
                          }}
                          onClick={() => per()}
                        >
                          Apply Filters
                        </button>
                      </td>
                    </tr>
                  </Row>
                  {/* </div> */}
                </Table>
              </Main>
              <div>{renderData(currentItems)}</div>
            </div>
          </Browsecontainer>
        </>
      )}
    </div>
  );
};

export default PartnerManagePod;

const CreatePodScreen = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Poppins, Medium;
  margin-left: 360px;
  justify-content: center;
  align-items: center;
  // margin: auto;
  margin-top: 5rem;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    flex-direction: column;
    margin: auto;
  }
`;
const Container1 = styled.div`
  font-family: Poppins, Medium;
  width: 60rem;
  height: max-height;
  box-shadow: 1px 3px 6px #00000029;
  border-radius: 16px;
  background-color: #f8f8f8;
  margin-top: 5rem;
  border: 1px solid #d8d8d8;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 25rem;
    height: max-height;
  }
`;
const Select2 = styled.select`
  font-family: Poppins, Medium;
  width: 22rem;
  height: 50px;
  border-radius: 10px;
  border: 2px solid #b7b7b7;
  color: grey;
  font-weight: 600;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 15rem;
    height: max-height;
  }
`;

const Select3 = styled.p`
  font-family: Poppins, Medium;
  width: 22rem;
  height: 50px;
  display: flex;
  // flex-direction:row;
  justify-content: center;
  align-items: center;

  border-radius: 10px;
  border: 2px solid #b7b7b7;
  color: grey;
  font-weight: 600;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 15rem;
    height: max-height;
  }
`;
const Multi = styled.div`
  font-family: Poppins, Medium;
  // width: 22rem;
  // height:50px;
  // border-radius: 10px;
  // border: 2px solid #B7B7B7;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 12rem;
    height: max-height;
  }
`;
const Button = styled.button`
  font-family: Poppins, Medium;
  background-color: #1dbf73;
  color: white;
  font-size: 15px;
  border-radius: 10px;
  height: 54px;
  width: 22rem;
  border: none;
  font-weight: 600;
  font-family: Poppins, Medium;
  box-shadow: 1px 3px 6px #00000029;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 15rem;
    height: max-height;
  }
`;

const Button2 = styled.button`
  font-family: Poppins, Medium;
  background-color: #00b4d8;
  color: white;
  font-size: 15px;
  border-radius: 50px;
  height: 30px;
  width: 5rem;
  margin-left: 10rem;
  border: none;
  font-weight: 600;
  font-family: Poppins, Medium;
  box-shadow: 1px 3px 6px #00000029;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 10rem;
    height: max-height;
    margin: auto;
  }
`;

const Canbudtton = styled.span`
  margin-left: -5.5rem;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    margin: auto;
  }
`;
const Postfield = styled.div`
  font-family: Poppins, Medium;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 10px;
`;
const Postfield1 = styled.span`
  font-family: Poppins, Medium;
  color: #8eaebc;
  font-weight: bold;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5rem;
  padding: 10px @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 12rem;
    height: max-height;
    display: flex;
    flex-direction: column;
    margin: auto;
  }
`;

const Input = styled.input`
  font-family: Poppins, Medium;
  width: 22rem;
  height: 50px;
  border-radius: 10px;
  border: 2px solid #b7b7b7;
  padding-left: 20px;
  font-weight: 600;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 15rem;
    height: max-height;
  }
`;
const Headingtext1 = styled.h3`
  font-family: Poppins, Medium;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 5rem;
  font-weight: 600;
  font-size: 1.5rem;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-top: 7rem;
  }
`;

const Browsecontainer = styled.div`
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    margin: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`;
const Main = styled.tr`
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    width: 100%;
  }
`;
const Table = styled.table`
  margin: auto;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    width: 20rem;
    height: max-content;
  }
`;
const Heading = styled.div`
  margin-top: 9rem;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    width: 70%;
    margin-top: 10rem;
    padding-bottom: 2rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
const Para = styled.p`
  margin-left: 5rem;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    width: 20rem;
    margin: auto;
    padding-bottom: 2rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
const Singlecontainer = styled.tr`
  display: flex;
  flex-direction: row;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: auto;
    width: 20rem;
  }
`;
const Row = styled.div`
  background-color: white;
  height: max-content;
  padding-bottom: 1rem;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`;

const SearchTag = styled.td`
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    width: 20rem;
    margin: auto;
    border-bottom: 1px solid #a2a2a2;
  }
`;
// const Select = styled.select`
// @media screen and (min-width:320px) and (max-width:1080px){
//   width:20rem;
//  }
//  `;
const Selecttag = styled.td`
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 20rem;
    border-bottom: 1px solid #a2a2a2;
  }
`;
const Clear = styled.p`
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: none;
  }
`;
const Search = styled.td`
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    margin: auto;
    width: 100%;
    align-items: center;
    flex-direction: column;
    margin-bottom: 20px;
  }
`;
const Row1 = styled.tr`
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    // margin:auto;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`;
const Row2 = styled.tr`
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    // margin:auto;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`;
// const Button = styled.button`
// @media screen and (min-width:320px) and (max-width:1080px){
//   display:flex;
// margin:auto;
//  flex-direction:column;
// }
// `;
const Pagination2 = styled.div`
  height: max-content;
  margin: auto;
  width: 83.5rem;

  background-color: white;

  margin-top: 3rem;
  border: 1px solid #efefef;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    margin: auto;
    width: 20rem;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
  }
`;
const Pega = styled.div`
  // display:flex;
  // justify-content:center;
  // align-items:center;
  // align-self:center;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 100%;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
const Numbers = styled.li`
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 7rem;
  }
`;
const Num = styled.ol`
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    //   align-items:center;
    // justify-content:center;
    flex-direction: column;
    marginleft: -3rem;
    width: 70%;
  }
`;
const Num1 = styled.p`
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    // display:flex;
    margin: auto;
    width: 100%;
  }
`;
const Num2 = styled.li`
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    margin: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
`;
const Col = styled.div`
  margin-top: -0.2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    margin: auto;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`;
