import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Container,
  Paper,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Divider,
  IconButton,
  InputAdornment,
  Alert,
  Snackbar,
  CircularProgress,
  Backdrop,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  Settings as SettingsIcon,
  GitHub as GitHubIcon,
  Assessment as JiraIcon,
  Cloud as SonarQubeIcon,
  Code as BitbucketIcon,
  Refresh as CircleCIIcon,
  Storage as AWSIcon,
  People as ARTIcon,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useAuthToken } from "../../TokenContext";

// Styled Components
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  borderRadius: '16px',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
  background: '#ffffff',
}));

const MainTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 700,
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(4),
  '& .MuiSvgIcon-root': {
    fontSize: '2rem',
  },
}));

const SectionContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.background.paper,
  borderRadius: '12px',
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  border: `1px solid ${theme.palette.divider}`,
  transition: 'all 0.3s ease',
  '&:hover': {
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.08)',
    transform: 'translateY(-2px)',
  },
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  color: theme.palette.primary.main,
  fontWeight: 600,
  marginBottom: theme.spacing(3),
  padding: theme.spacing(1.5),
  borderRadius: '8px',
  background: theme.palette.primary.light + '20',
  '& .MuiSvgIcon-root': {
    color: theme.palette.primary.main,
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '8px',
  padding: '12px 32px',
  textTransform: 'none',
  fontWeight: 600,
  fontSize: '16px',
  backgroundColor: '#1DBF73',
  '&:hover': {
    backgroundColor: '#19a864',
  },
  boxShadow: '0 4px 12px rgba(29, 191, 115, 0.2)',
}));

const LoadingOverlay = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  color: '#1DBF73',
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
}));

function PartnerSettings() {
  const { user } = useSelector((state) => state.auth);
  const token = useAuthToken();

  // State
  const [fetchlist, setFetchlist] = useState([]);
  const [fetchlistid, setFetchlistid] = useState('');
  const [ARTsList, setARTsList] = useState([]);
  const [selectedARTId, setSelectedARTId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const [formData, setFormData] = useState({
    Jira_URL: '',
    Jira_admin_login_email_address: '',
    Jira_admin_API_Token: '',
    Github_username: '',
    Github_API_Token: '',
    Github_Repo_name: '',
    ART_id: '',
    bitbucket_username: '',
    bitbucket_app_password: '',
    bitbucket_workspace: '',
    aws_region: '',
    Organizational_Code: user.data[0].partner_code,
    Jira_Kanben_Project_name: "",
    circleci_github_username: "",
    circleci_github_repo: '',
    circleci_api_token: '',
    sonarqube_cloud_url: '',
    sonarqube_api_token: '',
    aws_access_key_id: '',
    aws_secret_access_key: '',
  });

  // Handlers
  const handleSnackbarClose = () => setOpenSnackbar(false);

  const showNotification = (message, severity = 'success') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };

  const handleTogglePassword = (field) => {
    setShowPassword(prev => ({
      ...prev,
      [field]: !prev[field]
    }));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleARTSelect = (event) => {
    const selectedARTId = event.target.value;
    setSelectedARTId(selectedARTId);
    setFormData(prev => ({
      ...prev,
      ART_id: selectedARTId,
    }));
  };

  // API calls
  const fetchARTsList = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        process.env.REACT_APP_CUSTOMERADMIN_GENERICGIGLIST,
        { Organizational_Code: user.data[0].partner_code },
        { 
          headers: {
            Authorization: token,
            'Content-Type': 'application/json',
          }
        }
      );

      if (response.status === 200 && response.data?.data) {
        setARTsList(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching ARTs list:', error);
      showNotification('Failed to fetch ARTs list', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchauthorisationlist = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        process.env.REACT_APP_CUSTOMERADMIN_FETCHAUTHORIZATIONTOKENS,
        { Organizational_Code: user.data[0].partner_code },
        {
          headers: {
            Authorization: token,
            'Content-Type': 'application/json',
          }
        }
      );

      if (response.data?.body?.data?.length > 0) {
        const fetchedData = response.data.body.data[0];
        setFetchlist(response.data.body.data);
        setFetchlistid(fetchedData);
        setFormData(prev => ({
          ...prev,
          ...fetchedData,
          ART_id: fetchedData.ARTs_id || '',
        }));
      }
    } catch (error) {
      console.error('Error fetching authorization list:', error);
      showNotification('Failed to fetch settings', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const response = await fetch(
        process.env.REACT_APP_CUSTOMERADMIN_AUTHORIZATIONTOKEN,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token,
          },
          body: JSON.stringify({
            ART_id: parseInt(selectedARTId),
            ...formData
          }),
        }
      );

      if (response.status === 400) {
        showNotification('Settings updated successfully');
        window.location.reload();
      } else {
        showNotification('Failed to update settings', 'error');
      }
    } catch (error) {
      console.error('Error:', error);
      showNotification('An error occurred', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdate = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post(
        process.env.REACT_APP_CUSTOMERADMIN_UPDATEAUTHORIZATIONTOKENS,
        {
          id: fetchlistid.id,
          ...formData,
          ART_id: fetchlistid.ART_id,
        },
        {
          headers: {
            Authorization: token,
            'Content-Type': 'application/json',
          }
        }
      );

      if (response.status === 200) {
        showNotification('Settings updated successfully');
        window.location.reload();
      } else {
        showNotification('Failed to update settings', 'error');
      }
    } catch (error) {
      console.error('Error:', error);
      showNotification('An error occurred', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  // Effects
  useEffect(() => {
    fetchauthorisationlist();
    fetchARTsList();
  }, [token]);

  useEffect(() => {
    setSelectedARTId(formData.ART_id || '');
  }, [formData.ART_id]);

  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 8, mb: 4 }}>
        <MainTitle variant="h4">
          <SettingsIcon />
          Partner Settings
        </MainTitle>

        <StyledPaper elevation={3}>
          <form onSubmit={fetchlist.length === 0 ? handleSubmit : handleUpdate}>
            <Grid container spacing={3}>
              {/* ART Selection Section */}
              {fetchlist.length === 0 && (
                <Grid item xs={12}>
                  <SectionContainer>
                    <SectionTitle variant="h6">
                      <ARTIcon />
                      ART Selection
                    </SectionTitle>
                    <FormControl fullWidth>
                      <InputLabel>Select ART</InputLabel>
                      <Select
                        value={selectedARTId}
                        onChange={handleARTSelect}
                        label="Select ART"
                        required
                      >
                        <MenuItem value="">
                          <em>Select an ART</em>
                        </MenuItem>
                        {ARTsList.map((ART) => (
                          <MenuItem key={ART.ART_id} value={ART.ART_id}>
                            {ART.ART_Name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </SectionContainer>
                </Grid>
              )}

              {/* Jira Section */}
              <Grid item xs={12}>
                <SectionContainer>
                  <SectionTitle variant="h6">
                    <JiraIcon />
                    Jira Configuration
                  </SectionTitle>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Project Key"
                        name="Jira_Kanben_Project_name"
                        value={formData.Jira_Kanben_Project_name}
                        onChange={handleChange}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Jira URL"
                        name="Jira_URL"
                        type="url"
                        value={formData.Jira_URL}
                        onChange={handleChange}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Admin Email"
                        name="Jira_admin_login_email_address"
                        type="email"
                        value={formData.Jira_admin_login_email_address}
                        onChange={handleChange}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Admin API Token"
                        name="Jira_admin_API_Token"
                        type={showPassword.Jira_admin_API_Token ? 'text' : 'password'}
                        value={formData.Jira_admin_API_Token}
                        onChange={handleChange}
                        required
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => handleTogglePassword('Jira_admin_API_Token')}
                                edge="end"
                              >
                                {showPassword.Jira_admin_API_Token ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                </SectionContainer>
              </Grid>

              {/* GitHub Section */}
              <Grid item xs={12}>
                <SectionContainer>
                  <SectionTitle variant="h6">
                    <GitHubIcon />
                    GitHub Configuration
                  </SectionTitle>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Username"
                        name="Github_username"
                        value={formData.Github_username}
                        onChange={handleChange}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Repository Name"
                        name="Github_Repo_name"
                        value={formData.Github_Repo_name}
                        onChange={handleChange}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
  <TextField
    fullWidth
    label="API Token"
    name="Github_API_Token"
    type={showPassword.Github_API_Token ? 'text' : 'password'}
    value={formData.Github_API_Token}
    onChange={handleChange}
    required
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <IconButton
            onClick={() => handleTogglePassword('Github_API_Token')}
            edge="end">
            {showPassword.Github_API_Token ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>
      ),
    }}
  />
</Grid>
                  </Grid>
                </SectionContainer>
              </Grid>

              {/* CircleCI Section */}
              <Grid item xs={12}>
                <SectionContainer>
                  <SectionTitle variant="h6">
                    <CircleCIIcon />
                    CircleCI Configuration
                  </SectionTitle>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="CircleCI API Token"
                        name="circleci_api_token"
                        type={showPassword.circleci_api_token ? 'text' : 'password'}
                        value={formData.circleci_api_token}
                        onChange={handleChange}
                        required
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => handleTogglePassword('circleci_api_token')}
                                edge="end"
                              >
                                {showPassword.circleci_api_token ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="CircleCI GitHub Username"
                        name="circleci_github_username"
                        value={formData.circleci_github_username}
                        onChange={handleChange}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="CircleCI GitHub Repository"
                        name="circleci_github_repo"
                        value={formData.circleci_github_repo}
                        onChange={handleChange}
                        required
                      />
                    </Grid>
                  </Grid>
                </SectionContainer>
              </Grid>

              {/* SonarQube Section */}
              <Grid item xs={12}>
                <SectionContainer>
                  <SectionTitle variant="h6">
                    <SonarQubeIcon />
                    SonarQube Configuration
                  </SectionTitle>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="SonarQube Cloud URL"
                        name="sonarqube_cloud_url"
                        type="url"
                        value={formData.sonarqube_cloud_url}
                        onChange={handleChange}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="SonarQube API Token"
                        name="sonarqube_api_token"
                        type={showPassword.sonarqube_api_token ? 'text' : 'password'}
                        value={formData.sonarqube_api_token}
                        onChange={handleChange}
                        required
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => handleTogglePassword('sonarqube_api_token')}
                                edge="end"
                              >
                                {showPassword.sonarqube_api_token ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                </SectionContainer>
              </Grid>

              {/* AWS Section */}
              <Grid item xs={12}>
                <SectionContainer>
                  <SectionTitle variant="h6">
                    <AWSIcon />
                    AWS Configuration
                  </SectionTitle>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="AWS Access Key ID"
                        name="aws_access_key_id"
                        type={showPassword.aws_access_key_id ? 'text' : 'password'}
                        value={formData.aws_access_key_id}
                        onChange={handleChange}
                        required
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => handleTogglePassword('aws_access_key_id')}
                                edge="end"
                              >
                                {showPassword.aws_access_key_id ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="AWS Secret Access Key"
                        name="aws_secret_access_key"
                        type={showPassword.aws_secret_access_key ? 'text' : 'password'}
                        value={formData.aws_secret_access_key}
                        onChange={handleChange}
                        required
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => handleTogglePassword('aws_secret_access_key')}
                                edge="end"
                              >
                                {showPassword.aws_secret_access_key ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="AWS Region"
                        name="aws_region"
                        value={formData.aws_region}
                        onChange={handleChange}
                        required
                      />
                    </Grid>
                  </Grid>
                </SectionContainer>
              </Grid>

              {/* BitBucket Section */}
              <Grid item xs={12}>
                <SectionContainer>
                  <SectionTitle variant="h6">
                    <BitbucketIcon />
                    BitBucket Configuration
                  </SectionTitle>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="BitBucket Username"
                        name="bitbucket_username"
                        value={formData.bitbucket_username}
                        onChange={handleChange}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="BitBucket App Password"
                        name="bitbucket_app_password"
                        type={showPassword.bitbucket_app_password ? 'text' : 'password'}
                        value={formData.bitbucket_app_password}
                        onChange={handleChange}
                        required
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => handleTogglePassword('bitbucket_app_password')}
                                edge="end"
                              >
                                {showPassword.bitbucket_app_password ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="BitBucket Workspace"
                        name="bitbucket_workspace"
                        value={formData.bitbucket_workspace}
                        onChange={handleChange}
                        required
                      />
                    </Grid>
                  </Grid>
                </SectionContainer>
              </Grid>

              {/* Submit Button */}
              <Grid item xs={12}>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                  <StyledButton
                    type="submit"
                    variant="contained"
                    size="large"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : fetchlist.length === 0 ? (
                      'Submit'
                    ) : (
                      'Update'
                    )}
                  </StyledButton>
                </Box>
              </Grid>
            </Grid>
          </form>
        </StyledPaper>
      </Box>

      {/* Loading Overlay */}
      <LoadingOverlay open={isLoading}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
          <CircularProgress color="inherit" />
          <Typography variant="h6" sx={{ color: '#1DBF73' }}>
            Processing...
          </Typography>
        </Box>
      </LoadingOverlay>

      {/* Snackbar for notifications */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default PartnerSettings;