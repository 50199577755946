import React, { useState, useEffect } from 'react';
  import { useSelector } from 'react-redux';
  import { useAuthToken } from "../TokenContext";
  import axios from 'axios';
  import moment from 'moment';
  import { useIntl } from 'react-intl';
  import { 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow, 
    Paper,
    Dialog,
    Typography,
    Box,
    DialogTitle,
    DialogContent,useTheme
  } from '@mui/material';
  import { 
    MdSearch, 
    MdCalendarToday, 
    MdFilterList,
    MdEdit,
    MdVisibility,
    MdVideoLibrary,
    MdWarning,
    MdCheckCircle,
    MdAccessTime,
    MdArrowUpward,
    MdArrowDownward
  } from 'react-icons/md';
  



  import { styled } from '@mui/material/styles';
  
  // Header components
  const Header = styled(Box)(({ theme }) => ({
    marginBottom: '2rem',
    color: theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.common.black
  }));
  
  const FiltersContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: '1rem',
    marginBottom: '2rem',
    flexWrap: 'wrap',
    '@media (max-width: 768px)': {
      flexDirection: 'column'
    }
  }));
  
  const SearchContainer = styled(Box)({
    position: 'relative',
    flex: 1
  });
  
  const SearchIcon = styled(MdSearch)(({ theme }) => ({
    position: 'absolute',
    left: '12px',
    top: '50%',
    transform: 'translateY(-50%)',
    color: theme.palette.mode === 'dark' ? theme.palette.grey[400] : theme.palette.grey[600]
  }));
  
  // Detail Dialog components
  const DetailHeader = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '2rem'
  });
  
  const DetailDate = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    color: theme.palette.mode === 'dark' ? theme.palette.grey[400] : theme.palette.grey[600]
  }));
  
  const DetailText = styled(Typography)(({ theme, color }) => ({
    fontSize: '0.875rem',
    color: color || (theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.grey[800])
  }));
  
  const BadgeContainer = styled(Box)({
    display: 'flex',
    flexWrap: 'wrap',
    gap: '0.5rem'
  });
  
  const VideoButton = styled('button')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    padding: '0.5rem 1rem',
    border: `1px solid ${theme.palette.mode === 'dark' ? theme.palette.grey[700] : theme.palette.grey[300]}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : theme.palette.common.white,
    color: theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.grey[800],
    cursor: 'pointer',
    transition: 'all 0.2s',
    '&:hover': {
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main
    }
  }));
  
  // Loading and error states
  const LoadingContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '200px'
  });
  
  const Spinner = styled(Box)(({ theme }) => ({
    border: `4px solid ${theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.grey[200]}`,
    borderTop: `4px solid ${theme.palette.primary.main}`,
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    animation: 'spin 1s linear infinite',
    '@keyframes spin': {
      '0%': { transform: 'rotate(0deg)' },
      '100%': { transform: 'rotate(360deg)' }
    }
  }));
  
  const ErrorContainer = styled(Box)({
    textAlign: 'center',
    padding: '2rem',
    color: '#EF4444'
  });
  
  const ErrorText = styled(Typography)(({ theme }) => ({
    marginTop: '1rem',
    color: theme.palette.error.main
  }));
  
  const EmptyContainer = styled(Box)(({ theme }) => ({
    textAlign: 'center',
    padding: '3rem',
    color: theme.palette.mode === 'dark' ? theme.palette.grey[400] : theme.palette.grey[600]
  }));
  
  const EmptyTitle = styled(Typography)(({ theme }) => ({
    marginTop: '1rem',
    fontSize: '1.125rem',
    fontWeight: 500,
    color: theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.grey[900]
  }));
  
  const EmptyText = styled(Typography)(({ theme }) => ({
    marginTop: '0.5rem',
    fontSize: '0.875rem',
    color: theme.palette.mode === 'dark' ? theme.palette.grey[400] : theme.palette.grey[600]
  }));
  
  const ActionButtons = styled(Box)({
    display: 'flex',
    gap: '0.5rem'
  });
  
  // Main Container
  const Container = styled(Box)(({ theme }) => ({
    width: '95%',
    maxWidth: '1400px',
    margin: '0 auto',
    padding: '2rem',
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1]
  }));
  
  // Table Styles
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.dark : theme.palette.primary.main,
    color: theme.palette.common.white,
    fontWeight: 600,
    '&.MuiTableCell-head': {
      backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.dark : theme.palette.primary.main,
    }
  }));


// DetailTitle component
const DetailTitle = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  fontWeight: 500,
  color: theme.palette.mode === 'dark' ? theme.palette.grey[300] : theme.palette.grey[700],
  marginBottom: '0.5rem'
}));

// EditField component
const EditField = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem',
  marginBottom: '1rem',
  '& label': {
    color: theme.palette.mode === 'dark' ? theme.palette.grey[300] : theme.palette.grey[700],
  }
}));

// ButtonGroup component (if needed)
const ButtonGroup = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '1rem',
  marginTop: '1rem'
});

// SaveButton component
const SaveButton = styled('button')(({ theme }) => ({
  padding: '0.5rem 1rem',
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.dark : theme.palette.primary.main,
  color: theme.palette.common.white,
  border: 'none',
  borderRadius: theme.shape.borderRadius,
  cursor: 'pointer',
  fontWeight: 500,
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary.main : theme.palette.primary.dark,
  }
}));

// CancelButton component
const CancelButton = styled('button')(({ theme }) => ({
  padding: '0.5rem 1rem',
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.grey[100],
  color: theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.grey[800],
  border: `1px solid ${theme.palette.mode === 'dark' ? theme.palette.grey[700] : theme.palette.grey[300]}`,
  borderRadius: theme.shape.borderRadius,
  cursor: 'pointer',
  fontWeight: 500,
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : theme.palette.grey[200],
  }
}));

// Additional components that might be needed
const InfoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginRight: 'auto',
  overflow: 'hidden',
  color: theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.common.black,
}));

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.common.black,
  variant: 'h6'
}));

const SubTitle = styled(Typography)(({ theme }) => ({
  alignItems: 'center',
  justifyContent: 'center',
  margin: 'auto',
  color: theme.palette.mode === 'dark' ? theme.palette.grey[300] : theme.palette.common.black,
  fontSize: '14px',
  fontWeight: 600,
  fontFamily: 'Poppins'
}));
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.action.hover,
    },
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : theme.palette.background.default,
    },
    '&:hover': {
      backgroundColor: theme.palette.mode === 'dark' ? theme.palette.action.hover : theme.palette.action.selected,
    }
  }));
  
  // Search and Filter Components
  const SearchInput = styled('input')(({ theme }) => ({
    width: '100%',
    padding: '0.75rem 0.75rem 0.75rem 2.5rem',
    border: `1px solid ${theme.palette.mode === 'dark' ? theme.palette.grey[700] : theme.palette.grey[300]}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : theme.palette.common.white,
    color: theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.common.black,
    outline: 'none',
    '&:focus': {
      borderColor: theme.palette.primary.main,
    }
  }));
  
  const FilterSelect = styled('select')(({ theme }) => ({
    padding: '0.75rem',
    border: `1px solid ${theme.palette.mode === 'dark' ? theme.palette.grey[700] : theme.palette.grey[300]}`,
    borderRadius: theme.shape.borderRadius,
    minWidth: '180px',
    outline: 'none',
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : theme.palette.common.white,
    color: theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.common.black,
    '&:focus': {
      borderColor: theme.palette.primary.main,
    }
  }));
  
  // Detail Dialog Components
  const DetailContainer = styled(Box)(({ theme }) => ({
    padding: '1.5rem',
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : theme.palette.background.default,
  }));
  
  const DetailSection = styled(Box)(({ theme }) => ({
    marginBottom: '1.5rem',
    '& h4': {
      color: theme.palette.mode === 'dark' ? theme.palette.grey[300] : theme.palette.grey[700],
    },
    '& p': {
      color: theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.common.black,
    }
  }));
  
  const IssueBadge = styled(Box)(({ theme }) => ({
    padding: '0.25rem 0.5rem',
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : theme.palette.grey[200],
    borderRadius: '9999px',
    fontSize: '0.875rem',
    color: theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.common.black,
  }));
  
  const StatusBadge = styled(Box)(({ theme, isBlocked }) => ({
    display: 'inline-flex',
    alignItems: 'center',
    gap: '0.25rem',
    padding: '0.25rem 0.75rem',
    borderRadius: '9999px',
    fontSize: '0.875rem',
    color: theme.palette.common.white,
    backgroundColor: isBlocked ? 
      theme.palette.mode === 'dark' ? theme.palette.error.dark : theme.palette.error.main :
      theme.palette.mode === 'dark' ? theme.palette.success.dark : theme.palette.success.main,
  }));
  
  // Edit Dialog Components
  const EditForm = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
    padding: '1rem',
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : theme.palette.background.default,
  }));
  
  const EditInput = styled('input')(({ theme }) => ({
    padding: '0.5rem',
    border: `1px solid ${theme.palette.mode === 'dark' ? theme.palette.grey[700] : theme.palette.grey[300]}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.common.white,
    color: theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.common.black,
    '&:focus': {
      outline: 'none',
      borderColor: theme.palette.primary.main,
    }
  }));
  
  const EditTextarea = styled('textarea')(({ theme }) => ({
    padding: '0.5rem',
    border: `1px solid ${theme.palette.mode === 'dark' ? theme.palette.grey[700] : theme.palette.grey[300]}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.common.white,
    color: theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.common.black,
    minHeight: '100px',
    resize: 'vertical',
    '&:focus': {
      outline: 'none',
      borderColor: theme.palette.primary.main,
    }
  }));
  
  // Action Buttons
  const ActionButton = styled('button')(({ theme }) => ({
    padding: '0.5rem',
    border: 'none',
    background: 'none',
    color: theme.palette.mode === 'dark' ? theme.palette.grey[300] : theme.palette.grey[700],
    cursor: 'pointer',
    transition: 'color 0.2s',
    '&:hover': {
      color: theme.palette.primary.main,
    }
  }));

const Podactivityviewalltalent = ({ podId }) => {
  const token = useAuthToken();
  const { user } = useSelector(state => state.auth);
  
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedActivity, setSelectedActivity] = useState(null);
  
  // Filtering and Sorting States
  const [searchTerm, setSearchTerm] = useState('');
  const [dateFilter, setDateFilter] = useState('all');
  const [statusFilter, setStatusFilter] = useState('all');
  const [sortConfig, setSortConfig] = useState({ key: 'createdAt', direction: 'desc' });
  
  // Fetch activities
  useEffect(() => {
    if (!token) {
      console.log("Token is missing");
      return;
    }
    const fetchActivities = async () => {
      try {
        setLoading(true);
        setError(null); // Reset error state
        
        const response = await axios.post(
          process.env.REACT_APP_TALENT_PODACTIVITYDASHBORDTALENT,
          {
            talent_id: user.data[0].c_id,
            status: "view_all"
          },
          {
            headers: {
              'Authorization': token,
              'Content-Type': 'application/json',
            }
          }
        );

        if (response.data?.data?.data) {
          setActivities(response.data.data.data);
        }
      } catch (err) {
        console.error('Error fetching activities:', err);
        setError('Failed to load activities');
      } finally {
        setLoading(false);
      }
    };

    fetchActivities();
  }, [token, user.data]);

  // Handle video playback
  const handleViewVideo = async (loomUrl) => {
    try {
      const filename = loomUrl.split('/').slice(-1)[0];
      const cloudFrontUrl = process.env.REACT_APP_CLOUDFRONTs3;
      const fullUrl = cloudFrontUrl + filename;

      const response = await axios.post(
        process.env.REACT_APP_TALENT_CLOUDFRONTAPI,
        { "url": fullUrl }
      );

      const responseBody = JSON.parse(response.data.body);
      const signedUrl = responseBody.signedUrl;

      if (signedUrl) {
        window.open(signedUrl, '_blank');
      }
    } catch (error) {
      console.error('Error accessing video:', error);
      alert('Error accessing video. Please try again later.');
    }
  };

  // Helper function to parse jira issues
const parseJiraIssues = (jiraIssue) => {
  if (!jiraIssue) return [];
  if (Array.isArray(jiraIssue)) return jiraIssue;
  try {
    // If it's a string, try to parse it as JSON
    const parsed = JSON.parse(jiraIssue);
    return Array.isArray(parsed) ? parsed : [parsed];
  } catch {
    // If it's a comma-separated string
    if (typeof jiraIssue === 'string') {
      return jiraIssue.split(',').map(issue => issue.trim()).filter(Boolean);
    }
    return [];
  }
};

// Update your filtering logic
const filteredActivities = activities.filter(activity => {
  const jiraIssues = parseJiraIssues(activity.jira_issue);
  
  const matchesSearch = searchTerm === '' || (
    (activity.today_focus?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    activity.accomplishments?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    jiraIssues.some(issue => issue.toLowerCase().includes(searchTerm.toLowerCase())))
  );

  const matchesDate = dateFilter === 'all' || (() => {
    const activityDate = moment(activity.createdAt);
    switch(dateFilter) {
      case 'today':
        return activityDate.isSame(moment(), 'day');
      case 'week':
        return activityDate.isAfter(moment().subtract(7, 'days'));
      case 'month':
        return activityDate.isAfter(moment().subtract(30, 'days'));
      default:
        return true;
    }
  })();

  const matchesStatus = statusFilter === 'all' || 
    (statusFilter === 'blocked' && activity.blockers) ||
    (statusFilter === 'onTrack' && !activity.blockers);

  return matchesSearch && matchesDate && matchesStatus;
});

// Update the table cell rendering


// And the dialog rendering


  // Sorting logic
  const sortedActivities = [...filteredActivities].sort((a, b) => {
    const aValue = a[sortConfig.key];
    const bValue = b[sortConfig.key];

    if (sortConfig.direction === 'asc') {
      return aValue < bValue ? -1 : 1;
    } else {
      return aValue > bValue ? -1 : 1;
    }
  });

  const handleSort = (key) => {
    setSortConfig(prev => ({
      key,
      direction: prev.key === key && prev.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

 // Add these new state variables at the top of your component
const [editMode, setEditMode] = useState(false);
const [editingActivity, setEditingActivity] = useState(null);

// Add this function to handle edit submission
const handleEditSubmit = async (updatedActivity) => {
  try {
    const updatedData = {
      poddata: [{
        today_focus: updatedActivity.today_focus,
        blockers: updatedActivity.blockers,
        loom_url: updatedActivity.loom_url,
        accomplishments: updatedActivity.accomplishments,
        categories: updatedActivity.categories,
        jira_issue: parseJiraIssues(updatedActivity.jira_issue),
        id: updatedActivity.id
      }]
    };

    const response = await axios.post(
      process.env.REACT_APP_TALENT_PODACTIVITYUPDATETALENT,
      updatedData,
      {
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json',
        }
      }
    );

    if (response.status === 200) {
      // Update the activities list with edited data
      setActivities(prevActivities => 
        prevActivities.map(activity => 
          activity.id === updatedActivity.id ? updatedActivity : activity
        )
      );
      setEditMode(false);
      setEditingActivity(null);
      alert("Successfully updated activity.");
    }
  } catch (error) {
    console.error('Error updating activity:', error);
    alert('Failed to update activity. Please try again.');
  }
};

// Add the EditDialog component
const EditDialog = ({ activity, open, onClose }) => {
  const [editedActivity, setEditedActivity] = useState({...activity});

  const handleChange = (field, value) => {
    setEditedActivity(prev => ({
      ...prev,
      [field]: value
    }));
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Edit Activity</DialogTitle>
      <DialogContent>
        <EditForm>
          <EditField>
            <DetailTitle>Jira Issues</DetailTitle>
            <EditInput
              value={editedActivity.jira_issue}
              onChange={(e) => handleChange('jira_issue', e.target.value)}
              placeholder="Comma-separated Jira issues"
            />
          </EditField>

          <EditField>
            <DetailTitle>Today's Focus</DetailTitle>
            <EditTextarea
              value={editedActivity.today_focus}
              onChange={(e) => handleChange('today_focus', e.target.value)}
              placeholder="Today's focus..."
            />
          </EditField>

          <EditField>
            <DetailTitle>Accomplishments</DetailTitle>
            <EditTextarea
              value={editedActivity.accomplishments}
              onChange={(e) => handleChange('accomplishments', e.target.value)}
              placeholder="Accomplishments..."
            />
          </EditField>

          <EditField>
            <DetailTitle>Blockers</DetailTitle>
            <EditTextarea
              value={editedActivity.blockers}
              onChange={(e) => handleChange('blockers', e.target.value)}
              placeholder="Any blockers..."
            />
          </EditField>

          <ButtonGroup>
            <CancelButton onClick={onClose}>
              Cancel
            </CancelButton>
            <SaveButton onClick={() => {
              handleEditSubmit(editedActivity);
              onClose();
            }}>
              Save Changes
            </SaveButton>
          </ButtonGroup>
        </EditForm>
      </DialogContent>
    </Dialog>
  );
};


 
    const ActivityDetailDialog = ({ activity, open, onClose }) => (
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle>Activity Details</DialogTitle>
        <DialogContent>
          <DetailContainer>
            <DetailHeader>
              <DetailDate>
                <MdCalendarToday />
                {moment(activity.createdAt).format('MMMM DD, YYYY')}
              </DetailDate>
              <StatusBadge isBlocked={activity.blockers}>
                {activity.blockers ? "Blocked" : "On Track"}
              </StatusBadge>
            </DetailHeader>
  
            
            <DetailSection>
  <DetailTitle>Jira Issues</DetailTitle>
  <BadgeContainer>
    {parseJiraIssues(activity.jira_issue).map((issue, index) => (
      <IssueBadge key={`${issue}-${index}`}>{issue}</IssueBadge>
    ))}
  </BadgeContainer>
</DetailSection>
  
            <DetailSection>
              <DetailTitle>Today's Focus</DetailTitle>
              <DetailText>{activity.today_focus}</DetailText>
            </DetailSection>
  
            {activity.accomplishments && (
              <DetailSection>
                <DetailTitle>Accomplishments</DetailTitle>
                <DetailText>{activity.accomplishments}</DetailText>
              </DetailSection>
            )}
  
            {activity.blockers && (
              <DetailSection>
                <DetailTitle>Blockers</DetailTitle>
                <DetailText color="red">{activity.blockers}</DetailText>
              </DetailSection>
            )}
  
            {activity.loom_url && (
              <DetailSection>
                <DetailTitle>Video Update</DetailTitle>
                <VideoButton onClick={() => handleViewVideo(activity.loom_url)}>
                  <MdVideoLibrary />
                  View Video
                </VideoButton>
              </DetailSection>
            )}
          </DetailContainer>
        </DialogContent>
      </Dialog>
    );
    const [dialogOpen, setDialogOpen] = useState(false);
  
    return (
      <Container>
        <Header>
          <Title>Activity History</Title>
        </Header>
  
        <FiltersContainer>
          <SearchContainer>
            <SearchIcon />
            <SearchInput
              placeholder="Search activities..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </SearchContainer>
          
          <FilterSelect
            value={dateFilter}
            onChange={(e) => setDateFilter(e.target.value)}
          >
            <option value="all">All Time</option>
            <option value="today">Today</option>
            <option value="week">This Week</option>
            <option value="month">This Month</option>
          </FilterSelect>
  
          <FilterSelect
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
          >
            <option value="all">All Status</option>
            <option value="blocked">Blocked</option>
            <option value="onTrack">On Track</option>
          </FilterSelect>
        </FiltersContainer>
  
        {loading ? (
          <LoadingContainer>
            <Spinner />
          </LoadingContainer>
        ) : error ? (
          <ErrorContainer>
            <MdWarning size={32} />
            <ErrorText>{error}</ErrorText>
          </ErrorContainer>
        ) : sortedActivities.length === 0 ? (
          <EmptyContainer>
            <MdAccessTime size={48} />
            <EmptyTitle>No activities found</EmptyTitle>
            <EmptyText>Try adjusting your filters or create a new activity.</EmptyText>
          </EmptyContainer>
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell 
                    onClick={() => handleSort('createdAt')}
                    style={{ cursor: 'pointer' }}
                  >
                    Date
                    {sortConfig.key === 'createdAt' && (
                      sortConfig.direction === 'asc' ? 
                      <MdArrowUpward /> : 
                      <MdArrowDownward />
                    )}
                  </StyledTableCell>
                  <StyledTableCell>Talent Name</StyledTableCell>
                  <StyledTableCell>Jira Issues</StyledTableCell>
                  <StyledTableCell>Today's Focus</StyledTableCell>
                  <StyledTableCell>Accomplishments</StyledTableCell>

                  <StyledTableCell>Status</StyledTableCell>
                  <StyledTableCell>Actions</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedActivities.map((activity) => (
                  <StyledTableRow key={activity.id}>
                    <TableCell>
                      {moment(activity.createdAt).format('MMM DD, YYYY')}
                    </TableCell>
                    <TableCell style={{ maxWidth: '300px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      {activity.pod_candidates}
                    </TableCell>
                    <TableCell>
  <BadgeContainer>
    {parseJiraIssues(activity.jira_issue).map((issue, index) => (
      <IssueBadge key={`${issue}-${index}`}>{issue}</IssueBadge>
    ))}
  </BadgeContainer>
</TableCell>
                    <TableCell style={{ maxWidth: '300px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      {activity.today_focus}
                    </TableCell>

                    <TableCell style={{ maxWidth: '300px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      {activity.accomplishments}
                    </TableCell>
                    <TableCell>
                      <StatusBadge isBlocked={activity.blockers}>
                        {activity.blockers ? (
                          <><MdWarning /> Blocked</>
                        ) : (
                          <><MdCheckCircle /> On Track</>
                        )}
                      </StatusBadge>
                    </TableCell>
                    

<TableCell>
  <ActionButtons>
    <ActionButton onClick={() => {
      setSelectedActivity(activity);
      setDialogOpen(true);
    }}>
      <MdVisibility />
    </ActionButton>
    
    {activity.posted_to_client === 0 && (
      <ActionButton onClick={() => {
        setEditingActivity(activity);
        setEditMode(true);
      }}>
        <MdEdit />
      </ActionButton>
    )}
  </ActionButtons>
</TableCell>


{editMode && editingActivity && (
  <EditDialog
    activity={editingActivity}
    open={editMode}
    onClose={() => {
      setEditMode(false);
      setEditingActivity(null);
    }}
  />
)}

                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
  
        {selectedActivity && (
          <ActivityDetailDialog 
            activity={selectedActivity}
            open={dialogOpen}
            onClose={() => {
              setDialogOpen(false);
              setSelectedActivity(null);
            }}
          />
        )}
      </Container>
    );
  };
  
 
  export default Podactivityviewalltalent;