import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { 
  Modal, 
  Box, 
  Typography,
  Select,
  MenuItem,
  Card,
  CardContent,

  Container,
  styled,
  Paper,
  Table,
  CircularProgress,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Divider,
  useTheme
} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';     
import { useAuthToken } from '../TokenContext';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
const StyledCard = styled(Card)(({ theme }) => ({
  cursor: 'pointer',
  transition: theme.transitions.create(['box-shadow']),
  width: '300px',
  height: '250px',
  '&:hover': {
    boxShadow: theme.shadows[8]
  }
}));

const LoadingContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '140px'
});
const StyledModalBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxHeight: '85vh',
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(4),
  overflowY: 'auto'
}));

  
const Cardtime = ({ initialPodIds, selectedSprint, sprintStartDate, sprintEndDate }) => {
  const [timeData, setTimeData] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
  
  const token = useAuthToken();
  const intl = useIntl();
  const theme = useTheme();
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!token) return;
    
    if (user.data[0].type === "stakeholder") {
      fetchStakeholderData();
    } else {
      fetchManagerData();
    }
  }, [token, user.data[0].type, initialPodIds, selectedSprint, sprintStartDate, sprintEndDate]);

  const fetchStakeholderData = async () => {
    setIsLoading(true);
    try {
      const payload = {
        pod_id: initialPodIds || [],
        org_code: user.data[0].client_code,
        sprint_name: selectedSprint || '',
        sprint_start_date: sprintStartDate || '',
        sprint_end_date: sprintEndDate || ''
      };

      const response = await fetch(
        process.env.REACT_APP_STAKEHOLDER_JIRASHOWTIMEMETRICSSTAKEHOLDER, 
        {
          method: 'POST',
          headers: {
            'Authorization': token,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload)
        }
      );

      const data = await response.json();
      const parsedData = JSON.parse(data.body);
      setTimeData(parsedData);
    } catch (error) {
      console.error("Error fetching time details:", error);
      setTimeData(null);
    } finally {
        setIsLoading(false);
      }
  };

  const fetchManagerData = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        process.env.REACT_APP_CLIENT_JIRASHOWTIMEMANAGERID,
        {
          method: 'POST',
          headers: {
            'Authorization': token,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            manager_id: user.data[0].emp_id,
            pod_id: initialPodIds || null,
            sprint_name: selectedSprint || '',
            sprint_start_date: sprintStartDate || '',
            sprint_end_date: sprintEndDate || ''
          })
        }
      );

      const data = await response.json();
      const parsedData = JSON.parse(data.body);
      setTimeData(parsedData);
    } catch (error) {
      console.error("Error fetching time details:", error);
      setTimeData(null);
    } finally {
        setIsLoading(false);
      }
  };

  const formatTime = (timeString) => {
    if (!timeString) return '0h 0m';
    const [hours, minutes] = timeString.split(':');
    return `${hours}h ${minutes}m`;
  };

  const getDisplayData = () => {
    if (!timeData?.body) return { thisWeek: '0h 0m', lastWeek: '0h 0m' };
  
    let thisWeekTotal = '0:0:0';
    let lastWeekTotal = '0:0:0';
  
    const categories = ['Design', 'Development', 'Testing', 'Deployment', 'Bug-Fixing', 'Performance', 'Security'];
  
    if (!selectedFilter || selectedFilter === '') {
      // Show total time from total_time_by_pods
      const totalTime = timeData.body.total_time_by_pods;
      // Sum up all categories
      const total = categories.reduce((acc, category) => {
        const time = totalTime[category] || '0:0:0';
        const [h, m] = time.split(':').map(Number);
        return acc + (h * 60) + m;
      }, 0);
      thisWeekTotal = `${Math.floor(total / 60)}:${total % 60}:0`;
    } else if (selectedFilter === 'jira_show_time_by_this_week') {
      // Get time for this week
      const thisWeekData = timeData.body.jira_show_time_by_this_week;
      // Sum up all categories for this week
      const total = categories.reduce((acc, category) => {
        const time = thisWeekData[category] || '0:0:0';
        const [h, m] = time.split(':').map(Number);
        return acc + (h * 60) + m;
      }, 0);
      thisWeekTotal = `${Math.floor(total / 60)}:${total % 60}:0`;
    } else if (selectedFilter === 'jira_show_time_by_last_week') {
      // Get time for last week
      const lastWeekData = timeData.body.jira_show_time_by_last_week;
      // Sum up all categories for last week
      const total = categories.reduce((acc, category) => {
        const time = lastWeekData[category] || '0:0:0';
        const [h, m] = time.split(':').map(Number);
        return acc + (h * 60) + m;
      }, 0);
      lastWeekTotal = `${Math.floor(total / 60)}:${total % 60}:0`;
    } else {
      // Get time for specific talent
      const talentData = timeData.body.jira_show_time_by_talent.find(
        (talent) => talent.candidate_name === selectedFilter
      );
      if (talentData) {
        // Sum up all categories for the talent
        const total = categories.reduce((acc, category) => {
          const time = talentData[category] || '0:0:0';
          const [h, m] = time.split(':').map(Number);
          return acc + (h * 60) + m;
        }, 0);
        thisWeekTotal = `${Math.floor(total / 60)}:${total % 60}:0`;
      }
    }
  
    // When this week is selected, show this week's data
    if (selectedFilter === 'jira_show_time_by_this_week') {
      thisWeekTotal = Object.values(timeData.body.jira_show_time_by_this_week)
        .filter(val => typeof val === 'string')
        .reduce((acc, time) => {
          const [h, m] = time.split(':').map(Number);
          return acc + (h * 60) + m;
        }, 0);
      thisWeekTotal = `${Math.floor(thisWeekTotal / 60)}:${thisWeekTotal % 60}:0`;
      lastWeekTotal = '0:0:0';
    }
  
    // When last week is selected, show last week's data
    if (selectedFilter === 'jira_show_time_by_last_week') {
      lastWeekTotal = Object.values(timeData.body.jira_show_time_by_last_week)
        .filter(val => typeof val === 'string')
        .reduce((acc, time) => {
          const [h, m] = time.split(':').map(Number);
          return acc + (h * 60) + m;
        }, 0);
      lastWeekTotal = `${Math.floor(lastWeekTotal / 60)}:${lastWeekTotal % 60}:0`;
      thisWeekTotal = '0:0:0';
    }
  
    return {
      thisWeek: formatTime(thisWeekTotal),
      lastWeek: formatTime(lastWeekTotal)
    };
  };
  const getTopCategories = () => {
    if (!timeData?.body?.total_time_by_pods) return [];
    
    return Object.entries(timeData.body.total_time_by_pods)
      .filter(([category]) => ['Development', 'Bug-Fixing', 'Design'].includes(category))
      .map(([category, time]) => ({
        category,
        time: formatTime(time)
      }));
  };
  return (
    <Box sx={{ height: '100%' }}>
      <Box 
             display="flex" 
             flexDirection="column" 
             alignItems="center"
             sx={{ py: 3 }}
           >
     <Typography 
              variant="h5" 
              gutterBottom 
              sx={{ fontWeight: 'medium', mb: 3 }}
        >
          {intl.formatMessage({ id: 'timeLog' })}
        </Typography>
        <Select
          value={selectedFilter}
          onChange={(e) => setSelectedFilter(e.target.value)}
          sx={{
            width: 240,
            mb: 3,
            '& .MuiSelect-select': { py: 1.5 }
          }}
          displayEmpty
        >
          <MenuItem value="">
            {intl.formatMessage({ id: 'allReporters' })}
          </MenuItem>
          {timeData?.body?.jira_show_time_by_talent?.map((candidate) => (
            <MenuItem 
              key={candidate.candidate_id} 
              value={candidate.candidate_name}
            >
              {candidate.candidate_name}
            </MenuItem>
          ))}
          <Divider />
          <MenuItem value="jira_show_time_by_this_week">
            This Week
          </MenuItem>
          <MenuItem value="jira_show_time_by_last_week">
            Last Week
          </MenuItem>
        </Select>

        <StyledCard onClick={() => setModalIsOpen(true)}>
          <CardContent sx={{ p: 2 }}>
            <Box display="flex" alignItems="center" mb={2.5}>
              <AccessTimeIcon
                style={{
                  width: 32,
                  height: 32,
                  marginRight: 12,
                  color: theme.palette.primary.main
                }}
              />
              <Typography variant="h6"   sx={{fontWeight: 600,
      letterSpacing: '0.5px',
      backgroundColor: 'blue',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent'}}>Time Log</Typography>
            </Box>
            {isLoading ? (
  <Box display="flex" justifyContent="center" alignItems="center" sx={{ minHeight: 140 }}>
    <CircularProgress />
  </Box>
) : (
  <>
    <Box sx={{ mb: 2 }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={1}
      >
        <Typography color="text.secondary" variant="body2">
          Current Week:
        </Typography>
        <Typography variant="body2" fontWeight="medium">
          {getDisplayData().thisWeek}
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={1}
      >
        <Typography color="text.secondary" variant="body2">
          Last Week:
        </Typography>
        <Typography variant="body2" fontWeight="medium">
          {getDisplayData().lastWeek}
        </Typography>
      </Box>
      {/* <Divider sx={{ my: 1 }} /> */}
      {/* {getTopCategories().map((item, index) => (
        <Box
          key={item.category}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: 0.5 }}
        >
          <Typography color="text.secondary" variant="body2">
            {item.category}:
          </Typography>
          <Typography variant="body2">
            {item.time}
          </Typography>
        </Box>
      ))} */}
    </Box>
    <Box 
      display="flex" 
      justifyContent="center" 
      alignItems="center"
      sx={{ 
        mt: 1,
        pt: 1,
        borderTop: `1px solid ${theme.palette.divider}`,
        cursor: 'pointer'
      }}
    >
      <Typography 
        color="primary" 
        variant="body2"
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        View Details
        <ArrowForwardIcon sx={{ ml: 0.5, fontSize: 16 }} />
      </Typography>
    </Box>
  </>
)}
          </CardContent>
        </StyledCard>
      </Box>

      <Modal
        open={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        aria-labelledby="time-modal-title"
      >
        <StyledModalBox>
          <Typography
            id="time-modal-title"
            variant="h6"
            sx={{ mb: 4, fontWeight: 500 }}
          >
            Time Log Details
          </Typography>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper elevation={2} sx={{ p: 3 }}>
                <Typography
                  variant="subtitle1"
                  sx={{ mb: 2, fontWeight: 500 }}
                >
                  Time Categories
                </Typography>
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell 
                          sx={{ 
                            fontWeight: 500,
                            backgroundColor: theme.palette.primary.light
                          }}
                        >
                          Category
                        </TableCell>
                        <TableCell 
                          align="right"
                          sx={{ 
                            fontWeight: 500,
                            backgroundColor: theme.palette.primary.light
                          }}
                        >
                          Time Spent
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {timeData?.body?.total_time_by_pods &&
                        Object.entries(timeData.body.total_time_by_pods)
                          .filter(([category]) => [
                            'Bug-Fixing',
                            'Deployment',
                            'Design',
                            'Development',
                            'Performance',
                            'Security'
                          ].includes(category))
                          .map(([category, time], index) => (
                            <TableRow
                              key={category}
                              sx={{
                                '&:nth-of-type(odd)': {
                                  backgroundColor: theme.palette.action.hover,
                                },
                              }}
                            >
                              <TableCell>{category}</TableCell>
                              <TableCell align="right">{time}</TableCell>
                            </TableRow>
                          ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>

            {selectedFilter && timeData?.body?.jira_show_time_by_talent && (
              <Grid item xs={12}>
                <Paper elevation={2} sx={{ p: 3 }}>
                  <Typography
                    variant="subtitle1"
                    sx={{ mb: 2, fontWeight: 500 }}
                  >
                    Detailed Time Entries
                  </Typography>
                  <TableContainer>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell 
                            sx={{ 
                              fontWeight: 500,
                              backgroundColor: theme.palette.primary.light
                            }}
                          >
                            Date
                          </TableCell>
                          <TableCell 
                            align="right"
                            sx={{ 
                              fontWeight: 500,
                              backgroundColor: theme.palette.primary.light
                            }}
                          >
                            Time Spent
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {timeData.body.jira_show_time_by_talent
                          .find(t => t.candidate_name === selectedFilter)
                          ?.time_entries?.map((entry, index) => (
                            <TableRow
                              key={index}
                              sx={{
                                '&:nth-of-type(odd)': {
                                  backgroundColor: theme.palette.action.hover,
                                },
                              }}
                            >
                              <TableCell>{entry.date}</TableCell>
                              <TableCell align="right">
                                {entry.time_spent}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Grid>
            )}
          </Grid>
        </StyledModalBox>
      </Modal>
    </Box>
  );
};

Cardtime.propTypes = {
  initialPodIds: PropTypes.array.isRequired,
  selectedSprint: PropTypes.string,
  sprintStartDate: PropTypes.string,
  sprintEndDate: PropTypes.string
};

export default Cardtime;