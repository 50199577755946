import React, { useEffect, useState, useRef } from 'react';
import { Chart, registerables } from 'chart.js';
import 'chartjs-plugin-annotation';
import { useSelector } from 'react-redux';
import PopupDefectsdetailviewchart from './PopupDefectsdetailviewchart';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Modal from 'react-modal';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {Paper,useTheme} from '@mui/material';
import { useAuthToken } from "../TokenContext";
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

const Detaildefectsmetricstalent = ({ initialPodIds,selectedSprint }) => {
  const [metrics, setMetrics] = useState([]);
  const [selectedReporter, setSelectedReporter] = useState('');
  const { user } = useSelector((state) => state.auth);
  const completedChartRef = useRef(null);
  const [data, setData] = useState([]); 
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [selectedDefects, setSelectedDefects] = useState([]);
  const chartIdCounter = useRef(0);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [noDataAvailable, setNoDataAvailable] = useState(false); // State to track if there is no data available
  const intl = useIntl();
const theme =useTheme();
  const token = useAuthToken();

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handlePopupClose = () => {
    setIsPopupVisible(false);
    setSelectedDefects([]);
    chartIdCounter.current++;
  };

  useEffect(() => {
    const fetchDataForUser = async () => {
      Chart.register(...registerables);
      fetchMetrics();
    };

    fetchDataForUser();
    return () => {
      if (completedChartRef.current) {
        completedChartRef.current.destroy();
      }
    };
  }, [token, user.data[0].type, initialPodIds,selectedSprint]);

  const fetchMetrics = async () => {
    try {
      if (!token) {
        return; // Exit early if there is no token
      }
      const response = await fetch(
        process.env.REACT_APP_TALENT_JIRADEFECTSMETRICSTALENT,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
          },
          body: JSON.stringify({
            c_id: user.data[0].c_id,
            pod_id: initialPodIds ||[],
            sprint_name: selectedSprint || '',
          }),
        }
      );

      const responseData = await response.json();
      const parsedData = JSON.parse(responseData.body);
      if (parsedData?.reporters?.length > 0) {
        setMetrics(parsedData.reporters);
        setData(parsedData);
        setNoDataAvailable(false); // Reset noDataAvailable state if data is available
      } else {
        setMetrics([]);
        setNoDataAvailable(true); // Set noDataAvailable state if no data is available
      }
    } catch (error) {
      console.log('Error fetching metrics:', error);
      setMetrics([]);
      setNoDataAvailable(true); // Set noDataAvailable state if error occurs
    }
  };

  const createChart = (chartId, chartLabel, chartData) => {
    const ctx = document.getElementById(chartId);
  
    if (!ctx) {
      console.error('Canvas element not found');
      return;
    }
  
    // Destroy previous chart instance if it exists
    if (completedChartRef.current) {
      completedChartRef.current.destroy();
    }
  
    const chartInstance = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: chartLabel === 'Defects' ? ['Resolved Defects', 'Open Defects'] : ['Completed Stories', 'Open Stories'],
        datasets: [
          {
            label: chartLabel,
            data: chartData,
            backgroundColor: chartLabel === 'Defects' ? ['#FFCE56', 'red'] : ['#36A2EB', '#FF6384'],
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          annotation: {},
          legend: {
            labels: {
              generateLabels: () => [],
            },
          },
        },
        layout: {
          padding: {
            top: 10,
            bottom: 10,
            left: 10,
            right: 10,
          },
        },
        tooltips: {
          enabled: true,
          mode: 'single',
          callbacks: {
            label: function (tooltipItem, data) {
              const dataIndex = tooltipItem.dataIndex;
              const datasetIndex = tooltipItem.datasetIndex;
              const value = data.datasets[datasetIndex].data[dataIndex];
              return value;
            },
          },
        },
      },
    });
  
    completedChartRef.current = chartInstance;
  };

  useEffect(() => {
    if (!data || !data.reporters) {
      return;
    }
  
    if (!selectedReporter) {
      // Display pie chart with total user defect count
      if (data.total_user_defect_count.open_defects === 0 && data.total_user_defect_count.resolved_defects === 0) {
        console.log('No data available for defects');
        return;
      }
      createChart(
        `completedChart-${chartIdCounter.current}`,
        'Defects',
        [data.total_user_defect_count.resolved_defects, data.total_user_defect_count.open_defects]
      );
    } else {
      // Handle selectedReporter scenario
      const reporterData = data.reporters.find(reporter => reporter.Reporter === selectedReporter);
      if (reporterData) {
        setMetrics([reporterData]); // Filtered data for the selected reporter only
        if (reporterData.open_defects === 0 && reporterData.resolved_defects === 0) {
          console.log('No data available for the selected reporter');
          return;
        }
        createChart(
          `completedChart-${chartIdCounter.current}`,
          'Defects',
          [reporterData.resolved_defects, reporterData.open_defects]
        );
      } else {
        console.log('No data available for the selected reporter');
        // If no data is available for the selected reporter, create an empty chart
        createChart(`completedChart-${chartIdCounter.current}`, 'Defects', [0, 0]);
      }
    }
  }, [selectedReporter, data]);
  
  return (
    <div className="chart-container">
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <h2 className="chart-heading">{intl.formatMessage({ id: 'defects' })}</h2>
        <br />
        
        {data && data.reporters && data.reporters.length > 0 ? (
          <select   
            value={selectedReporter}
            onChange={(e) => setSelectedReporter(e.target.value)}
            style={{ width: '12rem', color: theme.palette.mode === 'dark' ? 'white' : 'black',      backgroundColor:  theme.palette.mode === 'dark' ? theme.palette.background.paper : 'white', }}
          >
            <option value="">{intl.formatMessage({ id: 'allReporters' })}</option>
            {data.reporters.map((reporter) => (
              <option key={reporter.Reporter} value={reporter.Reporter}>
                {reporter.Reporter}
              </option>
            ))}
          </select>
        ) : (
          <p>No reporters available</p>
        )}
  
        {data && data.total_user_defect_count && data.total_user_defect_count.open_defects === 0 && data.total_user_defect_count.resolved_defects === 0 ? (
          <p>No data available</p>
        ) : (
          <>
            <div className="chart-wrapper" style={{ width: "160px", height: "160px", margin: "auto" ,marginTop:"10px"}}>
              {metrics.length > 0 ? (
                <canvas key={`completedChart-${chartIdCounter.current}`} id={`completedChart-${chartIdCounter.current}`} ></canvas>
              ) : (
                <p>{intl.formatMessage({ id: 'noDataAvailable' })}</p>
              )}
            </div>
          </>
        )}
      </div>
  
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Defects Modal"
        style={{
          content: {
            width: '50%',
            maxWidth: '800px',
            margin: 'auto',
            marginTop: '5rem',
          },
        }}
      >
        <h6>Defects</h6>
        <TableContainer component={Paper}>
          <Table
            component={Paper}
            sx={{ maxWidth: 900, fontFamily: 'Poppins, Medium', borderRadius: '10px' }}
            aria-label="simple table"
          >
            <TableHead
              sx={{
                backgroundColor: '#00B4D8',
                '& td, & th': {
                  fontFamily: 'Poppins, Medium',
                  fontSize: '15px',
                  color: 'white',
                  fontWeight: '600',
                },
              }}
            >
              <TableCell align="right">{intl.formatMessage({ id: 'reporter' })}</TableCell>
              <TableCell align="right">{intl.formatMessage({ id: 'issueKey' })}</TableCell>
              <TableCell align="right">{intl.formatMessage({ id: 'issueSummary' })}</TableCell>
              <TableCell align="right">{intl.formatMessage({ id: 'issueStatus' })}</TableCell>
            </TableHead>
            <TableBody>
              {data && data.open_defects && data.open_defects.map((defect, index) => (
                <TableRow
                  key={index}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0, fontFamily: 'Poppins, Medium', fontSize: '15px' },
                    '&:nth-child(even)': {
                      backgroundColor: '#90E0EF',
                    },
                    '&:nth-child(odd)': {
                      backgroundColor: 'rgb(166, 194, 206, 30%)',
                    },
                    '& td, & th': {
                      fontFamily: 'Poppins, Medium',
                      fontSize: '15px',
                    },
                  }}
                >
                  <TableCell align="right">{defect.Reporter}</TableCell>
                  <TableCell align="right">{defect.issue_key}</TableCell>
                  <TableCell align="right">{defect.issue_summary}</TableCell>
                  <TableCell align="right">{defect.issue_status}</TableCell>
                </TableRow>
              ))}
              {data && data.closed_defects && data.closed_defects.map((defect, index) => (
                <TableRow
                  key={index}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0, fontFamily: 'Poppins, Medium', fontSize: '15px' },
                    '&:nth-child(even)': {
                      backgroundColor: '#90E0EF',
                    },
                    '&:nth-child(odd)': {
                      backgroundColor: 'rgb(166, 194, 206, 30%)',
                    },
                    '& td, & th': {
                      fontFamily: 'Poppins, Medium',
                      fontSize: '15px',
                    },
                  }}
                >
                  <TableCell align="right">{defect.Reporter}</TableCell>
                  <TableCell align="right">{defect.issue_key}</TableCell>
                  <TableCell align="right">{defect.issue_summary}</TableCell>
                  <TableCell align="right">{defect.issue_status}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Modal>
  
      {isPopupVisible && (
        <PopupDefectsdetailviewchart defects={selectedDefects} onClose={handlePopupClose} />
      )}
    </div>
  );
  
};

Detaildefectsmetricstalent.propTypes = {
  initialPodIds: PropTypes.array,
};

export default Detaildefectsmetricstalent;
