import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Container,
  Paper,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Divider,
  Alert,
  Snackbar,
  CircularProgress,
  Backdrop,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  Settings as SettingsIcon,
  GitHub as GitHubIcon,
  Assessment as JiraIcon,
  Cloud as SonarQubeIcon,
  Code as BitbucketIcon,
  People as PodIcon,
} from '@mui/icons-material';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useAuthToken } from "../TokenContext";

// Styled components
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  borderRadius: '16px',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
  background: '#ffffff',
}));

const MainTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 700,
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(4),
  '& .MuiSvgIcon-root': {
    fontSize: '2rem',
  },
}));

const SectionContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.background.paper,
  borderRadius: '12px',
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  border: `1px solid ${theme.palette.divider}`,
  transition: 'all 0.3s ease',
  '&:hover': {
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.08)',
    transform: 'translateY(-2px)',
  },
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  color: theme.palette.primary.main,
  fontWeight: 600,
  marginBottom: theme.spacing(3),
  padding: theme.spacing(1.5),
  borderRadius: '8px',
  background: theme.palette.primary.light + '20',
  '& .MuiSvgIcon-root': {
    color: theme.palette.primary.main,
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '8px',
  padding: '12px 32px',
  textTransform: 'none',
  fontWeight: 600,
  fontSize: '16px',
  boxShadow: '0 4px 12px rgba(29, 191, 115, 0.2)',
  backgroundColor: '#1DBF73',
  '&:hover': {
    backgroundColor: '#19a864',
    boxShadow: '0 6px 16px rgba(29, 191, 115, 0.3)',
  },
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  width: '100%',
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
  },
}));
const LoadingOverlay = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  color: '#fff',
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

function EditTalentSettingsForm() {
  const { user } = useSelector((state) => state.auth);
  const token = useAuthToken();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    id: user.data[0].c_id,
    podID: '',
    github_repo: '',
    github_username: '',
    jira_username: '',
    sonarqube_project_key: '',
    bitbucket_repo_slug: '',
    bitbucket_username: '',
  });

  const [podList, setPodList] = useState([]);
  const [podSelected, setPodSelected] = useState(false);

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const showNotification = (message, severity = 'success') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };

  useEffect(() => {
    const fetchPodList = async () => {
      try {
        const headers = {
          'Authorization': token,
          'Content-Type': 'application/json',
        };
        const response = await axios.post(
          process.env.REACT_APP_TALENT_PODLISTTALENT,
          { candidateID: user.data[0].c_id },
          { headers }
        );
        setPodList(response.data.data);
      } catch (error) {
        console.error('Error fetching pod list:', error);
        showNotification('Failed to fetch pod list', 'error');
      }
    };

    fetchPodList();
  }, [token, user.data]);

  useEffect(() => {
    if (formData.podID) {
      const fetchData = async () => {
        setIsLoading(true); // Start loading
        try {
          const headers = {
            'Authorization': token,
            'Content-Type': 'application/json',
          };
          const response = await axios.post(
            process.env.REACT_APP_TALENT_GETALENTSETTING,
            {
              candidateID: user.data[0].c_id,
              podID: formData.podID
            },
            { headers }
          );
          const data = response.data.body.data[0];
          setFormData(prev => ({
            ...prev,
            github_repo: data.github_repo || '',
            github_username: data.github_username || '',
            jira_username: data.jira_username || '',
            sonarqube_project_key: data.sonarqube_project_key || '',
            bitbucket_repo_slug: data.bitbucket_repo_slug || '',
            bitbucket_username: data.bitbucket_username || ''
          }));
          setPodSelected(true);
        } catch (error) {
          console.error('Error fetching data:', error);
          showNotification('Failed to fetch settings', 'error');
        }
        finally {
          setIsLoading(false); // Stop loading regardless of outcome
        }
      };

      fetchData();
    }
  }, [formData.podID, token, user.data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': token,
      };
      await axios.put(
        process.env.REACT_APP_TALENT_EDITTALENTSETTINGS,
        formData,
        { headers }
      );
      showNotification('Settings updated successfully!');
    } catch (error) {
      console.error('Error updating settings:', error);
      showNotification('Failed to update settings', 'error');
    }
  };

  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 8, mb: 4 }}>
        <MainTitle variant="h4">
          <SettingsIcon />
          Talent Settings
        </MainTitle>

        <StyledPaper elevation={3}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              {/* Pod Selection Section */}
              <Grid item xs={12}>
                <SectionContainer>
                  <SectionTitle variant="h6">
                    <PodIcon />
                    Pod Selection
                  </SectionTitle>
                  <StyledFormControl>
                    <InputLabel>Select Pod</InputLabel>
                    <Select
                      name="podID"
                      value={formData.podID}
                      onChange={handleChange}
                      label="Select Pod"
                      required
                    >
                      <MenuItem value="">
                        <em>Select a Pod</em>
                      </MenuItem>
                      {podList.map((pod) => (
                        <MenuItem key={pod.podID} value={pod.podID}>
                          {pod.podName}
                        </MenuItem>
                      ))}
                    </Select>
                  </StyledFormControl>
                </SectionContainer>
              </Grid>

              {isLoading && (
                <LoadingOverlay open={true}>
                  <CircularProgress color="primary" size={60} />
                  <Typography variant="h6" sx={{ color: '#1976d2' }}>
                    Loading Pod Data...
                  </Typography>
                </LoadingOverlay>
              )}

              {/* Only render form sections if pod is selected and not loading */}
              {podSelected && !isLoading && (
                <>
                  {/* GitHub Section */}
                  <Grid item xs={12}>
                    <SectionContainer>
                      <SectionTitle variant="h6">
                        <GitHubIcon />
                        GitHub Configuration
                      </SectionTitle>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <StyledTextField
                            fullWidth
                            label="GitHub Repository"
                            name="github_repo"
                            value={formData.github_repo}
                            onChange={handleChange}
                            required
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <StyledTextField
                            fullWidth
                            label="GitHub Username"
                            name="github_username"
                            value={formData.github_username}
                            onChange={handleChange}
                            required
                          />
                        </Grid>
                      </Grid>
                    </SectionContainer>
                  </Grid>

                  {/* Jira Section */}
                  <Grid item xs={12}>
                    <SectionContainer>
                      <SectionTitle variant="h6">
                        <JiraIcon />
                        Jira Configuration
                      </SectionTitle>
                      <StyledTextField
                        fullWidth
                        label="Jira Username"
                        name="jira_username"
                        value={formData.jira_username}
                        onChange={handleChange}
                        required
                      />
                    </SectionContainer>
                  </Grid>

                  {/* SonarQube Section */}
                  <Grid item xs={12}>
                    <SectionContainer>
                      <SectionTitle variant="h6">
                        <SonarQubeIcon />
                        SonarQube Configuration
                      </SectionTitle>
                      <StyledTextField
                        fullWidth
                        label="SonarQube Project Key"
                        name="sonarqube_project_key"
                        value={formData.sonarqube_project_key}
                        onChange={handleChange}
                        required
                      />
                    </SectionContainer>
                  </Grid>

                  {/* Bitbucket Section */}
                  <Grid item xs={12}>
                    <SectionContainer>
                      <SectionTitle variant="h6">
                        <BitbucketIcon />
                        Bitbucket Configuration
                      </SectionTitle>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <StyledTextField
                            fullWidth
                            label="Bitbucket Repository Slug"
                            name="bitbucket_repo_slug"
                            value={formData.bitbucket_repo_slug}
                            onChange={handleChange}
                            required
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <StyledTextField
                            fullWidth
                            label="Bitbucket Username"
                            name="bitbucket_username"
                            value={formData.bitbucket_username}
                            onChange={handleChange}
                            required
                          />
                        </Grid>
                      </Grid>
                    </SectionContainer>
                  </Grid>

                  {/* Submit Button */}
                  <Grid item xs={12}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                      <StyledButton
                        type="submit"
                        variant="contained"
                        size="large"
                      >
                        Save Settings
                      </StyledButton>
                    </Box>
                  </Grid>
                </>
              )}
            </Grid>
            
          </form>
        </StyledPaper>
      </Box>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default EditTalentSettingsForm;