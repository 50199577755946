import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { 
  Modal, 
  Box, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper,
  Typography,
  Select,
  CircularProgress,
  MenuItem,
  Card,
  CardContent,
  Container,
  styled
} from '@mui/material';
import BugReportIcon from '@mui/icons-material/BugReport'; import { useAuthToken } from "../TokenContext";
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

const StyledCard = styled(Card)(({ theme }) => ({
  cursor: 'pointer',
  transition: 'box-shadow 0.3s',
  width: '300px',
  height: '250px',
  '&:hover': {
    boxShadow: theme.shadows[4]
  }
}));

const LoadingContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '140px'
});

const StyledModalBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(3),
  width: '75%',
  maxHeight: '80vh',
  overflowY: 'auto'
}));

const CardDefects = ({ initialPodIds, selectedSprint }) => {
  const [metrics, setMetrics] = useState([]);
  const [selectedReporter, setSelectedReporter] = useState('');
  const [data, setData] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const token = useAuthToken();
  const intl = useIntl();
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    const fetchDataForStakeholder = async () => {
        setIsLoading(true);
      try {
        if (!token) {
          console.log('Token is missing. API requests will not be made.');
          return;
        }
        
        const payload = {
          pod_id: initialPodIds || [],
          org_code: user.data[0].client_code,
          sprint_name: selectedSprint || ''
        };

        const response = await fetch(
          process.env.REACT_APP_STAKEHOLDER_JIRADEFETCSMERICSSTAKEHOLDER,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token,
            },
            body: JSON.stringify(payload),
          }
        );

        const responseData = await response.json();
        const parsedData = JSON.parse(responseData.body);
        handleResponseData(parsedData);
      } catch (error) {
        console.error('Error fetching metrics:', error);
        resetState();
      }finally {
        setIsLoading(false);
      }
    };

    const fetchDataForUser = async () => {
      if (initialPodIds?.length > 0) {
        await fetchMetricsPod(initialPodIds);
      } else {
        await fetchMetrics();
      }
    };

    if (user.data[0].type === "stakeholder") {
      fetchDataForStakeholder();
    } else {
      fetchDataForUser();
    }
  }, [token, user.data[0].type, initialPodIds, selectedSprint]);

  const handleResponseData = (parsedData) => {
    if (parsedData?.reporters?.length > 0) {
      setMetrics(parsedData.reporters);
      setData(parsedData);
    } else {
      resetState();
    }
  };

  const resetState = () => {
    setMetrics([]);
    setData(null);
  };

  const fetchMetricsPod = async (selectedPodIds) => {
    setIsLoading(true);
    try {
      if (!token) return;
      
      const response = await fetch(
        process.env.REACT_APP_CLIENT_JIRAMETRICSMANAGERPODID,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
          },
          body: JSON.stringify({ 
            pod_id: selectedPodIds || null, 
            sprint_name: selectedSprint 
          }),
        }
      );

      const responseData = await response.json();
      const parsedData = JSON.parse(responseData.body);
      handleResponseData(parsedData);
    } catch (error) {
      console.log('Error fetching metrics:', error);
      resetState();
    }finally {
        setIsLoading(false);
      }
  };

  const fetchMetrics = async () => {
    setIsLoading(true);
    try {
      if (!token) return;
      
      const response = await fetch(
        process.env.REACT_APP_CLIENT_GETJIRAUSERDEFECTS,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
          },
          body: JSON.stringify({
            manager_id: user.data[0].emp_id,
            sprint_name: selectedSprint,
          }),
        }
      );

      const responseData = await response.json();
      const parsedData = JSON.parse(responseData.body);
      handleResponseData(parsedData);
    } catch (error) {
      console.log('Error fetching metrics:', error);
      resetState();
    }finally {
        setIsLoading(false);
      }
  };

  const getDisplayData = () => {
    if (!data) return { resolved: 0, open: 0 };

    if (!selectedReporter) {
      return {
        resolved: data.total_user_defect_count?.resolved_defects || 0,
        open: data.total_user_defect_count?.open_defects || 0
      };
    }

    const reporterData = data.reporters.find(r => r.Reporter === selectedReporter);
    return reporterData ? {
      resolved: reporterData.resolved_defects,
      open: reporterData.open_defects
    } : { resolved: 0, open: 0 };
  };

  return (
    <Box sx={{ height: '100%' }}>
      <Box 
        display="flex" 
        flexDirection="column" 
        alignItems="center"
        sx={{ py: 3 }}
      >
        <Typography 
          variant="h5" 
          gutterBottom 
          sx={{ fontWeight: 'medium', mb: 3 }}
        >
          {intl.formatMessage({ id: 'defects' })}
        </Typography>

        <Select
          value={selectedReporter}
          onChange={(e) => setSelectedReporter(e.target.value)}
          sx={{ 
            width: 240, 
            mb: 3,
            '& .MuiSelect-select': {
              py: 1.5
            }
          }}
          displayEmpty
        >
          <MenuItem value="">
            {intl.formatMessage({ id: 'allReporters' })}
          </MenuItem>
          {metrics.map((reporter) => (
            <MenuItem 
              key={reporter.Reporter} 
              value={reporter.Reporter}
            >
              {reporter.Reporter}
            </MenuItem>
          ))}
        </Select>

        <StyledCard onClick={() => setModalIsOpen(true)}>
          <CardContent sx={{ p: 3 }}>
            <Box display="flex" alignItems="center" mb={2}>
                
              <BugReportIcon 
                style={{ 
                  width: 32, 
                  height: 32, 
                  marginRight: 12, 
                  color: '#f44336'
                }} 
              />
              <Typography variant="h6"  sx={{ 
      fontWeight: 600,
      letterSpacing: '0.5px',
      background: 'linear-gradient(135deg, #f44336 0%, #d32f2f 100%)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent'
    }}>Defects</Typography>
            </Box>
            {isLoading ? (
                              <LoadingContainer>
                                <CircularProgress />
                              </LoadingContainer>
                            ) : (
                              <>
            <Box sx={{ mt: 5.5 }}>
           
              <Box 
                display="flex" 
                justifyContent="space-between" 
                alignItems="center"
                mb={1.5}
              >
                   
                <Typography color="text.secondary">TO DO:</Typography>
                <Typography 
                  fontWeight="bold"
                  sx={{ ml: 2 }}
                >
                  {getDisplayData().open}
                </Typography>
              </Box>
              <Box 
                display="flex" 
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography color="text.secondary">DONE:</Typography>
                <Typography 
                  fontWeight="bold"
                  sx={{ ml: 2 }}
                >
                  {getDisplayData().resolved}
                </Typography>
              </Box>
            </Box>
            </>
            )}
          </CardContent>
        </StyledCard>
      </Box>

      <Modal
        open={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        aria-labelledby="defects-modal-title"
      >
        <StyledModalBox>
          <Typography 
            id="defects-modal-title" 
            variant="h6" 
            sx={{ 
              mb: 3,
              fontWeight: 'medium'
            }}
          >
            Defects Details
          </Typography>
          <TableContainer 
            component={Paper}
            sx={{ maxHeight: '60vh' }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell 
                    sx={{ 
                      bgcolor: 'primary.light',
                      fontWeight: 'medium'
                    }}
                  >
                    {intl.formatMessage({ id: 'reporter' })}
                  </TableCell>
                  <TableCell 
                    sx={{ 
                      bgcolor: 'primary.light',
                      fontWeight: 'medium'
                    }}
                  >
                    {intl.formatMessage({ id: 'issueKey' })}
                  </TableCell>
                  <TableCell 
                    sx={{ 
                      bgcolor: 'primary.light',
                      fontWeight: 'medium'
                    }}
                  >
                    {intl.formatMessage({ id: 'issueSummary' })}
                  </TableCell>
                  <TableCell 
                    sx={{ 
                      bgcolor: 'primary.light',
                      fontWeight: 'medium'
                    }}
                  >
                    {intl.formatMessage({ id: 'issueStatus' })}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {[...(data?.open_defects || []), ...(data?.resolved_defects || [])].map((defect, index) => (
                  <TableRow 
                    key={index}
                    sx={{ 
                      '&:nth-of-type(odd)': { 
                        bgcolor: 'action.hover' 
                      },
                      '&:hover': {
                        bgcolor: 'action.selected'
                      }
                    }}
                  >
                    <TableCell>{defect.Reporter}</TableCell>
                    <TableCell>{defect.issue_key}</TableCell>
                    <TableCell>{defect.issue_summary}</TableCell>
                    <TableCell>{defect.issue_status}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </StyledModalBox>
      </Modal>
    </Box>
  );
};

CardDefects.propTypes = {
  initialPodIds: PropTypes.array,
  selectedSprint: PropTypes.string
};

export default CardDefects;