import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAuthToken } from '../TokenContext';
import { useIntl } from 'react-intl';
import {
  Box,
  Typography,
  Grid,
  Container,
  Select as MuiSelect,
  MenuItem,
  styled,
  FormControl,
  InputLabel,
  useTheme
} from '@mui/material';
import Select from 'react-select';
import CardStories from './CardStories';
import CardDefects from './CardDefects';
import PropTypes from 'prop-types';
import CardTasks from './CardTasks';
import Cardtime from './Cardtime';

// Styled Components

// Styled Components with dark mode support
const StyledContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(4),
  maxWidth: '100%',
  margin: '0 auto',
  height: '100%',
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : '#F5F5F5'
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  fontWeight: 700,
  marginTop: "2.5rem",
  marginBottom: theme.spacing(3),
  color: theme.palette.mode === 'dark' ? theme.palette.primary.light : '#2563EB',
  fontFamily: '"Poppins", sans-serif',
  fontSize: '28px',
  letterSpacing: '0.5px',
  position: 'relative',
  padding: '0 16px',
  
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: '-12px',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '60px',
    height: '3px',
    background: theme.palette.mode === 'dark' 
      ? `linear-gradient(90deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`
      : 'linear-gradient(90deg, #2563EB 0%, #60A5FA 100%)',
    borderRadius: '2px',
  },

  [theme.breakpoints.down('md')]: {
    fontSize: '24px',
    marginTop: '2rem',
    marginBottom: theme.spacing(2.5),
    '&::after': {
      width: '50px',
      bottom: '-10px',
    }
  },

  [theme.breakpoints.down('sm')]: {
    fontSize: '22px',
    marginTop: '1.5rem',
    letterSpacing: '0.3px',
    '&::after': {
      width: '40px',
      height: '2px',
    }
  },

  '@media (max-width: 320px)': {
    fontSize: '20px',
    marginTop: '1rem',
    padding: '0 8px',
  }
}));

const ContentContainer = styled(Box)(({ theme, collapsed }) => ({
  marginLeft: collapsed ? '60px' : '240px',
  width: 'auto',
  transition: theme.transitions.create(['margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  
  [theme.breakpoints.down('md')]: {
    marginLeft: '0',
    padding: theme.spacing(1),
  }
}));

const FilterContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(4),
  padding: '0 16px',
  
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    gap: theme.spacing(1.5),
    padding: '0 8px',
    marginBottom: theme.spacing(2),
  }
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  minWidth: 500,
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : 'white',
  borderRadius: theme.shape.borderRadius,
  '& .MuiOutlinedInput-root': {
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : 'white',
    '& fieldset': {
      borderColor: theme.palette.mode === 'dark' ? theme.palette.divider : 'rgba(0, 0, 0, 0.23)',
    },
    '&:hover fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
  '& .MuiInputLabel-root': {
    color: theme.palette.mode === 'dark' ? theme.palette.text.primary : 'inherit',
  },
  
  [theme.breakpoints.down('md')]: {
    minWidth: '100%',
    '& .MuiInputLabel-root': {
      fontSize: '0.9rem',
    },
    '& .MuiSelect-select': {
      padding: '12px',
    }
  }
}));

const CustomSelect = styled(Select)(({ theme }) => ({
  flex: 1,
  '& .select__control': {
    minHeight: '56px',
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : 'white',
    borderColor: theme.palette.divider,
    '&:hover': {
      borderColor: theme.palette.primary.main
    }
  },
  '& .select__menu': {
    zIndex: 2,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : 'white',
  },
  '& .select__option': {
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : 'white',
    color: theme.palette.mode === 'dark' ? theme.palette.text.primary : 'inherit',
    '&:hover': {
      backgroundColor: theme.palette.mode === 'dark' ? theme.palette.action.hover : 'rgba(0, 0, 0, 0.04)',
    }
  },
  '& .select__single-value': {
    color: theme.palette.mode === 'dark' ? theme.palette.text.primary : 'inherit',
  },
  '& .select__multi-value': {
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.action.selected : 'rgba(0, 0, 0, 0.08)',
  },
  '& .select__multi-value__label': {
    color: theme.palette.mode === 'dark' ? theme.palette.text.primary : 'inherit',
  },
  
  [theme.breakpoints.down('md')]: {
    '& .select__control': {
      minHeight: '48px',
    },
    '& .select__placeholder': {
      fontSize: '0.9rem',
    },
    '& .select__single-value': {
      fontSize: '0.9rem',
    }
  }
}));

const GridContainer = styled(Grid)(({ theme }) => ({
  padding: '0 16px',
  marginTop: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    padding: '0 8px'
  }
}));

const GridItem = styled(Grid)({
  display: 'flex',
  alignItems: 'stretch',
  minHeight: '200px',
  '& > *': {
    width: '100%',
    height: '100%',
    minHeight: 'inherit'
  }
});



const PodProductivity = ({collapsed}) => {
  const [podData, setPodData] = useState([]);
  const [selectedPods, setSelectedPods] = useState([]);
  const [sprintData, setSprintData] = useState([]);
  const [selectedSprint, setSelectedSprint] = useState({
    name: "",
    startDate: "",
    endDate: ""
  });
  const [isLoading, setIsLoading] = useState({
    stories: true,
    defects: true,
    tasks: true,
    timeLog: true
  });
  const [metricsData, setMetricsData] = useState({
    stories: null,
    defects: null,
    tasks: null,
    timeLog: null
  });

  const intl = useIntl();
  const theme = useTheme();
  const { user } = useSelector((state) => state.auth);
  const token = useAuthToken();

  useEffect(() => {
    fetchPodData();
    fetchSprintData();
  }, [token]);

  useEffect(() => {
    if (selectedPods.length > 0 || selectedSprint.name) {
      fetchMetrics();
    }
  }, [selectedPods, selectedSprint.name]);

  const fetchPodData = async () => {
    if (!token) return;

    try {
      const url = user.data[0].type === "stakeholder"
        ? process.env.REACT_APP_STAKEHOLDER_GETPODSFORSTAKEHOLDER
        : process.env.REACT_APP_CLIENT_GETPODSFORMANAGER;

      const body = user.data[0].type === "stakeholder"
        ? { org_code: user.data[0].client_code }
        : { emp_id: user.data[0].emp_id };

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...(user.data[0].type !== "stakeholder" && { Authorization: token }),
        },
        body: JSON.stringify(body),
      });

      const data = await response.json();
      if (data.statusCode === 200 && Array.isArray(data.body)) {
        setPodData(data.body);
      }
    } catch (error) {
      console.error('Error fetching pod data:', error);
    }
  };

  const fetchSprintData = async () => {
    if (!token) return;

    try {
      const url = user.data[0].type === "stakeholder"
        ? process.env.REACT_APP_STAKEHOLDER_SPRINTFILTERSTAKEHOLDER
        : process.env.REACT_APP_CLIENT_SPRINTFILTERMANAGER;

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...(user.data[0].type !== "stakeholder" && { Authorization: token }),
        },
        body: JSON.stringify({
          manager_id: user.data[0].emp_id,
          org_code: user.data[0].client_code,
        }),
      });

      const data = await response.json();
      if (data.statusCode === 200 && data.body) {
        const parsedBody = JSON.parse(data.body);
        if (Array.isArray(parsedBody.sprint_filter)) {
          setSprintData(parsedBody.sprint_filter);
        }
      }
    } catch (error) {
      console.error('Error fetching sprint data:', error);
    }
  };

  const fetchMetrics = async () => {
    setIsLoading({
      stories: true,
      defects: true,
      tasks: true,
      timeLog: true
    });

    try {
      // Fetch your metrics data here
      // For now using mock data
      setTimeout(() => {
        setMetricsData({
          stories: { todo: 34, done: 24 },
          defects: { todo: 58, done: 82 },
          tasks: { todo: 193, done: 15 },
          timeLog: {
            previousWeek: '85 hours 0 minutes',
            currentWeek: '17 hours 0 minutes'
          }
        });
        setIsLoading({
          stories: false,
          defects: false,
          tasks: false,
          timeLog: false
        });
      }, 1000);
    } catch (error) {
      console.error('Error fetching metrics:', error);
      setIsLoading({
        stories: false,
        defects: false,
        tasks: false,
        timeLog: false
      });
    }
  };

  const podOptions = podData.map((pod) => ({
    value: pod.pod_id,
    label: pod.pod_name
  }));

  const handlePodSelect = (selectedOptions) => {
    setSelectedPods(selectedOptions?.map((option) => option.value) || []);
  };

  const handleSprintSelect = (event) => {
    const selectedSprintName = event.target.value;
    const selectedSprintData = sprintData.find(
      (sprint) => sprint.sprint_name === selectedSprintName
    );
    
    setSelectedSprint({
      name: selectedSprintName,
      startDate: selectedSprintData?.sprint_start_date || "",
      endDate: selectedSprintData?.sprint_end_date || ""
    });
  };

  return (
    <Box sx={{ 
      p: 3, 
      backgroundColor: theme => theme.palette.mode === 'dark' ? theme.palette.background.default : '#F5F5F5',
      minHeight: '100vh',
      width: "100vw" 
    }}>
      <StyledTitle>
        {intl.formatMessage({ id: 'Pod Productivity' })}
      </StyledTitle>
<ContentContainer collapsed={collapsed} >
      <FilterContainer>
        <CustomSelect
          options={podOptions}
          isMulti
          value={podOptions.filter((option) => selectedPods.includes(option.value))}
          onChange={handlePodSelect}
          placeholder={intl.formatMessage({ id: "selectPods" })}
          classNamePrefix="select"
        />
        
        <StyledFormControl>
          <InputLabel>Select Sprint</InputLabel>
          <MuiSelect
            value={selectedSprint.name}
            onChange={handleSprintSelect}
            label="Select Sprint"
          >
            <MenuItem value="">
              <em>Select a Sprint</em>
            </MenuItem>
            {sprintData.map((sprint, index) => (
              <MenuItem key={index} value={sprint.sprint_name}>
                {sprint.sprint_name}
              </MenuItem>
            ))}
          </MuiSelect>
        </StyledFormControl>
      </FilterContainer>

      <GridContainer container spacing={3}>
        <GridItem item xs={12} sm={6} md={3}>
          <CardStories
            initialPodIds={selectedPods}
            selectedSprint={selectedSprint.name}
            isLoading={isLoading.stories}
          />
        </GridItem>
        <GridItem item xs={12} sm={6} md={3}>
          <CardDefects
            initialPodIds={selectedPods}
            selectedSprint={selectedSprint.name}
            isLoading={isLoading.defects}
          />
        </GridItem>
        <GridItem item xs={12} sm={6} md={3}>
          <CardTasks
            initialPodIds={selectedPods}
            selectedSprint={selectedSprint.name}
            sprintStartDate={selectedSprint.startDate}
            sprintEndDate={selectedSprint.endDate}
            isLoading={isLoading.tasks}
          />
        </GridItem>
        <GridItem item xs={12} sm={6} md={3}>
          <Cardtime
            initialPodIds={selectedPods}
            selectedSprint={selectedSprint.name}
            sprintStartDate={selectedSprint.startDate}
            sprintEndDate={selectedSprint.endDate}
            isLoading={isLoading.timeLog}
          />
        </GridItem>
      </GridContainer>
      </ContentContainer>
    </Box>
  );
};

PodProductivity.propTypes = {
  collapsed: PropTypes.bool
};

PodProductivity.defaultProps = {
  collapsed: false
};
export default PodProductivity;