// VideoViewer.js
import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { 
  Box, 
  Typography, 
  CircularProgress,
  Container,
  Paper
} from '@mui/material';

const VideoViewer = () => {
  const [videoData, setVideoData] = useState(null);
  const [error, setError] = useState(null);
  const { state } = useParams();
  const history = useHistory();
  const { isLoggedIn } = useSelector((state) => state.auth);

  useEffect(() => {
    try {
      // Check if user is logged in
      if (!isLoggedIn) {
        throw new Error('Authentication required');
      }

      // Decode and validate the state
      const decodedState = JSON.parse(atob(state));
      
      // Validate timestamp
      if (Date.now() > decodedState.exp) {
        throw new Error('Link expired');
      }

      // Validate auth flag
      if (!decodedState.isAuth) {
        throw new Error('Invalid access');
      }

      setVideoData(decodedState);

    } catch (error) {
      console.error('Video validation failed:', error);
      setError('Access denied. Please login and try again.');
      setTimeout(() => {
        history.push('/login');
      }, 3000);
    }
  }, [state, history, isLoggedIn]);

  if (error) {
    return (
      <Container maxWidth="lg" sx={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Typography color="error" variant="h6">{error}</Typography>
      </Container>
    );
  }

  if (!videoData) {
    return (
      <Container maxWidth="lg" sx={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ 
      minHeight: '100vh', 
      display: 'flex', 
      flexDirection: 'column',
      alignItems: 'center', 
      justifyContent: 'center',
      py: 4
    }}>
      <Paper 
        elevation={3}
        sx={{
          width: '100%',
          height: 'auto',
          aspectRatio: '16/9',
          overflow: 'hidden',
          bgcolor: 'black',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <video
          controls
          autoPlay
          playsInline
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'contain'
          }}
        >
          <source src={videoData.url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </Paper>
    </Container>
  );
};

export default VideoViewer;