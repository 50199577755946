import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Paper,
  Avatar,
  Typography,
  TextField,
  Button,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Divider,
  Breadcrumbs,
  Card,
  CardContent,
  styled,
  CircularProgress,
  Snackbar,
  Alert,
  tooltipClasses,
  useTheme,
} from '@mui/material';
import {
  Edit as EditIcon,
  ArrowBack as ArrowBackIcon,
  Person as PersonIcon,
  PhotoCamera as PhotoCameraIcon,
  Save as SaveIcon,
  Close as CloseIcon
} from '@mui/icons-material';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import S3FileUpload from 'react-s3';
import AWS from 'aws-sdk';
import { updateProfileclient } from "./action/auth";
import { useAuthToken } from "../TokenContext";
import PropTypes from 'prop-types';
import LanguageSelector from '../LanguageSelector';
import defaultimg from './assets/defaultimge.png';
import { useIntl } from 'react-intl';





const UploadOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  opacity: 0,
  transition: 'opacity 0.2s ease',
  borderRadius: '50%',
  cursor: 'pointer',
  '&:hover': {
    opacity: 1
  }
}));





// Styled Components with dark mode support
const ProfileContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(10),
  marginBottom: theme.spacing(8)
}));

const ProfileCard = styled(Card)(({ theme }) => ({
  borderRadius: 16,
  boxShadow: theme.palette.mode === 'dark' 
    ? '0 4px 24px 0 rgba(0, 0, 0, 0.2)'
    : '0 4px 24px 0 rgba(34, 41, 47, 0.1)',
  overflow: 'visible',
  position: 'relative',
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#fff'
}));

const ProfileHeader = styled(Box)(({ theme }) => ({
  height: 160,
  background: theme.palette.mode === 'dark'
    ? 'linear-gradient(135deg, #1a237e 0%, #0d47a1 100%)'
    : 'linear-gradient(135deg, #03045e 0%, #0077b6 100%)',
  borderRadius: '16px 16px 0 0',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  padding: '16px'
}));

const StyledLanguageSelector = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 16,
  right: 16,
  zIndex: 2,
  backgroundColor: theme.palette.mode === 'dark' 
    ? 'rgba(18, 18, 18, 0.9)'
    : 'rgba(255, 255, 255, 0.9)',
  borderRadius: '8px',
  padding: '4px',
  boxShadow: theme.palette.mode === 'dark'
    ? '0 2px 8px rgba(0,0,0,0.3)'
    : '0 2px 8px rgba(0,0,0,0.1)'
}));

const ProfileAvatarWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: '50%',
  bottom: -60,
  transform: 'translateX(-50%)',
  zIndex: 2
}));

const ProfileAvatar = styled(Avatar)(({ theme }) => ({
  width: 120,
  height: 120,
  border: `4px solid ${theme.palette.mode === 'dark' 
    ? theme.palette.background.paper 
    : '#fff'}`,
  boxShadow: theme.palette.mode === 'dark'
    ? '0 4px 12px rgba(0,0,0,0.3)'
    : '0 4px 12px rgba(0,0,0,0.15)',
  transition: 'transform 0.3s ease',
  '&:hover': {
    transform: 'scale(1.05)',
  }
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  '& .MuiOutlinedInput-root': {
    borderRadius: 8,
    backgroundColor: theme.palette.mode === 'dark' 
      ? theme.palette.background.paper 
      : theme.palette.background.default,
    transition: 'all 0.2s ease',
    '&:hover': {
      backgroundColor: theme.palette.mode === 'dark'
        ? theme.palette.action.hover
        : '#f8fafc'
    },
    '&.Mui-focused': {
      backgroundColor: theme.palette.mode === 'dark'
        ? theme.palette.background.paper
        : '#fff',
      boxShadow: theme.palette.mode === 'dark'
        ? '0 0 0 2px rgba(64, 196, 255, 0.2)'
        : '0 0 0 2px rgba(0, 180, 216, 0.2)'
    }
  },
  '& .MuiInputLabel-root': {
    color: theme.palette.mode === 'dark' 
      ? theme.palette.text.secondary
      : theme.palette.text.primary
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, 0.15)'
      : 'rgba(0, 0, 0, 0.15)'
  }
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  '& .MuiOutlinedInput-root': {
    borderRadius: 8,
    backgroundColor: theme.palette.mode === 'dark'
      ? theme.palette.background.paper
      : theme.palette.background.default,
    '&:hover': {
      backgroundColor: theme.palette.mode === 'dark'
        ? theme.palette.action.hover
        : '#f8fafc'
    }
  },
  '& .MuiInputLabel-root': {
    color: theme.palette.mode === 'dark'
      ? theme.palette.text.secondary
      : theme.palette.text.primary
  }
}));

const ActionButton = styled(Button)(({ theme }) => ({
  borderRadius: 8,
  padding: '10px 24px',
  textTransform: 'none',
  fontWeight: 600,
  boxShadow: theme.palette.mode === 'dark'
    ? '0 4px 12px rgba(64, 196, 255, 0.2)'
    : '0 4px 12px rgba(0, 180, 216, 0.2)',
  '&:hover': {
    boxShadow: theme.palette.mode === 'dark'
      ? '0 6px 16px rgba(64, 196, 255, 0.3)'
      : '0 6px 16px rgba(0, 180, 216, 0.3)'
  }
}));

const InfoSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: theme.palette.mode === 'dark'
    ? theme.palette.background.paper
    : theme.palette.grey[50],
  borderRadius: 12,
  marginBottom: theme.spacing(3)
}));

// Update the Snackbar styles
const StyledSnackbar = styled(Snackbar)(({ theme }) => ({
  '& .MuiAlert-root': {
    backgroundColor: theme.palette.mode === 'dark'
      ? theme.palette.background.paper
      : '#fff',
    boxShadow: theme.palette.mode === 'dark'
      ? '0 2px 8px rgba(0,0,0,0.3)'
      : '0 2px 8px rgba(0,0,0,0.1)',
    borderRadius: '8px'
  }
}));

// Update the Loading Overlay
const LoadingOverlay = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.mode === 'dark'
    ? 'rgba(0,0,0,0.8)'
    : 'rgba(255,255,255,0.8)',
  zIndex: 9999
}));

// Add these styles to the CardContent component
const StyledCardContent = styled(CardContent)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark'
    ? theme.palette.background.paper
    : '#fff',
  transition: 'background-color 0.3s ease'
}));
const Clientprofile = ({ currentLocale, changeLocale }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const token = useAuthToken();
  const { user } = useSelector(state => state.auth);
  const intl = useIntl();
const theme=useTheme();
  const [state, setState] = useState({
    fname: user.data[0].emp_firstname || '',
    mname: user.data[0].emp_middlename || '',
    lname: user.data[0].emp_lastname || '',
    email: user.data[0].email || '',
    phone_number: user.data[0].phone_number || '',
    country: user.data[0].emp_country || '',
    about: user.data[0].about || '',
    profilePicture: user.data[0].profilePicture || ''
  });

  const [edit, setEdit] = useState(user.data[0].profile === 0);
  const [image, setImage] = useState('');
  const [uploading, setUploading] = useState(false);
  const [authImage, setAuthImage] = useState('');
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  const config = {
    bucketName: process.env.REACT_APP_BUCKETNAME,
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_ACCESSKEYID,
    secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY
  };

  useEffect(() => {
    generatePreSignedUrl();
  }, []);

  const generatePreSignedUrl = async () => {
    if (!user.data[0].profilePicture) return;
    
    try {
      const response = await fetch(process.env.REACT_APP_TALENT_CLOUDFRONTAPI, {
        method: 'POST',
        headers: {
          'Authorization': token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          url: process.env.REACT_APP_CLOUDFRONT + user.data[0].profilePicture
        })
      });
      
      const data = await response.json();
      const signedUrl = JSON.parse(data.body).signedUrl;
      setAuthImage(signedUrl);
    } catch (error) {
      console.error("Error generating signed URL:", error);
    }
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files?.[0];
    if (!file) return;

    setUploading(true);
    try {
      // Create immediate preview
      const preview = URL.createObjectURL(file);
      setImage(preview);

      // Upload to S3
      const uploadResult = await S3FileUpload.uploadFile(file, config);
      setState(prev => ({
        ...prev,
        profilePicture: uploadResult.key
      }));

      // Update auth image for non-edit mode
      const newSignedUrl = `https://${config.bucketName}.s3.${config.region}.amazonaws.com/${uploadResult.key}`;
      setAuthImage(newSignedUrl);

      setSnackbar({
        open: true,
        message: 'Image uploaded successfully',
        severity: 'success'
      });
    } catch (error) {
      console.error('Upload error:', error);
      setSnackbar({
        open: true,
        message: 'Failed to upload image',
        severity: 'error'
      });
      // Revert to previous image
      setImage('');
    } finally {
      setUploading(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    try {
      const data = {
        emp_id: user.data[0].emp_id,
        emp_firstname: state.fname,
        emp_middlename: state.mname,
        emp_lastname: state.lname,
        phone_number: state.phone_number,
        emp_country: state.country,
        about: state.about,
        profilePicture: state.profilePicture
      };

      await dispatch(updateProfileclient(data, {
        'Authorization': token,
        'Content-Type': 'application/json'
      }));

      setSnackbar({
        open: true,
        message: 'Profile updated successfully',
        severity: 'success'
      });

      setEdit(false);
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to update profile',
        severity: 'error'
      });
    }
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setState(prev => ({
      ...prev,
      [id]: value
    }));
  };

  const countries = [ { value: 'usa', label: 'USA' },
    { value: 'canada', label: 'Canada' },
    { value: 'india', label: 'India' },
    { value: 'australia', label: 'Australia' },
    { value: 'united_kingdom', label: 'United Kingdom' },
    { value: 'germany', label: 'Germany' },
    { value: 'france', label: 'France' },
    { value: 'china', label: 'China' },
    { value: 'japan', label: 'Japan' },
    { value: 'brazil', label: 'Brazil' },
    { value: 'south_africa', label: 'South Africa' },
    { value: 'mexico', label: 'Mexico' },
    { value: 'italy', label: 'Italy' },
    { value: 'spain', label: 'Spain' },
    { value: 'russia', label: 'Russia' },
    { value: 'south_korea', label: 'South Korea' },
    { value: 'new_zealand', label: 'New Zealand' },
    { value: 'singapore', label: 'Singapore' },
    { value: 'united_arab_emirates', label: 'United Arab Emirates' },
    { value: 'saudi_arabia', label: 'Saudi Arabia' },
    { value: 'argentina', label: 'Argentina' },
    { value: 'netherlands', label: 'Netherlands' },
    { value: 'sweden', label: 'Sweden' },
    { value: 'switzerland', label: 'Switzerland' },
    { value: 'norway', label: 'Norway' },
    { value: 'denmark', label: 'Denmark' },
    { value: 'finland', label: 'Finland' },
    { value: 'portugal', label: 'Portugal' },
    { value: 'poland', label: 'Poland' },
    { value: 'turkey', label: 'Turkey' },
    { value: 'indonesia', label: 'Indonesia' },
    { value: 'malaysia', label: 'Malaysia' },
    { value: 'thailand', label: 'Thailand' },
    { value: 'vietnam', label: 'Vietnam' },
    { value: 'philippines', label: 'Philippines' },
    { value: 'egypt', label: 'Egypt' },
    { value: 'nigeria', label: 'Nigeria' },
    { value: 'kenya', label: 'Kenya' },
    { value: 'ghana', label: 'Ghana' },
    { value: 'colombia', label: 'Colombia' },
    { value: 'chile', label: 'Chile' },
    { value: 'peru', label: 'Peru' },
    { value: 'venezuela', label: 'Venezuela' },
    { value: 'pakistan', label: 'Pakistan' },
    { value: 'bangladesh', label: 'Bangladesh' },
    { value: 'sri_lanka', label: 'Sri Lanka' },
    { value: 'nepal', label: 'Nepal' },
    { value: 'bhutan', label: 'Bhutan' },
    { value: 'maldives', label: 'Maldives' },
    { value: 'iceland', label: 'Iceland' },
    { value: 'luxembourg', label: 'Luxembourg' },
    { value: 'qatar', label: 'Qatar' },
    { value: 'oman', label: 'Oman' },
    { value: 'kuwait', label: 'Kuwait' },
    { value: 'bahrain', label: 'Bahrain' },
    { value: 'iraq', label: 'Iraq' },
    { value: 'iran', label: 'Iran' },
    { value: 'afghanistan', label: 'Afghanistan' },
    { value: 'israel', label: 'Israel' },
    { value: 'jordan', label: 'Jordan' },
    { value: 'lebanon', label: 'Lebanon' },
    { value: 'morocco', label: 'Morocco' },
    { value: 'algeria', label: 'Algeria' },
    { value: 'tunisia', label: 'Tunisia' },
    { value: 'libya', label: 'Libya' },
    { value: 'ethiopia', label: 'Ethiopia' },
    { value: 'tanzania', label: 'Tanzania' },
    { value: 'uganda', label: 'Uganda' },
    { value: 'zimbabwe', label: 'Zimbabwe' },
    { value: 'zambia', label: 'Zambia' },
    { value: 'mozambique', label: 'Mozambique' },
    { value: 'botswana', label: 'Botswana' },
    { value: 'namibia', label: 'Namibia' },
    { value: 'angola', label: 'Angola' },
    { value: 'cuba', label: 'Cuba' },
    { value: 'jamaica', label: 'Jamaica' },
    { value: 'haiti', label: 'Haiti' },
    { value: 'dominican_republic', label: 'Dominican Republic' },
    { value: 'trinidad_and_tobago', label: 'Trinidad and Tobago' },
    { value: 'bahamas', label: 'Bahamas' },
    { value: 'barbados', label: 'Barbados' },
    { value: 'panama', label: 'Panama' },
    { value: 'costa_rica', label: 'Costa Rica' },
    { value: 'honduras', label: 'Honduras' },
    { value: 'guatemala', label: 'Guatemala' },
    { value: 'el_salvador', label: 'El Salvador' },
    { value: 'nicaragua', label: 'Nicaragua' },
    { value: 'belize', label: 'Belize' },
    { value: 'paraguay', label: 'Paraguay' },
    { value: 'uruguay', label: 'Uruguay' },
    { value: 'bolivia', label: 'Bolivia' },
    { value: 'ecuador', label: 'Ecuador' },
    { value: 'guinea', label: 'Guinea' },
    { value: 'ivory_coast', label: 'Ivory Coast' },
    { value: 'cameroon', label: 'Cameroon' },
    { value: 'senegal', label: 'Senegal' },
    { value: 'sudan', label: 'Sudan' },
    { value: 'south_sudan', label: 'South Sudan' },
    { value: 'congo', label: 'Congo' },
    { value: 'madagascar', label: 'Madagascar' },
    { value: 'malawi', label: 'Malawi' },
    { value: 'sierra_leone', label: 'Sierra Leone' },
    { value: 'chad', label: 'Chad' },
    { value: 'mali', label: 'Mali' },
    { value: 'burkina_faso', label: 'Burkina Faso' },
    { value: 'rwanda', label: 'Rwanda' },
    { value: 'burundi', label: 'Burundi' },
    { value: 'lesotho', label: 'Lesotho' },
    { value: 'eswatini', label: 'Eswatini' }
  
  ];
  const [country, setCountry] = useState('');
  
  const handleChange = (event) => {
    setCountry(event.target.value);
  };


  return (
    <ProfileContainer maxWidth="lg">
      <Breadcrumbs sx={{ mb: 4 }}>
        <Link 
          to="/client-dashboard"
          style={{
            color: '#03045e',
            textDecoration: 'none',
            fontSize: '16px',
            fontWeight: 600
          }}
        >
          {intl.formatMessage({ id: 'dashboard' })}
        </Link>
        <Typography color="textSecondary" fontWeight={600}>
          {intl.formatMessage({ id: 'manageProfile' })}
        </Typography>
      </Breadcrumbs>

      <ProfileCard>
        <ProfileHeader>
          <StyledLanguageSelector>
            <LanguageSelector 
              currentLocale={currentLocale} 
              changeLocale={changeLocale}
            />
          </StyledLanguageSelector>

          <ProfileAvatarWrapper>
          <ProfileAvatar
            src={image || authImage || defaultimg}
            alt={state.fname}
          />
          {edit && (
            <UploadOverlay>
              <label htmlFor="profile-image">
                <input
                  accept="image/*"
                  type="file"
                  hidden
                  id="profile-image"
                  onChange={handleImageUpload}
                />
                <IconButton 
                  component="span"
                  sx={{ 
                    color: 'white',
                    '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.1)' }
                  }}
                >
                  <PhotoCameraIcon />
                </IconButton>
              </label>
            </UploadOverlay>
          )}
        </ProfileAvatarWrapper>
        </ProfileHeader>

        

        {!edit && (
          <IconButton
            sx={{
              position: 'absolute',
              right: 16,
              top: 180,

              backgroundColor: theme.palette.mode === 'dark' ? '#2B2B2B' : 'white',
              '&:hover': { backgroundColor: '#f0f0f0' }
            }}
            onClick={() => setEdit(true)}
          >
            <EditIcon />
          </IconButton>
        )}

        <CardContent sx={{ pt: 10 }}>
          {edit ? (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <StyledTextField
                    fullWidth
                    label={intl.formatMessage({ id: 'firstName' })}
                    id="fname"
                    value={state.fname}
                    onChange={handleInputChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <StyledTextField
                    fullWidth
                    label={intl.formatMessage({ id: 'middleName' })}
                    id="mname"
                    value={state.mname}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <StyledTextField
                    fullWidth
                    label={intl.formatMessage({ id: 'lastName' })}
                    id="lname"
                    value={state.lname}
                    onChange={handleInputChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <StyledTextField
                    fullWidth
                    label={intl.formatMessage({ id: 'phoneNumber' })}
                    id="phone_number"
                    value={state.phone_number}
                    onChange={handleInputChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                <StyledFormControl fullWidth>
                    <InputLabel id="country-label">
                      {intl.formatMessage({ id: 'country' })}
                    </InputLabel>
                    <Select
                      labelId="country-label"
                      id="country"
                      value={state.country}
                      onChange={(e) => setState(prev => ({
                        ...prev,
                        country: e.target.value
                      }))}
                      label={intl.formatMessage({ id: 'country' })}
                      required
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 300,
                            width: 250
                          }
                        }
                      }}
                    >
                      <MenuItem value="">
                        <em>Select a country</em>
                      </MenuItem>
                      {countries.map((country) => (
                        <MenuItem 
                          key={country.value} 
                          value={country.value}
                          sx={{
                            '&:hover': {
                              backgroundColor: theme.palette.action.hover
                            }
                          }}
                        >
                          {country.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </StyledFormControl>
                </Grid>
                <Grid item xs={12}>
                  <StyledTextField
                    fullWidth
                    multiline
                    rows={4}
                    label={intl.formatMessage({ id: 'about' })}
                    id="about"
                    value={state.about}
                    onChange={handleInputChange}
                    required
                  />
                </Grid>
              </Grid>

              <Box sx={{ mt: 4, display: 'flex', gap: 2, justifyContent: 'center' }}>
                <ActionButton
                  variant="outlined"
                  onClick={() => setEdit(false)}
                  startIcon={<CloseIcon />}
                >
                  {intl.formatMessage({ id: 'cancel' })}
                </ActionButton>
                <ActionButton
                  type="submit"
                  variant="contained"
                  startIcon={<SaveIcon />}
                  disabled={uploading}
                >
                  {uploading ? 
                    <CircularProgress size={24} /> : 
                    intl.formatMessage({ id: user.data[0].profile === 0 ? 'create' : 'save' })
                  }
                </ActionButton>
              </Box>
            </form>
          ) : (
            <Box>
              <Typography variant="h5" align="center" gutterBottom>
                {`${state.fname} ${state.lname}`}
              </Typography>
              <Typography 
                variant="body1" 
                color="textSecondary" 
                align="center" 
                gutterBottom
              >
                {state.phone_number}
              </Typography>
              
              <Box sx={{ mt: 4 }}>
                <InfoSection>
                  <Typography 
                    variant="h6" 
                    color="primary" 
                    gutterBottom
                    sx={{ fontWeight: 600 }}
                  >
                    {intl.formatMessage({ id: 'basicInformation' })}
                  </Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <Typography variant="subtitle2" color="textSecondary">
                        {intl.formatMessage({ id: 'email' })}
                      </Typography>
                      <Typography variant="body1">{state.email}</Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography variant="subtitle2" color="textSecondary">
                        {intl.formatMessage({ id: 'country' })}
                      </Typography>
                      <Typography variant="body1">{state.country}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="subtitle2" color="textSecondary">
                        {intl.formatMessage({ id: 'about' })}
                      </Typography>
                      <Typography variant="body1">{state.about}</Typography>
                    </Grid>
                  </Grid>
                </InfoSection>
              </Box>
            </Box>
          )}
        </CardContent>
      </ProfileCard>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert 
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ 
            width: '100%',
            boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
            borderRadius: '8px'
          }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      {/* Loading Overlay */}
      {uploading && (
        <Box sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'rgba(255,255,255,0.8)',
          zIndex: 9999
        }}>
          <CircularProgress />
        </Box>
      )}
    </ProfileContainer>
  );
};


Clientprofile.propTypes = {
  changeLocale: PropTypes.func.isRequired,
  currentLocale: PropTypes.string.isRequired,
};
export default Clientprofile;