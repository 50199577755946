import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import { 
  IconButton, 
  Badge,
  Box,
  Typography,
  Button,
  Popover,
  Paper,
  useTheme,
  alpha,
} from '@mui/material';
import { NotificationsNone } from '@mui/icons-material';
import { useAuthToken } from "../TokenContext";

// Styled components using MUI
const NotificationContainer = styled(Box)({
  position: 'relative',
  display: 'inline-block'
});

const NotificationBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  }
}));

const NotificationList = styled(Paper)(({ theme }) => ({
  maxHeight: '400px',
  width: '300px',
  overflowY: 'auto',
  padding: theme.spacing(1),
  backgroundColor: theme.palette.mode === 'dark' ? '#1E1E1E' : '#fff',
  color: theme.palette.mode === 'dark' ? '#fff' : '#000',
  '&::-webkit-scrollbar': {
    width: '6px'
  },
  '&::-webkit-scrollbar-track': {
    background: theme.palette.mode === 'dark' ? '#333' : '#f1f1f1'
  },
  '&::-webkit-scrollbar-thumb': {
    background: theme.palette.mode === 'dark' ? '#666' : '#888',
    borderRadius: '3px'
  },
  boxShadow: theme.palette.mode === 'dark' 
    ? '0 4px 6px rgba(0, 0, 0, 0.4)' 
    : '0 4px 6px rgba(0, 0, 0, 0.1)'
}));

const NotificationItem = styled(Box)(({ theme, removed }) => ({
  padding: theme.spacing(1.5),
  borderBottom: `1px solid ${theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : theme.palette.divider}`,
  backgroundColor: theme.palette.mode === 'dark' ? '#2D2D2D' : '#fff',
  color: theme.palette.mode === 'dark' ? '#fff' : '#000',
  transition: 'all 0.3s ease-in-out',
  transform: removed ? 'translateX(100%)' : 'translateX(0)',
  opacity: removed ? 0 : 1,
  maxHeight: removed ? 0 : '1000px',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark' ? '#3D3D3D' : '#f5f5f5'
  }
}));

const ReadButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1a7f9c' : '#90E0EF',
  color: theme.palette.common.white,
  alignSelf: 'flex-end',
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark' ? '#2596be' : '#4a90e2'
  }
}));
const API_ENDPOINTS = {
  getNotifications: process.env.REACT_APP_CLIENT_CLIENTTASKNOTIFICATION,
  markAsRead: process.env.REACT_APP_TALENT_NOTIFICATIONREAD
};

const NotificationManagerIcon = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [removedIds, setRemovedIds] = useState([]);
  const { user } = useSelector((state) => state.auth);
  const token = useAuthToken();
  const theme = useTheme();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const fetchNotifications = async () => {
    if (!token || !user?.data?.[0]?.emp_id) {
      console.log('Token or user data is missing');
      return;
    }

    try {
      const response = await fetch(API_ENDPOINTS.getNotifications, {
        method: 'POST',
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          client_id: user.data[0].emp_id 
        }),
      });

      if (!response.ok) throw new Error('Failed to fetch notifications');
      
      const data = await response.json();
      setNotifications(data.data || []);
    } catch (error) {
      console.error('Error fetching notifications:', error);
      setNotifications([]);
    }
  };

  const markNotificationAsRead = async (notificationId) => {
    try {
      const response = await fetch(API_ENDPOINTS.markAsRead, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          notification_ids: [notificationId] 
        }),
      });

      if (!response.ok) throw new Error('Failed to mark notification as read');

      setRemovedIds(prev => [...prev, notificationId]);
      await fetchNotifications();
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  useEffect(() => {
    fetchNotifications();
    // Set up polling for new notifications every 30 seconds
    const pollInterval = setInterval(fetchNotifications, 30000);
    return () => clearInterval(pollInterval);
  }, [token, user?.data]);

  const renderNotificationContent = () => {
    if (!notifications?.length) {
      return (
        <Typography 
          variant="body2" 
          color="textSecondary"
          textAlign="center"
          py={2}
        >
          No new notifications
        </Typography>
      );
    }

    return notifications.map((notification) => (
      <NotificationItem 
        key={notification.id} 
        removed={removedIds.includes(notification.id)}
      >
        <Typography 
          variant="body2" 
          sx={{ 
            color: 'text.primary',
            wordBreak: 'break-word'
          }}
        >
          {notification.message}
        </Typography>
        <ReadButton
          variant="contained"
          size="small"
          onClick={() => markNotificationAsRead(notification.id)}
        >
          Read
        </ReadButton>
      </NotificationItem>
    ));
  };

  return (
    <NotificationContainer>
        <IconButton sx={{ color: theme.palette.mode === 'dark' ? '#fff' : '#000' }}
        onClick={handleClick}
        aria-label="notifications"
      >
        <NotificationBadge 
          badgeContent={notifications?.length || 0}
          invisible={!notifications?.length}
        >
          <NotificationsNone />
        </NotificationBadge>
      </IconButton>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <NotificationList>
          {renderNotificationContent()}
        </NotificationList>
      </Popover>
    </NotificationContainer>
  );
};

export default NotificationManagerIcon;