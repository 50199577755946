import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  IconButton,
  Button,
  Box,
  Typography,
  CircularProgress,
  Stack,
  Tooltip
} from '@mui/material';
import {
  Description as DescriptionIcon,
  Close as CloseIcon,
  Download as DownloadIcon,
  OpenInNew as OpenInNewIcon
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';

// Styled components
const ViewerDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    width: '100%',
    maxWidth: '90vw',
    height: '90vh',
    margin: theme.spacing(2)
  }
}));

const ViewerFrame = styled('iframe')({
  width: '100%',
  height: '100%',
  border: 'none'
});

const LoadingWrapper = styled(Box)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  textAlign: 'center'
});

const DialogHeader = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  left: 0,
  padding: theme.spacing(1),
  display: 'flex',
  justifyContent: 'flex-end',
  gap: theme.spacing(1),
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  backdropFilter: 'blur(5px)',
  zIndex: 1
}));

const DocumentViewer = ({ url, buttonText = "View Document", filename = "document" }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [loadError, setLoadError] = useState(false);

  // Check if the file is a PDF
  const isPDF = filename.toLowerCase().endsWith('.pdf') || 
                url.toLowerCase().includes('.pdf') ||
                url.toLowerCase().includes('application/pdf');

  const getViewerUrl = (docUrl) => {
    if (isPDF) {
      return docUrl;
    }
    return `https://docs.google.com/viewer?url=${encodeURIComponent(docUrl)}&embedded=true`;
  };

  const handleClose = () => {
    setIsOpen(false);
    setIsLoading(true);
    setLoadError(false);
  };

  const handleIframeLoad = () => {
    setIsLoading(false);
  };

  const handleDownload = async () => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error('Download failed:', error);
      window.open(url, '_blank');
    }
  };

  const handleOpenInNewTab = () => {
    window.open(url, '_blank');
  };

  return (
    <>
      <Button
        variant="outlined"
        startIcon={<DescriptionIcon />}
        onClick={() => setIsOpen(true)}
      >
        {buttonText}
      </Button>

      <ViewerDialog
        open={isOpen}
        onClose={handleClose}
        maxWidth={false}
      >
        <DialogContent sx={{ p: 0, position: 'relative', overflow: 'hidden' }}>
          <DialogHeader>
            <Tooltip title="Open in New Tab">
              <IconButton
                onClick={handleOpenInNewTab}
                size="small"
                sx={{
                  bgcolor: 'background.paper',
                  '&:hover': { bgcolor: 'action.hover' }
                }}
              >
                <OpenInNewIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Download">
              <IconButton
                onClick={handleDownload}
                size="small"
                sx={{
                  bgcolor: 'background.paper',
                  '&:hover': { bgcolor: 'action.hover' }
                }}
              >
                <DownloadIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Close">
              <IconButton
                onClick={handleClose}
                size="small"
                sx={{
                  bgcolor: 'background.paper',
                  '&:hover': { bgcolor: 'action.hover' }
                }}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </DialogHeader>

          {isLoading && (
            <LoadingWrapper>
              <Stack alignItems="center" spacing={2}>
                <CircularProgress />
                <Typography variant="body2">
                  Loading {isPDF ? 'PDF' : 'document'}...
                </Typography>
              </Stack>
            </LoadingWrapper>
          )}

          <ViewerFrame
            src={getViewerUrl(url)}
            title="Document Viewer"
            onLoad={handleIframeLoad}
          />
        </DialogContent>
      </ViewerDialog>
    </>
  );
};

export default DocumentViewer;