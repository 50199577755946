import React,{useState,useEffect} from 'react'
import styled from 'styled-components';
import {cardShadow,hoverEffect,themeColor} from '../utils';
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import moment from 'moment';
import { NavHashLink } from 'react-router-hash-link';
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa'; // Replace with the appropriate icons from React Icons
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import { useAuthToken } from "../TokenContext";
import MenuItem from '@mui/material/MenuItem';

import { useIntl } from 'react-intl';


function PodActivityStakeholder({expanded}) {
  const [getactivitiy, setGetactivitiy] = useState([]);
  const [sortingOrder, setSortingOrder] = useState('desc'); // Initial sorting order (descending)
  const [data, setData] = useState([]);
  const { isLoggedIn, user } = useSelector(state => state.auth);
  const token = useAuthToken();
  const intl = useIntl();
  const [selectedCreatedBy, setSelectedCreatedBy] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const list_activity = () => {

 if (!token) {
      return; // Exit early if there is no token
    }
    const body = {
      "client_code": user.data[0].client_code,
      "stakeholder_id": user.data[0].stakeholder_id,
    };
    const headers = {
      'Authorization' : token, // Replace with your actual token
      'Content-Type': 'application/json', // You can add more headers as needed
    };
    axios.post(process.env.REACT_APP_STAKEHOLDER_GETPODACTIVITYSTAKEHOLDER, body,{headers:headers})
      .then((res) => {
        if (res.data.data && res.data.data.activities) {
          const sortedData = res.data.data.activities.slice().sort((a, b) => {
            const dateA = new Date(a.createdAt);
            const dateB = new Date(b.createdAt);
            return sortingOrder === 'asc' ? dateA - dateB : dateB - dateA;
          });

          setGetactivitiy(sortedData);
          setData(sortedData);
        } else {
          setGetactivitiy([]);
          setData([]);
        }
      })
      .catch((err) => {
        console.log("catch", err);
      });
  }

  const toggleSortingOrder = () => {
    const sortedData = getactivitiy
      .slice()
      .sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return sortingOrder === 'asc' ? dateA - dateB : dateB - dateA;
      });

    setSortingOrder(sortingOrder === 'asc' ? 'desc' : 'asc');
    getactivitiy(sortedData);
  };

  useEffect(() => {
    list_activity();
  }, [token]);
 
  const filteredData =
  selectedCreatedBy.length === 0
    ? getactivitiy // Show all activities when no team member is selected
    : getactivitiy.filter((data) => selectedCreatedBy.includes(data.pod_candidates));

      
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (value) => {
    const isSelected = selectedCreatedBy.includes(value);
  
    setSelectedCreatedBy((prevSelected) =>
      isSelected
        ? prevSelected.filter((item) => item !== value)
        : [...prevSelected, value]
    );
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [editableData, setEditableData] = useState([]);

  const handleViewAndTranscribe = async (loomUrl, id) => {
    try {
      // Split the URL at '?' to extract the filename
      const [filename] = loomUrl.split('?')[0].split('/').slice(-1);
  
      // Construct the CloudFront URL
      const cloudFrontUrl = process.env.REACT_APP_CLOUDFRONTs3;
  
      // Combine the CloudFront URL with the filename
      const fullUrl = cloudFrontUrl + filename;
  
      // Make a request to get the signed URL for transcription
      const response = await axios.post(
       process.env.REACT_APP_TALENT_CLOUDFRONTAPI,
        { "url": fullUrl }
      );
  
      // Parse the response body as JSON
      const responseBody = JSON.parse(response.data.body);
  
      // Extract the signed URL from the parsed response body
      const signedUrl = responseBody.signedUrl;
  
      // Ensure that the signed URL is found in the transcription response
      if (!signedUrl) {
        console.error('Signed URL not found in transcription response:', responseBody);
        alert('Error transcribing video. Signed URL not found.');
        return;
      }
  
      // Update the corresponding item in editableData with the signed URL
      const updatedData = editableData.map((item) =>
        item.id === id ? { ...item, loom_url: signedUrl } : item
      );
      setEditableData(updatedData);
  
      // Open the video for viewing in a new tab using the full signed URL
      window.open(signedUrl, '_blank');
  
      // console.log('Transcription Data:', signedUrl);
      // alert('Video transcription completed successfully.');
    } catch (error) {
      console.error('Error transcribing video:', error);
      alert('Error transcribing video. Please try again later.');
    }
  };
  return (
    <div expanded={expanded}>
   
      {getactivitiy.length === 0 ? (
        <p>{intl.formatMessage({ id: 'noPodActivity' })}</p>
      ) : (
      <Border>
          
      <NavHashLink to={`/PodActivitystakeholderviewall`} smooth>
        <TitleText>{intl.formatMessage({ id: 'podActivity' })}</TitleText>
      </NavHashLink>
      <Button style={{marginBottom:'1rem'}}onClick={handleClick} variant="outlined">
            {selectedCreatedBy.length === 0
              ? intl.formatMessage({ id: 'selectPodTeamMember' })
              : selectedCreatedBy.map((item) => (
                  <Chip
                    key={item}
                    label={item}
                    color="primary"
                    style={{ marginRight: 5 }}
                  />
                ))}
          </Button>
          <Menu
  id="createdByMenu"
  anchorEl={anchorEl}
  open={Boolean(anchorEl)}
  onClose={handleClose}
>
  {[...new Set(getactivitiy.map((data) => data.pod_candidates))].map(
    (name) => (
      <MenuItem
        key={name}
        onClick={() => handleMenuItemClick(name)}
        selected={selectedCreatedBy.includes(name)}
      >
        {selectedCreatedBy.includes(name) && (
          <Chip
            label={name}
            color="primary"
            style={{ marginRight: 5 }}
          />
        )}
        {name}
      </MenuItem>
    )
  )}
</Menu>
        <Project style={{ display: "block" }}>
          <TableContainer component={Paper}>
            <Table    component={Paper}
              sx={{
                backgroundColor: 'white',
                borderRadius: '10px',
                fontFamily: 'Poppins, Medium',
                borderCollapse: 'separate', // Ensure borders do not collapse
              }}
              aria-label="simple table"
            >
              <TableHead  sx={{
                 
                 '& td, & th': {
                   fontFamily: 'Poppins,Medium',
                   fontSize: '15px',
                   color: '#17a2b8',
                   fontWeight:"bold",
                   
                 },
               }}
             >
                <TableRow>
                  <TableCell onClick={toggleSortingOrder}>
                  {intl.formatMessage({ id: 'submittedDate' })}
                    {sortingOrder === 'asc' ? (
                      <FaSortUp /> // Upward arrow icon for ascending order
                    ) : (
                      <FaSortDown /> // Downward arrow icon for descending order
                    )}
                  </TableCell>
                  <TableCell>{intl.formatMessage({ id: 'teamMember' })}</TableCell>
                 
                  <TableCell>{intl.formatMessage({ id: 'jiraIssue' })}</TableCell>
                 
               
                  <TableCell>{intl.formatMessage({ id: 'todaysFocus' })}</TableCell>
                  <TableCell>{intl.formatMessage({ id: 'accomplishments' })}</TableCell>
                  <TableCell>{intl.formatMessage({ id: 'blockers' })}</TableCell>
                  <TableCell>{intl.formatMessage({ id: 'videoUrl' })}</TableCell>

                 
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData && filteredData.length > 0 ?
                  filteredData.map((item, index) =>
                    <TableRow
                  sx={{
                    '&:last-child td, &:last-child th': {
                      border: 0,
                      fontFamily: 'Poppins, Medium',
                      fontSize: '15px',
                    },
                    
                    '& td, & th': {
                      fontFamily: 'Poppins, Medium',
                      fontSize: '15px',
                      color: 'black',
                      fontWeight: '600',
                      borderBottom: '1px solid #ccc', // Only bottom border for row lines
                      borderRight: 'none', // No right border for columns
                      borderLeft: 'none', // No left border for columns
                    },
                  }}
                      key={index}
                    >
                      <TableCell align="right">{moment(item.createdAt).format("MM-DD-YYYY")}</TableCell>
                      <TableCell>
                        {item.pod_candidates}
                      </TableCell>
                      <TableCell>{item.jira_issue}</TableCell>
                      
                      <TableCell>{item.today_focus}</TableCell>
                      <TableCell>{item.accomplishments}</TableCell>
                      <TableCell>{item.blockers}</TableCell>
                      <TableCell>
                      {item.loom_url ? (
 <Button onClick={() => handleViewAndTranscribe(item.loom_url, item.id)}>
 {intl.formatMessage({ id: "viewVideo" })}
 </Button>
) : (
  // Message when no video is available
  <p>{intl.formatMessage({ id: 'noDataAvailable' })}</p>
)}


</TableCell>
                      
                    </TableRow>
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4}>{intl.formatMessage({ id: 'noDataAvailable' })}</TableCell>
                    </TableRow>
                  )
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Project>
      </Border>
         )}
    </div>
  );
}

// Your styled components...



const Border=styled.div`
overflow:hidden;
background-color:white;
border-radius: 1rem ;
height:35rem;
overflow-y: scroll;
-ms-overflow-style: none;  /* IE and Edge */
scrollbar-width: none;  /* Firefox */
 padding:20px;
 @media screen and (min-width:320px) and (max-width:1080px){
  overflow-y:scroll;
  width:20rem;
 }
`;
const YourProjects=styled.div`
// border: 25px solid #90E0EF;
height:23rem;
 background-color:#0077b6 ;
border-radius: 1rem ;
padding:0.8rem;

width:51rem;
box-shadow: 0px 4px 6px #00000029;
transition:0.45 ease-in-out;
&:hover:{
box-shadow:${hoverEffect};


 
}

@media screen and (min-width:320px) and (max-width:1080px){
    diplay:flex;
    justify-content:center;
    align-items:center;
    margin:auto;
  width:20rem;
    height:max-content:
    margin-top:1rem;
    // margin-right: 260px
   }
`;


const TitleText = styled.h4`
    height:8%;
    color:black;
    font-weight:600;
    font-size:18px;
    font-family:Poppins,Medium;
    &:hover {
    
      color: #00B4D8; /* Change this to your desired text color on hover */
    }
    `;
    


const Project=styled.div`
 display :flex;
 align-items: center;

 margin-bottom:0.3rem;
`;

const Detail=styled.div`

margin-left:1rem;

`;
const Title=styled.h6`
font-weight:500;



@media screen and (min-width:320px) and (max-width:1080px){
    font-size:1rem;
   }
`;
const SubTitle=styled.h6`
font-weight:300;
display:flex;
flex-direction:row;
@media screen and (min-width:320px) and (max-width:1080px){
  display:flex;
  flex-direction:column;
 }

`;
const AllProjects=styled.div`

 text-align:center;
color: black;
float:right;
cursor:pointer;

border:2px solid green;
width:80px;
margin-top:-1rem;
font-size:15px;
align-self:center;

`;
export default PodActivityStakeholder