import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import styled from "styled-components";
import CircularProgress from '@mui/material/CircularProgress';

import { useAuthToken } from "../TokenContext";
import { useDispatch, useSelector } from "react-redux";
import { login } from "./action/auth";
import { faBullseye } from "@fortawesome/free-solid-svg-icons";
import DownloadIcon from '@mui/icons-material/Download'; // Material UI download icon

const SubscriptionNew = () => {
  const [plans, setPlans] = useState([]);
 
  const [showAddCardDialog, setShowAddCardDialog] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [showExistingCardDialog, setShowExistingCardDialog] = useState(false); // Add this state
 
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
   // New state for confirm dialog
  const [cardDetails, setCardDetails] = useState(null);
  const [newCard, setNewCard] = useState({
    number: "",
    name: "",
    exp_month: "",
    exp_year: "",
    cvc: "",
    postal_code:"",
  });
  const [showEditCardDialog, setShowEditCardDialog] = useState(false);
  const token = useAuthToken();
  const [isPartner, setIsPartner] = useState(false);
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const [createCustomerId, setCreateCustomerId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [APIresponse, setAPIresponse] = useState(null);
  const [paymentID, setPaymentID] = useState(null);
  const [step, setStep] = useState(null);
  const [addCard, setAddCard] = useState(false);
  const dispatch = useDispatch();
  const [getpoddetails, setGetpoddetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [useExistingCard, setUseExistingCard] = useState(false);
  const [currentPlan, setCurrentPlan] = useState({
    id: "",
    price: 0,
    balance: 1,
    type: "Web App",
    Product_Name:'',
  });
  const [numberOfPersons, setNumberOfPersons] = useState(1);
  const [billingCycle, setBillingCycle] = useState("monthly");
  const [totalAmount, setTotalAmount] = useState(0);
  let Customeriddb;
  const [isSubscribed, setIsSubscribed] = useState(false); 
  if (user.subscriptiondata.length > 0) {
    if (user.subscriptiondata[0].customerId) {
      Customeriddb = user.subscriptiondata[0].customerId;
    } else {
      // Handle the case where customerId is missing
    }
  } else {
    // Handle the case where SubscriptionDB is empty
  }
 // console.log('user',user)

 useEffect(() => {
  const fetchPlans = async () => {
    try {
      const headers = {
        Authorization: token,
        "Content-Type": "application/json",
      };
      const response = await axios.post(
        process.env.REACT_APP_CLIENT_STRIPEPRICELIST,
        {},
        { headers }
      );
      
      // Defensive check for response data
      if (!response.data?.data?.phase_1_admin) {
        throw new Error('Invalid response structure');
      }

      const planData = response.data.data.phase_1_admin;

      // Determine partner status before filtering
      const partnerStatus = user.data[0].type === "Provider";
      setIsPartner(partnerStatus);

      const filteredPlans = partnerStatus
        ? planData.filter((plan) => 
            plan.Product_Name.toLowerCase().includes("partner")
          )
        : planData.filter(
            (plan) =>
              !plan.Product_Name.toLowerCase().includes("partner")
          );

      // Ensure plans are not empty
      if (filteredPlans.length === 0) {
        console.warn('No plans found after filtering');
      }

      setPlans(filteredPlans);
    } catch (error) {
      console.error("Error fetching plans", error);
      
      // Optional: set an error state to show to the user
      // setError('Failed to fetch subscription plans');
    }
  };

  // Only fetch if token exists
  if (token) {
    fetchPlans();
  }
}, [token, user.data[0].type]); // Remove isPartner from dependency array
  useEffect(() => {
    const headers = {
      Authorization: token,
      "Content-Type": "application/json",
    };

    if (user.subscriptiondata.length === 0) {
      const data = {
        name: user.data[0].customer_firstname,
        email: user.data[0].email,
      };

      axios
        .post(process.env.REACT_APP_CREATECUSTOMER, data, { headers: headers })
        .then((response) => {
          const responseData = JSON.parse(response.data.body);
          setCreateCustomerId(responseData.customer.id);
        })
        .catch((error) => {
          console.error("Error in create customer API:", error);
        });
    }
  }, [user.subscriptiondata, token]);



  useEffect(() => {
    // Declare the async function inside useEffect
    const CardDetails = async () => {
      const headers = {
        Authorization: token,
        "Content-Type": "application/json",
      };
  
      const data = {
        customer: user.subscriptiondata.length > 0 ? Customeriddb : createCustomerId,
        paymentMethodId: user.subscriptiondata.length > 0 ? user.subscriptiondata[0]?.paymentMethodId : null,
      };
  
      try {
        const response = await axios.post(
          process.env.REACT_APP_CLIENT_LISTCARDSDETAILS,
          data,
          { headers: headers }
        );
        console.log('lisy', response);
  
        // Set the card details after fetching from the response
        const responseData = response.data.body.body.card; // Correct path to the card object
        setCardDetails(responseData); // Set the card details in state
      } catch (error) {
        console.error("Error in fetching card details:", error);
      }
    };
  
    // Call the async function after declaring it
    CardDetails();
  }, []); // Empty dependency array means this effect runs once on mount
  
  useEffect(() => {
    // Ensure subscription data is available before running checks
    if (user?.subscriptiondata && (user.subscriptiondata.length === 0 || user.subscriptiondata[0]?.Status !== "active")) {
      alert("Please choose a subscription plan  to access  pages.");
    }
  }, [user]);
useEffect(() => {
  

  if (currentPlan.Amount) {
    setTotalAmount(numberOfPersons * currentPlan.Amount);
  }
  
 

}, [numberOfPersons, currentPlan.Amount]);

const calculateTotalAmount = () => {
  if (currentPlan) {
    const baseAmount = currentPlan.price;
    const amountPerPerson = baseAmount * numberOfPersons;

    // Apply additional logic for billing cycle if necessary
    const finalAmount = billingCycle === "annually" ? amountPerPerson * 12 : amountPerPerson;

    setTotalAmount(finalAmount);
  } else {
    setTotalAmount(0);
  }
};

// Effect to recalculate total amount when currentPlan, numberOfPersons, or billingCycle changes
useEffect(() => {
  calculateTotalAmount();
}, [currentPlan, numberOfPersons, billingCycle]);

const handleNumberOfPersonsChange = (event) => {
  const value = Math.max(1, parseInt(event.target.value, 10)); // Ensure at least 1 person
  setNumberOfPersons(value);
};

const handleChoosePlan = async (plan) => {
  const planAmount = Number(plan.Amount) || 0;
  setCurrentPlan({
    id: plan.Price_ID,
    price: plan.Amount,
    balance: 1,
    type: "Web App",
    Product_Name: plan.Product_Name,
  });

  try {
    setLoading(true);
    //console.log("Chosen Plan:", plan);
    
    if (planAmount === 0) {
      // Free plan: directly proceed with the payment
      await createPayment(null, plan);
      setShowSuccessDialog(true);
    } else if (cardDetails) {
      // If card details exist, prompt the user to choose between using the existing card or adding a new one
      setShowExistingCardDialog(true); // Show the popup dialog
    } else {
      // No existing card: show the Add Card dialog
      setShowAddCardDialog(true);
    }
  } catch (error) {
    console.error("Subscription error:", error);
  } finally {
    setLoading(false);
  }
};




const handleBillingCycleChange = (event) => {
  setBillingCycle(event.target.value);
};

const [responseBody, setResponseBody] = useState(null); // State to hold the API response
const [showSecondCard, setShowSecondCard] = useState(false); // State to show the second card
const [totalAmountWithTax, setTotalAmountWithTax] = useState(0); // State to store the total amount including tax
const [showTaxDetails, setShowTaxDetails] = useState(false);


useEffect(() => {
 // console.log('Updated Total Amount with Tax:', totalAmountWithTax);
}, [totalAmountWithTax]);

const handleContinue = async () => {
  if (!currentPlan) return;
  setShowAddCardDialog(false);
  setLoading(true);

  const payload = {
    customerId: user.subscriptiondata.length > 0 ? user.subscriptiondata[0].customerId : createCustomerId,
    paymentMethodId:useExistingCard && user.subscriptiondata  && user.subscriptiondata.length > 0 && user.subscriptiondata[0].paymentMethodId !=null? user.subscriptiondata[0].paymentMethodId : paymentID,
    priceId: currentPlan.id,
    quantity: numberOfPersons,
  };

  const headers = {
    Authorization: token,
    "Content-Type": "application/json",
  };

  try {
    const response = await axios.post(
      process.env.REACT_APP_CLIENT_TESTSUBSCRIPTIONPAIDPLAN,
      payload
    );

    const responseBody = response.data.body;
    //console.log("Subscription response: ", responseBody);

    setResponseBody(responseBody); // Store the response in state
    const totalAmount = responseBody.subscription?.latest_invoice?.subtotal || 0;
    //console.log('Total Amount:', totalAmount);

    // Store the final amount (total + tax) in state


    // const totalAmount = responseBody.totalAmount || 0;  
    // const taxAmount = totalAmount * 0.1; 
    // const finalAmount = totalAmount + taxAmount;

    setTotalAmountWithTax(totalAmount); // Set total amount with tax
    setShowTaxDetails(true);
    setShowSecondCard(true); // Show the second card after continue

    setLoading(false);
  } catch (error) {
    console.error('Subscription error:', error);
  } finally {
    setLoading(false);
  }
};

const handleSubscribe = async () => {
  setLoading(true);
  const headers = {
    Authorization: token,
    "Content-Type": "application/json",
  };
  if (!responseBody) return; // Ensure responseBody is available before using it

  const subscription = responseBody.subscription || {};

  const subData = {
    Status: "active",
    startDate: subscription.current_period_start || "",
    endDate: subscription.current_period_end || "",
    priceID: currentPlan.id,
    subscriptionID: subscription.id || "free_plan_id",
    amount: currentPlan.price,
    CandidateBalance: currentPlan.balance,
    client_ID: user.data[0].customer_id,
    subId: user.subscriptiondata.length > 0 ? user.subscriptiondata[0].id : undefined,
    type: currentPlan.type,
    region: user.data[0].customer_country,
    Product_Name: currentPlan.Product_Name,
    organizational_code: user.data[0].client_code,
    customerId: user.subscriptiondata.length > 0 ? user.subscriptiondata[0].customerId : createCustomerId,
    paymentMethodId:useExistingCard && user.subscriptiondata  && user.subscriptiondata.length > 0 && user.subscriptiondata[0].paymentMethodId !=null? user.subscriptiondata[0].paymentMethodId : paymentID,
  };

  const subscriptionDbApi = process.env.REACT_APP_CLIENT_CREATESUBSCRIPTIONDB;
  try {
    const dbResponse = await axios.post(subscriptionDbApi, subData, { headers });
    //console.log("Subscription DB Response:", dbResponse);
    dispatch(login(user.data[0].email, "customer"));
    setIsSubscribed(true); // Update subscription status
    setLoading(false);
    // Handle subscription success
  } catch (error) {
    console.error('Subscription DB error:', error);
  }
  finally {
    setLoading(false);
  }
};

// JSX





  const createPayment = async (e, plan) => {
    if (e) e.preventDefault();
    setLoading(true);
    if (user?.subscriptiondata[0]?.Status === "active") {
      alert(
        "You already have an active plan. If you want to change your plan, please cancel your current plan before proceeding."
      );
      
    } else {
    try {
      const headers = {
        'Authorization': token,
        'Content-Type': 'application/json',
      };
  
      let response;
      let isSuccess = false;
  //console.log("planamout",plan.Amount)
      if (currentPlan.price === 0) {
        const data = {
          price_id: plan.Price_ID,
          customerId: user.subscriptiondata.length > 0 ? Customeriddb: createCustomerId,

        };
   //console.log('customerid',Customeriddb,createCustomerId)

     try {
        const response = await axios.post(process.env.REACT_APP_TESTCREATESUBSCRIPTION, data, {headers:headers});
    
        // console.log('createcustomer', response);
        


        const responseBody = JSON.parse(response.data.body);
        const subscriptionBody = JSON.parse(responseBody.subscription.body);
        const Start = subscriptionBody.subscription;
      
        //console.log("current_period_start:", Start);
      

        // Update state with current_period_start
        

        // Set the entire API response in state if needed
        setAPIresponse(Start);
       
        // Set loading to false after the API call is complete
       
       //console.log('apiresponse',response)
       
        setLoading(false);

        
      // createSubscription();
        // setAddcard(true);
        // setPaymentID(response.data.result.id);
      } catch (error) {
        // Handle any errors that occur during the API call
        console.error('Error in test-create-subscription API:', error);
      }
      } else {
        const paymentData = {
          number: newCard.number,
          exp_month: newCard.exp_month,
          exp_year: newCard.exp_year,
          cvc: newCard.cvc,
          postal_code:newCard.postal_code,
          name: newCard.name,
        
          customerId: user.subscriptiondata.length > 0 ? Customeriddb : createCustomerId,
        };
  
        response = await axios.post(process.env.REACT_APP_CLIENT_CREATEPAYMENTMETHOD, paymentData, { headers });
  
        if (response.data && response.data.result) {
          setPaymentID(response.data.result.id);
          setAddCard(true);
          isSuccess = true;
          setIsSuccess(true)
        } else {
          alert('invalid Card Details please choose plan again and enter valid details')
          throw new Error("Failed to create payment method.");
        }
      }
  
     
    } catch (error) {
      console.error('Error in createPayment:', error);
      throw error; // Rethrow to handle in calling function
    } finally {
      setLoading(false);
    }
  }
  };
  
  
  
  
  

 
  
  
  

  const handleAddCard = async () => {
    setLoading(true); // Start loader

    try {
      await createPayment(null, currentPlan);
      // Handle successful card addition
    } catch (error) {
      console.error("Error adding card:", error);
    } finally {
      setLoading(false); // Stop loader
      setShowAddCardDialog(false); // Close dialog after operation
    }
  };

  const handleCardInputChange = (e) => {
    const { name, value } = e.target;
    setNewCard({ ...newCard, [name]: value });
  };

  const handleEditCard = () => {
    setShowEditCardDialog(true);
  };

  const handleSaveCard = () => {
    setCardDetails(newCard);
    setShowEditCardDialog(false);
  };

  const handleConfirmSubscription = () => {
    createSubscription(); // Call createSubscription when confirmed
    setShowConfirmDialog(false); // Hide confirm dialog
  };
//console.log('price',currentPlan.Product_Name)
const createSubscription = async (plan) => {
  try {
    setLoading(true);

    const data = {
      paymentMethodId:useExistingCard &&user.subscriptiondata  && user.subscriptiondata.length > 0 && user.subscriptiondata[0].paymentMethodId !=null? user.subscriptiondata[0].paymentMethodId : paymentID,
      customerId: user.subscriptiondata.length > 0 ? Customeriddb : createCustomerId,
      items: [{ price: currentPlan.id }],
      subId: user.subscriptiondata.length > 0 ? user.subscriptiondata[0].subscriptionID : undefined,
    };

    const headers = {
      Authorization: token,
      "Content-Type": "application/json",
    };

    let response;

    if (currentPlan.price !== 0) {
      const subscriptionApi = process.env.REACT_APP_CLIENT_CREATESUBSCRIPTION;
     // console.log("Calling Subscription API:", subscriptionApi);
      response = await axios.post(subscriptionApi, data, { headers });
    } else {
      console.log("Free plan detected, using APIresponse");
      response = APIresponse;
    }

    const subData = {
      Status: "active",
      startDate: response.current_period_start || APIresponse.current_period_start,
      endDate: response.current_period_end || APIresponse.current_period_end,
      priceID: currentPlan.id,
      subscriptionID: response.id || "free_plan_id",
      amount: currentPlan.price,
      CandidateBalance: currentPlan.balance,
      client_ID: user.data[0].customer_id,
      subId: user.subscriptiondata.length > 0 ? user.subscriptiondata[0].id : undefined,
      type: currentPlan.type,
      region: user.data[0].customer_country,
      Product_Name: currentPlan.Product_Name,
      organizational_code: user.data[0].client_code,
      customerId: user.subscriptiondata.length > 0 ? Customeriddb : createCustomerId,
      paymentMethodId:useExistingCard &&user.subscriptiondata  && user.subscriptiondata.length > 0 && user.subscriptiondata[0].paymentMethodId !=null? user.subscriptiondata[0].paymentMethodId : paymentID,
    };

    const subscriptionDbApi = process.env.REACT_APP_CLIENT_CREATESUBSCRIPTIONDB;
    //console.log("Calling Subscription DB API:", subscriptionDbApi);
    const dbResponse = await axios.post(subscriptionDbApi, subData, { headers });

    //console.log("Subscription DB Response:", dbResponse);

    dispatch(login(user.data[0].email, "customer"));
    setStep("Subscribed");
    setLoading(false);
  } catch (err) {
    console.error("Error during subscription creation:", err.response ? err.response.data : err.message);
    
    setLoading(false);
  }
};







const deletesubscription = () => {
  setLoading(true); // Start loader
  
  let data = JSON.stringify({
    subId: user.subscriptiondata[0].subscriptionID,
  });

  let config = {
    method: "delete",
    maxBodyLength: Infinity,
    url: process.env.REACT_APP_CLIENT_CANCELSUBSCRIPTIONDB,
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      // Make the second API call immediately after the first one is successful
      const secondAPIData = JSON.stringify({
        client_Id: user.data[0].customer_id,
      });

      let secondAPIConfig = {
        method: "put",
        maxBodyLength: Infinity,
        url: process.env.REACT_APP_CLIENT_CANCELSUBSCRIPTIONDBSECOND,
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        data: secondAPIData,
      };

      axios
        .request(secondAPIConfig)
        .then((response) => {
          dispatch(login(user.data[0].email, "customer"));
          alert("Successfully Unsubscribed!");
        
          // setTimeout(() => {
          //   window.location.reload();
          // }, 2000);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false); // Stop loader
        });
    })
    .catch((error) => {
      console.log(error);
      setLoading(false); // Stop loader
    });
};






  useEffect(() => {
    if (!token) {
      
      setIsLoading(false);
      return;
    }
  
    if (user && user.subscriptiondata && user.subscriptiondata[0]) {
      const body = {
        customerId: user.subscriptiondata[0].customerId || null,
      };

      const headers = {
        'Authorization': token,
        'Content-Type': 'application/json',
      };

      axios.post(process.env.REACT_APP_CLIENT_LISTOFINVOICES, body, {  headers })
        .then((res) => {
          //console.log('invoices',res)
          const responseData = JSON.parse(res.data.body);
          setGetpoddetails(responseData.body);
          setIsLoading(false);
        })
        .catch((err) => {
          console.error("Error:", err);
          // setErrorMessage("Failed to fetch data. Please try again later.");
          setIsLoading(false);
        });
    } else {
      setErrorMessage("No Data Available");
      setIsLoading(false);
    }
  }, [token]);
  const handleConfirmClick = async () => {
    setIsLoading(false);
    try {
      await handleConfirmSubscription();
    } catch (error) {
      console.error('Subscription confirmation failed:', error);
    } finally {
      setIsLoading(true);
      setShowSuccessDialog(false);
    }
  };
  const [downloadingIndex, setDownloadingIndex] = useState(null);


  const handleClick = (index, event) => {
    event.preventDefault(); // Prevent default anchor behavior
    setDownloadingIndex(index);

    // Create a temporary link and trigger the download
    const link = document.createElement('a');
    link.href = getpoddetails[index].invoice_pdf;
    link.download = true;
    link.click();

    // Optionally set a timeout or other logic to handle download completion
    setTimeout(() => {
      setDownloadingIndex(null);
    }, 1000); // Adjust based on actual download time
  };
  return (
    <Container>
       {!isSuccess && (
     <>
      <Heading>
        Subscription
      </Heading>
      <Heading1>
      Choose a plan that works best for you!
      </Heading1>
      <HalfSection>
        <LeftSection>
          <Row>
            {/* <PlanCard>
              <PlanCardContent>
                <Typography variant="h6">Card</Typography>
                {cardDetails ? (
                  <CreditCardDesign>
                    <CardNumber>
              **** **** **** {cardDetails.last4}
            </CardNumber>
            <CardDetails>
              <CardName>Exp: {cardDetails.exp_month}/{cardDetails.exp_year}</CardName>
              <CardName>Brand: {cardDetails.brand}</CardName>
            </CardDetails>
                    <Typography variant="h6" color="inherit">
                       {cardDetails.name} 
                    </Typography>
                  </CreditCardDesign>
                ) : (
                  <Typography variant="body2">No card added</Typography>
                )}
              </PlanCardContent>
            </PlanCard> */}
            {plans
  .filter(plan => !plan.Product_Name.includes("Free Plan")) // Filter out "Free Plan" products
  .map(plan => (
    <PlanCard
      key={plan.Price_ID}
      style={{
        backgroundColor: plan.Amount === 0 ? "#cce5ff" : "#d4edda",
      }}
    >
      <PlanCardContent>
        <Typography variant="h6">{plan.Product_Name}</Typography>
        <Typography variant="body2">{plan.Desc}</Typography>
        <Typography variant="h5" color="primary">
          ${plan.Amount}
        </Typography>
        {user.subscriptiondata.length > 0 &&
        user.subscriptiondata[0].Status !== "inactive" &&
        user.subscriptiondata[0].priceID !== undefined ? (
          user.subscriptiondata[0].priceID === plan.Price_ID ? (
            <>
              <p style={{ color: "seagreen", textDecoration: "underline" }}>
                Current Plan
              </p>
              <Button
                variant="contained"
                color="secondary"
                onClick={deletesubscription}
                style={{ marginTop: "auto" }}
              >
                {loading ? (
                  <CircularProgress size={24} />
                ) : (
                  "Cancel Subscription"
                )}
              </Button>
            </>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                handleChoosePlan(plan);
                setCurrentPlan({
                  id: plan.Price_ID,
                  price: plan.Amount,
                  Product_Name: plan.Product_Name,
                  balance: 1,
                  type: "Web App"
                });
              }}
              style={{ marginTop: "auto" }}
            >
              {loading ? (
                <CircularProgress size={24} />
              ) : (
                "Choose Plan"
              )}
            </Button>
          )
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleChoosePlan(plan);
              setCurrentPlan({
                id: plan.Price_ID,
                price: plan.Amount,
                Product_Name: plan.Product_Name,
                balance: 1,
                type: "Web App"
              });
            }}
            style={{ marginTop: "auto" }}
          >
            {loading ? (
              <CircularProgress size={24} />
            ) : (
              "Choose Plan"
            )}
          </Button>
        )}
      </PlanCardContent>
    </PlanCard>
  ))}

          </Row>
          {/* <DetailsCard> */}
          
          <CreditCardDesign>
          <CardContent>
  <Typography variant="h6">Card Details</Typography>
  {cardDetails ? (
    <PaymentDetails>
      <CardNumber>
        **** **** **** {cardDetails.last4} {/* Mask the card number */}
      </CardNumber>
      <CardDetails>
        <CardName>Exp: {cardDetails.exp_month}/{cardDetails.exp_year}</CardName> {/* Expiration date */}
        <CardName>Brand: {cardDetails.brand}</CardName> {/* Card brand */}
      </CardDetails>
      {/* <IconContainer>
        <IconButton onClick={handleEditCard}>
          <EditIcon />
        </IconButton>
        <IconButton>
          <DeleteIcon />
        </IconButton>
      </IconContainer> */}
    </PaymentDetails>
  ) : (
    <Typography variant="body2">No card added</Typography>
  )}
</CardContent>

</CreditCardDesign>
          {/* </DetailsCard> */}

          
        </LeftSection>
        
        <RightSection>
          <TransactionCardinvoices>
            <Typography variant="h6">Invoices</Typography>
            <InvoiceHeader>
              <InvoiceLeft>
                <AmountHeading>Date</AmountHeading>
              </InvoiceLeft>
              <InvoiceRight>
                <AmountHeading>Amount</AmountHeading>
              </InvoiceRight>
            </InvoiceHeader>
  
            {isLoading ? (
              <p>Loading...</p>
            ) : errorMessage ? (
              <p>{errorMessage}</p>
            ) : !Array.isArray(getpoddetails) || getpoddetails.length === 0 ? (
              <p>You don't have any invoices.</p>
            ) : (
              getpoddetails.map((invoice, index) => (
                <InvoiceItem key={index}>
                  <InvoiceLeft>
                    <InvoiceDate>{invoice.created}</InvoiceDate>
                    <InvoiceStatus>{invoice.status} </InvoiceStatus>
                     {/* Add Download Button */}
                    
                  </InvoiceLeft>
                  <InvoiceLeft> <a
                href={invoice.invoice_pdf}
                download
                onClick={(event) => handleClick(index, event)}
                style={{ marginLeft: '10px', textDecoration: 'none' }}
                title="Download Invoice" // Hover text
              >
                <button
                  type="button"
                  
                  style={{ cursor: 'pointer', textDecoration:"none",border:"none",background: 'none',backgroundColor:'none',display: 'flex', alignItems: 'center' }}
                >
                  <DownloadIcon  style={{textDecoration:"none",backgroundColor:'none'}}/>
                  {downloadingIndex === index && <span style={{ marginLeft: '8px' }}>Downloading...</span>}
                </button>
              </a></InvoiceLeft>
                  <InvoiceRight>
                    {invoice.amount_paid}
                  </InvoiceRight>
                 

                </InvoiceItem>
                
              ))
            )}
          </TransactionCardinvoices>
        </RightSection>
      </HalfSection>
      {/* <HalfSection>
        <BillingInfo>
          <Typography variant="h6">Billing Info</Typography>
          
        </BillingInfo>
      </HalfSection> */}
  
      {/* Dialogs */}
      <Dialog
        open={showAddCardDialog}
        onClose={() => setShowAddCardDialog(false)}
      >
        <DialogTitle>Add Card</DialogTitle>
        <DialogContent>
          <TextField
            label="Card Number"
            name="number"
            value={newCard.number}
            onChange={handleCardInputChange}
            fullWidth
            margin="dense"
          />
          <TextField
            label="Cardholder Name"
            name="name"
            value={newCard.name}
            onChange={handleCardInputChange}
            fullWidth
            margin="dense"
          />
          <TextField
            label="Expiry Month"
            name="exp_month"
            value={newCard.exp_month}
            onChange={handleCardInputChange}
            fullWidth
            margin="dense"
          />
          <TextField
            label="Expiry Year"
            name="exp_year"
            value={newCard.exp_year}
            onChange={handleCardInputChange}
            fullWidth
            margin="dense"
          />
          <TextField
            label="Postal Code"
            name="postal_code"
            value={newCard.postal_code}
            onChange={handleCardInputChange}
            fullWidth
            margin="dense"
          />
          <TextField
            label="CVC"
            name="cvc"
            value={newCard.cvc}
            onChange={handleCardInputChange}
            fullWidth
            margin="dense"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowAddCardDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddCard} color="primary">
          {loading ? (
    <CircularProgress size={24} /> // Spinner or loading indicator
  ) : (
            "Add Card")}
          </Button>
        </DialogActions>
      </Dialog>
  
      <Dialog
        open={showSuccessDialog}
        onClose={() => setShowSuccessDialog(false)}
      >
        <DialogTitle>Subscription Successful</DialogTitle>
        <DialogContent>
          {isLoading ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
              <CircularProgress />
              <Typography variant="body1" style={{ marginLeft: '16px' }}>Processing...</Typography>
            </div>
          ) : (
            <Typography variant="body1">
              Your subscription was successful!
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleConfirmClick}
            color="primary"
            // disabled={isLoading}
          >
          {loading ? (<CircularProgress size={24} /> 
  ) : (
                       " Confirm")}
          </Button>
          <Button
            onClick={() => setShowSuccessDialog(false)}
            color="secondary"
            disabled={isLoading}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
  
      <Dialog
        open={showEditCardDialog}
        onClose={() => setShowEditCardDialog(false)}
      >
        <DialogTitle>Edit Card</DialogTitle>
        <DialogContent>
          <TextField
            label="Card Number"
            name="number"
            value={newCard.number}
            onChange={handleCardInputChange}
            fullWidth
            margin="dense"
          />
          <TextField
            label="Cardholder Name"
            name="name"
            value={newCard.name}
            onChange={handleCardInputChange}
            fullWidth
            margin="dense"
          />
          <TextField
            label="Expiry Month"
            name="exp_month"
            value={newCard.exp_month}
            onChange={handleCardInputChange}
            fullWidth
            margin="dense"
          />
          <TextField
            label="Expiry Year"
            name="exp_year"
            value={newCard.exp_year}
            onChange={handleCardInputChange}
            fullWidth
            margin="dense"
          />
          <TextField
            label="Postal Code"
            name="postal_code"
            value={newCard.postal_code}
            onChange={handleCardInputChange}
            fullWidth
            margin="dense"
          />
          <TextField
            label="CVC"
            name="cvc"
            value={newCard.cvc}
            onChange={handleCardInputChange}
            fullWidth
            margin="dense"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowEditCardDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleEditCard} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
     
      </> )}

      <Dialog
        open={showExistingCardDialog}
        onClose={() => setShowExistingCardDialog(false)}
      >
        <DialogTitle>Use Existing Card or Add New Card</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            You already have an existing card ending in {cardDetails?.last4}. Would you like to use it for this subscription?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsSuccess(true); // Navigate to the checkout screen
              setShowExistingCardDialog(false); // Close the popup
              setUseExistingCard(true);
            }}
            color="primary"
          >
            {loading ? (
    <CircularProgress size={24} /> // Spinner or loading indicator
  ) : (
           " Use Existing Card")}
          </Button>
          <Button
            onClick={() => {
              setShowAddCardDialog(true); // Show the add new card dialog
              setShowExistingCardDialog(false);
              setUseExistingCard(false);
            }}
            color="secondary"
          >
            {loading ? (
    <CircularProgress size={24} /> // Spinner or loading indicator
  ) : (
            "Add New Card")}
          </Button>
        </DialogActions>
      </Dialog>

     {isSuccess && !isSubscribed && (
      <div >
        <Heading>
        Checkout
      </Heading>
      {currentPlan && (
  <>
    <TransactionCard>
      
      <h2>Plan Details</h2>
      <p><strong>Plan Name:</strong> {currentPlan.Product_Name}</p>

      <div>
        <label>No. of Persons:</label>
        <input
          type="number"
          value={numberOfPersons}
          min="1"
          onChange={handleNumberOfPersonsChange}
        />
      </div>

      <div>
        <label>Billing Cycle:</label>
        <input
          type="radio"
          name="billingCycle"
          value="monthly"
          checked={billingCycle === "monthly"}
          onChange={handleBillingCycleChange}
        /> Monthly
        <input
          type="radio"
          name="billingCycle"
          value="annually"
          checked={billingCycle === "annually"}
          onChange={handleBillingCycleChange}
        /> Annually
      </div>

      <div>
        <strong>Total Charge:</strong> ${totalAmount.toFixed(2)}
      </div>

      {!showTaxDetails && (
        <button
          style={{alignSelf:'center', border: '2px solid #1DBF73', borderRadius: "40px", width: '8rem', height: '2.5rem', backgroundColor: 'white' }}
          onClick={handleContinue}
          disabled={loading}
        >
          {loading ? "Processing..." : "Continue"}
        </button>
      )}

      {showTaxDetails && (
        <>
          <div>
            <strong>Total Amount with Tax:</strong> ${totalAmountWithTax.toFixed(2)}
          </div>
          <button
            style={{alignSelf:'center', border: '2px solid #1DBF73', borderRadius: "40px", width: 'max-width', height: '3rem', backgroundColor: 'white' }}
            onClick={handleSubscribe}
            disabled={loading}
          >
             {loading ? (<CircularProgress size={24} /> 
  ) : ("Click to Subscribe")}
          </button>
        </>

      )}
    </TransactionCard>
     {/* Subscription Successful Section */}
   
  </>
)}
</div>)}


       


        {isSubscribed && (
      <div style={{ textAlign: 'center', marginTop: '2rem' }}>
        <h2>Subscription Successful!</h2>
        <p>Thank you for subscribing to {currentPlan.Product_Name}.</p>
        <p>Your subscription has been successfully activated.</p>
        <button
          style={{ border: '2px solid #1DBF73', borderRadius: "40px", width: '8rem', height: '2.5rem', backgroundColor: '#1DBF73', color: 'white' }}
          onClick={() => window.location.reload()} // Or any other action you want to take
        >
          Go back
        </button>
      </div>
    )}
    </Container>
  );
  
};

export default SubscriptionNew;
const Container = styled.div`
  width: 100vw;
  padding: 20px;
  height: 100vh;
  display: flex;
  margin: auto;
   overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
  margin-left: 3rem;
  background-color: #f0f0f0;
  gap: 20px;

  @media (max-width: 768px) {
   margin-left: 3rem;
     width: 100vw;
    padding: 10px;
  }
`;

const CardDetails = styled.div`
  margin-bottom: 16px;
`;
const Section1 =styled.div``;
const Section2 =styled.div``;
const HalfSection = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 10px;
    
  }
`;

const LeftSection = styled.div`
  width: 70%;
  height: 60vh;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-right: 20px;

  @media (max-width: 768px) {
    width: 90%;
    height: auto;
    padding-right: 0;
  }
`;

const RightSection = styled.div`
  width: 30%;
  height: 60vh;
  padding-left: 20px;
 
 overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    padding-left: 0;
    margin-top: 20px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
height: 140%;
 overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const PlanCard = styled(Card)`
  flex: 1;
  background-color: #f5f5f5;

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const PlanCardContent = styled(CardContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  height:100%;
  overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  
`;

const DetailsCard = styled(Card)`
  width: 100%;
  height: 50%;
   overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const PaymentDetails = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  position: relative;
`;

const CardNumber = styled(Typography)`
  font-size: 1.5em;
  letter-spacing: 2px;
  margin-bottom: 10px;
`;

const CardName = styled(Typography)`
  text-transform: uppercase;
  margin-bottom: 20px;
`;

const IconContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  gap: 10px;
`;

const BillingInfo = styled(Card)`
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 60%;
  height: 60vh;

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`;

const TransactionCard = styled(Card)`
 border-radius: 10px;
  background: linear-gradient(135deg,#91eae4,#86a8e7,#7f7fd5 );
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  width:50vw;
height:100%;
  @media (max-width: 768px) {
    height: auto;
  }
`;
const TransactionCardinvoices = styled(Card)`
 border-radius: 10px;
background: linear-gradient(135deg, #a8edea, #fed6e3);


  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  width:100%;

overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  @media (max-width: 768px) {
    height: auto;
  }
`;
const InvoiceItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid #e0e0e0;

  &:last-child {
    border-bottom: none;
  }
`;

const InvoiceHeading = styled.div`
  display: flex;
  padding: 10px;
  font-weight: bold;
  background-color: #f4f4f4; /* Light background for headings */
`;
const InvoiceHeader = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  margin-bottom: 10px;
`;

const AmountHeading = styled.div`
  text-align: right;
  font-weight: bold;
  margin-bottom: 0.5rem; /* Adjust spacing as needed */
`;


const InvoiceLeft = styled.div`
  display: flex;
  flex-direction: column;
`;

const InvoiceDate = styled(Typography)`
  font-weight: bold;
`;

const InvoiceStatus = styled(Typography)`
  color: grey;
`;

const InvoiceRight = styled.div`
  font-weight: bold;
`;

const CreditCardDesign = styled.div`
  width: 49%;
  
  height: 220px;
  border-radius: 10px;
  background: linear-gradient(135deg, #7f7fd5, #86a8e7, #91eae4);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
`;
const Heading = styled.p`
font-size:26px;
font-family:Poppins,SemiBold;
margin-top:2rem;

font-weight:600;
@media screen and (min-width:320px) and (max-width:1080px){

  
  display:flex;
    align-items:center;
    justify-content:center;
}
`;
const Heading1 = styled.p`
font-size:18px;
font-family:Poppins,Regular;


color:#A2A2A2;
font-weight:600;
@media screen and (min-width:320px) and (max-width:1080px){
// margin:auto;

display:flex;
  align-items:center;
  justify-content:center;
}
`;