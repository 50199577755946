import "./BrowseTalent.css";

import React, { useState, useEffect } from "react";

import styled from "styled-components";

import { useSelector } from "react-redux";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

import moment from "moment";

import defaultimg from "./assets/defaultimge.png";

import { useAuthToken } from "../TokenContext";
import { useIntl } from "react-intl";
import { NavHashLink } from "react-router-hash-link";

const FindTalentPartnerGuest = (props) => {
  const token = useAuthToken();
  const intl = useIntl();

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(4);
  const [pageNumberLimit, setPageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
  const [value, setValue] = useState("default");
  const [mis, setMis] = useState("");
  const [bdg, setBdg] = useState("");
  const [mr, setMr] = useState("");
  const [pri, setPri] = useState("");
  const [sec, setSec] = useState("");
  const [tz, setTz] = useState("");
  const [title, setTitle] = useState("");
  const [avail, setAvail] = useState("");
  const [pref, setPref] = useState("");
  const [rep, setRep] = useState([]);
  const [candidatedata, setCandidatedata] = useState([]);
  const [filderdata, setFilterdata] = useState([]);
  const [searchfilter, setSearchfilter] = useState("");
  const [searchApiData, setSearchApiData] = useState([]);
  const [len, setLen] = useState(0);

  const handleClick = (event) => {
    setCurrentPage(Number(event.target.id));
  };
  const available = (val) => {
    setAvail(val.target.value);
  };

  const jobtitle = (val) => {
    setTitle(val.target.value);
  };
  const resetAvailableField = () => {
    setAvail("");
  };
  const resetTitleField = () => {
    setAvail("");
  };
  const preferfield = (val) => {
    setPref(val.target.value);
  };
  const resetPreferField = () => {
    setPref("");
  };

  const resetInputField = () => {
    setMr("");
  };
  const handleJob = (val) => {
    setMis(val.target.value);
  };
  const resetJobField = () => {
    setMis("");
  };

  const resetBudgetField = () => {
    setBdg("");
  };
  const primaryfield = (val) => {
    setPri(val.target.value);
  };
  const resetPrimaryField = () => {
    setPri("");
  };
  const secondaryfield = (val) => {
    setSec(val.target.value);
  };
  const resetSecondaryField = () => {
    setSec("");
  };
  const timezonefield = (val) => {
    setTz(val.target.value);
  };
  const resetTimezoneField = () => {
    setTz("");
  };

  const pages = [];
  var replength = 0;
  var currentItems = [];
  if (rep) {
    replength = rep.length;

    for (let i = 1; i <= Math.ceil(replength / itemsPerPage); i++) {
      pages.push(i);
    }
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    currentItems = rep.slice(indexOfFirstItem, indexOfLastItem);
  }
  const renderPageNumbers = pages.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li key={number}>
        <button
          id={number}
          onClick={handleClick}
          className={currentPage == number ? "active" : null}
          aria-label={`Go to page ${number}`}
        >
          {number}
        </button>
      </li>
      );
    } else {
      return null;
    }
  });

  const { user } = useSelector((state) => state.auth);

  const renderData = (rep) => {
    return (
      <Pega>
        <Pagination1>
          <div className="paginationcontent">
            <Num>
              <ol className="pageNumbers">
                <Num2>
                  <li>
                    <button
                      onClick={handlePrevbtn}
                      disabled={currentPage == pages[0] ? true : false}
                    >
                      {intl.formatMessage({ id: "prev" })}
                    </button>
                  </li>
                  <Numbers>{renderPageNumbers} </Numbers>
                  <li>
                    <button
                      onClick={handleNextbtn}
                      disabled={
                        currentPage == pages[pages.length - 1] ? true : false
                      }
                    >
                      {" "}
                      {intl.formatMessage({ id: "next" })}
                    </button>
                  </li>
                </Num2>
              </ol>
              <Num1>
                <p className="length">
                  {" "}
                  {candidatedata ? candidatedata.length : 0}{" "}
                  {intl.formatMessage({ id: "candidates" })}
                </p>{" "}
                <br></br>
              </Num1>
            </Num>

            <ol className="ol">
              {rep && rep.length > 0
                ? rep.map((dev, index) => {
                    return (
                      <li className="smg" key={index}>
                        <div className="name">
                          {dev.profilePicture == null ||
                          dev.profilePicture == "" ? (
                            <img
                              src={defaultimg}
                              alt=""
                              style={{
                                width: 50,
                                height: 50,
                                borderRadius: "50px",
                              }}
                            ></img>
                          ) : (
                            <img
                              style={{
                                width: "50px",
                                height: "50px",
                                borderRadius: "50px",
                              }}
                              src={
                                "https://globldataprod.s3.amazonaws.com/" +
                                dev.profilePicture
                              }
                              alt=""
                            ></img>
                          )}

                          <NavHashLink
                            to={{
                              pathname: "/Candidatedetailsbyid",
                              state: { c_id: dev.c_id },
                            }}
                            smooth
                          >
                            <p className="firstname">{dev.firstName}</p>
                          </NavHashLink>
                          <p
                            style={{
                              backgroundColor:
                                dev.talent_type === "PARTNER"
                                  ? "#4CAF50"
                                  : dev.talent_type === "FREELANCER"
                                  ? "#FF9800"
                                  : dev.talent_type === "CLIENT"
                                  ? "#2196F3"
                                  : "black",
                              borderRadius: "50%",
                              width: "1.5rem",
                              height: "1.5rem",
                              marginLeft: "5px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          ></p>
                        </div>
                        <div className="name2">
                          <p className="place">{dev.country}</p>
                        </div>
                        <div>
                          <p className="name1">{dev.work_position}</p>
                        </div>
                        <div className="skill">
                          <p className="about">{dev.about}</p>
                        </div>
                        <div className="time">
                          <p className="role">{dev.primaryskill}</p>
                          <p className="role">{dev.secondaryskill}</p>
                        </div>
                        <Col>
                          <p className="time1">{dev.timezone}</p>
                          <p className="role">{dev.availability}</p>
                          <p className="role">{dev.workhours}</p>
                          {dev.currentStatus == "Yes" ? (
                            <p className="role" style={{ color: "green" }}>
                              Currently Available
                            </p>
                          ) : (
                            <>
                              <p className="role" style={{ color: "red" }}>
                                Currently Unavailable
                              </p>
                              {dev.availabledate !== null ? (
                                <p className="role" style={{ color: "red" }}>
                                  Available Date:{" "}
                                  {moment(dev.availabledate).format(
                                    "YYYY-MM-DD"
                                  )}
                                </p>
                              ) : (
                                <p className="role" style={{ color: "red" }}>
                                  No date available
                                </p>
                              )}
                            </>
                          )}
                        </Col>
                      </li>
                    );
                  })
                : "No data"}
            </ol>
          </div>
        </Pagination1>
      </Pega>
    );
  };
  useEffect(() => {
    per();
    setLen(rep.length);
  }, [token]);
  const handleNextbtn = () => {
    setCurrentPage(currentPage + 1);
    if (currentPage + 1 > maxPageNumberLimit) {
      setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };
  const handlePrevbtn = () => {
    setCurrentPage(currentPage - 1);
    if ((currentPage - 1) % pageNumberLimit == 0) {
      setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };
  const per = () => {
    const kk = mis !== "default" ? mis : "";
    const bd = bdg !== "default" ? bdg : "";
    const ps = pri !== "default" ? pri : "";
    const ss = sec !== "default" ? sec : "";
    const tmz = tz !== "default" ? tz : "";
    const avb = avail !== "default" ? avail : "";
    const Prefered = pref !== "default" ? pref : "";

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        project_location: kk,
        project_name: title,
        budget: bd,
        primaryskill: ps,
        secondaryskill: ss,
        timezone: tmz,
        availability: avb,
        workhours: Prefered,
        about: mr,
        partner_code: user.data[0].partner_code,
      }),
    };

    fetch(
      process.env.REACT_APP_PARTNER_PPODPARTNERGUESTFINDTALENT,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          return response.json().then((errorData) => {
            throw new Error(errorData.message || "Something went wrong");
          });
        }
        return response.json();
      })
      .then((json) => {
        setRep(json.data);
        setCandidatedata(json.data);
        setFilterdata(json.data);
        setSearchApiData(json.data);
      })
      .catch((error) => {
        console.error("Error:", error);
        // Display the error message to the user, you can set this in the state and show it in your UI
        alert(`Error: ${error.message}`);
      });
  };

  const handlefilter = (e) => {
    if (e.target.value == "") {
      setRep(searchApiData);
    } else {
      const filterResult = searchApiData.filter(
        (item) =>
          item.firstName.toLowerCase().includes(e.target.value.toLowerCase()) ||
          item.primaryskill
            .toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          item.secondaryskill
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
      );
      if (filterResult.length > 0) {
        setRep(filterResult);
      } else {
        setRep([{ name: "No Data" }]);
      }
    }

    setSearchfilter(e.target.value);
  };

  return (
    <Browsecontainer>
      <div>
        <Heading>{intl.formatMessage({ id: "browseTalent" })}</Heading>

        <br></br>

        <Main>
          {/* <div className='center'> */}
          <Table>
            <div className="singlecontainer">
              <Singlecontainer>
                <td className="mis">
                  <SearchTag>
                    <SearchOutlinedIcon
                      style={{
                        marginTop: "15px",
                        marginLeft: "20px",
                        margin: "auto",
                        color: "#7E7E7E",
                      }}
                    />
                    <input
                      className="jobsearch"
                      type="text"
                      placeholder={intl.formatMessage({ id: "keyword" })}
                      value={searchfilter}
                      onChange={(e) => handlefilter(e)}
                    />
                  </SearchTag>
                </td>

                <td>
                  {" "}
                  <Clear
                    onClick={() => {
                      resetInputField();
                      resetJobField();
                      resetBudgetField();
                      resetPrimaryField();
                      resetSecondaryField();
                      resetTimezoneField();
                      resetPreferField();
                      resetAvailableField();
                      resetTitleField();
                    }}
                  >
                    {intl.formatMessage({ id: "clear" })}
                  </Clear>{" "}
                </td>
                <Search>
                  <td>
                    <button
                      style={{
                        marginTop: "10px",
                        width: "10rem",
                        height: "2.6rem",
                        color: "white",
                        border: "1px solid  #1DBF73",
                        borderRadius: "5px",
                        cursor: "pointer",
                        backgroundColor: "#1DBF73",
                        boxShadow: "1px 6px 9px #00000029",
                        fontFamily: "Poppins,Regular",
                        fontWeight: "500",
                      }}
                      onClick={() => per()}
                    >
                      Search
                    </button>
                  </td>
                </Search>
              </Singlecontainer>
            </div>

            <Row>
              {/* <tr> */}
              <Row1>
                <td>
                  <select
                    className="options"
                    value={pri}
                    onChange={((val) => setPri(val.target.value), primaryfield)}
                  >
                    <option value="default">Primary Skill</option>
                    <option value="html" style={{ color: "black" }}>
                      {" "}
                      html
                    </option>
                    <option value="css" style={{ color: "black" }}>
                      {" "}
                      CSS
                    </option>
                    <option value="JavaScript" style={{ color: "black" }}>
                      JavaScript
                    </option>
                    <option
                      value="Adobe Illustrator"
                      style={{ color: "black" }}
                    >
                      {" "}
                      Adobe Illustrator
                    </option>
                    <option value="ReactJS" style={{ color: "black" }}>
                      {" "}
                      ReactJS
                    </option>
                    <option value="AngularJS" style={{ color: "black" }}>
                      {" "}
                      AngularJS
                    </option>
                    <option value="Java" style={{ color: "black" }}>
                      {" "}
                      Java
                    </option>
                    <option value="Node.js" style={{ color: "black" }}>
                      Node.js
                    </option>
                    <option value="Vue" style={{ color: "black" }}>
                      {" "}
                      Vue
                    </option>
                    <option value="Next" style={{ color: "black" }}>
                      {" "}
                      Next
                    </option>
                    <option value="Typescript" style={{ color: "black" }}>
                      {" "}
                      Typescript
                    </option>
                    <option value="Gatsby" style={{ color: "black" }}>
                      {" "}
                      Gatsby
                    </option>
                    <option value="Three.js" style={{ color: "black" }}>
                      Three.js
                    </option>
                    <option value="Tableau" style={{ color: "black" }}>
                      Tableau
                    </option>
                    <option value="Python" style={{ color: "black" }}>
                      Python
                    </option>

                    <option
                      value="Quality Assurance"
                      style={{ color: "black" }}
                    >
                      Quality Assurance
                    </option>
                    <option
                      value="IOS Development (Swift/Objective C)"
                      style={{ color: "black" }}
                    >
                      IOS Development (Swift/Objective C)
                    </option>
                    <option
                      value="Android Development (Java/Kotlin)"
                      style={{ color: "black" }}
                    >
                      Android Development (Java/Kotlin)
                    </option>
                    <option value="Devops" style={{ color: "black" }}>
                      Devops
                    </option>
                    <option
                      value="Cloud (AWS/Azure/GCP)"
                      style={{ color: "black" }}
                    >
                      Cloud (AWS/Azure/GCP)
                    </option>
                  </select>
                </td>
                <td>
                  <select
                    className="options"
                    value={sec}
                    onChange={
                      ((val) => setSec(val.target.value), secondaryfield)
                    }
                  >
                    <option value="default">Secondary Skill </option>
                    <option style={{ color: "black" }}>Python</option>
                    <option value="Java" style={{ color: "black" }}>
                      Java
                    </option>
                    <option value="PHP" style={{ color: "black" }}>
                      PHP
                    </option>
                    <option value="SQL" style={{ color: "black" }}>
                      SQL
                    </option>
                    <option value="Git" style={{ color: "black" }}>
                      Git
                    </option>
                    <option value="c++" style={{ color: "black" }}>
                      c++
                    </option>
                    <option value="Perl" style={{ color: "black" }}>
                      Perl
                    </option>
                    <option value="AngularJS" style={{ color: "black" }}>
                      {" "}
                      AngularJS
                    </option>

                    <option value="Kotlin" style={{ color: "black" }}>
                      Kotlin
                    </option>
                    <option value="AWS" style={{ color: "black" }}>
                      AWS
                    </option>
                    <option value="NodeJs" style={{ color: "black" }}>
                      NodeJs
                    </option>

                    <option value="Azure" style={{ color: "black" }}>
                      Azure
                    </option>
                  </select>
                </td>
              </Row1>
              <Row2>
                <td>
                  <select
                    className="options"
                    value={avail}
                    onChange={((val) => setAvail(val.target.value), available)}
                  >
                    <option value="default">Availability</option>
                    <option value="Part time">Part Time</option>
                    <option value="Full time">Full Time</option>
                  </select>
                </td>
                <td>
                  <select
                    className="options"
                    value={tz}
                    onChange={((val) => setTz(val.target.value), timezonefield)}
                  >
                    <option value="default">Time Zone</option>
                    <option value="indian_standard_time">Indian Standard Time</option>
<option value="eastern_standard_time">Eastern Standard Time</option>
<option value="central_standard_time">Central Standard Time</option>
<option value="mountain_standard_time">Mountain Standard Time</option>
<option value="pacific_standard_time">Pacific Standard Time</option>
<option value="greenwich_mean_time">Greenwich Mean Time</option>
<option value="central_european_time">Central European Time</option>
<option value="western_european_time">Western European Time</option>
<option value="australian_eastern_standard_time">Australian Eastern Standard Time</option>
<option value="australian_central_standard_time">Australian Central Standard Time</option>
<option value="australian_western_standard_time">Australian Western Standard Time</option>
<option value="new_york_time">New York Time (Eastern Daylight Time)</option>
<option value="los_angeles_time">Los Angeles Time (Pacific Daylight Time)</option>
<option value="london_time">London Time (British Summer Time)</option>
<option value="paris_time">Paris Time (Central European Summer Time)</option>
<option value="tokyo_time">Tokyo Time (Japan Standard Time)</option>
<option value="sydney_time">Sydney Time (Australian Eastern Daylight Time)</option>
<option value="dubai_time">Dubai Time (Gulf Standard Time)</option>
<option value="moscow_time">Moscow Time (Moscow Standard Time)</option>
<option value="brazil_time">Brazil Time (Brasilia Time)</option>
<option value="chicago_time">Chicago Time (Central Daylight Time)</option>
<option value="houston_time">Houston Time (Central Daylight Time)</option>
<option value="cape_town_time">Cape Town Time (South Africa Standard Time)</option>

                  </select>
                </td>
              </Row2>
              <Row2>
                <td>
                  <select
                    className="options"
                    value={pref}
                    onChange={((val) => setPref(val.target.value), preferfield)}
                  >
                    <option value="default">Prefered Work Hours</option>
                    <option value="Morning Shift, 9am - 6pm">
                      Morning Shift, 9am - 6pm
                    </option>
                    <option value="Night Shift, 10pm - 7am">
                      {" "}
                      Night Shift, 10pm - 7am{" "}
                    </option>
                  </select>
                </td>
                <td>
                  <select
                    className="options"
                    value={title}
                    onChange={((val) => setTitle(val.target.value), jobtitle)}
                  >
                    <option value="default">Job title</option>
                    <option value="Software Engineer">Software Engineer</option>
                    <option value="Full Stack Developer">
                      Full Stack Developer
                    </option>
                    <option value="Java Developer">Java Developer</option>
                  </select>
                </td>
              </Row2>
              <tr>
                <td>
                  <select
                    className="options"
                    value={mis}
                    onChange={((val) => setMis(val.target.value), handleJob)}
                  >
                    <option value="default">Location</option>
                    <option value="usa">USA</option>
<option value="india">India</option>
<option value="canada">Canada</option>
<option value="australia">Australia</option>
<option value="uk">United Kingdom</option>
<option value="germany">Germany</option>
<option value="france">France</option>
<option value="china">China</option>
<option value="japan">Japan</option>
<option value="brazil">Brazil</option>
<option value="south_africa">South Africa</option>
<option value="mexico">Mexico</option>
<option value="italy">Italy</option>
<option value="spain">Spain</option>
<option value="russia">Russia</option>
<option value="south_korea">South Korea</option>
<option value="new_zealand">New Zealand</option>
<option value="singapore">Singapore</option>
<option value="uae">United Arab Emirates</option>
<option value="saudi_arabia">Saudi Arabia</option>
<option value="argentina">Argentina</option>
<option value="netherlands">Netherlands</option>
<option value="sweden">Sweden</option>
<option value="switzerland">Switzerland</option>
<option value="norway">Norway</option>
<option value="denmark">Denmark</option>
<option value="finland">Finland</option>
<option value="portugal">Portugal</option>
<option value="poland">Poland</option>
<option value="turkey">Turkey</option>
<option value="indonesia">Indonesia</option>
<option value="malaysia">Malaysia</option>
<option value="thailand">Thailand</option>
<option value="vietnam">Vietnam</option>
<option value="philippines">Philippines</option>
<option value="egypt">Egypt</option>
<option value="nigeria">Nigeria</option>
<option value="kenya">Kenya</option>
<option value="ghana">Ghana</option>
<option value="colombia">Colombia</option>
<option value="chile">Chile</option>
<option value="peru">Peru</option>
<option value="venezuela">Venezuela</option>
<option value="pakistan">Pakistan</option>
<option value="bangladesh">Bangladesh</option>
<option value="sri_lanka">Sri Lanka</option>
<option value="nepal">Nepal</option>
<option value="bhutan">Bhutan</option>
<option value="maldives">Maldives</option>
<option value="iceland">Iceland</option>
<option value="luxembourg">Luxembourg</option>
<option value="qatar">Qatar</option>
<option value="oman">Oman</option>
<option value="kuwait">Kuwait</option>
<option value="bahrain">Bahrain</option>
<option value="iraq">Iraq</option>
<option value="iran">Iran</option>
<option value="afghanistan">Afghanistan</option>
<option value="israel">Israel</option>
<option value="jordan">Jordan</option>
<option value="lebanon">Lebanon</option>
<option value="morocco">Morocco</option>
<option value="algeria">Algeria</option>
<option value="tunisia">Tunisia</option>
<option value="libya">Libya</option>
<option value="ethiopia">Ethiopia</option>
<option value="tanzania">Tanzania</option>
<option value="uganda">Uganda</option>
<option value="zimbabwe">Zimbabwe</option>
<option value="zambia">Zambia</option>
<option value="mozambique">Mozambique</option>
<option value="botswana">Botswana</option>
<option value="namibia">Namibia</option>
<option value="angola">Angola</option>
<option value="cuba">Cuba</option>
<option value="jamaica">Jamaica</option>
<option value="haiti">Haiti</option>
<option value="dominican_republic">Dominican Republic</option>
<option value="trinidad_tobago">Trinidad and Tobago</option>
<option value="bahamas">Bahamas</option>
<option value="barbados">Barbados</option>
<option value="panama">Panama</option>
<option value="costa_rica">Costa Rica</option>
<option value="honduras">Honduras</option>
<option value="guatemala">Guatemala</option>
<option value="el_salvador">El Salvador</option>
<option value="nicaragua">Nicaragua</option>
<option value="belize">Belize</option>
<option value="paraguay">Paraguay</option>
<option value="uruguay">Uruguay</option>
<option value="bolivia">Bolivia</option>
<option value="ecuador">Ecuador</option>
<option value="guinea">Guinea</option>
<option value="ivory_coast">Ivory Coast</option>
<option value="cameroon">Cameroon</option>
<option value="senegal">Senegal</option>
<option value="sudan">Sudan</option>
<option value="south_sudan">South Sudan</option>
<option value="congo">Congo</option>
<option value="madagascar">Madagascar</option>
<option value="malawi">Malawi</option>
<option value="sierra_leone">Sierra Leone</option>
<option value="chad">Chad</option>
<option value="mali">Mali</option>
<option value="burkina_faso">Burkina Faso</option>
<option value="rwanda">Rwanda</option>
<option value="burundi">Burundi</option>
<option value="lesotho">Lesotho</option>
<option value="eswatini">Eswatini</option>

                  </select>
                </td>
              </tr>
              <tr></tr>
              {/* </tr> */}
              <tr>
                {" "}
                <td>
                  <button
                    className="optionsaplly"
                    style={{
                      width: "10rem",
                      height: "2rem",
                      color: "white",
                      border: "none",
                      borderRadius: "5px",
                      cursor: "pointer",
                      backgroundColor: "#1DBF73",
                      boxShadow: "1px 6px 9px #00000029",
                      fontFamily: "Poppins,Regular",
                      fontWeight: "500",
                    }}
                    onClick={() => per()}
                  >
                    {intl.formatMessage({ id: "applyFilters" })}
                  </button>
                </td>
              </tr>
            </Row>
          </Table>
        </Main>

        {renderData(currentItems)}
      </div>
    </Browsecontainer>
  );
};
const Browsecontainer = styled.div`
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    margin: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`;
const Main = styled.tr`
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    width: 100%;
  }
`;
const Table = styled.table`
  margin-left: 5rem;
  background-color: green;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    width: 20rem;
    height: max-content;
  }
`;
const Heading = styled.div`
  margin-top: 9rem;
  font-size: 1.625rem;
  margin-left: 5rem;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    margin: auto;
    width: 70%;
    margin-top: 10rem;
    padding-bottom: 2rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
const Para = styled.p`
  margin-left: 5rem;

  color: #a2a2a2;
  font-size: 1.125rem;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    margin: auto;
    width: 20rem;

    padding-bottom: 2rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
const Singlecontainer = styled.tr`
  display: flex;
  flex-direction: row;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: auto;
    width: 20rem;
  }
`;
const Row = styled.div`
  background-color: white;
  height: max-content;
  padding-bottom: 1rem;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`;
const Input = styled.input`
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    border: none;
    width: 100%;
    height: 3rem;
    align-items: center;
    // display:flex;
    // flex-direction:row;
  }
`;
const SearchTag = styled.td`
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    width: 20rem;
    margin: auto;
    border-bottom: 1px solid #a2a2a2;
  }
`;

const Clear = styled.p`
  margin-top: 20px;
  width: 6rem;
  margin-left: 31rem;
  cursor: pointer;
  font-weight: 600;
  font-size: 18px;
  font-family: Poppins, Regular;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-left: auto;
  }
`;
const Search = styled.td`
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    margin: auto;
    width: 100%;
    align-items: center;
    flex-direction: column;
    margin-bottom: 20px;
  }
`;
const Row1 = styled.tr`
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    // margin:auto;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`;
const Row2 = styled.tr`
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    // margin:auto;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`;

const Pagination1 = styled.div`
  height: max-content;
  margin-left: 5rem;
  width: 83.5rem;

  background-color: white;

  margin-top: 3rem;
  border: 1px solid #efefef;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    margin: auto;
    width: 20rem;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
  }
`;
const Pega = styled.div`
  // display:flex;
  // justify-content:center;
  // align-items:center;
  // align-self:center;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 100%;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
const Numbers = styled.li`
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 7rem;
  }
`;
const Num = styled.ol`
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    //   align-items:center;
    // justify-content:center;
    flex-direction: column;
    marginleft: -3rem;
    width: 70%;
  }
`;
const Num1 = styled.p`
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    // display:flex;
    margin: auto;
    width: 100%;
  }
`;
const Num2 = styled.li`
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    margin: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
`;
const Col = styled.div`
  margin-top: -0.2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    margin: auto;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`;
const SelectField = styled.select`
  border: 1px solid #cccccc;
  border-radius: 5px;
  width: 20rem;
  margin-left: 1rem;
  height: 3.1rem;
  font-weight: 600;
  color: grey;
  font-family: Poppins, Medium;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 15rem;
  }
`;
export default FindTalentPartnerGuest;
