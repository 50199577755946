import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import styled from 'styled-components';
import { useEffect ,useState} from 'react';
import axios from 'axios';
import './stylereact.css';
import { useIntl } from 'react-intl';

import { useAuthToken } from "../TokenContext";
const PodAssist = (props) => {
  const [inputText, setInputText] = useState('');
  const [jiraItemData, setJiraItemData] = useState([]);
  const [storyDescriptionData, setStoryDescriptionData] = useState([]);
  const [testCasesData, setTestCasesData] = useState([]);
  const [editStoryDescription, setEditStoryDescription] = useState(false);
  const [editJiraItem, setEditJiraItem] = useState(false);
  const [editTestCases, setEditTestCases] = useState(false);
  const [availableStories, setAvailableStories] = useState([]);
  const [selectedStory, setSelectedStory] = useState('');
  const [isValid, setIsValid] = useState(true); // State to track validation
  
  const [isLoadingJira, setIsLoadingJira] = useState(false);
  const [isLoadingStoryDescription, setIsLoadingStoryDescription] = useState(false);
  const [isLoadingTestCases, setIsLoadingTestCases] = useState(false);

  const [errorMessage, setErrorMessage] = useState(null);
  const intl = useIntl();

  const token = useAuthToken();

  const handleInputChange = (e) => {
    setInputText(e.target.value);
    setIsValid(!!e.target.value); 
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    

    setIsLoadingJira(true);
    setIsLoadingStoryDescription(true);
    setIsLoadingTestCases(true);
    setErrorMessage(null);

   
    const headers = {
      'Authorization' : token, // Replace with your actual token
      'Content-Type': 'application/json', // You can add more headers as needed
    };
    try {
      const payload = { story_description: inputText };
      
      const responseJira = await axios.post(
        process.env.REACT_APP_CLIENT_JIRAAUTOMATE,
        payload,
        { headers: headers }
      );
      setJiraItemData(responseJira.data);
      setIsLoadingJira(false);

      const responseStory = await axios.post(
        process.env.REACT_APP_CLIENT_JIRAAUTOMATESTORYDESCRIPTION,
        payload,
        { headers: headers }
      );
      setStoryDescriptionData(responseStory.data);
      setIsLoadingStoryDescription(false);

      const responseTestCases = await axios.post(
        process.env.REACT_APP_CLIENT_JIRAAUTOMATETESTCASE,
        payload,
        { headers: headers }
      );
      setTestCasesData(responseTestCases.data);
      setIsLoadingTestCases(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoadingJira(false);
      setIsLoadingStoryDescription(false);
      setIsLoadingTestCases(false);


   
      return; // Add this line to prevent the rest of the function from executing in case of an error
    }
  };

    // Toggle edit modes
    const toggleEditStoryDescription = () => {
      setEditStoryDescription(!editStoryDescription);
    };
  
    const toggleEditJiraItem = () => {
      setEditJiraItem(!editJiraItem);
    };
  
    const toggleEditTestCases = () => {
      setEditTestCases(!editTestCases);
    };

    useEffect(() => {
      // Fetch the list of stories from your API
    
      const fetchStories = async () => {
        const headers = {
          'Authorization' : token, // Replace with your actual token
          'Content-Type': 'application/json', // You can add more headers as needed
        };
        try {
          //https://2r7agy3rwe.execute-api.us-east-1.amazonaws.com/default/jira_story_list
          const response = await axios.get(process.env.REACT_APP_CLIENT_JIRASTROYLIST, {headers:headers});
          const storyData = JSON.parse(response.data.body);
          const stories = Object.values(storyData); // Extract story descriptions
          setAvailableStories(stories);
        } catch (error) {
          console.error('Error fetching stories:', error);
        }
      };
  
      fetchStories();
    }, [token]);

    const handleSelectChange = (selectedOption) => {
      setSelectedStory(selectedOption);
      setIsValid(!!selectedOption); 
    };
    
   
    

    const handleSaveData = async () => {
      try {
        if (!selectedStory) {
          alert('Please select a story before saving.');
          return;
        }
        if (!inputText) {
          alert('Please enter a Story Description.');
          return;
        }
        
        // Prepare the payload with all the data
        const payload = {
          key: 'GLOB', // Add your key data here
          summary: selectedStory.value,
          description: inputText,
          Acceptance_criteria: jiraItemData,
          test_case: testCasesData,
          story_description: storyDescriptionData,
        };
    
        const headers = {
          'Authorization' : token, // Replace with your actual token
          'Content-Type': 'application/json', // You can add more headers as needed
        };
        // Send the data to your save API
        //https://wqlik1klq3.execute-api.us-east-1.amazonaws.com/default/jira_add_items
        const response = await axios.post(
          process.env.REACT_APP_CLIENT_JIRAADDITEMS,
          payload,{headers:headers}
        );
    
        alert('Saved Successfully');
        window.location.reload();
        
      } catch (error) {
        console.error('Error saving data:', error);
      }
    };
    
   
  

    const getButtonText = () => {
      return editStoryDescription ? intl.formatMessage({ id: 'cancel' }) : intl.formatMessage({ id: 'edit' });
    };
   
    const jiraButton = editJiraItem ? (
      <Button2 onClick={toggleEditJiraItem}>{intl.formatMessage({ id: 'cancel' })}</Button2>
    ) : (
      <Button2 onClick={toggleEditJiraItem}>{intl.formatMessage({ id: 'edit' })}</Button2>
    );

    const testCasesButton = editTestCases ? (
      <Button2 onClick={toggleEditTestCases}>{intl.formatMessage({ id: 'cancel' })}</Button2>
    ) : (
      <Button2 onClick={toggleEditTestCases}>{intl.formatMessage({ id: 'edit' })}</Button2>
    );


    const handleSendResults = async () => {
      try {
        const payload = {
          manager_id: 1618,
          latest_response: testCasesData,
          user_input: inputText
        };
  
        const headers = {
          'Authorization' : token,
          'Content-Type': 'application/json',
        };
  
        const apiUrl = 'https://q0aippwzde.execute-api.us-east-1.amazonaws.com/prod/jira_story_create_manager'
        ;
        
        const response = await axios.post(apiUrl, payload, {headers:headers});
  
        alert('Results sent successfully');
      } catch (error) {
        console.error('Error sending results:', error);
        alert('Failed to send results. Please try again.');
      }
    }
  return (
    <Div>
      <TitleText>{intl.formatMessage({ id: 'podAssist' })}</TitleText>
      <Postfield>
        <form onSubmit={handleSubmit}>
          <Input type="text" placeholder={intl.formatMessage({ id: 'enterStoryDescription' })} value={inputText} onChange={handleInputChange} required />
          {!isValid && <p style={{ color: 'red' }}>{intl.formatMessage({ id: 'pleaseEnterStoryDescription' })} .</p>}
          <Button2 type="submit">{intl.formatMessage({ id: 'submit' })}</Button2>
        </form>
      </Postfield>
      {/* <Select
  value={selectedStory}
  onChange={handleSelectChange}
  options={availableStories.map((story) => ({ label: story, value: story }))}
  isClearable={false}
  isSearchable
  isCreatable  // Enable option creation
  placeholder="Select or type a story..."
  required  // Mark the field as required
/> */}

      <TableContainer sx={{marginTop:"2rem"}} component={Paper}>
   <Table
            component={Paper}
            sx={{ maxWidth: 700,height:"50rem", fontFamily: 'Poppins,Medium', borderRadius: '10px' }}
            aria-label="simple table">
    <TableHead     sx={{
                backgroundColor: '#00B4D8',
                '& td, & th': {
                  fontFamily: 'Poppins,Medium',
                  fontSize: '15px',
                  color: 'white',
                  fontWeight: '600',
                },
              }}>
      <TableRow>
        <TableCell>{intl.formatMessage({ id: 'storyDescription' })}</TableCell>
        <TableCell>{intl.formatMessage({ id: 'acceptanceCriteria' })}</TableCell>
        <TableCell>{intl.formatMessage({ id: 'testCases' })}</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
  <TableRow>
    <TableCell>
      <div style={{ width: '20rem', height: '90%' }}>
      {isLoadingStoryDescription ? (
  <p>{intl.formatMessage({ id: 'loadingStoryDescription' })}...</p>
) : (
  editStoryDescription && (
    <Input
      type="text"
      value={storyDescriptionData}
      onChange={(e) => setStoryDescriptionData(e.target.value)}
      style={{ width: '100%', height: '100%' }}
    />
  )
)}
{!isLoadingStoryDescription && !editStoryDescription && (
  <p>{storyDescriptionData}</p>
)}

        <div>
          {isLoadingStoryDescription ? null : (
          <Button2 onClick={toggleEditStoryDescription}>{getButtonText()}</Button2>
          )}
        </div>
      </div>
    </TableCell>
    <TableCell>
      <div style={{ width: '20rem', height: '90%' }}>
        {isLoadingJira ? (
          <p>{intl.formatMessage({ id: 'loadingAcceptanceCriteria' })}...</p>
        ) : (
          editJiraItem ? (
            <Input
              type="text"
              value={jiraItemData}
              onChange={(e) => setJiraItemData(e.target.value)}
              style={{ width: '100%', height: '100%' }}
            />
          ) : (
            <p>{jiraItemData}</p>
          )
        )}
        <div>
        {!isLoadingJira && jiraButton}
        </div>
      </div>
    </TableCell>
    <TableCell>
      <div style={{ width: '20rem', height: '90%' }}>
        {isLoadingTestCases ? (
          <p>{intl.formatMessage({ id: 'loadingTestCases' })}...</p>
        ) : (
          editTestCases ? (
            <Input
              type="text"
              value={testCasesData}
              onChange={(e) => setTestCasesData(e.target.value)}
              style={{ width: '100%', height: '100%' }}
            />
          ) : (
            <p>{testCasesData}</p>
          )
        )}
        <div>
        {!isLoadingTestCases && testCasesButton}
        </div>
      </div>
    </TableCell>
  </TableRow>
</TableBody>


  </Table>
</TableContainer>

{errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
<Button3 onClick={handleSendResults}>Send Results</Button3>
    
    </Div>
  );
};

export default PodAssist;

// ... (your styled components)

const Button3 = styled.button`
  display:flex;
  justify-content:center; 
  align-items:center;
  margin:auto;
  margin-top: 1rem;
  background-color: #1DBF73;
  color: white;
  font-size: 18px;
  border-radius: 10px;
  height: 40px;
  width: 10rem;
  border: none;
  font-weight: 600;
  font-family: Poppins, Medium;
  box-shadow: 1px 3px 6px #00000029;
`;

const Div =styled.div`
display:inline;
justify-content:center;
align-items:center;
margin:auto;
// margin-left: 5rem;
margin-top: 10rem;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    flex-direction: column;

    margin: auto;
  width: 20rem;
  }

`;
const CardContent = styled.div`
  margin: 0.4rem;
  width: 40rem;
  padding:1rem;
  
  height:max-height;

  border-radius:15px;
  font-family:Poppins,Medium;
  background-color:#F2F4F5;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 20rem;
    margin:auto;
  }
`;
const Input=styled.textarea`
font-family:Poppins,Medium;
width: 40rem;
height:100px;
border-radius: 10px;
border: 2px solid #B7B7B7;
padding-left:25px;
font-weight:600;
    @media screen and (min-width:320px) and (max-width:1080px){
        width:12rem;
        height:max-height;
        }
`;
const ButtonEdit =styled.button`

  text-decoration: none  !important;

  background:none;
 
 
  border: none  !important;
  margin-left: auto;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    flex-direction: column;

    margin: auto;
    margin-top:2rem;
  }

`;
const ButtonAdd =styled.button`
display: flex;
    flex-direction: row;
justify-content: center;
align-items: center;

    margin: auto;
  text-decoration: none  !important;
  border-radius: 10px;
 font-weight:600;
 background:none;
  color: #00B4D8;
  border: none  !important;

  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    flex-direction: column;

    margin: auto;
    margin-top:2rem;
  }

`;

const Para =styled.p`
width: max-width;
float: right;
padding: 5px;
color: white;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
   margin: auto;
  }

`;
const Button=styled.button`
display: flex;
    flex-direction: column;
justify-content: center;
align-items: center;
    margin: auto;
margin-top:1rem;

border:none;
border-radius: 10px;
background-color:#1DBF73;
box-shadow: 1px 3px 6px #00000029;
width:5rem;
height:2rem;
font-size:18px;
font-weight:600;
color:white;

@media screen and (min-width:320px) and (max-width:1080px){
  width:5rem;
 
  
 }
`;
const Postfield=styled.div`
font-family:Poppins,Medium;
display:flex;
justify-content:center;
align-items:center;
margin:auto;
padding:10px
`;
const Buttondelete =styled.button`

  text-decoration: none  !important;
 
  background:none  !important;

  color: black;
  border: none  !important;
  margin-left: auto;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    flex-direction: column;

    margin: auto;
    margin-top:2rem;
  }

`;
const Buttonpost =styled.button`

  text-decoration: none  !important;
  border-radius: 10px;
  background-color:seagreen;
  width: 4rem;
  height: 2rem;
  color: white;
  border: none  !important;
  margin-left: auto;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    display: flex;
    flex-direction: column;

    margin: auto;
    margin-top:2rem;
  }

`;
const Button2 = styled.button`
display:flex;
justify-content:center;
align-items:center;
margin:auto;
font-family:Poppins,Medium;
    background-color: #00B4D8;
    color: white;
   font-size: 15px;
     border-radius: 50px;
     height: 30px;
        width:5rem;
        
border:none;
    font-weight: 600;
    font-family: Poppins,Medium;
    box-shadow: 1px 3px 6px #00000029;
    @media screen and (min-width:320px) and (max-width:1080px){
        width:10rem;
        height:max-height;
        margin:auto;
        }
`;

const Num2 = styled.li`
        @media screen and (min-width:320px) and (max-width:1080px){
          display:flex;
        margin:auto;
        flex-direction:column;
        align-items:center;
        justify-content:center;
        width:100%;
}
        `;
        const Numbers = styled.li`
        @media screen and (min-width:320px) and (max-width:1080px){
          display:flex;
        align-items:center;
        justify-content:center;
        width:7rem;
}
        `;
        const TitleText = styled.h4`
  font-weight: 600;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
   margin-top:2rem;
  }

`;
const Span=styled.span`
cursor:pointer;
`;

const SelectField = styled.select`
 
  border-radius: 5px;
  width: 9.5rem;
  height: 3rem;
  font-weight: 600;
  color: white;
  font-family: Poppins, Medium;
background-color:#00B4D8;
border:none;
  @media screen and (min-width: 320px) and (max-width: 1080px) {
    width: 15rem;
    margin: auto;
  }
`;