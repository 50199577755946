import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import star from './assets/star.png';
import { useSelector } from 'react-redux';
import { useAuthToken } from '../TokenContext';

const TIME_INTERVALS = [
  { label: 'year', seconds: 31536000 },
  { label: 'month', seconds: 2592000 },
  { label: 'day', seconds: 86400 },
  { label: 'hour', seconds: 3600 },
  { label: 'minute', seconds: 60 },
  { label: 'second', seconds: 1 }
];

const ReviewsAndRatingClient = () => {
  const { user } = useSelector(state => state.auth);
  const [reviews, setReviews] = useState([]);
  const token = useAuthToken();

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await axios.post(
          process.env.REACT_APP_CLIENT_GETCLIENTREVIEW,
          { clientID: user.data[0].emp_id },
          { headers: { Authorization: token, 'Content-Type': 'application/json' }}
        );
        setReviews(response.data.data === "There Are No Reviews." ? [] : response.data.data);
      } catch (err) {
        console.error("Error fetching reviews:", err);
      }
    };

    fetchReviews();
  }, [token, user.data]);

  const timeSince = (date) => {
    const seconds = Math.floor((Date.now() - new Date(date).getTime()) / 1000);
    const interval = TIME_INTERVALS.find(i => i.seconds < seconds);
    const count = Math.floor(seconds / interval.seconds);
    return `${count} ${interval.label}${count !== 1 ? 's' : ''} ago`;
  };

  return (
    <Container>
      <TitleText>Review & Rating</TitleText>
      <ReviewsContainer>
        <CardContent>
          {reviews.length > 0 ? (
            reviews.map((review, index) => (
              <ReviewCard key={index}>
                <ReviewHeader>
                  <InfoContainer>
                    <Avatar src={review.profilePicture} alt="" />
                    <Info>
                      <ReviewName>
                        {review.podName}
                        <RatingContainer>
                          <StarIcon src={star} alt="rating" />
                          {review.rating}
                        </RatingContainer>
                      </ReviewName>
                      <TimeStamp>{timeSince(review.createdAt)}</TimeStamp>
                    </Info>
                  </InfoContainer>
                </ReviewHeader>
                <ReviewText>{review.review}</ReviewText>
              </ReviewCard>
            ))
          ) : (
            <NoReviews>There are no reviews.</NoReviews>
          )}
        </CardContent>
      </ReviewsContainer>
    </Container>
  );
};

const Container = styled.div`
  display: inline;
  margin-top: 6rem;
  margin-left: 6.5rem;

  @media screen and (max-width: 1080px) {
    display: flex;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 25rem;
  }
`;

const ReviewsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;

  @media screen and (max-width: 1080px) {
    width: 100%;
    height: max-content;
    margin: auto;
    flex-direction: column;
  }
`;

const CardContent = styled.div`
  margin: 0.4rem;
  width: 40rem;
  padding: 1rem;
  height: max-content;
  border-radius: 10px;
  font-family: Poppins, sans-serif;
  background-color: #F2F4F5;

  @media screen and (max-width: 1080px) {
    width: 22rem;
    margin: auto;
  }
`;

const ReviewCard = styled.div`
  border-bottom: 1px solid #CECECE;
  padding: 1rem;
`;

const ReviewHeader = styled.div`
  display: flex;
  margin-bottom: 0.8rem;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Avatar = styled.img`
  width: 50px;
  height: 50px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 15px;
`;

const ReviewName = styled.h6`
  font-weight: 600;
  margin: 0;
`;

const RatingContainer = styled.span`
  margin-left: 1.5rem;
  font-size: 13px;
  font-weight: bold;
`;

const StarIcon = styled.img`
  width: 10px;
  height: 10px;
  margin-right: 4px;
`;

const TimeStamp = styled.span`
  font-size: 13px;
  font-weight: 600;
`;

const ReviewText = styled.p`
  font-weight: 600;
  width: 15rem;
  overflow-wrap: anywhere;

  @media screen and (max-width: 1080px) {
    width: 17rem;
  }
`;

const TitleText = styled.h4`
  font-weight: 600;
  
  @media screen and (max-width: 1080px) {
    margin-top: 2rem;
  }
`;

const NoReviews = styled.div`
  padding: 1rem;
  text-align: center;
`;

export default ReviewsAndRatingClient;